import React, { useState, useEffect } from "react";
import "styled-components";
import "../hojas-de-estilo/style.css";
import {
  getVehicleSearch,
  addVehicleReceiving,
} from "../Servicios/SolicitudesService";
import {
  List,
  Button,
  Col,
  Row,
  Spin,
  Divider,
  Input,
  Tabs, Modal,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import VehicleCardInfo from "./VehicleCardInfo";
import {VehiculoEstadoInspeccionEnum} from "../shared/enums/vehiculo-estado-inspeccion.enum";
import {VehiculoEstadoEnum} from "../shared/enums/vehiculo-estado.enum";
import VehicleEvidenceList from "../Componentes/VehicleEvidenceList/VehicleEvidenceList";
import {getListFolderId} from "../Servicios/GoogleService";
import copy from "copy-to-clipboard";
import {errorMessage, successMessage} from "../utils/toast-message";
import Spinner from "../shared/spinner";
import {hasRol} from "../Servicios/AuthService";
import roles from "../utils/roles";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import VehiculoInspeccionForm from "../Componentes/VehiculoInspeccionForm/VehiculoInspeccionForm";
import useCurrentUser from "../Hooks/UserHook";
import moment from "moment/moment";
import SwitchTabButton from "../Componentes/SwitchTabButton/SwitchTabButton";
import UploadGoogleDrive from "../Componentes/UploadGoogleDrive/UploadGoogleDrive";
import { StringFormats } from "../utils/string-formats";
import { enviarEmail } from "../Servicios/SolicitudesService";
import { NotificacionEntregaVehiculoClienteEmail } from "../Resources/HtmlEmailBody/NotificacionEntregaVehiculoCliente.email";
import environment from "../environments/environment";
import {editCar} from "../Servicios/VehicleService";
import { uploadVehicleFile } from "../Servicios/VehicleService";
const { Search } = Input;


const TAB_FLORIDA = "1";
const TAB_CALIFORNIA = "2";

interface VehicleReceiptFilter {
  lote: string;
  estado: string;
  origen: string;
  estadoInspeccion: string;
}

const localStorageFilter = (key = undefined, isDate = false) => {
  if (!localStorage.getItem('vehicleReceipt:filter')) return undefined;
  if (!key) return JSON.parse(localStorage.getItem('vehicleReceipt:filter'));
  if (!JSON.parse(localStorage.getItem('vehicleReceipt:filter'))[key]) return undefined;

  if (isDate) {
    return new Date(JSON.parse(localStorage.getItem('vehicleReceipt:filter'))[key]);
  }

  return JSON.parse(localStorage.getItem('vehicleReceipt:filter'))[key];
}

const VehicleReceipts = () => {
  const pageSize = 50;
  const currentUser = useCurrentUser();

  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [vehiculo, setVehiculo] = useState(undefined);
  const [pageIndex, setPageIndex] = useState(1);
  const [countData, setCountData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [copyLoading, setCopyLoading] = useState(false);
  const [editEstadoInspeccion, setEditEstadoInspeccion] = useState(undefined);
  const [isModalOpenEvidence, setIsModalOpenEvidence] = useState(false);
  const [marcarRecibidoBodega, setMarcarRecibidoBodega] = useState(false);
  const [isModalOpenListEvidence, setIsModalOpenListEvidence] = useState(false);
  const [makeReceiptAndInspection, setMakeReceiptAndInspection] = useState(false);
  const [openVehiculoInspeccionForm, setOpenVehiculoInspeccionForm] = useState(false);
  const [stateVehicleForm, setStateVehicleForm] = useState({
    reciving: false,
    inspection: false,
    newVehicle: false,
  });
  // validar si puede ver un origen o ambos
  const canViewOnlyOneOrigen = hasRol(roles.CAOFFICE, roles.FLOFFICE, roles.RECIVING_CA, roles.RECIVING_FL);

  const [filters, setFilters] = useState({
    lote: localStorageFilter('lote') ?? '',
    estado: localStorageFilter('estado') ?? VehiculoEstadoEnum.noRecibido,
    origen: localStorageFilter('origen') ?? TAB_FLORIDA,
    estadoInspeccion: localStorageFilter('estadoInspeccion') ?? VehiculoEstadoInspeccionEnum.noInspeccionado,
  })

  const saveFilters = filters => {
    setFilters(filters);

    const localFilters = localStorageFilter();

    if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('vehicleReceipt:filter', JSON.stringify(filters));
    }
  }

  const loadMoreData = (pageIndex, pageSize) => {
    let lote = filters.lote;
    let estado = filters.estado;
    let origen = filters.origen;
    let estadoInspeccion = filters.estadoInspeccion;

    if (lote !== "") {
      estado = "";
      origen = "";
      estadoInspeccion = "";
    }

    setIsLoading(true);
    getVehicleSearch(pageIndex, pageSize, lote, estado, origen, '', estadoInspeccion)
      .then((itemData) => {
        setPageIndex(itemData.nextPage);
        setCountData(itemData.count);
        setData([...data, ...itemData.data]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const initVehicleList = async (pageIndex, pageSize, filters: VehicleReceiptFilter) => {
    let lote = filters.lote;
    let estado = filters.estado;
    let origen = filters.origen;
    let estadoInspeccion = filters.estadoInspeccion;

    if (lote !== "") {
      estado = "";
      origen = "";
      estadoInspeccion = "";
    }

    setIsLoading(true);
    const itemData = await getVehicleSearch(pageIndex, pageSize, lote, estado, origen, '', estadoInspeccion);
      console.log("itemData", itemData);
    setIsLoading(false);

    setCountData(itemData.count);
    setPageIndex(itemData.nextPage);
    setData([...itemData.data]);

    if (lote !== "" && itemData.data[0]) {
      let origen = "";
      if (hasRol(roles.FLOFFICE, roles.RECIVING_FL)) origen = TAB_FLORIDA;
      else if (hasRol(roles.CAOFFICE, roles.RECIVING_CA)) origen = TAB_CALIFORNIA;

      saveFilters({
        ...filters,
        origen: !canViewOnlyOneOrigen
          ? itemData.data[0].origen
          : origen,
        estado: itemData.data[0].estado,
        estadoInspeccion: itemData.data[0].estadoInspeccion
      });
    }
  }

  useEffect(() => {
    init().then();

    // validar que el recibo y la inspeccion se haga al mismo tiempo
    setMakeReceiptAndInspection(hasRol(roles.CAOFFICE, roles.RECIVING_CA));
  }, []);
  
  const init = async () => {
    let id = filters.origen;
    if (hasRol(roles.FLOFFICE, roles.RECIVING_FL)) id = TAB_FLORIDA;
    else if (hasRol(roles.CAOFFICE, roles.RECIVING_CA)) id = TAB_CALIFORNIA;

    const newFilters = {
      ...filters,
      origen: id
    }

    saveFilters(newFilters);
    await initVehicleList(1, 100, newFilters);
  }

  const onSave = async (values) => {
    console.log("values", values);
    // del values necesito sacar foto de titulo, frontal, dejarlo en otro objeto 
    let inputFotos = {
      fotoTitulo: values.fotoFile.titulo?.length > 0 ? values.fotoFile.titulo[0] : null,
      fotoFrontal: values.fotoFile.frontal?.length > 0 ? values.fotoFile.frontal[0] : null,
      fotoVin: values.fotoFile.vin?.length > 0 ? values.fotoFile.vin[0] : null,
    }

    delete values.fotoFile;

    const input = {
      ...values,
      origen: currentUser?.idubc,
      tituloRecibidoPor: values?.updateBy,
      estado: VehiculoEstadoEnum.recibidoEnBodega,
      estadoInspeccion: VehiculoEstadoInspeccionEnum.inspeccionado,
      recibidoSinTitulo: !values.titulo,
      inspeccionadoPor: values?.updateBy,
    };

    console.log("input", input);
    console.log("inputFotos", inputFotos);
    setIsLoading(true);
    const {data, status} = await addVehicleReceiving(input);
    console.log("data", data);
    const saveFile = await uploadVehicleFile(data.vehiculoId, data.lote, inputFotos.fotoTitulo, inputFotos.fotoFrontal, inputFotos.fotoVin);
    
    setIsLoading(false);
     

    if (data && status) {
      notifyReceiving();
      setVehiculo(undefined);
      setOpenVehiculoInspeccionForm(false);
      inputFotos = {};
      setStateVehicleForm({ reciving: false, inspection: false, newVehicle: false });
      await initVehicleList(1, 100, filters);
    } else {
      notifyNotReceiving();
    }
  };

  const onUpdate = async (values, quote) => {
    setIsLoading(true);
    let inputFotos = {
      fotoTitulo: values.fotoFile.titulo?.length > 0 ? values.fotoFile.titulo[0] : null,
      fotoFrontal: values.fotoFile.frontal?.length > 0 ? values.fotoFile.frontal[0] : null,
      fotoVin: values.fotoFile.vin?.length > 0 ? values.fotoFile.vin[0] : null,
    }

    delete values.fotoFile;

    const input = {
      ...values,
      fecha: marcarRecibidoBodega
        ? moment().format("YYYY-MM-DDTHH:mm:ss")
        : vehiculo.fecha,
      estado: marcarRecibidoBodega
          ? VehiculoEstadoEnum.recibidoEnBodega
          : vehiculo.estado,
      recibidoSinTitulo: marcarRecibidoBodega
        ? !values.titulo
        : vehiculo.recibidoSinTitulo,
      tituloRecibidoPor: marcarRecibidoBodega
        ? values.updateBy
        : vehiculo.tituloRecibidoPor,
      estadoInspeccion: makeReceiptAndInspection
        ? VehiculoEstadoInspeccionEnum.inspeccionado
        : editEstadoInspeccion
          ? editEstadoInspeccion
          : vehiculo.estadoInspeccion,
      inspeccionadoPor: makeReceiptAndInspection
        ? marcarRecibidoBodega
          ? values.updateBy
          : vehiculo.inspeccionadoPor
        : editEstadoInspeccion
          ? values.inspeccionadoPor
          : vehiculo.inspeccionadoPor
    }

    const {data, status} = await editCar(vehiculo.vehiculoId, input);
    await uploadVehicleFile(vehiculo.vehiculoId, vehiculo.lote, inputFotos.fotoTitulo, inputFotos.fotoFrontal, inputFotos.fotoVin);

    if (!data) {
      setIsLoading(false);
      return;
    }

    if (makeReceiptAndInspection || data.estadoInspeccion === VehiculoEstadoInspeccionEnum.noInspeccionado) {
      const element = await getListFolderId(data.lote);
      const copyLink = `https://drive.google.com/drive/folders/${element.data}`;

      const titulos = {
        trueTitle: `INGRESO DEL VEHÍCULO EN ${data?.origen === "1" ? "FLORIDA" : "CALIFORNIA"
          } ${data ? StringFormats.strDate(data.fecha) : ""} - LOTE: ${data?.lote
          } ${data?.vehiculoName} ${quote ? `ASESOR: ${quote?.creador}` : ""
          }`.toUpperCase(),
        falseTitle: `INGRESO DE VEHÍCULO **SIN TITULO** EN ${data?.origen === "1" ? "FLORIDA" : "CALIFORNIA"
          } ${data ? StringFormats.strDate(data.fecha) : ""} - LOTE: ${data?.lote
          } ${data?.vehiculoName} ${quote ? `ASESOR: ${quote?.creador}` : ""
          }`.toUpperCase(),
      };

      const item = await enviarEmail({
        to: [environment.emails.grua],
        body: NotificacionEntregaVehiculoClienteEmail(
          data,
          data.origen === "1" ? "FLORIDA" : "CALIFORNIA",
          (data.estado === VehiculoEstadoEnum.recibidoEnBodega) ? null : copyLink,
          quote
        ),
        subject: data.titulo ? titulos.trueTitle : titulos.falseTitle,
      });

      if (item && item.status) {
        successMessage("Correo enviado correctamente");
      } 
    }

    if (status) {
      notifyReceiving();

      setIsLoading(false);
      setVehiculo(undefined);
      setMarcarRecibidoBodega(false);
      setEditEstadoInspeccion(undefined);
      setOpenVehiculoInspeccionForm(false);
      setStateVehicleForm({ reciving: false, inspection: false, newVehicle: false });
      await initVehicleList(1, 100, filters);
    } else {
      setIsLoading(false);
    }
  };

  const notifyReceiving = () => successMessage("Datos guardados correctamente");
  const notifyNotReceiving = () => errorMessage("Ya se encuentra registrado el vehiculo");

  const handleChangeTab = async (key) => {
    const newFilters = {
      ...filters,
      origen: key,
    };

    setPageIndex(1);
    saveFilters(newFilters);

    await initVehicleList(1, pageSize, newFilters);
  }

  const handleChangeTabEstado = async (key) => {
    const newFilters = {
      ...filters,
      estado: key,
    };

    setPageIndex(1);
    saveFilters(newFilters);

    await initVehicleList(1, pageSize, newFilters);
  }

  const handleChangeTabEstadoInspeccion = async (key) => {
    const newFilters = {
      ...filters,
      estadoInspeccion: key,
    };

    setPageIndex(1);
    saveFilters(newFilters);

    await initVehicleList(1, pageSize, newFilters);
  }


  const handleOkListEvidence = () => {
    setVehiculo(undefined);
    setIsModalOpenListEvidence(false);
  };

  const handleCancelListEvidence = () => {
    setVehiculo(undefined);
    setIsModalOpenListEvidence(false);
  };

  const handleViewEvidence = (record) => {
    setVehiculo(record);
    setIsModalOpenListEvidence(true);
  }

  const handleCopyLink = async (record) => {
    setCopyLoading(true);
    await getListFolderId(record.lote).then((element) => {
      if (element.data.length === 0) {
        errorMessage("No se ha subido evidencia");
        setCopyLoading(false);
      } else {
        copy(`https://drive.google.com/drive/folders/${element.data}`);
        successMessage(`Se ha copiado el link con exito!`);
        setCopyLoading(false);
      }
    });
  }

  const handleOnSearch = async (value) => {
    if (value && value !== '') {
      await initVehicleList(1, pageSize, filters);
    } else {
      handleCancelSearch();
    }
  }

  const handleCancelSearch = () => {
    const newFilters = {
      ...filters,
      lote: ''
    };

    saveFilters(newFilters);
    initVehicleList(1, pageSize, newFilters).then();
  }

  const itemsOrigen = [
    { key: TAB_FLORIDA, label: 'Florida' },
    { key: TAB_CALIFORNIA, label: 'California' }
  ];

  const itemsEstado = [
    { key: VehiculoEstadoEnum.noRecibido, label: 'No Recibidos' },
    { key: VehiculoEstadoEnum.recibidoEnBodega, label: 'Recibidos' }
  ];

  const itemsEstadoInspeccion = [
    { key: VehiculoEstadoInspeccionEnum.noInspeccionado, label: 'No Inspeccionados' },
    { key: VehiculoEstadoInspeccionEnum.inspeccionado, label: 'Inspeccionados' }
  ];

  const handleReceiveVehicle = vehicle => {
    setTitle(`RECIBIR ${vehicle?.vehiculoName}`);
    setVehiculo(vehicle);
    setMarcarRecibidoBodega(true);
    setOpenVehiculoInspeccionForm(true);
    setStateVehicleForm({ ...stateVehicleForm, reciving: true });
  }

  const handleInspectVehicle = vehicle => {
    setTitle(`INSPECCIONAR ${vehicle?.vehiculoName}`);
    setVehiculo(vehicle);
    setOpenVehiculoInspeccionForm(true);
    setEditEstadoInspeccion(VehiculoEstadoInspeccionEnum.inspeccionado);
    setStateVehicleForm({ ...stateVehicleForm, inspection: true });
  }

  const handleUploadEvidence = (record) => {
    console.log("record", record);
    setIsModalOpenEvidence(true);
    setVehiculo(record);
  }

  const handleOkEvidence = () => {
    setVehiculo(undefined);
    setIsModalOpenEvidence(false);
  };

  const handleCancelEvidence = () => {
    setVehiculo(undefined);
    setIsModalOpenEvidence(false);
  };

  return (
    <div>
      <h4>Recepción de Vehículos</h4>
      <hr/>
      <div style={{width: "100%"}}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={18} lg={18} xl={16}>
            <Search
              placeholder="Buscar Vehiculo por Lote"
              allowClear
              onTouchCancel={handleCancelSearch}
              enterButton="Buscar"
              size="large"
              value={filters.lote}
              onSearch={handleOnSearch}
              onChange={(event) => saveFilters({...filters, lote: event.target.value})}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={8}>
            <Button
              block
              type="primary"
              size="large"
              onClick={() => {
                setOpenVehiculoInspeccionForm(true);
                setTitle("Agregar Vehículo");
                setStateVehicleForm({ ...stateVehicleForm, newVehicle: true });
              }}
            >
              Agregar Vehículo
            </Button>
          </Col>
        </Row>
        <br/>
        <ShowWhen show={!canViewOnlyOneOrigen}>
          <Tabs activeKey={filters.origen} onChange={handleChangeTab} items={itemsOrigen}/>
        </ShowWhen>

        <Row gutter={16}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <SwitchTabButton tabs={itemsEstado}
                             selected={filters.estado}
                             onSelect={handleChangeTabEstado}/>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <SwitchTabButton tabs={itemsEstadoInspeccion}
                             selected={filters.estadoInspeccion}
                             onSelect={handleChangeTabEstadoInspeccion}/>
          </Col>
        </Row>

        <ShowWhen show={makeReceiptAndInspection}>
          <div className="alert alert-info">
            NOTA: Al momento de <span className="fw-bold">recibir</span> el vehículo tambien se
            realizara la <span className="fw-bold">inspección</span> del mismo
          </div>
        </ShowWhen>
        <Spin spinning={isLoading}>
          <div
            id="scrollableDiv"
            className="w-100 p-3 overflow-auto"
            style={{
              height: "calc(100vh - 200px)",
              border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
          >
            <InfiniteScroll
              dataLength={data.length}
              next={() => loadMoreData(pageIndex, pageSize)}
              hasMore={data.length < countData}
              loader={isLoading && <Spin size="large"/>}
              endMessage={
                <Divider plain>It is all, nothing more 🤐</Divider>
              }
              scrollableTarget="scrollableDiv"
            >
              <Spinner loading={copyLoading}>
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3,
                  }}
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item>
                      <VehicleCardInfo vehicle={item}
                              
                                       onViewEvidence={() => handleViewEvidence(item)}
                                       onCopyLink={() => handleCopyLink(item)}
                                       onReceive={item.estado === VehiculoEstadoEnum.recibidoEnBodega
                                         ? null
                                         : () => handleReceiveVehicle(item)}
                                       onUploadEvidence={() => handleUploadEvidence(item)}
                                       onInspect={item.estado === VehiculoEstadoEnum.recibidoEnBodega && item.estadoInspeccion === VehiculoEstadoInspeccionEnum.noInspeccionado
                                         ? () => handleInspectVehicle(item) : null}
                                       onUpdate={() => {
                                         setVehiculo(item);
                                         setTitle(`MODIFICAR ${item?.vehiculoName}`);
                                         setOpenVehiculoInspeccionForm(true);
                                       }}/>
                    </List.Item>
                  )}
                />
              </Spinner>
            </InfiniteScroll>
          </div>
        </Spin>
      </div>

      <Modal width={'auto'}
             style={{top: '20px'}}
             open={openVehiculoInspeccionForm}
             cancelButtonProps={{style: {display: "none"}}}
             okButtonProps={{style: {display: "none"}}}
             onCancel={() => {
               setVehiculo(undefined);
               setMarcarRecibidoBodega(false);
               setOpenVehiculoInspeccionForm(false);
               setStateVehicleForm({ reciving: false, inspection: false, newVehicle: false });
             }}>
        <VehiculoInspeccionForm title={title}
                                origen={filters.origen}
                                vehicle={vehiculo}
                                isLoading={isLoading}
                                showCustomerField={true}
                                showInspectionField={!!vehiculo && vehiculo.estado === VehiculoEstadoEnum.recibidoEnBodega}
                                showInspection={makeReceiptAndInspection || stateVehicleForm.inspection}
                                isReceiving={stateVehicleForm.reciving}
                                newVehicle={stateVehicleForm.newVehicle}
                                onSubmit={(value, quote) => vehiculo ? onUpdate(value, quote) : onSave(value)}
                                onCancel={() => {
                                  setVehiculo(undefined);
                                  setMarcarRecibidoBodega(false);
                                  setOpenVehiculoInspeccionForm(false);
                                  setStateVehicleForm({ reciving: false, inspection: false, newVehicle: false });
                                }}/>
      </Modal>

      <Modal
        title={`Evidencia para el lote ${vehiculo ? vehiculo.lote : ''}`}
        open={isModalOpenEvidence}
        onOk={handleOkEvidence}
        onCancel={handleCancelEvidence}
        cancelButtonProps={{style: {display: "none"}}}
        okButtonProps={{style: {display: "none"}}}
      >
       <UploadGoogleDrive vehiculo={vehiculo} fechaRecibido={vehiculo ? vehiculo.fecha : ''} tipoEvidencia={'recibo'} open={isModalOpenEvidence} handleCancelEvidence={handleCancelEvidence}/>
      </Modal>

      <Modal
        title="Lista de evidencia"
        open={isModalOpenListEvidence}
        onOk={handleOkListEvidence}
        onCancel={handleCancelListEvidence}
        cancelButtonProps={{style: {display: "none"}}}
        okButtonProps={{style: {display: "none"}}}
        width={"100%"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <hr/>
        <VehicleEvidenceList lote={vehiculo ? vehiculo.lote : ''}/>
      </Modal>
    </div>
  );
};

export default VehicleReceipts;
