import React  from "react";
import { Row, Col, Typography, Table } from "antd";
import moment from "moment";
const { Title, Text } = Typography;

const columns = [
  {
    title: "YEAR",
    dataIndex: "year",
    key: "year",
  },
  {
    title: "MAKE",
    dataIndex: "make",
    key: "make",
  },
  {
    title: "MODEL",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "COLOR",
    dataIndex: "color",
    key: "color",
  },
  {
    title: "VIN #",
    dataIndex: "vin",
    key: "vin",
  },
  {
    title: "LOT #",
    dataIndex: "lot",
    key: "total",
  },
  {
    title: "KEYS",
    dataIndex: "keys",
    key: "keys",
  },
];

const columnsGeneral = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
];

const data = [
  {
    key: "1",
    year: "Item 1",
    make: 100,
    model: 2,
    color: 200,
    vin: 200,
    lot: 200,
    keys: 200,
  },
  {
    key: "2",
    year: "Item 1",
    make: 100,
    model: 2,
    color: 200,
    vin: 200,
    lot: 200,
    keys: 200,
  },
];




class ComponentToPrint extends React.Component {
 
  render() {
    const exportationDetails = this.props.exportation?.exportDetails?.filter((element) => element.state === true).map((element, index) => ({
      key: index.toString(),
      year: element?.werehouse?.vehiculo?.year,
      make: element?.werehouse?.vehiculo?.make,
      model: element?.werehouse?.vehiculo?.model,
      color: element?.werehouse?.vehiculo?.color,
      vin: element?.werehouse?.vehiculo?.vin,
      lot: element?.werehouse?.vehiculo?.lote,
      keys: element?.werehouse?.numLlave,
    }));

    return (
      <div >
        <Row style={{ marginTop: 10 }}>
          <Col
            span={12}
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../image/matuscolor.png")}
              alt="Company Logo"
              width={"80%"}
            />
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Title level={2}>LOADING SHEET</Title>
              </Col>
            </Row>
            <Row span={12}>
              <Col span={24} style={{paddingRight:20}}>
                <Table
                  columns={columnsGeneral}
                  dataSource={[
                    {
                      key: "1",
                      title: "DATE:",
                      description: moment(this.props.exportation?.createdate).format("DD/MM/YYYY HH:mm:ss"),
                    },
                    {
                      key: "2",
                      title: "CLIENT:",
                      description: this.props.exportation?.exportDetails[0]?.werehouse?.aduana?.nameAduana,
                    },{
                      key: "3",
                      title: "REFERENCE NUMBER:",
                      description: this.props.exportation?.shipmentName,
                    },{
                      key: "4",
                      title: "BOOKING NUMBER:",
                      description: this.props.exportation?.bookingNum,
                    },{
                      key: "5",
                      title: "CONTAINER NUMBER:",
                      description: this.props.exportation?.exportationContainers?.map((element) => element?.containerNumber).join(", "),
                    },{
                      key: "6",
                      title: "TYPE CONTAINER:",
                      description: this.props.exportation?.exportationContainers?.map((element) => element?.container?.nameContainer).join(", "),
                    },{
                      key: "7",
                      title: "SEAL NUMBER:",
                      description: this.props.exportation?.exportationContainers?.map((element) => element?.sealNumber).join(", "),
                    }
                  ]}
                  pagination={false}
                  showHeader={false}
                  size="small"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 50, padding: 10 }}>
          <Col span={24}>
            <Table columns={columns} dataSource={exportationDetails} pagination={false} />
          </Col>

          <Col span={24} style={{marginTop:20, textAlign:"center"}} >
            <h3>Nota</h3> 
            <p>{this.props.exportation?.noteLoading}</p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ComponentToPrint;
