import React, { useState, useEffect } from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import "moment/locale/es";

import { totalCoordinacionPerOrigenByMonth } from "../../../../Servicios/Home";

const monthActuality = moment().format("MMMM");

const TotalOriginCurrentMonth = () => {

  const [totales, setTotales] = useState(null); // [ {totalEntregasEnBodega: 0, total: 0}, {totalEntregasEnBodega: 0, total: 0}
  const [state, setState] = useState({
    series: [100, 100],
    options: {
      chart: {
        type: "donut",

      },
      labels: ["FLORIDA", "CALIFORNIA"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      legend: {
        position: "bottom", // Posición de la leyenda abajo del gráfico
        horizontalAlign: "center",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.series[opts.seriesIndex];
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },

  });

  useEffect(() => {
    totalCoordinacionPerOrigenByMonth().then((data) => {
      console.log("totalCoordinacionPerOrigenByMonth", data);
      setTotales(data);
      setState({
        series: [data[0].total, data[1].total],
        options: {
          chart: {
            type: "donut",
          },
          // labels: [`${data[0].label}: ${data[0].total}`,`${data[1].label}: ${data[1].total}`],
          labels: [`${data[0].label}`,`${data[1].label}`],

          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          legend: {
            position: "bottom", // Posición de la leyenda abajo del gráfico
            horizontalAlign: "center",
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return opts.w.globals.series[opts.seriesIndex];
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      });
    } );
  }, [])
  

  return (
    <Card
      title={`Total de Coordinaciones por Origen - ${monthActuality.toUpperCase()}`}
      style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}
    >
        <div>
      <div
        id="chart"
        style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",   
          width: "100%", // Ajustar el contenedor del gráfico para que sea responsivo
          height: 515, // Ajustar el contenedor del gráfico para que sea responsivo
        }}
      >
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="donut"
          height={545} // Utilizar la altura definida
          width={450} // Utilizar el ancho definido
        />

      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            Entregas en Bodega CALIFORNIA: { totales ? totales[0].totalEntregasEnBodega : 0}
          </div>
          <div>
           Entregas en Bodega FLORIDA: {totales ? totales[1].totalEntregasEnBodega : 0}
          </div>
        </div>
      <div id="html-dist"></div>
      </div>
    </Card>
  );
};

export default TotalOriginCurrentMonth;
