import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEarthAmericas, faEye, faPencil, faPenToSquare, faUserPlus} from '@fortawesome/free-solid-svg-icons';
import {Link, useParams} from "react-router-dom";
import {editClient, listClients} from "../../Servicios/ClienteService";
import Form from 'react-bootstrap/Form';
import DataTable from 'react-data-table-component';
import 'styled-components';
import '../../hojas-de-estilo/style.css';
import {permisos} from "../../utils/permisos";
import {hasRol, validarPermisos} from "../../Servicios/AuthService";
import {Col, Modal, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import SpinnerTable from "../../Componentes/SpinnerTable";
import {getUserSellers} from "../../Servicios/UsuarioService";
import {errorMessage} from "../../utils/toast-message";
import {ClienteClassPay} from "../../shared/constants/cliente-class-pay.const";
import roles from "../../utils/roles";

const FilterComponent = ({filterText, onFilter}) => (
  <>
    <Form.Control
      className="input-buscar"
      id="search"
      type="text"
      placeholder="Buscar Cliente por Nombre o Importadoras"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}/>
  </>
);

const ClientTable = () => {
  const {id} = useParams();

  const [client, setClient] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  const [classPay, setClassPay] = useState(undefined);
  const [asignacionId, setAsignacionId] = useState(undefined);
  const [modificarCliente, setModificarCliente] = useState(undefined);

  const showData = async (currentPage, pageCount, search) => {
    setIsLoading(true);
    const data = await listClients(id, 0, currentPage, pageCount, search);
    setIsLoading(false);

    setClient(data.list);
    setTotalElements(data.totalElements);
  }

  useEffect(() => {
    showData(currentPage, pageCount, debouncedFilterText).then();
    getUserSellers().then(data => setSellers(data));
  }, [id])

  const columns = [
    {
      name: 'Visualizar',
      width: "90px",
      cell: (row) => <Button as={Link} to={`/seeProfileClient/${row.id}`} variant="success">
        <FontAwesomeIcon icon={faEye}/>
      </Button>
    },
    {
      name: 'Editar',
      width: "70px",
      cell: (row) => <Button as={Link} to={`/editarCli/${row.id}`} variant="primary">
        <FontAwesomeIcon icon={faPenToSquare}/>
      </Button>
    },
    {
      name: 'Segmento',
      selector: row => row.segmentoName,
      width: "150px",
    },
    {
      name: 'Cliente',
      selector: row => row.nombreCompleto,
      width: "250px",
    },
    {
      name: 'Numero Contacto',
      selector: row => row.contNum
    },
    {
      name: 'Nombre Importadora',
      selector: row => row.importadoraName,
      width: "300px",
    },
    {
      name: 'Clasificación de Pago',
      cell: row => <div className="d-flex align-items-center justify-content-start gap-3">
        {
          puedeModificarCliente && hasRol(roles.COBROS, roles.SUPERVISOR, roles.ADMIN)
            ? <FontAwesomeIcon icon={faPencil}
                               className="cursor-pointer"
                               onClick={() => {
                                 setClassPay(row.classPay == null || row.classPay === '' ? "0" : row.classPay);
                                 setModificarCliente(row);
                               }} />
            : <></>
        }
        <p className="mb-0">
          {row.classPay}
        </p>
      </div>,
      width: "200px",
    },
    {
      name: 'Asignación',
      cell: (row) => <>
        <div className="d-flex align-items-center justify-content-start gap-3">
          {
            puedeModificarCliente && hasRol(roles.SUPERVISOR, roles.ADMIN)
              ? <FontAwesomeIcon icon={faPencil}
                                 className="cursor-pointer"
                                 onClick={() => {
                                   setAsignacionId(row.asignacionId ?? "0");
                                   setModificarCliente(row);
                                 }} />
              : <></>
          }
          <p className="mb-0">
            {row.asignacionVendedor ? row.asignacionVendedor.fullName : row.asignacion }
          </p>
        </div>
      </>,
      width: "200px",
    },
    {
      name: 'Pais',
      selector: row => row.countryName
    },
    {
      name: 'Aduana Pref',
      selector: row => row.aduanaName
    }
  ]

  const [columnas, setColumnas] = useState(columns);

  const [filterText, setFilterText] = useState("");
  const [debouncedFilterText, setDebouncedFilterText] = React.useState("");

  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const puedeAgregarCliente = validarPermisos(permisos.AGREGAR_CLIENTE);
  const puedeAgregarImport = validarPermisos(permisos.AGREGAR_IMPORTADORA);
  const puedeModificarCliente = validarPermisos(permisos.MODIFICAR_CLIENTE);

  useEffect(() => {
    const c = [...columnas];
    if (!puedeModificarCliente) {
      c.splice(1, 2);
    } else {
      if (!puedeModificarCliente) {
        c.splice(1, 1);
      }
    }

    setColumnas([...c]);
  }, [puedeModificarCliente]);

  const handlePerRowsChange = (newPerPage) => {
    setPageCount(newPerPage);
    setCurrentPage(1);

    showData(1, newPerPage, debouncedFilterText).then();
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    showData(newPage, pageCount, debouncedFilterText).then();
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);

      setFilterText('');
      setDebouncedFilterText('');
    }
  };

  const handleFilterText = (value) => {
    setFilterText(value);
  }

  useEffect(() => {
    showData(currentPage, pageCount, debouncedFilterText).then();
  }, [debouncedFilterText]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedFilterText(filterText);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filterText, 500]);

  const handleSaveAsignacion = async () => {
    const input = {
      ...modificarCliente,
      asignacionId: +asignacionId,
    }

    setIsLoading(true);
    const data = await editClient(modificarCliente.id, input);
    setIsLoading(false);

    if (data && data.status) {
      showData(currentPage, pageCount, debouncedFilterText).then();
      setAsignacionId(undefined);
      setModificarCliente(undefined);
    } else {
      errorMessage(data.message);
    }
  }

  const handleSaveClassPay = async () => {
    const input = {
      ...modificarCliente,
      classPay: classPay,
      apellido1: modificarCliente.apellido1 ?? "",
    }

    setIsLoading(true);
    const data = await editClient(modificarCliente.id, input);
    setIsLoading(false);

    if (data && data.status) {
      showData(currentPage, pageCount, debouncedFilterText).then();
      setClassPay(undefined);
      setModificarCliente(undefined);
    } else {
      errorMessage(data.message);
    }
  }

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <Content>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FilterComponent onFilter={e => handleFilterText(e.target.value)} onClear={handleClear} filterText={filterText}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="w-100 d-flex align-items-center justify-content-end flex-wrap gap-2">
              {
                puedeAgregarCliente ?
                  <Button className="newCli" as={Link} to={`/addClient/${id}`} variant="primary">
                    <FontAwesomeIcon icon={faUserPlus}/> Crear Nuevo Cliente
                  </Button>
                  : <></>
              }
              {
                puedeAgregarImport ?
                  <Button className="newCli" as={Link} to="/importer" variant="primary">
                    <FontAwesomeIcon icon={faEarthAmericas}/> Ver/Agregar Importadoras
                  </Button> : <></>
              }
            </div>
          </Col>
        </Row>
      </Content>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Row gutter={[16, 24]}>
      <Col
        xs={{span: 24}}
        md={{span: 24}}
        lg={{span: 24}}
        xl={{span: 24}}>
        <DataTable
          columns={columnas}
          data={client}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={isLoading}
          progressComponent={<SpinnerTable/>}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          fixedHeaderScrollHeight="600px"
        />
      </Col>

      <Modal open={!!modificarCliente && !!asignacionId}
             title={'Modificar Asignación'}
             onCancel={() => {
               setAsignacionId(undefined);
               setModificarCliente(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <Form.Select className="my-3"
                     onChange={(event) => {
                       setAsignacionId(event.target.value);
                     }}
                     name='asignacionId'
                     value={asignacionId ?? "0"}>
          <option disabled value="0">Seleccione</option>
          {
            sellers.map(e => <option value={e.idUser}>{e.firstName} {e.lastName}</option>)
          }
        </Form.Select>
        <div className="w-100 text-end">
          <Button onClick={handleSaveAsignacion}>Guardar</Button>
        </div>
      </Modal>

      <Modal open={!!modificarCliente && !!classPay}
             title={'Modificar Clasificación de Pago'}
             onCancel={() => {
               setClassPay(undefined);
               setModificarCliente(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <Form.Select className="my-3"
                     onChange={(event) => {
                       setClassPay(event.target.value);
                     }}
                     name='classPay'
                     value={classPay ?? "0"}>
          <option disabled value="0">Seleccione</option>
          {
            ClienteClassPay.map(e => <option value={e.value}>{e.label}</option>)
          }
        </Form.Select>
        <div className="w-100 text-end">
          <Button onClick={handleSaveClassPay}>Guardar</Button>
        </div>
      </Modal>
    </Row>
  );
}

export default ClientTable;