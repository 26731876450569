import React, {useState} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import '../hojas-de-estilo/login.css';
import logocolor from '../image/matuscolor.png';
import Button from 'react-bootstrap/Button';
import {forgot} from '../Servicios/AuthService';
import Loadingsmall from '../Componentes/Loadingsmall';

const ResetPassword = () => {

    const [email, setEmail] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const [message, setMessage] = useState('');
    const [msjsuccess, setMsjsuccess] = useState('');

    const submit = async () => {
        setIsLoading(true);

        forgot(email).then(() => {

            setMsjsuccess("Se ha enviado un correo electronico con los pasos para recuperar su contraseña");
            setIsLoading(false);
        }).catch(error => {
            if (error) {
                setMessage(error.message);
                setTimeout(() => {
                    setMessage(" ");
                }, 3000);
            }
            setIsLoading(false);
        });
    }


    return (
        <Container className='modal-dialog text-center bodylogin'>
            <Row className="justify-content-md-center modal-content">
                <Col className='resetpassword' xs lg="4">
                    <Row style={{display: 'flex', width: "160", height: "59"}}>
                        <img
                            src={logocolor}
                        /></Row>
                    <br/>
                    <h4>Restablece tu contraseña</h4>
                    <Form.Control type="text"
                                  value={email}
                                  id="email_field"
                                  class="form-control input-block"
                                  autofocus="autofocus"
                                  onChange={(event) => setEmail(event.target.value)}
                                  placeholder="Ingrese su correo"/>
                    {isLoading ? <Loadingsmall/> : <></>}
                    <br/>
                    {
                        msjsuccess != "" ? <div className=' alert alert-success'>{msjsuccess}</div> : ''
                    }
                    {
                        message != "" ? <p>{message}</p> : ''
                    }
                    <br/>
                    <Row style={{justifyContent: "center"}}>
                        <Button type="button" onClick={submit} style={{width: "200px"}}>Enviar reset a mi email</Button>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ResetPassword;
