import React from 'react'
import { SpinnerCircularFixed } from 'spinners-react';

const Loadingsmall = () => {
  return (
    <div>
      <SpinnerCircularFixed size={50} thickness={100} speed={100} color="rgba(28, 46, 81, 1)" secondaryColor="rgba(38, 153, 214, 1)" />
    </div>
  )
}

export default Loadingsmall
