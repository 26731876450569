import {Modal, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import RolPermisoForm from "../Roles/RolPermisoForm";

const UsuarioRolTable = ({ roles, doEdit, doDelete }) => {
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState(undefined);

  useEffect(() => {
    if (roles) {
      setRoleList(roles.map(e => {
        return {
          item: e,
          id: e.id,
          rolName: e.rol.name,
          countryId: e.countryId,
          countryName: !e.countryName || e.countryName === "" ? "ALL COUNTRIES" : e.countryName
        }
      }));
    }
  }, [roles]);

  const columns = [
    { title: 'Rol',             dataIndex: "rolName",     key: "rolName" },
    { title: 'Access Country',  dataIndex: "countryName", key: "countryName" },
    {
      title: 'Options',
      dataIndex: 'options',
      key: 'options',
      render: (_, element) => (<>
        <Space key={element.id}>
          <Button variant={"primary"}
                  size={'sm'}
                  onClick={() => setSelectedRole(element.item)}><FontAwesomeIcon icon={faEye}/></Button>
          <Button variant={"danger"}
                  size={'sm'}
                  onClick={() => doDelete ? doDelete(element.item) : ''}><FontAwesomeIcon icon={faTrash}/></Button>
          {
            element.countryId == null &&
            <Button variant={"primary"}
                    size={'sm'}
                    onClick={() => doEdit ? doEdit(element.item) : ''}><FontAwesomeIcon icon={faPencil}/></Button>
          }
        </Space>
      </>)
    }
  ];

  return(
    <>
      <Table size={"middle"}
             columns={columns}
             dataSource={roleList}
             pagination={{ pageSize: 10 }} />

      <Modal open={selectedRole !== undefined}
             title={'Permisos'}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}
             onCancel={() => {
               setSelectedRole(undefined);
             }}>
        <RolPermisoForm rol={selectedRole ? selectedRole.rol : undefined} />
      </Modal>
    </>
  );
}

export default UsuarioRolTable;
