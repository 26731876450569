import {server} from "../environments/environment";
import {ApiService} from "./ApiService";

export const addVehicleReceiving = async (vehiculo) => {
  const response = await ApiService.post(`${server}vehiculo/receiving`, vehiculo);
  return response.json();
}

export const addQuote = async (quote) => {
  const response = await ApiService.post(`${server}Quote`, quote);
  return response.json();
}

export const showQuote = async (day = 0, month = 0, year = 0, currentPage = 0, pageCount = 0,
                                search = "", estado = "", creador = "", origenId = 0, countriesId = "",
                                sendedToReview = false, pendingToComplete = false, fromDate = 0, toDate = 0, subastaId = 0) => {
  let params = {
    mes: month,
    anio: year,
    day: day,
    pageCount: pageCount,
    currentPage: currentPage,
    origenId: origenId,
    fromDate: fromDate,
    toDate: toDate,
    subastaId: subastaId
  };

  if (search !== "") params['search'] = search;
  if (estado !== "") params['estado'] = estado;
  if (creador !== "") params['creador'] = creador;
  if (countriesId !== "") params['countriesId'] = countriesId;

  if (sendedToReview) params['sendedToReview'] = true;
  if (pendingToComplete) params['pendingToComplete'] = true;

  const queryParams = new URLSearchParams(params);

  const response = await ApiService.get(`${server}Quote?${queryParams.toString()}`);
  return response.json();
}

export const SolicitudesByClient = async (clienteId = 0) => {
  const URL = `${server}Quote`
  let response;
  response = await ApiService.get(`${URL}?clienteId=${clienteId}`);
  const data = await response.json()
  return data.list;
}

function formatDateToReadable(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return date.toLocaleString(undefined, options);
}

export const VehiculoFilMakeModelvehi = async (fechaInicio = 0, fechaFinal = 0, origen = 0) => {
  const URL = `${server}Vehiculo/MakeModelOnlyFecha`
  let response;
  response = await ApiService.get(`${URL}?fechaInicio=${fechaInicio}&fechaFin=${fechaFinal}&origen=${origen}`);
  const data = await response.json()
  return data.list;
}

export const filtroPendingTitles = async (origen = 0) => {
  const URL = `${server}Vehiculo/pendingTitles`
  let response;
  response = await ApiService.get(`${URL}?origen=${origen}`);
  const data = await response.json()
  return data.list;
}

export const getPDFId = async (id) => {
  const URL = `${server}Quote/reporte`
  try {
    const response = await ApiService.get(`${URL}/${id}`);
    const data = await response.json()

    return data.data;
  } catch (ex) {
    throw new Error(ex);
  }
}

export const decodeVin = async (vin) => {
  const URL = "https://zylalabs.com/api/74/vin+decoder+api/139/decode+vin"
  try {
    const response = await fetch(URL + "?vin=" + vin, {
      method: 'GET',
      headers: {
        "Authorization": 'Bearer 156|iFmN4RxMw2jDgd2HvbhE9J1Qw17w47xcqILJG4Y4',
        "accept": '*/*',
        "Content-type": 'application/json',
      },
    });
    const data = await response.json()
    const decode = data.data;
    return decode;

  } catch (ex) {
    throw new Error(ex);
  }
}

export const showQuotePoridvehi = async (id = 0) => {
  const URL = `${server}Quote/ByVehi`
  let response;
  response = await ApiService.get(`${URL}?id=${id}`);
  const data = await response.json()
  return data.data;
}

export const viewQuote = async (id, cancelada = false) => {
  try {
    const response = await ApiService.get(`${server}quote/${id}?cancelada=${cancelada}`);
    const data = await response.json()
    const quote = data.data;

    if (quote.fechaQ && quote.fechaQ.indexOf("T00:00:00") !== -1) {
      quote.fechaQ = quote.fechaQ.substring(0, quote.fechaQ.indexOf("T00:00:00"));
    }

    if (quote.expiracionQ && quote.expiracionQ.indexOf("T00:00:00") !== -1) {
      quote.expiracionQ = quote.expiracionQ.substring(0, quote.expiracionQ.indexOf("T00:00:00"));
    }

    return quote;

  } catch (ex) {
    throw new Error(ex);
  }
}

export const viewCar = async (id) => {
  const URL = `${server}Vehiculo`
  try {
    const response = await ApiService.get(`${URL}/${id}`);
    const data = await response.json()

    return data.data;
  } catch (ex) {
    throw new Error(ex);
  }
}



export const viewSub = async (id) => {
  const URL = `${server}Subasta`
  try {
    const response = await ApiService.get(`${URL}/${id}`);
    const data = await response.json()
    const quote = data.data;
    return quote;

  } catch (ex) {
    throw new Error(ex);
  }
}

export const getVehicleModels = async (make) => {
  const apiUrl = 'https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/';
  try {
    const response = await fetch.get(`${apiUrl}${make}`);
    return response.data.Results;
  } catch (error) {
    console.error('Error al obtener modelos de vehículos:', error);
    return [];
  }
}

export const editQuote = async (id, quote) => {
  const URL = `${server}Quote`
  const response = await ApiService.put(`${URL}/${id}`, quote);

  return response.json();
}

export const cancelQuote = async (id, quo) => {
  const URL = `${server}Quote`
  const response = await ApiService.put(`${URL}/${id}/cancelar`, quo);
  const data = await response.json()

  if (data.status)
    return data.data;
  else throw new Error(data.message);
}

export const enviarEmail = async (infomail) => {
  try {
    const response = await ApiService.post(`${server}email`, infomail);
    return response.json();
  } catch (ex) {
    throw new Error(ex.toString());
  }
}

export const getVehicleSearch = async (pageIndex = 0, pageSize = 100, lote = '', estado = '', origen = '', vin = '', estadoInspeccion = '') => {

  const parameterUrl = {
    pageIndex,
    pageSize,
  }

  if (vin !== "") parameterUrl['vin'] = vin;
  if (lote !== "") parameterUrl['lote'] = lote;
  if (estado !== "") parameterUrl['estado'] = estado;
  if (origen !== "") parameterUrl['origen'] = origen;
  if (estadoInspeccion !== "") parameterUrl['estadoInspeccion'] = estadoInspeccion;

  const URL = `${server}Vehiculo/buscar`
  let response;
  response = await ApiService.get(`${URL}?${new URLSearchParams(parameterUrl)}`);
  const data = await response.json()
  return data;
}