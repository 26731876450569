import HomeInfo from "../views/Home/Home";
import MainLayout from "../layout/MainLayout";
import GuestGuard from "../utils/route-guard/GuestGuard";
import FindCliente from "../Carteracliente/findcliente";
import Coordinaciones from "../Coordinaciones/coordinaciones";
import TablaSolicitud from "../Solicitudes/TablaSolicitud";
import Solicitud from "../Solicitudes/Solicitud";
import Vehiculo from "../Solicitudes/Vehiculo";
import QuoteCoor from "../Coordinaciones/QuoteCoor";
import Transporte from "../Coordinaciones/Transporte";
import Subastas from "../Solicitudes/Subastas";
import VehicleReceipts from "../WereHouse/VehicleReceipts";
import PendinTitles from "../Coordinaciones/PendinTitles";
import TableWerehouse from "../WereHouse/TableWerehouse";
import VisualizarCli from "../Carteracliente/VisualizarCli";
import Importadoras from "../Carteracliente/Importadoras";
import EditarQuote from "../Solicitudes/EditarQuote";
import EditarCoor from "../Coordinaciones/EditarCoor";
import WerehouseCoor from "../WereHouse/WerehouseCoor";
import EditWR from "../WereHouse/EditWR";
import VisualizarWR from "../WereHouse/VisualizarWR";
import AgregarCoor from "../Coordinaciones/AgregarCoor";
import VisualizarCoor from "../Coordinaciones/VisualizarCoor";
import NewWROrigin from "../WereHouse/NewWROrigin";
import NewVehicle from "../WereHouse/NewVehicle";
import CambiarPass from "../User/CambiarPass";
import SendPrint from "../Solicitudes/SendPrint";
import ExportationFormPage from "../views/Exportations/ControlBooking/ExportationFormPage";
import ExportationControlBookingPage from "../views/Exportations/ControlBooking/ExportationControlBookingPage";
import QuotationList from "../views/Quotation/quotationList";
import QuotationForm from "../views/Quotation/quotationForm";
import UploadEvidenceReceipts from "../views/Receipts/uploadEvidenceReceipts";
import Aduanas from "../Carteracliente/Aduanas";
import ReceivedToday from "../WereHouse/ReceivedToday";
import PendingTitlesVehiculos from "../Vehiculos/PendingTitlesVehiculos";
import MultiplesSolicitudes from "../Solicitudes/MultiplesSolicitudes";
import MultipleCoordinaciones from "../Coordinaciones/MultipleCoordinaciones";
import SendToAES from "../views/Exportations/SendToAES";
import CustomerRequest from "../views/CustomerRequest";
import ReportComments from "../views/Reports/reportComments";
import ClienteFormPage from "../Carteracliente/ClienteFormPage";
import PortCodePage from "../views/PortCode/PortCodePage";
import UsuarioPage from "../Admin/Usuarios/UsuarioPage";
import UsuarioViewPage from "../Admin/Usuarios/UsuarioViewPage";
import RolPage from "../Admin/Roles/RolPage";
import PermisoPage from "../Admin/Permisos/PermisoPage";
import TablaSolicitudHistorial from "../Solicitudes/TablaSolicitudHistorial";
import TablaSolicitudReport from "../Solicitudes/TablaSolicitudReport";
import StorageReport from "../Coordinaciones/StorageReport";
import TitlePage from "../views/Title/TitlePage";
import CoordinacionSinTituloPage from "../Coordinaciones/CoordinacionSinTitulo/CoordinacionSinTituloPage";
import ExportationShippingInstruction from "../views/Exportations/ExportationShippingInstruction";
import ExportationAduanaPage from "../views/Exportations/ExportationAduanaPage";
import ExportationNavieraPage from "../views/Exportations/ExportationNavieraPage";
import ClienteActividadPage from "../Carteracliente/ClienteActividadPage";
import CarrierPage from "../views/Carrier/CarrierPage";
import PayTTransportPage from "../Accounting/PagosTransportistas/PayTransportPage";
import ExportationContainerPage from "../views/Exportations/PlanifContenedores/ExportationContainerPage";
import SolicitudEstadoPorSemanaPage from "../Solicitudes/SolicitudEstadoPorSemanaPage";

const MainRoutes = {
  path: "/",
  element: (
    <GuestGuard>
      <MainLayout />
    </GuestGuard>
  ),
  children: [
    //FLUJO DE RUTAS DE SIDEBAR
    {
      path: "/",
      element: <HomeInfo />,
    },
    {
      path: "/home",
      element: <HomeInfo />,
    },
    {
      path: "/portfolio/country/:id",
      element: <FindCliente />,
    },
    {
      path: "/coordinations/country/:id",
      element: <Coordinaciones />,
    },
    {
      path: "/coordinations/recibidos-sin-titulo",
      element: <CoordinacionSinTituloPage />,
    },
    {
      path: "/coordinations/:id/view",
      element: <VisualizarCoor />,
    },
    {
      path: "/coordinations/:id/edit",
      element: <EditarCoor />,
    },
    {
      path: "/requests/list",
      element: <TablaSolicitud />,
    },
    {
      path: "/requests/list/history",
      element: <TablaSolicitudHistorial />,
    },
    {
      path: "/requests/list/report",
      element: <TablaSolicitudReport />,
    },
    {
      path: "/vehicle",
      element: <Vehiculo />,
    },
    {
      path: "/request",
      element: <Solicitud />,
    },
    {
      path: "/incomingRequests/:id",
      element: <QuoteCoor />,
    },
    {
      path: "/storageReport",
      element: <StorageReport />,
    },
    {
      path: "/addCarrier",
      element: <Transporte />,
    },
    {
      path: "/addAuction",
      element: <Subastas />,
    },
    {
      path: "/vehicleReceipts",
      element: <VehicleReceipts />,
    },
    {
      path: "/pendingTitles",
      element: <PendinTitles/>,
    },
    {
      path: "/warehouseReceipt/:id",
      element: <TableWerehouse />,
    },
    {
      path: "/importer",
      element: <Importadoras />,
    },
    // FLUJO EN EL CONTENEDOR <h@r!i3 />
    {
      path: "/seeProfileClient/:id",
      element: <VisualizarCli />,
    },
    {
      path: "/editarCli/:id",
      element: <ClienteFormPage />,
    },
    {
      path: "/addClient/:countryId",
      element: <ClienteFormPage />,
    },
    {
      path: "/newWarehouse",
      element: <NewWROrigin />,
    },
    {
      path: "/addCoord/:id",
      element: <AgregarCoor />,
    },
    {
      path: "/editQuotes/:id",
      element: <EditarQuote />,
    },
    {
      path: "/editCordinations/:id",
      element: <EditarCoor />,
    },
    {
      path: "/seeCordinations/:id",
      element: <VisualizarCoor />,
    },
    {
      path: "/newWereHouse/:id",
      element: <WerehouseCoor />,
    },
    {
      path: "/editWereHouse/:id",
      element: <EditWR />,
    },
    {
      path: "/viewWereHouse/:id",
      element: <VisualizarWR />,
    },
    {
      path: "exportation/list",
      element: <ExportationControlBookingPage />,
    },
    {
      path: "exportation/edit/:id",
      element: <ExportationFormPage />,
    },
    {
      path: "exportation/create",
      element: <ExportationFormPage />,
    },
    {
      path: "exportation/:id/shipping-instruction",
      element: <ExportationShippingInstruction />,
    },
    {
      path: "exportation/aduana/list",
      element: <ExportationAduanaPage />,
    },
    {
      path: "exportation/naviera/list",
      element: <ExportationNavieraPage />,
    },
    {
      path: "/newCar",
      element: <NewVehicle />,
    },
    {
      path: "/changepass",
      element: <CambiarPass />,
    },
    {
      path: "/sendprintRequest/:id",
      element: <SendPrint />,
    },
    {
      path: "/quotation/list",
      element: <QuotationList />,
    },
    {
      path: "/quotation/edit/:id",
      element: <QuotationForm />,
    },
    {
      path: "/quotation/create",
      element: <QuotationForm />,
    },
    {
      path: "/uploadEvidenceReceipts",
      element: <UploadEvidenceReceipts />,
    },
    {
      path: "/customs",
      element: <Aduanas />,
    },
    {
      path: "/receivedtoday",
      element: <ReceivedToday/>,
    },
    {
      path: "/titlesPendingCar",
      element: <PendingTitlesVehiculos/>
    },
    {
      path: "/multiplesSolicitudes",
      element: <MultiplesSolicitudes/>
    },
    {
      path: "/multipleCoordinations",
      element: <MultipleCoordinaciones/>
    },
    {
      path: "/reportComments",
      element: <ReportComments />,
    },
    {
      path: "/sendToAES/:id",
      element: <SendToAES/>,
    },
    {
      path: "/customerRequest",
      element: <CustomerRequest />,
    },
    {
      path: "/catalogs/portCode",
      element: <PortCodePage />
    },
    {
      path: "/catalogs/title",
      element: <TitlePage />
    },
    {
      path: "/admin/users",
      element: <UsuarioPage />
    },
    {
      path: "/admin/users/:id",
      element: <UsuarioViewPage />
    },
    {
      path: "/admin/roles",
      element: <RolPage />
    },
    {
      path: "/admin/permissions",
      element: <PermisoPage />
    },
    {
      path: "/clientes/actividad",
      element: <ClienteActividadPage />
    },
    {
      path: "/catalogs/carrier",
      element: <CarrierPage />
    },
    {
      path: "/payments/truks",
      element: <PayTTransportPage />
    },
    {
      path: "/exportation/container",
      element: <ExportationContainerPage />
    },
    {
      path: '/solicitud/estado-por-semana/:id',
      element: <SolicitudEstadoPorSemanaPage />
    }
  ],
};

export default MainRoutes;
