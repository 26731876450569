import React, {useEffect, useState} from "react";
import {deletePermiso, getPermissions} from "../../Servicios/AdminService";
import {Modal, Space, Table} from "antd";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import PermisoForm from "./PermisoForm";
import Spinner from "../../shared/spinner";
import {errorMessage, successMessage} from "../../utils/toast-message";

const PermisoPage = () => {
  const [permisos, setPermisos] = useState([]);
  const [filteredPermisos, setFilteredPermisos] = useState([]);

  const [loading, setLoading] = useState(false);
  const [addNewPermiso, setAddNewPermiso] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [editPermiso, setEditPermiso] = useState(undefined);
  const [dropPermiso, setDropPermiso] = useState(undefined);

  useEffect(() => {
    init().then();
  }, []);

  const init = async () => {
    setLoading(true);
    const permisos = await getPermissions();
    setLoading(false);

    setPermisos([...permisos]);
    setFilteredPermisos([...permisos]);
  }

  const columns = [
    { title: 'ID',    dataIndex: "id",  key: "id" },
    { title: 'Name',  dataIndex: "name", key: "name" },
    { title: 'Codigo',  dataIndex: "code", key: "code" },
    {
      title: 'Options',
      dataIndex: 'options',
      key: 'options',
      render: (_, element) => (<>
        <Space key={element.id}>
          <Button variant={"info"}
                  size={'sm'}
                  onClick={() => setEditPermiso(element)}><FontAwesomeIcon icon={faPencil}/></Button>
          <Button variant={"danger"}
                  size={'sm'}
                  onClick={() => {
                    setDropPermiso(element);
                    setOpenConfirmationModal(true);
                  }}><FontAwesomeIcon icon={faTrash}/></Button>
        </Space>
      </>)
    }
  ];

  const handleSearch = (event) => {
    const data = permisos
      .filter(e => event === "" || e.name.toLowerCase().indexOf(event.toLowerCase()) !== -1);

    setFilteredPermisos(data);
  }

  const handleDeletePermiso = async () => {
    setOpenConfirmationModal(false);
    setLoading(true);

    const data = await deletePermiso(dropPermiso.id);
    setLoading(false);
    setDropPermiso(undefined);

    if (data.status) {
      successMessage("El permiso fue eliminado correctamente");
      await init();
    } else {
      errorMessage(data.message);
    }
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h4 className="m-0">Permisos de Usuario</h4>
          <Button variant={"primary"} onClick={() => setAddNewPermiso(true)}>
            <FontAwesomeIcon icon={faPlus}/> Nuevo Permiso
          </Button>
        </div>

        <div className="mb-3">
          <InputSearch placeholder={'buscar rol'} doChange={handleSearch}/>
        </div>

        <Table size={"middle"}
               columns={columns}
               dataSource={filteredPermisos}
               pagination={{pageSize: 10}}/>

        <Modal title={'Crear Permiso'}
               open={addNewPermiso || editPermiso}
               onCancel={() => {
                 setAddNewPermiso(false);
                 setEditPermiso(undefined);
               }}
               okButtonProps={{style: {display: "none"}}}
               cancelButtonProps={{style: {display: "none"}}}>
          <PermisoForm permiso={editPermiso}
                       doSubmit={() => {
                         setAddNewPermiso(false);
                         setEditPermiso(undefined);

                         init().then();
                       }} />
        </Modal>

        <Modal open={openConfirmationModal}
               title={'Confirmar'}
               onOk={handleDeletePermiso}
               onCancel={() => {
                 setDropPermiso(undefined);
                 setOpenConfirmationModal(false);
               }}>
          Esta seguro que desea eliminar este permiso?
        </Modal>
      </Spinner>
    </>
  );
}

export default PermisoPage;
