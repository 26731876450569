import {server} from "../environments/environment";
import {ApiService} from "./ApiService";
// capitalizar la primera letra y el resto en minuscula
const capitalizarPrimerLetra = (cadena) => {
    return cadena.charAt(0).toUpperCase() + cadena.slice(1).toLowerCase();
}
export const listNote =  async (nombre, clientesid = 0, limiteRegistros = 10, pagina = 1) => {
    nombre = nombre.replace(" ", "%20");
    const url = `${server}Notas`;
    const response = await ApiService.get(`${url}?nombre=${nombre}&clientesid=${clientesid}&limiteRegistros=${limiteRegistros}&pagina=${pagina}`);
    const data = await response.json()
     
    // tranformar la data para que sea consumida por el componente de manera que liste y agrupe al cliente 
    // con sus notas    
    data.list = data.list.reduce((acc, nota) => {
        console.log("nota", nota);
        console.log("acc", acc);
        const cliente = acc?.find((cliente) => cliente.clientesId === nota.clientesId);

        console.log("cliente", cliente);

        if (cliente) {
            cliente.notas.push({nota: capitalizarPrimerLetra(nota.nota), fecha: nota.fecha});
        } else {
            acc.push({
                clientesId: nota.clientesId,
                nombreCliente: nota.clientes.nombreCompleto ,
                email: nota.clientes.email,
                contactNumber: nota.clientes.contNum,
                autor: nota.autor,
                id: nota.id,
                notas: [{nota: capitalizarPrimerLetra(nota.nota), fecha: nota.fecha}],
            });
        }
        console.log("acc", acc);
        return acc;
    }
    , []);

    return data.list;
}

export const listClient = async () => {
    const url = `${server}Usuarios/Ventas`;
    const response = await ApiService.get(url);
    const data = await response.json();
    return data.list;
}