import {Space, Table, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import {deleteTitle, getTitles} from "../../Servicios/TitleService";
import TitleForm from "./TitleForm";
import Button from "react-bootstrap/Button";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConfirmDialog from "../../Componentes/ConfirmDialog/ConfirmDialog";
import {successMessage} from "../../utils/toast-message";
import Spinner from "../../shared/spinner";

const TitlePage = () => {
  const [titleList, setTitleList] = useState([]);
  const [filterTitleList, setFilterTitleList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [selectedTitle, setSelectedTitle] = useState(undefined);
  const [selectedDeleteTitle, setSelectedDeleteTitle] = useState(undefined);
  
  const columns = [
    { title: 'Descripción', dataIndex: 'titleName', key: 'titleName' },
    { title: 'Estado', dataIndex: 'state', key: 'state', width: 180 },
    { title: 'Embarcable',
      dataIndex: 'embarkable',
      key: 'embarkable',
      width: 180,
      render: value => value
        ? <div className="badge bg-primary">SI</div>
        : <div className="badge bg-danger">NO</div>
    },
    {
      title: 'Options',
      key: 'options',
      dataIndex: 'options',
      width: 100,
      render: (_, element) => (
        <>
          <Space size="middle" align="center">
            <Tooltip title="Edit">
              <Button size={"sm"} variant={"primary"} onClick={() => setSelectedTitle(element)}>
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button size={"sm"} variant={"danger"} onClick={() => setSelectedDeleteTitle(element)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Tooltip>
          </Space>
        </>
      )
    }
  ];

  useEffect(() => {
    init().then();
  }, []);

  const init = async () => {
    setLoading(true);
    const data = await getTitles();
    setLoading(false);

    setTitleList(data);
    setFilterTitleList(data);
  }

  const handleInputSearch = (event) => {
    const data = titleList
      .filter(e => event === "" || `${e.titleName}-${e.state}`.toLowerCase().indexOf(event.toLowerCase()) !== -1);

    setFilterTitleList(data);
  }

  const handleDeleteTitle = async () => {
    setLoading(true);
    await deleteTitle(selectedDeleteTitle.idTitle);
    setLoading(false);

    successMessage("Title deleted successfully");
    setSelectedDeleteTitle(undefined);
    await init();
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
          <h5 className="m-0">Title</h5>
          <Button type="primary" onClick={() => setOpenModal(true)}>Add new Title</Button>
        </div>

        <div className="mb-3">
          <InputSearch placeholder={'Search for title type or state'}
                       doChange={handleInputSearch}/>
        </div>

        <Table dataSource={filterTitleList}
               columns={columns}/>

        <TitleForm open={openModal || selectedTitle}
                   title={selectedTitle}
                   onCancel={() => {
                     setOpenModal(false);
                     setSelectedTitle(undefined);
                   }}
                   onSave={() => {
                     setOpenModal(false);
                     setSelectedTitle(undefined);
                     init().then();
                   }}/>

        <ConfirmDialog open={selectedDeleteTitle}
                       message={"Esta seguro que desea eliminar este título"}
                       onCancel={() => setSelectedDeleteTitle(undefined)}
                       onOk={handleDeleteTitle}/>
      </Spinner>
    </>
  );
}

export default TitlePage;
