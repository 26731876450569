import React, { useState, useEffect } from "react";
import {
  Select,
  Spin,
  Typography,
  Row,
  Col,
  Table,
  Card,
  Divider,
  Avatar,
} from "antd";
import moment from "moment";
import "moment/locale/es";
import { totalCoordinacionByWeek } from "../../../../Servicios/Home";
import salvador from "../../../../image/pais/salvador.png";
import costarica from "../../../../image/pais/costarica.png";
import guatemala from "../../../../image/pais/guatemala.png";
import nicaragua from "../../../../image/pais/nicaragua.png";
import honduras from "../../../../image/pais/honduras.png";
import { StringFormats } from "../../../../utils/string-formats";
import ShowWhen from "../../../../Componentes/ShowWhen/ShowWhen";
const { Option } = Select;
const { Title, Text } = Typography;

const returnPais = (idpais) => {
  if (idpais === "EL SALVADOR") {
    return salvador;
  } else if (idpais === "GUATEMALA") {
    return guatemala;
  } else if (idpais === "COSTA RICA") {
    return costarica;
  } else if (idpais === "NICARAGUA") {
    return nicaragua;
  } else if (idpais === "HONDURAS") {
    return honduras;
  } else if (idpais === "FLORIDA" || idpais === "CALIFORNIA") {
    return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png";
  }
};

const Home = () => {
  const [weekData, setWeekData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(moment().week());
  const [selectedWeek, setSelectedWeek] = useState(moment().week());

  const fetchWeekData = async (week) => {
    setLoading(true);
    try {
      const response = await totalCoordinacionByWeek(week);
      console.log("response", response);
      setWeekData(response);
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("currentWeek", currentWeek);
    fetchWeekData(currentWeek);
  }, [currentWeek]);

  const handleWeekChange = (week) => {
    setSelectedWeek(week);
    fetchWeekData(week);
  };

  const weeks = [];
  for (let i = 1; i <= currentWeek; i++) {
    weeks.push(i);
  }

  const selectedMoment = moment().week(selectedWeek);
  const year = selectedMoment.year();
  const month = selectedMoment.format("MMMM");

  const getColumns = () => {
    const days = [
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
      "domingo",
    ];

    const columns = [
      {
        title: "País",
        dataIndex: "pais",
        key: "pais",
        fixed: "left",
        // width: 150,
        render: (text) => (
          <div>
            <Avatar alt={text} src={returnPais(text)} />
            <span style={{ marginLeft: "10px" }}>{text}</span>
          </div>
        ),
      },
    ];

    days.forEach((day) => {
      columns.push({
        title: (
          <div>
            <div>{day}</div>
            <div style={{ fontSize: "small" }}>
              {(weekData?.length > 0 &&
                moment(
                  weekData[0].semana.find((d) => d.dia === day)?.fecha
                ).format("MM/DD/YYYY")) ||
                "N/A"}
            </div>
          </div>
        ),
        dataIndex: day,
        key: day,
        render: (text, record) => {
          return <div>
            <ShowWhen show={record?.pais !== 'FLORIDA' && record?.pais !== 'CALIFORNIA'}>
              <div>California: {record[day]?.california || 0}</div>
              <div>Florida: {record[day]?.florida || 0}</div>
            </ShowWhen>
            <strong>Total: {record[day]?.total || 0}</strong>
          </div>
        },
      });
    });

    return columns;
  };

  const dataSource = weekData.map((countryData) => {
    const data = {pais: countryData.pais};
    countryData.semana.forEach((dayData) => {
      data[dayData.dia] = {
        california: dayData.california,
        florida: dayData.florida,
        total: dayData.total,
      };
    });
    return data;
  });

  return (
    <Card
      title={`Detalles de Coordinaciones`}
      style={{
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        width: "100%",
        height: "100%",

        marginBottom: "20px",
      }}
    >
      <div>
        {/* <Title level={2}>Coordinaciones por Semana</Title> */}
        <Row gutter={16}>
          <Col span={24}>
            <Select
              value={selectedWeek}
              onChange={handleWeekChange}
              style={{ width: "100%" }}
            >
              {weeks.map((week) => (
                <Option key={week} value={week}>
                  Semana {week}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Divider>
          {" "}
          <Text>{`Año: ${year}, Mes: ${month}`}</Text>
        </Divider>
        <Table
          dataSource={dataSource}
          columns={getColumns()}
          rowKey="pais"
          pagination={false}
          scroll={{ x: 1300 }}
          loading={loading}
        />
      </div>
    </Card>
  );
};

export default Home;
