import React from "react";
import { Modal, Spin } from "antd";
const PdfReport = ({ visible, setVisible, data, setData}) => {
  
  // hacer el calculo del height del iframe para que se ajuste al tamaño de la pantalla 
  const height = window.innerHeight - 300;
  const onCancel = () => {
    setVisible(false);
    // limpiar el pdf
    setData(null);
  };
  return (
    <Modal
      title="Reporte en PDF"
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={"70%"}
    >
      <Spin spinning={!data}  tip="Cargando PDF..." size="large">
      {
        data ? (
          <iframe
            src={`data:application/pdf;base64,${data}`}
            width="100%"
            height={height}
            title="pdf"
          />
        ) : <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: height
        }}>
          

        </div>
      }
      </Spin>
    </Modal>
  );
};

export default PdfReport;
