import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import React, {useState} from "react";
import {Modal} from "antd";

const EliminarClienteBtn = ({ onCancelar }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleCancelarSolicitud = () => {
    setOpenConfirmationModal(false);
    if (onCancelar) onCancelar();
  }

  return(
    <>
      <Button onClick={() => setOpenConfirmationModal(true)} variant={'outline-danger'}>
        <FontAwesomeIcon icon={faTimes} /> Eliminar Cliente
      </Button>

      <Modal open={openConfirmationModal}
             title={'Confirmar'}
             onOk={handleCancelarSolicitud}
             onCancel={() => {
               setOpenConfirmationModal(false);
             }}>
        <p>Esta seguro que desea eliminar este cliente?</p>
      </Modal>
    </>
  );
}

export default EliminarClienteBtn;
