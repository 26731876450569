import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import queryString from "query-string";

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (queryString.parse(location.search).ctx) {
      return navigate(`/changue-pass?ctx=${queryString.parse(location.search).ctx}`);
    }

    if (localStorage.getItem("token")) {
      navigate("/home");
    } else {
      navigate("/login");
    }
  }, [localStorage.getItem("token")]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
