import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import {addImport, editImport} from "../../Servicios/ComponedoresService";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {errorMessage} from "../../utils/toast-message";
import {showProvinces, showCountries} from "../../Servicios/CombosService";
import Spinner from "../../shared/spinner";

const initImportadora = {
  id: "",
  impName: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  state: "",
  city: "",
  postalCode: "",
  phoneNumber: "",
  idProvince: "",
  idCountry: "0",
};

const ImportadoraForm = ({ importadora, onCancel, afterSubmit, titulo, esConsignee, country }) => {

  const [nuevaImportadora, setNuevaImportadora] = useState(initImportadora);
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init().then(() => {
      if (importadora) {
        setNuevaImportadora(importadora);
      } else {
        setNuevaImportadora({
          ...initImportadora,
          idCountry: country ?? '0',
        });
      }
    });
  }, [importadora]);

  const init = async () => {
    if (countries.length === 0) {
      const countries = await showCountries();
      setCountries(countries);
    }

    if (provinces.length === 0) {
      const provinces = await showProvinces();
      setProvinces(provinces);
    }
  }

  const handleChange = e => {
    const {name, value} = e.target;

    if (name === 'idCountry') {
      setNuevaImportadora({
        ...nuevaImportadora,
        [name]: value,
        idProvince: ''
      });
    } else {
      setNuevaImportadora({
        ...nuevaImportadora,
        [name]: value
      });
    }
  }

  const insertarImport = async (input) => {
    setLoading(true);
    const data = await addImport(input);
    setLoading(false);
    if (data && data.status) {
      afterSubmit(data.data);
    } else {
      errorMessage(data.message);
    }
  }

  const editarImport = async (input) => {
    setLoading(true);
    const data = await editImport(importadora.id, input);
    setLoading(false);
    if (data && data.status) {
      afterSubmit(data.data);
    } else {
      errorMessage(data.message);
    }
  }

  const handleSubmit = () => {
    if (nuevaImportadora.impName === "") {
      errorMessage("El nombre es requerido");
      return;
    }

    if (nuevaImportadora.addressLine1 === "") {
      errorMessage("La dirección es requerida");
      return;
    }

    const input = {
      ...nuevaImportadora,
      consignee: !!esConsignee,
      idProvince: nuevaImportadora.idProvince !== ""
        ? +nuevaImportadora.idProvince
        : null
    }

    if (importadora) {
      editarImport(input).then();
    } else {
      insertarImport(input).then();
    }
  }

  return(
    <Spinner loading={loading}>
      <Form.Label>Nombre de {titulo ?? 'Importadora'}</Form.Label>
      <Form.Control onChange={handleChange}
                    name='impName'
                    value={nuevaImportadora?.impName}
                    type="text"
                    placeholder={`Nombre ${titulo ?? 'Importadora'}`}/>
      <br/>
      <Form.Label>Dirección linea 1</Form.Label>
      <Form.Control onChange={handleChange}
                    name='addressLine1'
                    type="text"
                    value={nuevaImportadora?.addressLine1}
                    placeholder="Dirección Linea 1"/>
      <br/>
      <Form.Label>Dirección linea 2</Form.Label>
      <Form.Control onChange={handleChange}
                    name='addressLine2'
                    type="text"
                    value={nuevaImportadora?.addressLine2}
                    placeholder="Dirección Linea 2"/>
      <br/>
      <div className="row">
        <div className="col-6">
          <Form.Label>Country</Form.Label>
          <Form.Control onChange={handleChange}
                        disabled={!!country}
                        name='idCountry'
                        value={nuevaImportadora?.idCountry}
                        as="select"
                        placeholder="País">
            <option>Seleccione</option>
            {
              countries.map((country, index) => {
                return <option key={'country-' + index} value={country.id}>{ country.nameCountry }</option>
              })
            }
          </Form.Control>
        </div>
        <div className="col-6">
          <Form.Label>Departamento</Form.Label>
          <Form.Control onChange={handleChange} name='idProvince' as='select' value={nuevaImportadora?.idProvince}>
            <option>Seleccione</option>
            {
              provinces
                .filter(province => +nuevaImportadora.idCountry === +province.idCountry)
                .map((province, index) => (
                  <option key={'province-' + index} value={province.id}>{ province.name }</option>
                ))
            }
          </Form.Control>
        </div>
      </div>
      <br/>
      <Form.Label>Ciudad</Form.Label>
      <Form.Control onChange={handleChange} name='city' value={nuevaImportadora?.city} type="text" placeholder="Ciudad"/>
      <br/>
      <div className="row">
        <div className="col-4">
          <Form.Label>Codigo Postal</Form.Label>
          <Form.Control onChange={handleChange} name='postalCode' value={nuevaImportadora?.postalCode} type="text" placeholder="Codigo Postal"/>
        </div>
        <div className="col-8">
          <Form.Label>Telefono</Form.Label>
          <Form.Control onChange={handleChange} name='phoneNumber' value={nuevaImportadora?.phoneNumber} type="text" placeholder="Telefono"/>
        </div>
      </div>
      <div className="text-end mt-3">
        <Button variant="secondary" className="me-3" onClick={onCancel}>Cancelar</Button>
        <Button variant="primary" onClick={handleSubmit}><FontAwesomeIcon icon={faFloppyDisk}/> {importadora ? 'Actualizar' : 'Guardar'}</Button>
      </div>
    </Spinner>
  );
}

export default ImportadoraForm;
