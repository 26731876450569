import React from "react";
import Sidebar from "../../Componentes/Sidebar";
import HeaderComponent from "../../Componentes/Header";
import {  Layout, theme} from "antd";
import { Outlet } from "react-router-dom";
import "./index.css";

const { Content } = Layout;

const MainLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{height: '100% !important'}} className="simi-main-layout">
      <Sidebar />
      <Layout>
        <HeaderComponent />
        <Content className="simi-main-content">
          <div className="simi-main-content--panel" style={{background: colorBgContainer}}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
