export const permisos = {
    
    AGREGAR_CLIENTE: 'AGREGAR_CLIENTE',
    MODIFICAR_CLIENTE: 'MODIFICAR_CLIENTE',
    ELIMINAR_CLIENTE: 'ELIMINAR_CLIENTE',

    VER_CLIENTE: 'VER_CLIENTE',
    VER_CLIENTE_SALVADOR: 'VER_CLIENTE_SALVADOR',
    VER_CLIENTE_GUATEMALA: 'VER_CLIENTE_GUATEMALA',
    VER_CLIENTE_CR: 'VER_CLIENTE_CR',
    VER_CLIENTE_HONDURAS: 'VER_CLIENTE_HONDURAS',

    REPORTE_COMENT_CLIENTES: 'REPORTE_COMENT_CLIENTES',

    VER_COORDINACION_CA: 'VER_COORDINACION_CA',
    VER_COORDINACION_FL: 'VER_COORDINACION_FL',
    AGREGAR_COORDINACION: 'AGREGAR_COORDINACION',
    MODIFICAR_COORDINACION: 'MODIFICAR_COORDINACION',
    VER_SOLICITUDES: 'VER_SOLICITUDES',
    VER_TITULOS_PENDIENTES: 'VER_TITULOS_PENDIENTES',
    EDITAR_COOR_OFFICE: 'EDITAR_COOR_OFFICE',

    AGREGAR_COMENTARIO_CLIENTE: 'AGREGAR_COMENTARIO_CLIENTE',
    AGREGAR_IMPORTADORA: 'AGREGAR_IMPORTADORA',
    AGREGAR_ADUANA: 'AGREGAR_ADUANA',
    AGREGAR_SEGMENTO: 'AGREGAR_SEGMENTO',
    AGREGAR_TRANSPORTE: 'AGREGAR_TRANSPORTE',
    AGREGAR_SOLICITUDES: 'AGREGAR_SOLICITUDES',
    AGREGAR_WEREHOUSE: 'AGREGAR_WEREHOUSE',
    AGREGAR_SUBASTAS: 'AGREGAR_SUBASTAS',

    RECIBO_DE_VEHICULOS: 'RECIBO_DE_VEHICULOS',
    RECEIVING_CA: 'RECEIVING_CA',
    RECEIVING_FL: 'RECEIVING_FL',
    EDITAR_VEHICULOS: 'EDITAR_VEHICULOS',
    RECIBIDOS_HOY_FL: 'RECIBIDOS_HOY_FL',
    RECIBIDOS_HOY_CA: 'RECIBIDOS_HOY_CA',

    INGRESO_OFICINA: 'INGRESO_OFICINA',

    CATALOGO_TIPETITLES: 'CATALOGO_TIPETITLES', 

    WAREHOUSE_FL: 'WAREHOUSE_FL',
    WAREHOUSE_CA: 'WAREHOUSE_CA',

    EXPORTATIONS: 'EXPORTATIONS',
    EXPORTATION_AGREGAR: 'EXPORTATION_AGREGAR',

    QUOTATION: 'QUOTATION',

    MULTIPLES_SOLICITUDES: 'MULTIPLES_SOLICITUDES',
    VER_RECIBIDOS_EN_CA: 'VER_RECIBIDOS_EN_CA',

    REPORT_COMENT_BY_USERS:'REPORT_COMENT_BY_USERS',
    INSPECCION_VEHICULO: 'INSPECCION_VEHICULO',

    VER_SOLICITUDES_HISTORIAL: 'VER_SOLICITUDES_HISTORIAL',
    VER_SOLICITUDES_REPORTE: 'VER_SOLICITUDES_REPORTE',

    VER_REPORTE_STORAGE: 'VER_REPORTE_STORAGE',
    PAGOS_TRANSPORTISTAS: 'PAGOS_TRANSPORTISTAS',

    PLANIFICACION_CONTENEDOR_VER: 'PLANIFICACION_CONTENEDOR_VER',
    PLANIFICACION_CONTENEDOR_AGREGAR: 'PLANIFICACION_CONTENEDOR_AGREGAR',
    PLANIFICACION_CONTENEDOR_CONEAR: 'PLANIFICACION_CONTENEDOR_CONEAR',

    VEHICULO_MODIFICAR_TITULO_RECIBIDO: 'VEHICULO_MODIFICAR_TITULO_RECIBIDO'
}