import React, {useState, useEffect}from "react";
import { Card, Row, Col } from "antd";
import Typography from "@mui/material/Typography";
import WalletCountCard from "../../../Componentes/widgets/wallletCountCard";
import { useTheme } from "@mui/material/styles";
import todoterreno from "../../../image/todoterreno.png";
import { totalVehiculosEnBodegaPerOrigen } from "../../../Servicios/Home";


const TotalVehicleReceived = () => {

    const theme = useTheme();

    const [TotalVehicle, setTotalVehicle] = useState({
        ayer: {
            california: 0,
            florida: 0,
        },
        hoy: {
            california: 0,
            florida: 0,
        },
    });
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        totalVehiculosEnBodegaPerOrigen().then((data) => {
            setTotalVehicle(data);
        });
    } , []);

  return (
    <Card
      title="Total de Vehiculos Recibidos"
      style={{
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        height: "100%",
      }}
      loading={loading}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h5">Ayer</Typography>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{
                marginBottom: "20px",
            }}>
              <WalletCountCard
                primary="California"
                secondary={TotalVehicle.ayer.california}
                color={theme.palette.info.light}
                    iconPrimary={todoterreno} 
                />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{
                marginBottom: "20px",
            }}>
              <WalletCountCard
                primary="Florida"
                secondary={TotalVehicle.ayer.florida}
                color={theme.palette.info.light}
                iconPrimary={todoterreno} 

              />
            </Col>
          </Row>
        </Col>
        
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row gutter={24}>
                <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                }}
                >
                <Typography variant="h5">Hoy</Typography>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{
                    marginBottom: "20px",
                }}>
                <WalletCountCard
                    primary="California"
                    secondary={TotalVehicle.hoy.california}
                    color="#60E396"
                    iconPrimary={todoterreno} 

                />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{
                    marginBottom: "20px",
                }}>
                <WalletCountCard
                    primary="Florida"
                    secondary={TotalVehicle.hoy.florida}
                    color="#60E396"
                    iconPrimary={todoterreno} 

                />
                </Col>
            </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default TotalVehicleReceived;
