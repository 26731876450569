import React, {useState} from "react";
import {Button, Drawer, Image, Spin} from "antd";

import moment from "moment";
import DetalleExportInfo from "../../views/Exportations/PlanifContenedores/DetalleExportInfo";
import ShowWhen from "../ShowWhen/ShowWhen";
import {validarPermisos} from "../../Servicios/AuthService";
import {permisos} from "../../utils/permisos";

const DetailExportationDrawer = ({open, onClose, exportationContainer, isLoading, conear}) => {
  return (
    <Drawer
      title="Detalle de la exportación"
      placement="right"
      closable={true}
      onClose={onClose}
      open={open}
    >
      <Spin spinning={isLoading} tip="Cargando..." size="large">
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Shipment Name:</strong>
          <span>{exportationContainer?.exportation?.shipmentName}</span>
        </p>

        {/* SECUENCIA */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Secuencia:</strong>
          <span>{exportationContainer?.sequence}</span>
        </p>

        {/* FECHA DE EXPORTACIÓN */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Fecha:</strong>
          <span>{moment(exportationContainer?.loadingDate).format("DD/MM/YYYY")}</span>
        </p>

        {/* Cono asignado */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Cono asignado:</strong>
          <span className="badge"
                style={{background: exportationContainer?.colorCono?.codigo}}>{exportationContainer?.colorCono?.name}</span>
        </p>

        {/* Naviera */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Naviera:</strong>
          <span>{exportationContainer?.carrier?.oceanCarrierName}</span>
        </p>
        {/* NUMERO DE CONTENEDOR */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Número de contenedor:</strong>
          <span>{exportationContainer?.containerNumber}</span>
        </p>
        {/* SELLO */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Sello:</strong>
          <span>{exportationContainer?.sealNumber}</span>
        </p>
        {/* BOOKING */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Booking:</strong>
          <span>{exportationContainer?.exportation?.bookingNum}</span>
        </p>
        {/* Taño de contenedor */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Tamaño de contenedor:</strong>
          <span>{exportationContainer?.container?.nameContainer}</span>
        </p>

        {/* Destino */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Destino:</strong>
          <span>{exportationContainer?.aduana?.nameAduana}</span>
        </p>

        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Capitán:</strong>
          <span>{exportationContainer?.captain}</span>
        </p>

        {/* CANTIDAD */}
        <p style={{display: "flex", justifyContent: "space-between"}}>
          <strong>Cantidad:</strong>
          <span>{exportationContainer?.detalleExport?.length}</span>
        </p>

        <ShowWhen show={!exportationContainer?.conePlaced && validarPermisos(permisos.PLANIFICACION_CONTENEDOR_CONEAR)}>
          <Button type={'primary'} block={true} onClick={conear}>Marcar como Coneado</Button>
        </ShowWhen>

        <hr/>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <h5>Detalles de la exportación</h5>
        </div>
        <hr/>

        {
          exportationContainer?.detalleExport?.map((detail, index) => (
            <div key={index}>
              <DetalleExportInfo detalleExport={detail}/>
              <hr/>
            </div>
          ))
        }
      </Spin>
    </Drawer>
  );
};

export default DetailExportationDrawer;
