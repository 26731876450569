import React, {useState, useEffect} from 'react';
import {Form, Container, Col, Row, Button} from 'react-bootstrap';
import {useParams, Link, useNavigate, useSearchParams} from "react-router-dom";
import {cancelQuote, editQuote, viewQuote} from '../Servicios/SolicitudesService';
import useCurrentUser from "../Hooks/UserHook";
import {enviarEmail} from "../Servicios/SolicitudesService";
import {ToastContainer, toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckDouble, faLeftLong, faPaperPlane, faTimes} from '@fortawesome/free-solid-svg-icons';
import getPdf from "../Servicios/Base64ToPdf";
import {getPDFId} from "../Servicios/SolicitudesService";
import Loadingsmall from '../Componentes/Loadingsmall';
import Spinner from '../shared/spinner';
import {
  CarOutlined,
  UserOutlined,
  RiseOutlined,
  CheckCircleOutlined,
  TrophyOutlined,
  DollarCircleOutlined,
  SendOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {SolicitudRecoleccionVehiculo} from "../Resources/HtmlEmailBody/SolicitudRecoleccionVehiculo";
import environment from "../environments/environment";
import CancelarSolicitudBtn from "./CancelarSolicitudBtn";
import {errorMessage, successMessage} from "../utils/toast-message";
import {hasRol, validarPermisos} from "../Servicios/AuthService";
import {permisos} from "../utils/permisos";
import {SolicitudEstadoEnum} from "../shared/enums/solicitud-estado.enum";
import {SolicitudActivaEnum} from "../shared/enums/solicitud-activa.enum";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import roles from "../utils/roles";

const SendPrint = () => {

  const {id} = useParams();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [soli, setSoli] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImprimir, setIsLoadingImprimir] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const puedeAgregarSolicitud = validarPermisos(permisos.AGREGAR_SOLICITUDES);

  const currentUser = useCurrentUser();
  const [showT, setShowT] = useState(false);

  const currentUserEmail = currentUser?.email;

  const [to, setTo] = useState("");
  const [emailsListTo, setEmailsListTo] = useState([environment.emails.grua]);
  //const [emailsListTo, setEmailsListTo] = useState(['juanflores9152@gmail.com']);

  const [cc, setCc] = useState("");
  const [emailsListCC, setEmailsListCC] = useState([]);
  const [cco, setCco] = useState("");
  const [emailsListCCO, setEmailsListCCO] = useState([]);
  const [attach, setAttach] = useState([]);

  const [sendCo, setSendCo] = useState({
    to: emailsListTo,
    cc: emailsListCC,
    cco: emailsListCCO,
    subject: "",
    body: "",
    attachedFile: attach,
  });

  const mostrarPDF = () => {
    setIsLoadingImprimir(true);
    if (id !== undefined && id !== 0)
      getPDFId(id).then((pdf) => {
        getPdf('Solicitud recoleccion', pdf)
      }).finally(() => {
        setIsLoadingImprimir(false);
      });
  }

  const handleShowT = () => {
    setShowT(true);

    let title = soli.subasta.subId === 571
      ? `NOTIFICACIÓN DE ENTREGA EN BODEGA ${soli.origen.state} DE VEHÍCULO, LOTE: ${soli?.lote}`
      : `NOTIFICACIÓN DE SOLICITUD DE RECOLECCIÓN DE VEHÍCULO ${soli.origen.state}, LOTE: ${soli?.lote}`;

    setSendCo({
      ...sendCo,
      subject: `${title}${soli.dealer ? ' CUENTA DEALER' : ''}${soli.cutVehicle ? ' VEHÍCULO PARA CORTE' : ''}`,
      body: SolicitudRecoleccionVehiculo(soli),
    });

    let correos = [];
    correos.push(currentUserEmail);

    if (soli?.emailCli && soli?.emailCli !== "") {
      correos.push(soli?.emailCli);
    }

    if (soli?.emailSecond && soli?.emailSecond !== "") {
      correos.push(soli?.emailSecond);
    }

    setEmailsListCC(correos);
  }

  useEffect(() => {
    setIsLoading(true);
    viewQuote(id, !!params.get('cancelada')).then((infoso) => {
      setSoli(infoso);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [id]);

  useEffect(() => {
    setSendCo({...sendCo, to: emailsListTo})
  }, [emailsListTo])

  useEffect(() => {
    setSendCo({...sendCo, cc: emailsListCC})
  }, [emailsListCC])

  useEffect(() => {
    setSendCo({...sendCo, cco: emailsListCCO})
  }, [emailsListCCO])

  useEffect(() => {
    setSendCo({...sendCo, attachedFile: attach})
  }, [attach])

  const agregarTo = (event) => {
    event.preventDefault();
    setEmailsListTo(currentToc => [...currentToc, to]);
    setTo('');
  }

  const handleDeleteTO = (deletingItem) => {
    const lisTo = emailsListTo.filter((item) => item !== deletingItem);
    setEmailsListTo(lisTo);
  }

  const agregarCC = (event) => {
    event.preventDefault();
    setEmailsListCC(currentCC => [...currentCC, cc]);
    setCc('');
  }

  const handleDeleteCC = (deletingItem) => {
    const lisCC = emailsListCC.filter((item) => item !== deletingItem);
    setEmailsListCC(lisCC);
  }

  const agregarCCO = (event) => {
    event.preventDefault();
    setEmailsListCCO(currentCCO => [...currentCCO, cco]);
    setCco('');
  }

  const handleDeleteCCO = (deletingItem) => {
    const lisCCO = emailsListCCO.filter((item) => item !== deletingItem);
    setEmailsListCCO(lisCCO);
  }

  const handleDeleteAttch = (deletingItem) => {
    const lisattc = attach.filter((id) => id !== deletingItem);
    setAttach(lisattc);
  }

  const agregarGT = () => {
    setEmailsListCC(currentGT => [...currentGT, "pagosgt@matusintl.com", "logisticagt@matusintl.com"]);
  }

  const agregarHN = () => {
    setEmailsListCC(currentHN => [...currentHN, "honduras@matusintl.com"]);
  }

  // metodo para convertir files a base64
  const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

  // guardar los files en un arreglo
  const handleFileChange = async (e) => {
    const files = e.target.files;

    const temp = [...attach];
    for (let i = 0; i < files.length; i++) {
      const base64 = await convertFileToBase64(files[i]);
      const nombre = files[i].name;

      temp.push({nombreArchivo: nombre, archivo: base64});
    }
    setAttach([...temp]);
  }

  const handleFileChangeDrag = async (fileList) => {
    setSelectedFiles(fileList);
  };

  const handleCloseT = () => {
    setEmailsListCC([]);
    setShowT(false);
  }

  const enviarCorreo = async () => {
    enviarEmail(sendCo).then(() => {
      notify();
      handleCloseT();
      setAttach([]);
    })
  }

  const handleChange = e => {
    const {name, value} = e.target;
    setSendCo({
      ...sendCo,
      [name]: value
    });
  }

  const eliminarUP = () => {
    setAttach([]);
  }

  const notify = () => toast.success("Revise su bandeja de correo", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleReactivarSolicitud = async () => {
    const input = {
      fechaQ: soli.fechaQ,
      buyer: soli.buyer,
      costoQ: soli.costoQ ? soli.costoQ.toString() : "",
      quoted: soli.quoted ? soli.quoted.toString() : "",
      creador: soli.creador,
      registradoBy: soli.registradoBy,
      bl: soli.bl ? soli.bl.toString() : "",
      estGrua: soli.estGrua ? soli.estGrua.toString() : "",
      estado: soli.coorAsign !== null && soli.coorAsign !== 0
        ? SolicitudEstadoEnum.Asignado
        : SolicitudEstadoEnum.SinAsignar,
      activa: SolicitudActivaEnum.Activa,
      proceso: soli.proceso,
      coorAsign: soli.coorAsign,
      tipoPago: soli.tipoPago,
      comentarios: "",
      gatePass: soli.gatePass,
      dealer: soli.dealer,
      aduanaId: soli.aduanaId,
      origenId: soli.origenId,
      clienteId: soli.clienteId,
      vehiculoId: soli.vehiculoId,
      subastaId: soli.subastaId,
      countryId: soli.countryId,
      importadoraId: soli.importadoraId,
      userCreatedId: soli.userCreatedId,
      userAssignedId: soli.userAssignedId,
      userSellerId: soli.userSellerId,
      cutVehicle: soli.cutVehicle,
      idClienteFinal: soli.idClienteFinal,
      sendedToReview: soli.sendedToReview,
      pendingToComplete: soli.pendingToComplete,
    }

    setIsLoading(true);
    const data = await editQuote(soli.quoteId, input)
    setIsLoading(false);

    if (data && data.status) {
      successMessage("La solicitud fue reactivada correctamente");
      navigate('/requests/list');
    } else {
      errorMessage(data.message);
    }
  }

  const handleCancelarSolicitud = async (description) => {
    setIsLoading(true);
    const data = await cancelQuote(soli.quoteId, {comentarios: description})
    setIsLoading(false);

    if (data) {
      successMessage("La solicitud fue cancelada correctamente");
      navigate('/requests/list');
    }
  }

  return (
    <Container>
      <Spinner loading={isLoading}>
        <Row className="justify-content-md-center">
          <h4>Solicitud de Recolección</h4>
        </Row>
        <hr/>
        <Row className="mb-3">
          <Col lg={12} xs={12}>
            <Button style={{width: '120px'}} as={Link} to={`/requests/list`} variant="warning">
              <FontAwesomeIcon icon={faLeftLong}/> Regresar
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={12} xs={12}>
            <div className="d-flex align-items-center justify-content-start gap-2">
              {
                soli && soli.estado !== SolicitudEstadoEnum.Cancelado &&
                <Button onClick={handleShowT} type="primary">
                  <SendOutlined/> Enviar correo
                </Button>
              }
              {
                soli && soli.estado !== SolicitudEstadoEnum.Cancelado &&
                <Button onClick={mostrarPDF}>
                  <PrinterOutlined/> Imprimir
                </Button>
              }
              {
                puedeAgregarSolicitud && soli && soli.estado !== SolicitudEstadoEnum.Cancelado && hasRol(roles.ADMIN, roles.SUPERVISOR) &&
                <CancelarSolicitudBtn onCancelar={handleCancelarSolicitud}/>
              }
              {
                soli && soli.estado === SolicitudEstadoEnum.Cancelado &&
                <Button onClick={handleReactivarSolicitud}>
                  <FontAwesomeIcon icon={faCheckDouble} /> Reactivar Solicitud
                </Button>
              }
            </div>
          </Col>
          <Col xs="12" lg="12" className="mt-3">
            {isLoadingImprimir ? <div className="text-center"><Loadingsmall/></div> : <></>}
          </Col>
        </Row>
        <br/>
        <Row className="justify-content-md-center">
          <Col xs lg="6">

            <Row style={{alignItems: "flex-end"}}>
              <strong>{soli?.fechaQPdf}</strong>
            </Row>
            <Row>
              <Col xs="1" lg="1">
                <CarOutlined/>
              </Col>
              <Col>
                Lote #: {soli?.lote} <br/>
                Vehiculo: {soli?.vehiculoName} <br/>
                VIN #: {soli?.vin} <br/>
                Buyer #: {soli?.buyer}
                <ShowWhen show={soli?.vehiculo?.title}>
                  <br/>
                  Tipo de Título: {soli?.vehiculo?.title?.titleName}
                </ShowWhen>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col xs="1" lg="1">
                <TrophyOutlined/>
              </Col>
              <Col>
                Subasta: {soli?.subName} <br/>
                Dirección: {soli?.direccion}
              </Col>
            </Row>
            <br/>
            <Row>
              <Col xs="1" lg="1">
                <UserOutlined/>
              </Col>
              <Col>
                Cliente: {soli?.clienteName} <br/>
                Importadora: {soli?.import}
              </Col>
            </Row>
            <br/>
            {
              soli?.clienteFinal && (<>
                  <Row>
                    <Col xs="1" lg="1">
                      <UserOutlined/>
                    </Col>
                    <Col>
                      Cliente Final: {soli?.clienteFinal?.nombreCompleto} <br/>
                      Dirección: {soli?.clienteFinal?.address} <br/>
                      Email: {soli?.clienteFinal?.email} <br/>
                      Telefono: {soli?.clienteFinal?.contNum}
                    </Col>
                  </Row>
                  <br/></>

              )
            }
            <Row>
              <Col xs="1" lg="1">
                <RiseOutlined/>
              </Col>
              <Col>
                Origen: {soli?.nameOrigen}
              </Col>
            </Row>
            <br/>
            <Row>
              <Col xs="1" lg="1">
                <CheckCircleOutlined/>
              </Col>
              <Col>
                Destino: {soli?.countryName} <br/>
                Almacenadora: {soli?.aduanaName}
              </Col>
            </Row>
            <br/>
            <Row>
              <Col xs="1" lg="1">
                <DollarCircleOutlined/>
              </Col>
              <Col>
                Flete: $ {soli?.n1} <br/>
                Grua: $ {soli?.n2} <br/>
                BL: $ {soli?.n3} <br/>
                Costo Adicional: $ {soli?.n4} <br/>
                Total: $ {soli?.total}
                <br/>

                {
                  soli?.SendedToReview &&
                  <>
                    <p className="m-0 mt-3">Motivo de Revisión:</p>
                    <p className="m-0">{soli?.proceso}</p>
                  </>
                }

                <br/>
                Registrada por: {soli?.registradoBy} <br/>
                Vendedor: {soli?.creador}

              </Col>
              <ToastContainer/>
            </Row>
          </Col>
        </Row>
      </Spinner>
      <>
        <Modal
          show={showT}
          onHide={handleCloseT}
          backdrop="static"
          keyboard={false}
          className="emailsoli"
        >
          <Modal.Header closeButton>
            <Modal.Title>Enviando solicitud de vehiculo: {soli?.vehiculoName} lote: {soli?.lote}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row className="mb-3">
              <Col xs={12} lg="3">
                <Button onClick={agregarGT} variant="secondary">+CC, GT</Button>
              </Col>
              <Col xs={12} lg="3">
                <Button onClick={agregarHN} variant="secondary">+CC, HN</Button>
              </Col>
            </Row>

            <Form.Label><strong>Para:</strong></Form.Label>
            {emailsListTo?.map((item, index) => {
              return <li key={index}
                         className="mb-2">
                {item}
                <button className="btn btn-sm btn-outline-primary"
                        onClick={() => handleDeleteTO(item)}><FontAwesomeIcon icon={faTimes}/></button>
              </li>
            })}
            <Row>
              <Col xs={12} lg="8">
                <Form.Control value={to} onChange={(e) => setTo(e.target.value)} aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"/>
              </Col>
              <Col xs={12} lg="2">
                <Button onClick={agregarTo} variant="primary">Agregar</Button>
              </Col>
            </Row>
            <Row>
              <Form.Label><strong>CC:</strong></Form.Label>
              {emailsListCC?.map((item, index) => {
                return <li key={index} className="mb-2">{item}
                  <button className="btn btn-sm btn-outline-primary"
                          onClick={() => handleDeleteCC(item)}><FontAwesomeIcon icon={faTimes}/></button>
                </li>
              })}
              <Col xs={12} lg="8">
                <Form.Control value={cc} onChange={(e) => setCc(e.target.value)} aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"/>
              </Col>
              <Col xs={12} lg="2">
                <Button onClick={agregarCC} variant="primary">Agregar</Button>
              </Col>
            </Row>
            <Row>
              <Form.Label><strong>CCO:</strong></Form.Label>
              {emailsListCCO?.map((item, index) => {
                return <li key={index} className="mb-2">{item}
                  <button className="btn btn-sm btn-outline-primary"
                          onClick={() => handleDeleteCCO(item)}><FontAwesomeIcon icon={faTimes}/></button>
                </li>
              })}
              <Col xs={12} lg="8">
                <Form.Control value={cco} onChange={(e) => setCco(e.target.value)} aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"/>
              </Col>
              <Col xs={12} lg="2">
                <Button onClick={agregarCCO} variant="primary">Agregar</Button>
              </Col>
            </Row>
            <Form.Label><strong>Asunto:</strong></Form.Label>
            <Form.Control name="subject" onChange={handleChange} value={sendCo?.subject} as="textarea" rows={2}/>

            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Label><strong>Adjuntar archivos</strong></Form.Label>
              <Form.Control type="file"
                            name="attachedFile"
                            onClick={eliminarUP}
                            onChange={handleFileChange}
                            multiple/>
            </Form.Group>
            {attach?.map((attach, key) => {
              return <li key={key}>{attach.id}{attach.nombreArchivo}</li>
            })}

            <Form.Label><strong>Mensaje</strong></Form.Label>
            <Form.Control disabled readOnly name="body"
                          value="**El mensaje esta predeterminado por el sistema con los datos ingresados en la solicitud**
                - Asegurarse que los correos esten bien escritos y aparezcan en la lista agregados" as="textarea"
                          rows={3}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseT}>
              Cancelar
            </Button>
            <Button onClick={enviarCorreo} variant="primary"><FontAwesomeIcon icon={faPaperPlane}/> Enviar</Button>
          </Modal.Footer>
        </Modal>
      </>
    </Container>

  )
}

export default SendPrint;
