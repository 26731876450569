import {DatePicker, Form, Input, InputNumber, Select} from "antd";
import React, {useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTimes} from "@fortawesome/free-solid-svg-icons";
import ShowWhen from "../ShowWhen/ShowWhen";
import dayjs from "dayjs";
import {StringFormats} from "../../utils/string-formats";

interface IEditableCell {
  type: 'date' | 'select' | 'number' | 'numberText' | 'text',
  value: string;
  selectList?: { value: string, label: string }[];
  onSubmit: () => void;
  editable: boolean;
  children: any;
}

const EditableCell = (props: IEditableCell) => {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const [showInput, setShowInput] = useState(false);

  const handleShowInput = () => {
    if (!props.editable) return;
    setShowInput(true);

    setTimeout(() => {
      inputRef.current.focus();
    }, 500);
  }

  const handleChangeSelect = event => {
    setShowInput(false);
    if (props.onSubmit) {
      props.onSubmit(event);
    }
  }

  const handleChangeText = event => {
    if (props.onSubmit) {
      props.onSubmit(event.target.value);
    }
  }

  const handleChangeDate = event => {
    setShowInput(false);
    if (event && event.$d && props.onSubmit) {
      props.onSubmit(StringFormats.dateDB(event.$d));
    }
  }

  const inputTypes = {
    'date': <>
      <DatePicker ref={inputRef}
                  className="w-1001"
                  format="YYYY-MM-DD"
                  defaultValue={props.value === "--/--/----" ? undefined : dayjs(props.value)}
                  onChange={handleChangeDate}></DatePicker>
    </>,
    'select': <>
      <Select ref={inputRef}
              className="w-100"
              defaultValue={{value: props.value, label: props.value}}
              options={props.selectList ?? []}
              onChange={handleChangeSelect}></Select>
    </>,
    'number': <>
      <InputNumber ref={inputRef}
                   className="w-100"
                   defaultValue={!props.value || props.value === "--" ? undefined : +props.value.replace('$', '')}
                   onBlur={handleChangeText}></InputNumber>
    </>,
    'text': <>
      <Input ref={inputRef}
             className="w-100"
             defaultValue={props.value}
             onBlur={handleChangeText}></Input>
    </>
  }

  const handleBlur = event => {
    event.preventDefault();
    event.stopPropagation();
    setShowInput(false);
  }

  if (showInput) {
    return (
      <div className="cell-editable" onBlur={handleBlur}>
        <Form form={form} className="w-100">
          {inputTypes[props.type]}
        </Form>
        <ShowWhen show={props.type !== "select"}>
          <FontAwesomeIcon className="text-danger"
                           icon={faTimes}
                           onClick={() => setShowInput(false)}/>
        </ShowWhen>
      </div>
    );
  }

  return (
    <div className="cell-editable w-100">
      <p className="m-0" onClick={handleShowInput}>{props.children}</p>
      <ShowWhen show={props.editable}>
        <FontAwesomeIcon icon={faPencil} onClick={handleShowInput} className="icon" />
      </ShowWhen>
    </div>
  );
};

export default EditableCell;
