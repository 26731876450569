import ExportationTable from "./ExportationTable";
import {useEffect, useState} from "react";
import {getExportations} from "../../Servicios/ExportationServices";
import Spinner from "../../shared/spinner";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import {ExportationStateEnum} from "../../shared/enums/exportation-state.enum";
import {deleteAllWareHouse} from "../../store/whareHouse";
import {getFetchExportationShipmentName} from "../../store/exportation";
import {useDispatch} from "react-redux";

const ExportationNavieraPage = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [exportations, setExportations] = useState([]);
  const [pagination , setPagination] = useState({current: 1, pageSize: 5, total: 0 });

  useEffect(() => {
    initExportations(pagination.current, pagination.pageSize, search).then();
  }, []);

  const initExportations = async (currentPage, pageCount, search = "") => {
    setLoading(true);
    const data = await getExportations(0, currentPage, pageCount, search, ExportationStateEnum.ShippingInstruction);
    setLoading(false);

    if (data && data.status) {
      setExportations(data.list.map((e, i) => ({...e, key: i})));

      if (pagination.total === 0) {
        setPagination({ ...pagination, total: data.totalElements });
      }
    }
  }

  const handlePageChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
    });

    initExportations(pagination.current, pagination.pageSize, search).then();
  }

  const handleSearch = (value) => {
    setSearch(value);
    setPagination({...pagination, current: 1})
    initExportations(1, pagination.pageSize, value).then();
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="mb-3">
          <InputSearch placeholder={'Search by shipping name or booking number'}
                       doChange={handleSearch} />
        </div>
        <ExportationTable pagination={pagination}
                          exportations={exportations}
                          onPageChange={handlePageChange}
                          onEditExportation={async (exportation) => {
                            await dispatch(deleteAllWareHouse([]));
                            await dispatch(getFetchExportationShipmentName(exportation.shipmentName));
                          }} />
      </Spinner>
    </>
  );
}

export default ExportationNavieraPage;
