import React, {useEffect, useState} from "react";
import {showImportadora} from "../../Servicios/CombosService";
import Select from "react-select";

const InputSearchImportadora = ({ selectedImportadora, onSelectImportadora }) => {
  const pageCount = 100;

  const [searchName, setSearchName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [importadoraList, setImportadoraList] = useState([]);
  const [searchNameDebounce, setSearchNameDebounce] = useState("");

  useEffect(() => {
    loadImportadoraList(currentPage, searchNameDebounce).then();
  }, [currentPage, searchNameDebounce, selectedImportadora]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchName !== null) {
        setSearchNameDebounce(searchName);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchName, 500]);

  const loadImportadoraList = async (currentPage, searchName = "") => {
    setLoading(true);
    const data = await showImportadora(searchName, searchName !== "" ? 1 : currentPage, pageCount);
    setLoading(false);

    if (selectedImportadora) {
      const list = data.list;
      if (!importadoraList.some(e => e.id === selectedImportadora.id)) {
        list.push(selectedImportadora);
      }

      setImportadoraList(currentPage === 1 ? [...list] : [...importadoraList, ...list]);
    } else {
      setImportadoraList(currentPage === 1 ? [...data.list] : [...importadoraList, ...data.list]);
    }
  };

  const handleSearchable = (event) => {
    event.preventDefault();
    return true;
  }

  const handleChange = (event) => {
    if (onSelectImportadora) {
      onSelectImportadora(event.importadora);
    }
  }

  return(
    <>
      <Select name="searchImportadora"
              isSearchable={handleSearchable}
              onChange={handleChange}
              isLoading={isLoading}
              defaultValue={{ label: "Escriba y Seleccione una opción", value: "empty" }}
              onInputChange={(inputValue) => {
                setCurrentPage(1);
                setSearchName(inputValue);
              }}
              value={!selectedImportadora ? undefined : {
                value: selectedImportadora.id,
                label: `${selectedImportadora.impName}`,
                importadora: selectedImportadora
              }}
              options={importadoraList && importadoraList?.length
                ? importadoraList?.map(importadora => ({
                  label: `${importadora.impName}`,
                  value: importadora.id,
                  importadora: importadora
                }))
                : []
              }
      />
    </>
  );
}

export default InputSearchImportadora;
