import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { addQuote } from "../Servicios/SolicitudesService";
import Select from "react-select";
import {
  showSubasta,
  showAduana,
  showOrigen,
  showVehiclesoli,
} from "../Servicios/CombosService";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import {
  faFloppyDisk,
  faCheck,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import Card from "react-bootstrap/Card";
import car from "../image/card.png";
import useCurrentUser from "../Hooks/UserHook";
import Modal from "react-bootstrap/Modal";
import { Modal as ModalAntD } from "antd/lib";
import Spinner from "../shared/spinner";
import SpinnerTable from "../Componentes/SpinnerTable";
import DataTable from "react-data-table-component";
import VehiculoForm from "../Componentes/VehiculoForm/VehiculoForm";
import { errorMessage } from "../utils/toast-message";
import { getUserSellers } from "../Servicios/UsuarioService";
import ClienteForm from "../Carteracliente/ClienteForm";
import InputSearchCustomer from "../Componentes/InputSearchCustomer/InputSearchCustomer";
import { getCountriesId } from "../Servicios/AuthService";
import SubastaForm from "../Componentes/SubastaForm/SubastaForm";
import {
  Tooltip,
  Button as ButtonAnt,
  Steps,
  theme,
  Alert,
  Popconfirm,
} from "antd";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <Form.Control
      className="input-buscar"
      id="search"
      type="text"
      placeholder="Buscar Vehiculo por Lote"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

const Solicitud = () => {
  let { id } = useParams();
  const currentUser = useCurrentUser();

  const [subastas, setSubastas] = useState([]);
  const [aduanas, setAduanas] = useState([]);
  const [origen, setOrigen] = useState([]);

  const [listaVehi, setListaVehi] = useState([]); // eslint-disable-line no-unused-vars
  const [infovehi, setInfovehi] = useState([]);

  const [isSubmitted, setSubmitted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const [selectedUser, setSelectedUser] = useState(currentUser?.fullName);
  const [gatePassRequired, setGatePassRequired] = useState(false);

  const [userList, setUserList] = useState([]);

  const [selectedClient, setSelectedClient] = useState(undefined);
  const [selectedSubasta, setSelectedSubasta] = useState(undefined);
  const [selectedFinalClient, setSelectedFinalClient] = useState(undefined);
  const [selectedImportadora, setSelectedImportadora] = useState(undefined);

  const [showModalClient, setShowModalClient] = useState(false);
  const [showModalFinalClient, setShowModalFinalClient] = useState(false);

  const [showSubastaSelect, setShowSubastaSelect] = useState(false);

  const [isCommentInternal, setIsCommentInternal] = useState(false);

  const [showA, setShowA] = useState(false);
  const handleCloseA = () => setShowA(false);
  const handleShowA = () => setShowA(true);

  const navigate = useNavigate();
  const idpais = currentUser?.idpais;

  var today = new Date();
  var day = today.getDate();
  var month = today.getMonth() + 1;
  var year = today.getFullYear();
  const fechaactual = `${day}/${month}/${year}`;
  var moment = require("moment");
  var now = moment().format();

  const [newQuote, setNewQuote] = useState({
    id: "",
    fechaQ: now,
    buyer: "",
    costoQ: null,
    quoted: "",
    creador: "",
    registradoBy: "",
    aduanaId: "",
    origenId: "",
    clienteId: "",
    vehiculoId: id,
    subastaId: "",
    countryId: "",
    importadoraId: "",
    bl: "20",
    estGrua: "",
    estado: "SinAsignar",
    activa: "Activa",
    proceso: "Sin ninguna Accion Realizada",
    coorasign: 0,
    comentarios: "",
    tipoPago: "",
    gatePass: "",
    dealer: false,
    cutVehicle: false,
    sendedToReview: false,
    pendingToComplete: false,
  });

  const formularioEsValido = () => {
    return (
      newQuote.buyer !== "" &&
      newQuote.quoted !== "" &&
      newQuote.estGrua !== "" &&
      newQuote.tipoPago !== "" &&
      newQuote.bl !== "" &&
      newQuote.clienteId !== 0 &&
      (newQuote.subastaId !== 0 || newQuote.subastaId === null) &&
      newQuote.aduanaId !== 0 &&
      newQuote.origenId !== 0 &&
      newQuote.vehiculoId !== 0 &&
      (!gatePassRequired || newQuote.gatePass !== "")
    );
  };

  const loadvehi = async () => {
    setIsLoading(true);
    const data = await showVehiclesoli("NO_SOLICITUD").finally(() => {
      setIsLoading(false);
    });
    setListaVehi(data);
  };

  const loadUserSellers = async () => {
    setIsLoading(true);
    const data = await getUserSellers().finally(() => {
      setIsLoading(false);
    });

    const countriesId = getCountriesId();

    setUserList(
      data.filter((e) => {
        const idPais = e.usr ? e.usr.idpais.toString() : "";
        return countriesId === "0" || countriesId.indexOf(idPais) !== -1;
      })
    );
  };

  const loadSubastas = async () => {
    const data = await showSubasta();
    setSubastas(data);
  };

  const loadAduana = async () => {
    const countriesId = getCountriesId();
    const data = await showAduana();
    setAduanas(
      data.filter(
        (e) => countriesId === "0" || countriesId.indexOf(e.countryId) !== -1
      )
    );
  };

  const loadOrigen = async () => {
    const data = await showOrigen();
    setOrigen(data);
  };

  const insertByTable = (row) => {
    setInfovehi(row);
    setNewQuote({ ...newQuote, vehiculoId: row.vehiculoId });
    loadAduana().then();
    next();
  };

  useEffect(() => {
    loadvehi().then();
  }, []);

  useEffect(() => {
    if (currentUser && currentUser?.idpais != null) {
      // loadClientes(currentUser.idpais);
      loadSubastas().then();
      loadOrigen().then();
      loadUserSellers().then();
      if (currentUser?.idpais !== 0) {
        loadAduana().then();
      }
    }
    setNewQuote({
      ...newQuote,
      registradoBy: currentUser?.fullName,
      creador: currentUser?.fullName,
    });
  }, [currentUser, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewQuote({
      ...newQuote,
      [name]: value,
    });

    if (selectedSubasta && selectedSubasta.subName.indexOf("COPART") !== -1) {
      name === "gatePass" && value !== ""
        ? setGatePassRequired(false)
        : setGatePassRequired(true);
    }
  };

  const handleSelectChangeCliente = (client) => {
    setSelectedClient(client);
    setSelectedImportadora(client.importadora);

    setNewQuote({
      ...newQuote,
      tipoPago: client.classPay,
      clienteId: client.id,
      importadoraId: client.importadoraId, // Guardamos el id de la importadora
    });
  };

  const handleSelectChangeClienteFinal = (client) => {
    setSelectedFinalClient(client);

    setNewQuote({
      ...newQuote,
      idClienteFinal: client.id,
    });
  };

  const handleCancelCustomer = () => {
    if (showModalClient) setShowModalClient(false);
    if (showModalFinalClient) setShowModalFinalClient(false);
  };

  const handleSaveCustomer = async (customer) => {
    if (showModalClient) {
      setSelectedClient(customer);
      setSelectedImportadora(customer.importadora);
      setShowModalClient(false);

      setNewQuote({
        ...newQuote,
        clienteId: customer.id,
        tipoPago: customer.classPay,
        importadoraId: customer.importadoraId,
      });
    }

    if (showModalFinalClient) {
      setSelectedFinalClient(customer);
      setShowModalFinalClient(false);

      setNewQuote({
        ...newQuote,
        idClienteFinal: customer.id,
      });
    }
  };

  const handleSelectChangeSubs = ({ value, label, subasta }) => {
    setNewQuote({ ...newQuote, subastaId: value });
    setSelectedSubasta(subasta);

    if (label.indexOf("COPART") !== -1 && newQuote.gatePass === "") {
      setGatePassRequired(true);
    } else {
      setGatePassRequired(false);
    }
  };

  const handleSelectChangeAdu = (e) => {
    const { value } = e.target;
    const countryId =
      e.target.options[e.target.selectedIndex].dataset.countryid;
    console.log(countryId);
    setNewQuote({
      ...newQuote,
      aduanaId: value,
      countryId: countryId, // Guardamos el id del país
      bl: countryId === "3" ? "0" : "20",
    });
  };

  const handleOnChangeCK = (evt) => {
    const { name, checked } = evt.target;
    setNewQuote({
      ...newQuote,
      [name]: checked,
    });
  };

  const handleOnChangeEB = (evt) => {
    const { checked } = evt.target;
    setNewQuote({
      ...newQuote,
      subastaId: null,
    });

    setShowSubastaSelect(checked);
  };

  const handleVehicleSaved = (vehicle) => {
    setInfovehi(vehicle);
    setNewQuote({ ...newQuote, vehiculoId: vehicle.vehiculoId });
    loadAduana().then();
    notifyV();
    nextSaveVehicle();
  };

  const handleVehicleError = (error) => {
    errorMessage(error);
  };

  const insertarSubasta = async (newSubasta) => {
    setSelectedSubasta(newSubasta);

    setNewQuote({
      ...newQuote,
      subastaId: newSubasta.subId,
      origenId: newSubasta.origen,
    });

    loadSubastas().then();
    handleCloseA();
  };

  const insertarQuote = async () => {
    setIsLoadingSave(true);
    setSubmitted(true);

    if (!formularioEsValido()) {
      setIsLoadingSave(false);
      return;
    }

    setIsLoading(true);
    const data = await addQuote({
      ...newQuote,
      buyer: +newQuote.buyer,
      origenId: +newQuote.origenId,
      clienteId: +newQuote.clienteId,
      importadoraId: +newQuote.importadoraId,
      aduanaId: +newQuote.aduanaId,
      countryId: +newQuote.countryId,
      vehiculoId: +newQuote.vehiculoId,
      subastaId: newQuote.subastaId ? +newQuote.subastaId : null,
      notaInterna: newQuote.notaInterna ? newQuote.notaInterna : "",
    });
    setIsLoading(false);
    setIsLoadingSave(false);

    if (data && data.status) {
      notify();
      navigate(`/sendprintRequest/${data.data.quoteId}`);
    } else {
      errorMessage(data.message);
    }
  };

  const notifyV = () =>
    toast.success(
      "Vehiculo registrado correctamente, complete datos de solicitud.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

  const notify = () =>
    toast.success("Solicitud guardada correctamente", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const columns = [
    {
      name: "Seleccionar",
      width: "100px",
      cell: (row) => (
        <Button
          type="button"
          onClick={() => insertByTable(row)}
          variant="primary"
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      ),
    },
    {
      name: "Lote",
      selector: (row) => row.lote,
      width: "120px",
    },
    {
      name: "Vehiculo",
      selector: (row) => row.vehiculoName,
    },
    {
      name: "VIN",
      selector: (row) => row.vin,
      width: "170px",
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = listaVehi.filter((item) => {
    return (
      (item?.lote &&
        item?.lote
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item?.vehiculoName &&
        item?.vehiculoName
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase()))
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Container>
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          <Col xs={12} md={12}>
            <Form.Label className="input-buscar">Filtrar vehiculo</Form.Label>
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </Col>
        </Row>
      </Container>
    );
  }, [filterText, resetPaginationToggle]);

  const steps = [
    {
      title: "Registro de Vehículo",
      content: (
        <Card>
          <Card.Body>
            <VehiculoForm
              onVehicleSaved={handleVehicleSaved}
              onVehicleError={handleVehicleError}
            />
          </Card.Body>
        </Card>
      ),
    },
    {
      title: "Seleccione vehiculo para crear solicitud",
      content: (
        <Card>
          <Card.Body>
            <Container>
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle}
                paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                progressPending={isLoading}
                progressComponent={<SpinnerTable />}
                persistTableHead
                pagination
              />
            </Container>
          </Card.Body>
        </Card>
      ),
    },
    {
      title: "Datos de solicitud para recolección",
      content: (
        <Card>
          <Card.Body>
            <Card.Text>
              <strong>
                <Form.Label>Fecha Ingreso: {fechaactual}</Form.Label>
              </strong>
              <Row className="mt-3">
                <Col xs={12} lg="3">
                  <img // eslint-disable-line jsx-a11y/alt-text
                    src={car}
                    style={{ width: "100px", height: "100px" }}
                  />
                </Col>
                <Col xs={12} lg={9}>
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <p className="fw-bold mb-0"># Lote:</p>
                      <p className="fw-bold mb-0">Vehículo:</p>
                      <p className="fw-bold mb-0"># VIN:</p>
                      <ShowWhen show={infovehi?.title}>
                        <p className="fw-bold mb-0">Tipo de Título:</p>
                      </ShowWhen>
                    </div>
                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-10">
                      <p className="mb-0">{infovehi?.lote}</p>
                      <p className="mb-0">{infovehi?.vehiculoName}</p>
                      <p className="mb-0">{infovehi?.vin}</p>
                      <ShowWhen show={infovehi?.title}>
                        <p className="mb-0">{infovehi?.title?.titleName}</p>
                      </ShowWhen>
                    </div>
                  </div>
                </Col>
                <Col xs={12} lg="4">
                  <Form.Check
                    label="Matus Dealer?"
                    name="dealer"
                    onChange={handleOnChangeCK}
                    type="checkbox"
                  />
                </Col>
                <Col xs={12} lg="4">
                  <Form.Check
                    label="Cortar Vehículo"
                    name="cutVehicle"
                    onChange={handleOnChangeCK}
                    type="checkbox"
                  />
                </Col>
                <Col xs={12} lg="4" className="pt-3 pt-sm-3 pt-md-0">
                 
                    <Form.Check
                      label="Entrega en Bodega"
                      name="cutVehicle"
                      onChange={handleOnChangeEB}
                      type="checkbox"
                    />
                 
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} lg="4">
                  <Form.Label
                    className={`text-aling-left ${
                      gatePassRequired ? "required" : ""
                    }`}
                  >
                    Gate Pass:
                  </Form.Label>
                  <Form.Control
                    aria-label="Small"
                    className={gatePassRequired ? "is-invalid" : ""}
                    type="text"
                    onChange={handleChange}
                    name="gatePass"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                  {gatePassRequired ? (
                    <p className={`m-0 font-12 text-danger font-weight-bold`}>
                      Campo Requerido
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs={12} lg="4">
                  <Form.Label
                    className={`text-aling-left ${
                      isSubmitted &&
                      (newQuote.buyer === "0" || newQuote.buyer === "")
                        ? "required"
                        : ""
                    }`}
                  >
                    Buyer
                  </Form.Label>
                  <Form.Control
                    aria-label="Small"
                    className={
                      isSubmitted && newQuote.buyer === "" ? "is-invalid" : ""
                    }
                    type="number"
                    onChange={handleChange}
                    name="buyer"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                  {isSubmitted &&
                    (newQuote.buyer === "0" || newQuote.buyer === "") && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                </Col>
                <Col xs={12} lg="4">
                  <Form.Label
                    className={`text-aling-left ${
                      isSubmitted && newQuote.tipoPago === "" ? "required" : ""
                    }`}
                  >
                    Tipo de Pago
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    disabled={true}
                    name="tipoPago"
                    value={newQuote.tipoPago}
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                  <p className={`m-0 font-12 font-weight-bold`}>
                    Seleccionar un cliente
                  </p>
                  {isSubmitted && newQuote.tipoPago === "" && (
                    <p className={`m-0 font-12 text-danger font-weight-bold`}>
                      Campo Requerido
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <Form.Label
                      className={`text-aling-left m-0 ${
                        isSubmitted &&
                        (newQuote.clienteId === "0" ||
                          newQuote.clienteId === "")
                          ? "required"
                          : ""
                      }`}
                    >
                      Cliente
                    </Form.Label>
                    <Button
                      variant={"primary"}
                      size={"sm"}
                      onClick={() => setShowModalClient(true)}
                    >
                      <FontAwesomeIcon icon={faPencil} /> Agregar Cliente
                    </Button>
                  </div>
                  <InputSearchCustomer
                    selectedCustomer={selectedClient}
                    filterByFinalCustomer={false}
                    onSelectedCustomer={handleSelectChangeCliente}
                  />
                  {isSubmitted &&
                    (newQuote.clienteId === "0" ||
                      newQuote.clienteId === "") && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                </Col>
              </Row>
              {!showSubastaSelect ? (
                <Row className="mt-3">
                  <Col>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <Form.Label
                        className={`text-aling-left m-0 ${
                          isSubmitted &&
                          (newQuote.subastaId === "0" ||
                            newQuote.subastaId === "")
                            ? "required"
                            : ""
                        }`}
                      >
                        Subasta
                      </Form.Label>
                      <Button onClick={handleShowA} size={"sm"}>
                        Agregar Nueva Subasta
                      </Button>
                    </div>
                    <Select
                      onChange={handleSelectChangeSubs}
                      defaultValue={{
                        label: "Seleccione una subasta",
                        value: "empty",
                      }}
                      value={
                        !selectedSubasta
                          ? undefined
                          : {
                              label: selectedSubasta.subName,
                              value: selectedSubasta.subId,
                              subasta: selectedSubasta,
                            }
                      }
                      name="subastaId"
                      options={
                        subastas &&
                        subastas.length &&
                        subastas.map((subs) => ({
                          label: subs.subName + "-" + subs.direccion,
                          value: subs.subId,
                          subasta: subs,
                        }))
                      }
                    />
                    {isSubmitted &&
                      (newQuote.subastaId === "0" ||
                        newQuote.subastaId === "") && (
                        <p
                          className={`m-0 font-12 text-danger font-weight-bold`}
                        >
                          Campo Requerido
                        </p>
                      )}
                  </Col>
                </Row>
              ) : null}
              <Row className="mt-3">
                <Col xs={12} lg="6">
                  <Form.Label
                    className={`text-aling-left ${
                      isSubmitted &&
                      (newQuote.origenId === "0" || newQuote.origenId === "")
                        ? "required"
                        : ""
                    }`}
                  >
                    Origen
                  </Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    name="origenId"
                    value={origen.origenId}
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  >
                    <option disabled selected>
                      Seleccione
                    </option>
                    {origen &&
                      origen.length &&
                      origen.map((ori, index) => {
                        return (
                          <option key={index} value={ori.id}>
                            {ori.nameOrigen + " / " + ori.city}
                          </option>
                        );
                      })}
                  </Form.Select>
                  {isSubmitted &&
                    (newQuote.origenId === "0" || newQuote.origenId === "") && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                </Col>
                <Col xs={12} lg="6">
                  <Form.Label
                    className={`text-aling-left ${
                      isSubmitted &&
                      (newQuote.aduanaId === "0" || newQuote.aduanaId === "")
                        ? "required"
                        : ""
                    }`}
                  >
                    Aduana destino
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => handleSelectChangeAdu(e)}
                    name="aduanaId"
                    value={aduanas.aduanaId}
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  >
                    <option disabled selected>
                      Seleccione
                    </option>
                    {aduanas &&
                      aduanas.length &&
                      aduanas.map((adu, index) => {
                        return (
                          <option
                            key={index}
                            value={adu.id}
                            data-countryid={adu.countryId}
                          >
                            {adu.nameAduana} - {adu.countryName}
                          </option>
                        );
                      })}
                  </Form.Select>
                  {isSubmitted &&
                    (newQuote.aduanaId === "0" || newQuote.aduanaId === "") && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} lg="3">
                  <Form.Label
                    className={`text-aling-left ${
                      isSubmitted &&
                      (newQuote.quoted === "0" || newQuote.quoted === "")
                        ? "required"
                        : ""
                    }`}
                  >
                    Costo de Flete
                  </Form.Label>
                  <Form.Control
                    aria-label="Small"
                    className={
                      isSubmitted && newQuote.quoted === "" ? "is-invalid" : ""
                    }
                    onChange={handleChange}
                    name="quoted"
                    type="number"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                  {isSubmitted &&
                    (newQuote.quoted === "0" || newQuote.quoted === "") && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                </Col>
                <Col xs={12} lg="3">
                  <Form.Label
                    className={`text-aling-left ${
                      isSubmitted &&
                      (newQuote.estGrua === "0" || newQuote.estGrua === "")
                        ? "required"
                        : ""
                    }`}
                  >
                    Costo de Grua
                  </Form.Label>
                  <Form.Control
                    aria-label="Small"
                    onChange={handleChange}
                    className={
                      isSubmitted && newQuote.estGrua === "" ? "is-invalid" : ""
                    }
                    name="estGrua"
                    type="number"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                  {isSubmitted &&
                    (newQuote.estGrua === "0" || newQuote.estGrua === "") && (
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>
                        Campo Requerido
                      </p>
                    )}
                </Col>
                <Col xs={12} lg="3">
                  <Form.Label
                    className={`text-aling-left ${
                      isSubmitted && newQuote.bl === "" ? "required" : ""
                    }`}
                  >
                    Costo BL
                  </Form.Label>
                  <Form.Control
                    aria-label="Small"
                    onChange={handleChange}
                    className={
                      isSubmitted && newQuote.bl === "" ? "is-invalid" : ""
                    }
                    value={newQuote?.bl}
                    name="bl"
                    type="number"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                  {isSubmitted && newQuote.bl === "" && (
                    <p className={`m-0 font-12 text-danger font-weight-bold`}>
                      Campo Requerido
                    </p>
                  )}
                </Col>
                <Col xs={12} lg="3">
                  <Form.Label className="text-aling-left">
                    Costo Adicional
                  </Form.Label>
                  <Form.Control
                    aria-label="Small"
                    onChange={handleChange}
                    name="costoQ"
                    type="number"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Label className="text-aling-left">
                    Comentarios por correo (Copia cliente)
                  </Form.Label>
                  <Form.Control
                    maxLength={150}
                    onChange={handleChange}
                    name="comentarios"
                    as="textarea"
                    rows={3}
                  />
                </Col>
              </Row>

              {!isCommentInternal && (
                <Row className="mt-3">
                  <Col className="text-center">
                    <Popconfirm
                      title="Comentario Interno"
                      description="Este comentario solo será visible para los usuarios internos de Matus, el cliente no podrá verlo. ¿Desea agregar un comentario interno?"
                      onConfirm={() => {
                        setIsCommentInternal(!isCommentInternal);
                      }}
                      onCancel={() => {
                        setIsCommentInternal(false);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <ButtonAnt
                        type="primary"
                      
                      >
                        Agregar Comentario Interno
                      </ButtonAnt>
                    </Popconfirm>
                  </Col>
                </Row>
              )}
              {isCommentInternal && (
                <Row className="mt-3">
                  <Col>
                    <Form.Label
                      style={{
                        display: "flex ",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100% !important",
                      }}
                    >
                      <strong>Comentario interno Matus (Opcional)</strong>
                      <ButtonAnt
                        type="primary"
                        danger
                        onClick={() => setIsCommentInternal(!isCommentInternal)}
                      >
                        {" "}
                        Cerrar
                      </ButtonAnt>
                    </Form.Label>
                    <Form.Control
                      maxLength={150}
                      onChange={handleChange}
                      name="notaInterna"
                      as="textarea"
                      rows={3}
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <Form.Label className="text-aling-left m-0">
                      Cliente Final (Opcional)
                    </Form.Label>
                    <Button
                      variant={"primary"}
                      size={"sm"}
                      onClick={() => setShowModalFinalClient(true)}
                    >
                      <FontAwesomeIcon icon={faPencil} /> Agregar Cliente
                    </Button>
                  </div>
                  <InputSearchCustomer
                    selectedCustomer={selectedFinalClient}
                    filterByFinalCustomer={true}
                    onSelectedCustomer={handleSelectChangeClienteFinal}
                  />
                </Col>
              </Row>
            </Card.Text>
            <Row className="mt-3">
              <Col>
                <Form.Label
                  className="text-aling-left"
                  style={{ color: "gray" }}
                >
                  Registrado por: <br /> {currentUser?.fullName}
                </Form.Label>
              </Col>
              <Col>
                <Form.Label className="text-align-right">
                  Asignado a:
                </Form.Label>
                <Form.Select
                  value={selectedUser}
                  onChange={(e) => {
                    const user = userList.find(
                      (user) => user.idUser === +e.target.value
                    );

                    setSelectedUser(user.idUser);
                    setNewQuote((prevState) => ({
                      ...prevState,
                      creador: `${user.firstName} ${user.lastName}`,
                      userSellerId: user.idUser,
                    }));
                  }}
                >
                  <option value={currentUser?.fullName}>
                    {currentUser?.fullName}
                  </option>
                  {userList.map((user) => (
                    <option key={user.idUser} value={user.idUser}>
                      {user.firstName} {user.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <ButtonAnt
                type="primary"
                onClick={() => insertarQuote()}
                block
                icon={<FontAwesomeIcon icon={faFloppyDisk} className="me-2" />}
                style={{
                  marginTop: "20px",
                  maxWidth: "400px",
                }}
                loading={isLoadingSave}
              >
                Guardar Recolección
              </ButtonAnt>
              <ToastContainer />
            </Row>
          </Card.Body>
        </Card>
      ),
    },
  ];

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
    loadvehi().then();
  };

  const nextSaveVehicle = () => {
    setCurrent(current + 2);
  };

  const prev = () => {
    setCurrent(current - 1);
    loadvehi().then();
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    // color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    marginTop: 16,
  };

  return (
    <Spinner loading={isLoading}>
      <Container>
        <Steps current={current} items={items} />
        <div style={contentStyle}>{steps[current].content}</div>
        <div
          style={{
            marginTop: 24,
          }}
        >
          {/* {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Continuar
              </Button>
            )} */}
          {/* {current === steps.length - 1 && (
              <Button type="primary" onClick={() => {}}>
                Done
              </Button>
            )} */}

          {current === 0 && (
            <Row className="mt-3">
              <Col className="text-center">
                <Form.Label className="text-aling-left">
                  <Alert
                    message="Nota"
                    description="Si registró anteriormente un vehículo y no completo la
                    solicitud dar click en la siguiente opción:"
                    type="info"
                  />

                  <br />
                  <Button variant="secondary" onClick={() => next()}>
                    Click Aqui
                  </Button>
                </Form.Label>
                <ToastContainer />
              </Col>
            </Row>
          )}

          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Atras
            </Button>
          )}
        </div>
      </Container>

      <Modal
        show={showA}
        onHide={handleCloseA}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new auction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubastaForm onCancel={handleCloseA} afterSave={insertarSubasta} />
        </Modal.Body>
      </Modal>

      <ModalAntD
        open={showModalClient || showModalFinalClient}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <ClienteForm
          shortSaved={true}
          countryId={0}
          importadora={showModalClient ? undefined : selectedImportadora}
          finalClient={showModalFinalClient}
          submitCliente={handleSaveCustomer}
          onCancel={handleCancelCustomer}
        />
      </ModalAntD>
    </Spinner>
  );
};

export default Solicitud;
