import {Button, Dropdown, Input, Modal, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faCopy, faEye, faFileExcel, faFileText, faMailBulk, faPencil} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import DetalleExportationTable from "./DetalleExportationTable";
import {getBillOfLadingMaster, getDockReceipt, saveExportation} from "../../Servicios/ExportationServices";
import {useState} from "react";
import SendEmail from "../../utils/email";
import {errorMessage, successMessage} from "../../utils/toast-message";
import PdfReport from "../../utils/pdfReport";
import {ExportationStateEnum} from "../../shared/enums/exportation-state.enum";
import ExportacionInfo from "./ExportacionInfo";
import {StringFormats} from "../../utils/string-formats";
import useCurrentUser from "../../Hooks/UserHook";
const XLSX = require("xlsx");

const ExportationTable = ({ pagination, exportations, showShippingInstruction, onPageChange, onRefreshTable, onEditExportation }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataReport, setDataReport] = useState("");
  const [codshipment, setCodshipment] = useState("");
  const [bookingNumber, setBookingNumber] = useState("");
  const [visibleEmail, setVisibleEmail] = useState(false);
  const [clonarExportacion, setClonarExportacion] = useState(undefined);

  const items = element => {
    const items = [
      {
        key: '1',
        icon: <FontAwesomeIcon icon={faFileText} />,
        label: 'Generar Dock Receipt',
        onClick: () => generateDockReceivePDF(element.shipmentName),
        isVisible: true,
      },
      {
        key: '2',
        icon: <FontAwesomeIcon icon={faFileText} />,
        label: 'Generar BL Master',
        onClick: () => generateBillOfLadingPDF(element.shipmentName),
        isVisible: true,
      },
      {
        key: '3',
        icon: <FontAwesomeIcon icon={faEye} />,
        label: 'Ver exportación',
        onClick: () => handleVerExportacion(element),
        isVisible: element.state === ExportationStateEnum.ShippingInstruction,
      },
      {
        key: '4',
        icon: <FontAwesomeIcon icon={faPencil} />,
        label: 'Editar exportación',
        onClick: () => handleEditarExportacion(element),
        isVisible: element.state !== ExportationStateEnum.ShippingInstruction,
      },
      {
        key: '5',
        icon: <FontAwesomeIcon icon={faMailBulk} />,
        label: 'Enviar Correo',
        onClick: () => handleEnviarCorreo(element),
        isVisible: true,
      },
      {
        key: '6',
        icon: <FontAwesomeIcon icon={faPencil} />,
        label: 'Generar Shipping Instruction',
        onClick: () => navigate(`/exportation/${element.exportationId}/shipping-instruction`),
        isVisible: element.state === ExportationStateEnum.ShippingInstruction,
      },
      {
        key: '7',
        icon: <FontAwesomeIcon icon={faCopy} />,
        label: 'Clonar exportación',
        onClick: () => setClonarExportacion(element),
        isVisible: true,
      },
      {
        key: '8',
        icon: <FontAwesomeIcon icon={faFileExcel} />,
        label: 'Exportar Excel',
        onClick: () => handleExportarExcel(element),
        isVisible: true,
      },
    ];

    return items.filter(e => e.isVisible);
  }

  const columns = [
    {
      title: "",
      key: "option",
      dataIndex: "option",
      width: "70px",
      fixed: 'left',
      visible: true,
      render: (_, element) => (
        <Dropdown trigger={['click']}
                  menu={{ items: items(element) }}
                  placement="bottomLeft" >
          <Button shape="circle" type={'primary'}><FontAwesomeIcon size={'sm'} icon={faBars} /></Button>
        </Dropdown>
      ),
    },
    {
      visible: true,
      title: "Create Date",
      dataIndex: "createDate",
      key: "createDate",
      width: "100px",
      render: (_, element) => (
        <>
          <p className="mb-0">{moment(element.createDate).format('DD/MM/yyyy')}</p>
          <p className="mb-0">{moment(element.createDate).format('HH:mm:ss')}</p>
        </>
      )
    },
    {
      visible: true,
      title: "Shipment Name",
      dataIndex: "shipmentName",
      key: "shipmentName",
      width: "100px",
    },
    {
      visible: true,
      title: "Booking Number",
      dataIndex: "bookingNum",
      key: "bookingNum",
      width: "100px",
    },
    {
      visible: true,
      title: "Destino",
      width: "140px",
      dataIndex: "aduanaName",
      key: "aduanaName",
      render: (value, element) => `${value}, ${element?.aduana?.countryName}`
    },
    {
      visible: true,
      title: "Carrier",
      width: "140px",
      dataIndex: "carrierName",
      key: "carrierName",
      render: (_, element) => `${element?.carrier?.oceanCarrierName}`
    },
    {
      visible: true,
      title: "ETA",
      width: "140px",
      dataIndex: "arrivalDate",
      key: "arrivalDate",
      render: (value) => value ? <>
        <p className="mb-0">{moment(value).format('DD MMM yyyy')}</p>
      </> : '---'
    },
    {
      visible: true,
      title: "ETD",
      width: "140px",
      dataIndex: "departureDate",
      key: "departureDate",
      render: (value) => value ? <>
        <p className="mb-0">{moment(value).format('DD MMM yyyy')}</p>
      </> : '---'
    },
    {
      visible: showShippingInstruction,
      title: "Shipping Instruction",
      width: "80px",
      dataIndex: "shippingInstruction",
      key: "shippingInstruction",
      render: (_, element) => !!element.shippingInstructionVersion
        ? <div className="badge bg-success">GENERADO</div>
        : <div className="badge bg-info">PENDIENTE</div>
    }
  ];

  const handleVerExportacion = exportation => {
    navigate(`/exportation/edit/${exportation.exportationId}`);
  }

  const handleEditarExportacion = exportation => {
    onEditExportation(exportation);
    handleVerExportacion(exportation);
  }

  const handleEnviarCorreo = async exportation => {
    setVisibleEmail(true);
    setCodshipment(exportation.shipmentName);

    setLoading(true);
    const res = await getDockReceipt(exportation.shipmentName);
    setLoading(false);
    setDataReport(res);
  }

  const generateDockReceivePDF = async (shipmentName) => {
    setVisible(true);
    const data = await getDockReceipt(shipmentName)

    if (data.status) {
      setDataReport(data.data);
    } else {
      setVisible(false);
      errorMessage(data.message);
    }
  }

  const generateBillOfLadingPDF = async (shipmentName) => {
    setVisible(true);
    const data = await getBillOfLadingMaster(shipmentName);

    if (data.status) {
      setDataReport(data.data);
    } else {
      setVisible(false);
      errorMessage(data.message);
    }
  }

  const expandedRowRender = (exp) => {
    return <DetalleExportationTable detalleExportations={exp?.exportDetails}
                                    onRefreshTable={onRefreshTable} />
  };

  const handleTableChange = (pagination) => {
    if (onPageChange) onPageChange(pagination);
  }

  const handleClonarExportacion = async () => {
    if (bookingNumber === "") {
      errorMessage('Debe completar el booking number');
      return;
    }

    const input = {
      ...clonarExportacion,
      bookingNum: bookingNumber,
      billLadingNum: bookingNumber,
      userId: currentUser.idusr,
      createDate: StringFormats.dateDB(new Date())
    }

    setLoading(true);
    const result = await saveExportation(input);
    setLoading(false);

    if (result && result.status) {
      successMessage('Exportacion clonada exitosamente');
      handleEditarExportacion(result.data);

      setBookingNumber('');
      setClonarExportacion(undefined);
    } else {
      errorMessage(result.message);
    }
  }

  const handleExportarExcel = exportation => {
    const list = exportation.exportDetails.map((detail) => {
      return {
        '1': detail.codeExpSecuential,
        '2': detail?.werehouse?.importadora?.impName,
        '3': detail.itn,
        '4': detail.werehouse.vin,
        '5': `CBP09-ITN ${detail.itn} CERTIFICATE`,
        '6': exportation.carrier.oceanCarrierName,
        '7': exportation.portCodeLoading.port
      }
    });

    list.unshift({
      '1': 'Waybill Number',
      '2': 'Consignee',
      '3': 'ITN',
      '4': 'VIN',
      '5': 'Nombre Archivo',
      '6': 'Carrier Name',
      '7': 'Port'
    });

    list.unshift({'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''});
    list.unshift({'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''});

    list.unshift({
      '1': moment(new Date()).format('ddd MMM DD, yyyy'),
      '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''
    });

    list.unshift({
      '1': 'Listado de Hijos (USD)',
      '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''
    });

    list.unshift({
      '1': 'Shipment',
      '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''
    });

    list.unshift({
      '1': 'MATUS INTERNATIONAL INC.',
      '2': '', '3': '', '4': '', '5': '', '6': '', '7': ''
    });

    const worksheet = XLSX.utils.json_to_sheet(list, {
      skipHeader: true,
    });

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Shipments");

    // Genera el archivo Excel
    XLSX.writeFile(workbook, `shipments_${exportation?.shipmentName}.xlsx`, {
      bookType: "xlsx",
      type: "blob",
    });
  }

  return(
    <>
      <Table
        columns={columns.filter(e => e.visible)}
        expandable={{expandedRowRender,  defaultExpandedRowKeys: ["0"]}}
        dataSource={exportations}
        size="small"
        scroll={{x: 1300, y: 600}}
        pagination={pagination}
        onChange={handleTableChange}
      />

      <SendEmail visible={visibleEmail}
                 setVisible={setVisibleEmail}
                 titulo={`Envio de Dock Receipt ${codshipment}`}
                 to={[]}
                 cc={[]}
                 cco={[]}
                 isLoading={loading}
                 subject={`Envio de Dock Receipt ${codshipment}`}
                 body={`Estimado cliente, adjunto encontrara el Dock Receipt de la exportación ${codshipment}`}
                 attachments={[
                   {
                     nombreArchivo: "Dockreceipt.pdf",
                     archivo: "data:aplication/pdf;base64," + dataReport,
                   }
                 ]}
      />

      <PdfReport visible={visible} setVisible={setVisible} data={dataReport} setData={setDataReport} />

      <Modal width={'80%'}
             open={clonarExportacion}
             title={'Clonar Exportación'}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}
             onCancel={() => {
               setClonarExportacion(undefined);
             }}>
        <ul>
          <li><p className="mb-0">Por favor verificar la información de la exportación antes de realizar la
            clonación</p></li>
          <li><p className="mb-0">Esta información podra ser modificada mas adelante</p></li>
          <li><p className="mb-0">Los contenedores y los warehouse deben ser agregados posteriormente</p></li>
        </ul>

        <p className="mb-1 fw-bold">Booking Number de la nueva exportación</p>
        <Input placeholder={'booking number'} value={bookingNumber} onChange={(event) => setBookingNumber(event.target.value)} />
        <hr/>
        <ExportacionInfo exportacion={clonarExportacion}/>
        <div className="text-end">
        <Button loading={loading}
                type={'primary'}
                onClick={handleClonarExportacion}><FontAwesomeIcon className="me-2" icon={faCopy} /> Clonar</Button>
        </div>
      </Modal>
    </>
  );
}

export default ExportationTable;
