import React from 'react';
import { Container } from 'react-bootstrap';
import { SpinnerRoundFilled } from 'spinners-react';

export default function LoadingSpinner () {
  
  return (
    <Container className='divpadre'>
      <div className='divhijo'>
         <SpinnerRoundFilled size={90} thickness={128} speed={140} color="rgba(28, 46, 81, 1)" />
      </div>
    </Container>
  );
};

