import LoginLayout from "../layout/LoginLayout";
import CambiarPass from "../User/CambiarPass";
import Login from "../User/Login";
import ResetPasword from "../User/ResetPassword";
import Tracking from "../views/Tracking";
import AuthGuard from "../utils/route-guard/AuthGuard";

const LoginRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <LoginLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/*",
      element: <Login />,
    },
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/reset-password",
      element: <ResetPasword />,
    },
    {
      path: "/changue-pass",
      element: <CambiarPass />,
    }
  ],
};

const PublicRoutes = {
  path: "/tracking",
  element: (<Tracking />),
};

// quiero exportar las rutas de login y publicas
export { LoginRoutes, PublicRoutes };
