import React, { useEffect } from 'react';
import { useContext, createContext, useState } from 'react';
import { getToken } from "../Servicios/AuthService";

const useProvideAuth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = getToken();
    setUser(token);
  }, []);

  return {
    user
  };
}


const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();

    const [user, setUser] = useState(null);
    const [isLogin, setIsLogin] = useState(false);

    const login = () => {
      setIsLogin(true);
    }
    
    const logout = () => {
      setIsLogin(false);
    }

    useEffect(() => {
      setUser(isLogin ? auth : null);
    }, [isLogin]);

    useEffect(() => {
      setUser(auth);
      setIsLogin(!!auth);
    }, []);

    return (
      <authContext.Provider value={{...user, isLogin: isLogin, login: login, logout: logout}}>
        {children}
      </authContext.Provider>
    );
  }

  const authContext = createContext({
    isLogin: false,
    login: () => {},
    logout: () => {}
  });


  export function useAuth() {
    return useContext(authContext);
  }

 
  export default ProvideAuth;