export const ClienteClassPay = [
  { value: "PREPAGO", label: "PREPAGO" },
  { value: "POSPAGO", label: "POSPAGO" },
  { value: "PREPAGO GRUA", label: "PREPAGO GRUA" },
  { value: "PREPAGO FLETE", label: "PREPAGO FLETE" },
  { value: "PREPAGO 25%", label: "PREPAGO 25%" },
  { value: "PREPAGO 50%", label: "PREPAGO 50%" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
];
