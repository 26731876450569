import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {deleteUserRol, getUserById} from "../../Servicios/AdminService";
import UsuarioRolTable from "./UsuarioRolTable";
import Button from "react-bootstrap/Button";
import {faChevronLeft, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "antd";
import UsuarioRolForm from "./UsuarioRolForm";
import Spinner from "../../shared/spinner";
import {errorMessage, successMessage} from "../../utils/toast-message";
import {useNavigate} from "react-router-dom";
import UsuarioPermisoForm from "./UsuarioPermisoForm";

const UsuarioViewPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState(undefined);
  const [selectedUserRole, setSelectedUserRole] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [addNewUserRole, setAddNewUserRole] = useState(false);
  const [addNewUserPermission, setAddNewUserPermission] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    if (id) {
      init().then();
    }
  }, [id]);

  const init = async () => {
    const data = await getUserById(id);
    setUser(data);
  };

  const handleDeleteRol = async () => {
    setOpenConfirmationModal(false);
    setLoading(true);

    const data = await deleteUserRol(selectedUserRole.id);
    setLoading(false);
    setSelectedUserRole(undefined);

    if (data.status) {
      successMessage("El rol fue eliminado correctamente");
      await init();
    } else {
      errorMessage(data.message);
    }
  }

  return(
    <>
      <Spinner loading={loading}>
        <Button variant={"warning"}
                className="mb-3"
                onClick={() => navigate(`/admin/users`)}>
          <FontAwesomeIcon icon={faChevronLeft}/> Regresar
        </Button>

        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-4">
                <h5 className="fw-bold">Datos del Usuario</h5>

                <p className="m-0"><span className="fw-bold">Username:</span> {user?.username?.toUpperCase()}</p>
                <p className="m-0"><span className="fw-bold">Full Name:</span> {user?.firstName} {user?.lastName}</p>
                <p className="m-0"><span className="fw-bold">Email:</span> {user?.email}</p>
              </div>
              <div className="col-8">
                <h5 className="fw-bold">Ubicación</h5>

                <p className="m-0">{user?.usr?.city} ({user?.usr?.reference})</p>
                <p className="m-0">{user?.usr?.address}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5 className="m-0 fw-bold">Roles Asignados</h5>
          <div>
            <Button size={'sm'}
                    variant={"primary"}
                    onClick={() => setAddNewUserRole(true)}>
              <FontAwesomeIcon icon={faPlus}/> Asignar nuevo Rol
            </Button>
            <Button size={'sm'}
                    className={'ms-3'}
                    variant={"primary"}
                    onClick={() => setAddNewUserPermission(true)}>
              <FontAwesomeIcon icon={faPlus}/> Asignar nuevo Permiso
            </Button>
          </div>
        </div>

        <UsuarioRolTable roles={user?.roles}
                         doEdit={(item) => {
                           setAddNewUserRole(true);
                           setSelectedUserRole(item);
                         }}
                         doDelete={(item) => {
                           setOpenConfirmationModal(true);
                           setSelectedUserRole(item);
                         }}/>

        <Modal open={addNewUserRole}
               title={'Asignar nuevo Rol'}
               onCancel={() => {
                 setAddNewUserRole(false);
                 setSelectedUserRole(undefined);
               }}
               okButtonProps={{style: {display: 'none'}}}
               cancelButtonProps={{style: {display: 'none'}}}>
          <UsuarioRolForm rolId={selectedUserRole ? selectedUserRole.rol.id : undefined}
                          usuarioId={id}
                          afterSave={() => {
                            setAddNewUserRole(false);
                            init().then();
                          }}/>
        </Modal>

        <Modal open={addNewUserPermission}
               title={'Asignar nuevo Permiso'}
               onCancel={() => {
                 setAddNewUserPermission(false);
               }}
               okButtonProps={{style: {display: 'none'}}}
               cancelButtonProps={{style: {display: 'none'}}}>
          <UsuarioPermisoForm usuario={user} afterSave={() => init()} />
        </Modal>

        <Modal open={openConfirmationModal}
               title={'Confirmar'}
               onOk={handleDeleteRol}
               onCancel={() => {
                 setOpenConfirmationModal(false);
                 setSelectedUserRole(undefined);
               }}>
          Esta seguro que desea quitarle este rol al usuario?
        </Modal>
      </Spinner>
    </>
  );
}

export default UsuarioViewPage;
