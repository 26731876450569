import React, {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faComment,
  faPenToSquare,
  faPaperPlane,
  faCarTunnel,
  faEye,
  faXmark, faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  showCoord,
  deleteCoor,
  notifyCustomerCoordinacion,
  viewCoordi,
  editCoor, reOpenCoor,
} from "../../Servicios/CoordService";
import Form from "react-bootstrap/Form";
import "styled-components";
import "../../hojas-de-estilo/style.css";
import {permisos} from "../../utils/permisos";
import {validarPermisos} from "../../Servicios/AuthService";
import useCurrentUser from "../../Hooks/UserHook";
import {
  showComments,
  addComent,
} from "../../Servicios/ComponedoresService";
import Alert from "react-bootstrap/Alert";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import moment from "moment";
import {
  Badge,
  Drawer,
  Row,
  Col,
  Space,
  Modal,
  Tabs,
  Input,
  Form as FormAnt,
  Select,
  Spin, Table,
} from "antd";
import {CoordinacionNotificarCliente} from "../../Resources/HtmlEmailBody/CoordinacionNotificarCliente";
import SendEmail from "../../utils/email";
import {CoordinacionNotasImportantes} from "../../Resources/PdfBase64/CoordinacionNotasImportantes";
import "./CoordinacionTable.css";
import {CoordinacionEntryTypeEnum} from "../../shared/enums/coordinacion-entry-type.enum";
import environment from "../../environments/environment";
import {successMessage, errorMessage} from "../../utils/toast-message";
import {CoordinacionStateEnum} from "../../shared/enums/coordinacion-state.enum";
import {StringFormats} from "../../utils/string-formats";
import CoordinacionFilter from "./CoordinacionFilter";
import EditableCell from "../../Componentes/EditableCell/EditableCell";

const XLSX = require("xlsx");

interface CoordinacionFilters {
  pageCount: number;
  currentPage: number;
  state: string;
  search: string;
  aduanaId: number;
  year: number;
  month: number;
  day: Date | undefined;
  entryType: string;
  estPickUp: Date | undefined;
  estDelivery: Date | undefined;
  actDelivery: Date | undefined;
  transporteId: number;
}

function CoordinacionTable() {
  const {id} = useParams();
  const navigate = useNavigate();
  const puedeModificarCoordinacion = validarPermisos(permisos.MODIFICAR_COORDINACION);

  const [isLoading, setIsLoading] = useState(false);

  const localStorageFilter = (key = undefined, isDate = false) => {
    if (!localStorage.getItem('coordinacion:filter')) return undefined;
    if (!key) return JSON.parse(localStorage.getItem('coordinacion:filter'));
    if (!JSON.parse(localStorage.getItem('coordinacion:filter'))[key]) return undefined;

    if (isDate) {
      return new Date(JSON.parse(localStorage.getItem('coordinacion:filter'))[key]);
    }

    return JSON.parse(localStorage.getItem('coordinacion:filter'))[key];
  }

  const [coordinacionFilters, setCoordinacionFilters] = useState({
    pageCount: localStorageFilter('pageCount') ?? 100,
    currentPage: localStorageFilter('currentPage') ?? 1,
    state: localStorageFilter('state') ?? "",
    search: localStorageFilter('search') ?? "",
    aduanaId: localStorageFilter('aduanaId') ?? 0,
    year: localStorageFilter('year') ?? new Date().getFullYear(),
    month: localStorageFilter('month') ?? new Date().getMonth() + 1,
    day: localStorageFilter('day', true),
    entryType: localStorageFilter('entryType') ?? CoordinacionEntryTypeEnum.entryTypeByOwn,
    estPickUp: localStorageFilter('estPickUp', true),
    estDelivery: localStorageFilter('estDelivery', true),
    actDelivery: localStorageFilter('actDelivery', true),
    transporteId: localStorageFilter('transporteId') ?? 0,
  });

  const [idCor, setIdCor] = useState(0);
  const ITEMS_PER_PAGE = 5;
  const [notes, setNotes] = useState([]);

  const [totalElements, setTotalElements] = useState(0);

  const [currentTab, setCurrentTab] = useState("1");
  const [idcordinacion, setIdcordinacion] = useState();

  const [coordi, setCoordi] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [coordinacionSendEmail, setCoordinacionSendEmail] = useState(undefined);
  const [
    showModalConfirmDeleteCoordination,
    setShowModalConfirmDeleteCoordination,
  ] = useState(false);
  const [
    showModalReopenCoordination,
    setShowModalReopenCoordination,
  ] = useState(false);

  const {TextArea} = Input;
  const [textArea, setTextArea] = useState("");
  const [statusTextArea, setStatusTextArea] = useState("");
  const [showModalST, setShowModalST] = useState(false);
  const [coordiId, setCoordiId] = useState([]);
  const [form] = FormAnt.useForm();
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [actDelivery, setActDelivery] = useState(null);
  const [searchedLote, setSearchedLote] = useState("");

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const fechaactual = `${day}/${month}/${year}`;

  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const date = hoy.toISOString();

  const currentUser = useCurrentUser();

  const [coment, setComent] = useState({
    id: "",
    fecha: date,
    nota: "",
    autor: "",
    coordId: "",
  });

  const showCoordinaciones = async (filters: CoordinacionFilters) => {
    setIsLoading(true);

    const data = await showCoord(
      id,
      filters.state,
      filters.year,
      filters.month,
      filters.currentPage,
      filters.pageCount,
      filters.search,
      filters.entryType,
      filters.aduanaId,
      filters.day ? filters.day.getDate() : 0,
      filters.estPickUp ? filters.estPickUp.getTime() : 0,
      filters.estDelivery ? filters.estDelivery.getTime() : 0,
      0,
      0,
      "",
      0,
      filters.actDelivery ? filters.actDelivery.getTime() : 0,
      filters.transporteId,
    );

    setIsLoading(false);

    if (data.status) {
      setCoordi(data.list);
      setTotalElements(data.totalElements);

      if (data.list.length === 1 && data.list[0].lote === filters.search) {
        setSearchedLote(data.list[0].lote);

        setCoordinacionFilters({
          ...coordinacionFilters,
          currentPage: 1,
          state: data.list[0].state,
          aduanaId: 0,
          year: moment(data.list[0].fechacor).year(),
          month: moment(data.list[0].fechacor).month() + 1,
          day: null,
          entryType: data.list[0].entryType,
          estPickUp: null,
          estDelivery: null,
          actDelivery: null,
          transporteId: 0,
        });
      }
    }
  };

  const deleteCoordinacion = async (id, description) => {
    try {
      setIsLoading(true);
      await deleteCoor(id, description);
      setIsLoading(false);
    } catch (ex) {
      console.log(ex);
    } finally {
      showCoordinaciones(coordinacionFilters).then();
    }
  };

  const reopenCoordinacion = async (id, description) => {
    try {
      setIsLoading(true);
      await reOpenCoor(id, description);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
      successMessage('La Coordinación ha sido reactivada');
      showCoordinaciones(coordinacionFilters).then();
    }
  };

  const mostComent = async () => {
    if (idCor !== 0) {
      const data = await showComments(idCor);
      setNotes(data.slice(0, ITEMS_PER_PAGE));
      setTotalElements(data.length);

      showDrawer();
    }
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setIdCor(0);
    setOpenDrawer(false);
    showCoordinaciones(coordinacionFilters).then();
  };

  useEffect(() => {
    // validacion para evitar busqueda repetitiva de lote
    // detiene la llamada al BE cuando se ingresa un lote
    if (searchedLote === "" || searchedLote !== coordinacionFilters.search) {
      setSearchedLote("");
      showCoordinaciones(coordinacionFilters).then();
    }

    const filters = localStorageFilter();

    if (JSON.stringify(coordinacionFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('coordinacion:filter', JSON.stringify(coordinacionFilters));
    }

    setTab();
  }, [id, coordinacionFilters]);

  const setTab = () => {
    if (coordinacionFilters.entryType === CoordinacionEntryTypeEnum.entryTypeByClient) {
      setCurrentTab("3");
    } else if (coordinacionFilters.entryType === CoordinacionEntryTypeEnum.entryTypePendingByClient) {
      setCurrentTab("4");
    } else {
      if (coordinacionFilters.state === CoordinacionStateEnum.ordenCancelada) {
        setCurrentTab("2");
      } else {
        setCurrentTab("1");
      }
    }
  }

  const handleShowEmailModal = (coordinacion) => {
    setCoordinacionSendEmail(coordinacion);
  };

  useEffect(() => {
    mostComent().then();
  }, [idCor]);

  useEffect(() => {
    if (currentUser)
      setComent({
        ...coment,
        coordId: idCor,
        autor: currentUser?.fullName,
        nota: "",
      });
  }, [currentUser, idCor]);

  const insertarComent = async () => {
    try {
      const data = await addComent({
        ...coment,
        coordId: +coment.coordId,
      });
      if (data) {
        mostComent().then();
        setComent({
          ...coment,
          nota: "",
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setComent({
      ...coment,
      [name]: value,
    });
  };

  const handleSendEmail = async () => {
    setIsLoading(true);
    await notifyCustomerCoordinacion(coordinacionSendEmail.id);
    setIsLoading(false);

    setCoordinacionSendEmail(undefined);
    await showCoordinaciones(coordinacionFilters);
  };

  const handleChangeActDelivery = e => {
    const {name, value} = e.target;
    setActDelivery(value);
  };

  const handleModificarCoordOffice = async (row) => {
    setShowModalST(true);
    setIsLoadingModal(true);
    const getAllCoordination = await viewCoordi(row?.id)
      .finally(() => setIsLoadingModal(false));

    setCoordiId(getAllCoordination);
    setActDelivery(getAllCoordination.actDelivery ? getAllCoordination.actDelivery : null);

    form.setFieldsValue({
      st_Cliente: getAllCoordination.st_Cliente,
      st_Driver: getAllCoordination.st_Driver,
      st_Matus: getAllCoordination.st_Matus,
      st_Paid: getAllCoordination.st_Paid,
      state: getAllCoordination.state,
    });
  }

  const coordinacionStateClassName = {
    [CoordinacionStateEnum.vehiculoNoPagadoSubasta]: 'cell-bg-green',
    [CoordinacionStateEnum.vehiculoPagadoSubasta]: 'cell-bg-light-red',
    [CoordinacionStateEnum.vehiculoPendientePrepago]: 'cell-bg-brown',
    [CoordinacionStateEnum.vehiculoAsignadoChofer]: 'cell-bg-yellow',
    [CoordinacionStateEnum.vehiculoDireccionParticular]: 'cell-bg-cyan',
    [CoordinacionStateEnum.vehiculoParaCorte]: 'cell-bg-cyan-light',
    [CoordinacionStateEnum.noPickUp]: 'cell-bg-red',
    [CoordinacionStateEnum.noDelivery]: 'cell-bg-pink',
    [CoordinacionStateEnum.vehiculoPosteado]: 'cell-bg-pink-dark',
    [CoordinacionStateEnum.choferAsignado]: 'cell-bg-yellow',
    [CoordinacionStateEnum.vehiculoBodegaSinWarehouse]: 'cell-bg-orange',
    [CoordinacionStateEnum.vehiculoBodegaConWarehouse]: 'cell-bg-white',
    [CoordinacionStateEnum.vehiculoBodegaTituloPendiente]: 'cell-bg-gray',
    [CoordinacionStateEnum.vehiculoCuentaDealer]: 'cell-bg-green-dark',
    [CoordinacionStateEnum.vehiculoEntregaBodega]: 'cell-bg-blue-dark',
    [CoordinacionStateEnum.ordenCancelada]: 'cell-bg-black',
    [CoordinacionStateEnum.completado]: 'cell-bg-blue',
  }

  const columnActionTemplate = (value, row) => {
    const puedeAgregarWerehouse = validarPermisos(permisos.AGREGAR_WEREHOUSE);
    const puedeModificarCoord = validarPermisos(permisos.MODIFICAR_COORDINACION);
    const puedeModificarCoordOffice = validarPermisos(permisos.EDITAR_COOR_OFFICE);

    return (
      <Space>
        {puedeModificarCoord && (
          <Button
            type="button"
            onClick={() => navigate(`/coordinations/${row?.id}/edit`)}
            variant="primary"
            size="sm"
          >
            <FontAwesomeIcon icon={faPenToSquare} size={"xs"}/>
          </Button>
        )}

        {puedeModificarCoordOffice && (
          <Button
            type="button"
            onClick={() => handleModificarCoordOffice(row)}
            variant="primary"
            size="sm"
          >
            <FontAwesomeIcon icon={faPenToSquare} size={"xs"}/>
          </Button>
        )}

        {puedeAgregarWerehouse && (
          <Button
            as={Link}
            to={`/newWereHouse/${row?.id}`}
            variant="success"
            size="sm"
          >
            <FontAwesomeIcon icon={faCarTunnel}/>
          </Button>
        )}

        <Button
          onClick={() => navigate(`/coordinations/${row?.id}/view`)}
          variant="info"
          size="sm"
        >
          <FontAwesomeIcon icon={faEye}/>
        </Button>

        <Badge count={row?.totalComentarios}>
          <Button
            onClick={() => setIdCor(row?.id)}
            value={row?.id}
            variant="warning"
            size="sm"
          >
            <FontAwesomeIcon icon={faComment}/>
          </Button>
        </Badge>
        {puedeModificarCoord && (
          <Button
            onClick={() => {
              setIdcordinacion(row?.id);
              setShowModalConfirmDeleteCoordination(true);
            }}
            variant="danger"
            size="sm"
          >
            <FontAwesomeIcon icon={faXmark}/>
          </Button>
        )}
        {puedeModificarCoord && (
          <Button
            onClick={() => handleShowEmailModal(row)}
            variant="primary"
            size="sm"
            title="Enviar correo al cliente"
          >
            <FontAwesomeIcon icon={faPaperPlane}/>
          </Button>
        )}
      </Space>
    );
  }

  const handleSave = (value, item, index, key) => {
    const newCoord = [...coordi];
    item[key] = value;

    newCoord[index] = {
      ...item,
      [key]: value
    };

    setCoordi([...newCoord]);

    // modificar en la base de datos
    let input = {
      fechacor: item.fechacor,
      clienteId: item.clienteId,
      importadoraId: item.importadoraId,
      countryId: item.countryId,
      vehiculoId: item.vehiculoId,
      origenId: item.origenId,
      aduanaId: item.aduanaId,
      paid: item.paid,
      firstBusDay: item.firstBusDay,
      lastFreeDay: item.lastFreeDay,
      estPickUp: item.estPickUp,
      actPickUp: item.actPickUp,
      estDelivery: item.estDelivery,
      actDelivery: item.actDelivery,
      estArrival: item.estArrival,
      actArrival: item.actArrival,
      cost: item.cost,
      rate_Quoted: item.rate_Quoted,
      profit: item.profit,
      st_Cliente: item.st_Cliente,
      st_Driver: item.st_Driver,
      st_Matus: item.st_Matus,
      st_Paid: item.st_Paid,
      nota: item.nota,
      state: item.state,
      quoteId: item.quoteId,
      transporteId: item.transporteId,
      subastaId: item.subastaId,
      buyer: item.buyer,
      type_pay: item.type_pay,
      flete: item.flete,
      bl: item.bl,
      gatePassPin: item.gatePassPin,
      cDealer: item.cDealer,
      costAdditional: item.costAdditional,
      createBy: item.createBy,
      updateBy: item.updateBy,
      updateDate: item.updateDate,
      usuarioId: item.usuarioId,
      dispatchDate: item.dispatchDate,
      entryType: item.entryType,
    }

    input[key] = value;

    try {
      setIsLoading(true);
      editCoor(item.id, input).then(() => setIsLoading(false));
    } catch (ex) {
      errorMessage("Error al actualizar la coordinación");
    }
  }

  const antdColumns = [
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'left',
      width: 280,
      render: columnActionTemplate,
    },
    {key: 'id', dataIndex: 'id', title: '#', width: 70, fixed: 'left',},
    {
      key: 'fechacor', dataIndex: 'fechacor', title: 'Fecha', width: 150,
      render: (value) => <>{moment(value).format("l")}</>
    },
    {key: 'import', dataIndex: 'import', title: 'Importadora', width: 250},
    {key: 'aduanaCom', dataIndex: 'aduanaCom', title: 'Destino', width: 180},
    {key: 'vehiculoName', dataIndex: 'vehiculoName', title: 'Vehículo', width: 200},
    {
      key: 'subs', dataIndex: 'subs', title: 'Ubic./Subasta', width: 240,
      render: value => <div className={value ? '' : 'cell-bg-yellow'}>{value ?? "VEHICLE BY CLIENT"}</div>,
    },
    {key: 'lote', dataIndex: 'lote', title: 'Lote', width: 130},
    {key: 'gatePassPin', dataIndex: 'gatePassPin', title: 'PIN', width: 120},
    {key: 'buyer', dataIndex: 'buyer', title: 'Buyer', width: 120},
    {
      key: 'state',
      dataIndex: 'state',
      title: 'Estado',
      width: 240,
      render: (value, item, index) =>
        <div className={coordinacionStateClassName[value]}>
          <EditableCell type={'select'}
                        value={value}
                        editable={puedeModificarCoordinacion}
                        onSubmit={(newValue) => handleSave(newValue, item, index, 'state')}
                        selectList={[
                          {label: 'Vehículo en bodega sin WareHouse', value: 'Vehículo en bodega sin WareHouse'},
                          {label: 'Vehículo en bodega con WareHouse', value: 'Vehículo en bodega con WareHouse'},
                          {label: 'Vehículo en bodega con título pendiente', value: 'Vehículo en bodega con título pendiente'},
                          {label: 'Vehiculo no pagado en subasta', value: 'Vehiculo no pagado en subasta'},
                          {label: 'Vehiculo pagado en subasta', value: 'Vehiculo pagado en subasta'},
                          {label: 'Pendiente de prepago', value: 'Pendiente de prepago'},
                          {label: 'Chofer asignado', value: 'Chofer asignado'},
                          {label: 'Vehículo de dirección particular', value: 'Vehículo de dirección particular'},
                          {label: 'Vehículo para corte', value: 'Vehículo para corte'},
                          {label: 'No pick Up', value: 'No pick Up'},
                          {label: 'No delivery', value: 'No delivery'},
                          {label: 'Vehículo Posteado', value: 'Vehículo Posteado'},
                          {label: 'Vehículo de la cuenta dealer', value: 'Vehículo de la cuenta dealer'},
                          {label: 'Vehículo para entrega en bodega', value: 'Vehículo para entrega en bodega'},
                          {label: 'COMPLETADO', value: 'COMPLETADO'},
                        ]}>
            {value}
          </EditableCell>
        </div>
    },
    {
      key: 'transporteName', dataIndex: 'transporteName', title: 'Transporte', width: 120,
      render: value => <>{value ?? "NO ASIGNADO"}</>
    },
    {key: 'tipoPago', dataIndex: 'tipoPago', title: 'Tipo de Pago', width: 120},
    {
      key: 'paid', dataIndex: 'paid', title: 'Fecha de Pago', width: 160,
      render: (value, item, index) => <EditableCell type={'date'}
                                                    editable={puedeModificarCoordinacion}
                                                    value={value ? moment(value).format("l") : "--/--/----"}
                                                    onSubmit={(newValue) => handleSave(newValue, item, index, 'paid')}>
        {value ? moment(value).format("l") : "--/--/----"}
      </EditableCell>
    },
    {
      key: 'firstBusDay', dataIndex: 'firstBusDay', title: 'First Day', width: 160,
      render: (value, item, index) =>
        <EditableCell type={'date'}
                        editable={puedeModificarCoordinacion}
                        value={value ? moment(value).format("l") : "--/--/----"}
                        onSubmit={(newValue) => handleSave(newValue, item, index, 'firstBusDay')}>
          {value ? moment(value).format("l") : "--/--/----"}
        </EditableCell>
    },
    {
      key: 'lastFreeDay', dataIndex: 'lastFreeDay', title: 'Last Day', width: 160,
      render: (value, item, index) =>
        <EditableCell type={'date'}
                      editable={puedeModificarCoordinacion}
                      value={value ? moment(value).format("l") : "--/--/----"}
                      onSubmit={(newValue) => handleSave(newValue, item, index, 'lastFreeDay')}>
          {value ? moment(value).format("l") : "--/--/----"}
        </EditableCell>
    },
    {
      key: 'estPickUp', dataIndex: 'estPickUp', title: 'Est.Recolec.', width: 160,
      render: (value, item, index) =>
        <EditableCell type={'date'}
                      editable={puedeModificarCoordinacion}
                      value={value ? moment(value).format("l") : "--/--/----"}
                      onSubmit={(newValue) => handleSave(newValue, item, index, 'estPickUp')}>
          {value ? moment(value).format("l") : "--/--/----"}
        </EditableCell>
    },
    {
      key: 'actPickUp', dataIndex: 'actPickUp', title: 'Act.Recolec.', width: 160,
      render: (value, item, index) =>
        <EditableCell type={'date'}
                      editable={puedeModificarCoordinacion}
                      value={value ? moment(value).format("l") : "--/--/----"}
                      onSubmit={(newValue) => handleSave(newValue, item, index, 'actPickUp')}>
          {value ? moment(value).format("l") : "--/--/----"}
        </EditableCell>
    },
    {
      key: 'estDelivery', dataIndex: 'estDelivery', title: 'Est.Entrega', width: 160,
      render: (value, item, index) =>
        <EditableCell type={'date'}
                        editable={puedeModificarCoordinacion}
                        value={value ? moment(value).format("l") : "--/--/----"}
                        onSubmit={(newValue) => handleSave(newValue, item, index, 'estDelivery')}>
          {value ? moment(value).format("l") : "--/--/----"}
        </EditableCell>
    },
    {
      key: 'actDelivery', dataIndex: 'actDelivery', title: 'Act.Entrega', width: 160,
      render: (value, item, index) =>
        <EditableCell type={'date'}
                      editable={puedeModificarCoordinacion}
                      value={value ? moment(value).format("l") : "--/--/----"}
                      onSubmit={(newValue) => handleSave(newValue, item, index, 'actDelivery')}>
          {value ? moment(value).format("l") : "--/--/----"}
        </EditableCell>
    },
    {
      key: 'dispatchDate', dataIndex: 'dispatchDate', title: 'Fecha Despacho', width: 160,
      render: (value, item, index) =>
        <EditableCell type={'date'}
                      editable={puedeModificarCoordinacion}
                      value={value ? moment(value).format("l") : "--/--/----"}
                      onSubmit={(newValue) => handleSave(newValue, item, index, 'dispatchDate')}>
          {value ? moment(value).format("l") : "--/--/----"}
        </EditableCell>
    },
    {
      key: 'flete', dataIndex: 'flete', title: 'Flete Cliente', width: 120,
      render: (value, item, index) =>
        <EditableCell type={'number'}
                      editable={puedeModificarCoordinacion}
                      value={value ? StringFormats.currency(value) : "--"}
                      onSubmit={(newValue) => handleSave(+newValue, item, index, 'flete')}>
          {value ? StringFormats.currency(value) : "--"}
        </EditableCell>
    },
    {
      key: 'rate_Quoted', dataIndex: 'rate_Quoted', title: 'Grua Cliente', width: 120,
      render: (value, item, index) =>
        <EditableCell type={'number'}
                      editable={puedeModificarCoordinacion}
                      value={value ? StringFormats.currency(value) : "--"}
                      onSubmit={(newValue) => handleSave(+newValue, item, index, 'rate_Quoted')}>
          {value ? StringFormats.currency(value) : "--"}
        </EditableCell>
    },
    {
      key: 'cost', dataIndex: 'cost', title: 'V.Grua Matus', width: 140,
      render: (value, item, index) =>
        <EditableCell type={'number'}
                      editable={puedeModificarCoordinacion}
                      value={value ? StringFormats.currency(value) : "--"}
                      onSubmit={(newValue) => handleSave(newValue, item, index, 'cost')}>
          {value ? StringFormats.currency(value) : "--"}
        </EditableCell>
    },
  ];

  const antdCancelColumns = [
    {
      fixed: 'left',
      width: '120px',
      render: (_, row) => {
        return <Button size={'sm'} onClick={() => {
          setIdcordinacion(row?.id);
          setShowModalReopenCoordination(true);
        }}><FontAwesomeIcon icon={faPenToSquare} size={"xs"}/> Reactivar</Button>
      }
    },
    {key: 'id', dataIndex: 'id', title: '#', width: 70, fixed: 'left',},
    {
      key: 'fechacor', dataIndex: 'fechacor', title: 'Fecha', width: 150,
      render: (value) => <>{moment(value).format("l")}</>
    },
    {key: 'import', dataIndex: 'import', title: 'Importadora', width: 250},
    {key: 'aduanaName', dataIndex: 'aduanaName', title: 'Destino', width: 180},
    {key: 'vehiculoName', dataIndex: 'vehiculoName', title: 'Vehículo', width: 200},
    {
      key: 'subs', dataIndex: 'subs', title: 'Ubic./Subasta', width: 240,
      render: value => <div className={value ? '' : 'cell-bg-yellow'}>{value ?? "VEHICLE BY CLIENT"}</div>,
    },
    {key: 'lote', dataIndex: 'lote', title: 'Lote', width: 130},
    {key: 'gatePassPin', dataIndex: 'gatePassPin', title: 'PIN', width: 120},
    {key: 'buyer', dataIndex: 'buyer', title: 'Buyer', width: 120},
    {
      key: 'state',
      dataIndex: 'state',
      title: 'Estado',
      width: 240,
    },
    {
      key: 'reasonCancellation',
      dataIndex: 'reasonCancellation',
      title: 'Notas',
      width: 340,
    },
  ];

  const handlePageChange = (page, pageSize) => {
    const filters = {
      ...coordinacionFilters,
      currentPage: page,
      pageCount: pageSize,
    };

    // add validation of compare prevState with newState
    // to avoid useEffect execute twice
    if (JSON.stringify(coordinacionFilters) !== JSON.stringify(filters))
      setCoordinacionFilters(filters);
  };

  const onChange = (key) => {
    setCurrentTab(key);

    const filters = {
      ...coordinacionFilters,
      pageCount: 100,
      currentPage: 1,
      state: key === "2"
        ? CoordinacionStateEnum.ordenCancelada
        : coordinacionFilters.state === CoordinacionStateEnum.ordenCancelada
          ? ""
          : coordinacionFilters.state,
      entryType: key === "3"
        ? CoordinacionEntryTypeEnum.entryTypeByClient
        : key === "4"
          ? CoordinacionEntryTypeEnum.entryTypePendingByClient
          : CoordinacionEntryTypeEnum.entryTypeByOwn
    };

    // add validation of compare prevState with newState
    // to avoid useEffect execute twice
    if (JSON.stringify(coordinacionFilters) !== JSON.stringify(filters))
      setCoordinacionFilters(filters);
  };

  const items = [
    {
      key: "1",
      label: "Coordinaciones Activas",
      children: (
        <>
          <Table columns={antdColumns}
                 dataSource={coordi}
                 size="small"
                 loading={isLoading}
                 scroll={{x: 1300, y: 600}}
                 pagination={{
                   position: ["bottomRight"],
                   total: totalElements,
                   showSizeChanger: true,
                   showTotal: total => `${total} elementos`,
                   current: coordinacionFilters.currentPage,
                   pageSize: coordinacionFilters.pageCount,
                   pageSizeOptions: [20, 50, 100, 150, 200, 500],
                   onChange: handlePageChange
                 }}/>
        </>
      ),
    },
    {
      key: "2",
      label: "Coordinaciones Canceladas",
      children: (
        <>
          <Table columns={antdCancelColumns}
                 dataSource={coordi}
                 size="small"
                 loading={isLoading}
                 scroll={{x: 1300, y: 600}}
                 pagination={{
                   position: ["bottomRight"],
                   total: totalElements,
                   showSizeChanger: true,
                   showTotal: total => `${total} elementos`,
                   current: coordinacionFilters.currentPage,
                   pageSize: coordinacionFilters.pageCount,
                   pageSizeOptions: [20, 50, 100, 150, 200, 500]
                 }}/>
        </>
      ),
    },
    {
      key: "3",
      label: "Entrega en bodega por Cliente",
      children: (
        <>
          <Table columns={antdColumns}
                 dataSource={coordi}
                 size="small"
                 loading={isLoading}
                 scroll={{x: 1300, y: 600}}
                 pagination={{
                   position: ["bottomRight"],
                   total: totalElements,
                   showSizeChanger: true,
                   showTotal: total => `${total} elementos`,
                   current: coordinacionFilters.currentPage,
                   pageSize: coordinacionFilters.pageCount,
                   pageSizeOptions: [20, 50, 100, 150, 200, 500]
                 }}/>
        </>
      ),
    },
    {
      key: "4",
      label: "Pendiente de entrega en bodega por Cliente",
      children: (
        <>
          <Table columns={antdColumns}
                 dataSource={coordi}
                 size="small"
                 loading={isLoading}
                 scroll={{x: 1300, y: 600}}
                 pagination={{
                   position: ["bottomRight"],
                   total: totalElements,
                   showSizeChanger: true,
                   showTotal: total => `${total} elementos`,
                   current: coordinacionFilters.currentPage,
                   pageSize: coordinacionFilters.pageCount,
                   pageSizeOptions: [20, 50, 100, 150, 200, 500]
                 }}/>
        </>
      ),
    },
  ];

  const onFinish = async (values) => {
    values.st_Cliente = values.st_Cliente ? values.st_Cliente : coordiId.st_Cliente;
    values.st_Driver = values.st_Driver ? values.st_Driver : coordiId.st_Driver;
    values.st_Matus = values.st_Matus ? values.st_Matus : coordiId.st_Matus;
    values.st_Paid = values.st_Paid ? values.st_Paid : coordiId.st_Paid;
    values.state = values.state ? values.state : coordiId.state;

    const input = {
      fechacor: coordiId.fechacor,
      clienteId: coordiId.clienteId,
      importadoraId: coordiId.importadoraId,
      countryId: coordiId.countryId,
      vehiculoId: coordiId.vehiculoId,
      origenId: coordiId.origenId,
      aduanaId: coordiId.aduanaId,
      paid: coordiId.paid,
      firstBusDay: coordiId.firstBusDay,
      lastFreeDay: coordiId.lastFreeDay,
      estPickUp: coordiId.estPickUp,
      actPickUp: coordiId.actPickUp,
      estDelivery: coordiId.estDelivery,
      actDelivery: actDelivery ? actDelivery : coordiId.actDelivery,
      estArrival: coordiId.estArrival,
      actArrival: coordiId.actArrival,
      cost: coordiId.cost,
      rate_Quoted: coordiId.rate_Quoted,
      profit: coordiId.profit,
      st_Cliente: values.st_Cliente ? values.st_Cliente : coordiId.st_Cliente,
      st_Driver: values.st_Driver ? values.st_Driver : coordiId.st_Driver,
      st_Matus: values.st_Matus ? values.st_Matus : coordiId.st_Matus,
      st_Paid: values.st_Paid ? values.st_Paid : coordiId.st_Paid,
      nota: coordiId.nota,
      state: values.state ? values.state : coordiId.state,
      quoteId: coordiId.quoteId,
      transporteId: coordiId.transporteId,
      subastaId: coordiId.subastaId,
      buyer: coordiId.buyer,
      type_pay: coordiId.type_pay,
      flete: coordiId.flete,
      bl: coordiId.bl,
      gatePassPin: coordiId.gatePassPin,
      cDealer: coordiId.cDealer,
      costAdditional: coordiId.costAdditional,
      createBy: coordiId.createBy,
      updateBy: coordiId.updateBy,
      updateDate: coordiId.updateDate,
      usuarioId: coordiId.usuarioId,
      dispatchDate: coordiId.dispatchDate,
      entryType: coordiId.entryType,
    }

    try {
      const resp = await editCoor(coordiId.id, input);
      setShowModalST(false);
      form.resetFields();
      setActDelivery(null);
      setCoordiId([]);
      showCoordinaciones(coordinacionFilters).then();
      successMessage("Se ha actualizado la coordinación correctamente");
    } catch (ex) {
      errorMessage("Error al actualizar la coordinación");
    }
  }

  const exportToExcel = async () => {
    setIsLoading(true);

    const data = await showCoord(
      id,
      coordinacionFilters.state,
      coordinacionFilters.year,
      coordinacionFilters.month,
      0,
      0,
      coordinacionFilters.search,
      coordinacionFilters.entryType,
      coordinacionFilters.aduanaId,
      coordinacionFilters.day ? coordinacionFilters.day.getDate() : 0,
      coordinacionFilters.estPickUp ? coordinacionFilters.estPickUp.getTime() : 0,
      coordinacionFilters.estDelivery ? coordinacionFilters.estDelivery.getTime() : 0,
      0,
      0,
      "",
      0,
      coordinacionFilters.actDelivery ? coordinacionFilters.actDelivery.getTime() : 0,
    );

    setIsLoading(false);

    if (data.status) {
      const list = data.list.map(e => {
        return {
          'Fecha': moment(e.fechacor).format('l'),
          'Transporte': e.transporteName,
          'Lote': e.lote,
          'Vehiculo': e.vehiculoName,
          'Ubic./Subasta': e.subs,
          'Estado': e.state,
          'Act Delivery': e.actDelivery ? moment(e.actDelivery).format('l') : "--/--/----",
          'V. Grua Matus': e.cost ? +e.cost : 0,
          'Storage Driver': e.st_Driver ? +e.st_Driver : 0,
          'Storage Pagado': e.st_Paid ? +e.st_Paid : 0,
        }
      });

      const worksheet = XLSX.utils.json_to_sheet(list);
      const range = XLSX.utils.decode_range(worksheet['!ref']);

      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cell_1 = XLSX.utils.encode_cell({ r: R, c: 7 });
        const cell_2 = XLSX.utils.encode_cell({ r: R, c: 8 });
        const cell_3 = XLSX.utils.encode_cell({ r: R, c: 9 });

        if (!worksheet[cell_1]) continue;
        worksheet[cell_1].z = '"$"#,##0.00_);("$"#,##0.00)';

        if (!worksheet[cell_2]) continue;
        worksheet[cell_2].z = '"$"#,##0.00_);("$"#,##0.00)';

        if (!worksheet[cell_3]) continue;
        worksheet[cell_3].z = '"$"#,##0.00_);("$"#,##0.00)';
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Coordinaciones");

      // Genera el archivo Excel
      XLSX.writeFile(workbook, "coordinaciones.xlsx", {
        bookType: "xlsx",
        type: "blob",
      });
    }
  }

  return (
    <>
      <Modal
        title="Confirmar Cancelación"
        open={showModalConfirmDeleteCoordination}
        onOk={() => {
          if (textArea === "") {
            setStatusTextArea("error");
            return;
          } else {
            deleteCoordinacion(idcordinacion, {
              description: textArea,
            });
            setShowModalConfirmDeleteCoordination(false);
            setTextArea("");
            setStatusTextArea("success");
          }
        }}
        onCancel={() => {
          setShowModalConfirmDeleteCoordination(false);
          setTextArea("");
          setStatusTextArea("success");
        }}
        okText="Cancelar Coordinación"
        cancelText="Cerrar"
        okButtonProps={{style: {backgroundColor: "red"}}}
      >
        <p>¿Está seguro que desea cancelar la coordinación?</p>
        <TextArea
          status={statusTextArea}
          value={textArea}
          onChange={(e) => {
            setTextArea(e.target.value);
            setStatusTextArea("success");
          }}
          placeholder="Escriba el motivo de la cancelación"
          autoSize={{minRows: 3, maxRows: 5}}
        />
        {statusTextArea === "error" ? (
          <p style={{color: "red"}}>Este campo es requerido</p>
        ) : (
          <></>
        )}
      </Modal>

      <Modal
        title="Confirmar Reactivación"
        open={showModalReopenCoordination}
        onOk={() => {
          reopenCoordinacion(idcordinacion, {description: ''}).then();
          setShowModalReopenCoordination(false);
        }}
        onCancel={() => {
          setShowModalReopenCoordination(false);
        }}
        okText="Reactivar Coordinación"
        cancelText="Cerrar"
      >
        <p>¿Está seguro que desea reactivar la coordinación?</p>
      </Modal>

      <Modal title="Actualizar Storage" open={showModalST} onOk={() => form.submit()}
             onCancel={() => setShowModalST(false)} okText="Actualizar Storage">
        <Spin spinning={isLoadingModal} tip="Cargando...">
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <p><strong>Grua Cotizada: </strong>{coordiId.rate_Quoted}  </p>

            <p><strong>Costo de Grua: </strong>{coordiId.cost} </p>
            <p><strong>Profit: </strong>{coordiId.profit} </p>
          </div>

          <FormAnt form={form} layout="vertical" onFinish={onFinish}>
            <br/>
            <Form.Label className='text-aling-left'>Act Delivery</Form.Label>
            <Form.Control onChange={handleChangeActDelivery}
                          name='actDelivery'
                          value={actDelivery ? actDelivery : ''}
                          type="date"
                          aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                          style={{
                            marginBottom: "10px"
                          }}
            />

            <FormAnt.Item label="Storage-Client"
                          name="st_Cliente"
            >
              <Input/>
            </FormAnt.Item>

            <FormAnt.Item label="Storage-Driver"
                          name="st_Driver"
            >
              <Input/>
            </FormAnt.Item>

            <FormAnt.Item label="Storage-Matus"
                          name="st_Matus"
            >
              <Input/>
            </FormAnt.Item>

            <FormAnt.Item label="Storage Pagado"
                          name="st_Paid"
            >
              <Input/>
            </FormAnt.Item>

            <FormAnt.Item label="Asigne un estado a esta coordinación"
                          name="state"
            >
              <Select placeholder="Seleccione un estado" style={{width: "100%"}}>
                <Select.Option value="Vehículo en bodega sin WareHouse">Vehículo en bodega sin WareHouse</Select.Option>
                <Select.Option value="Vehículo en bodega con WareHouse">Vehículo en bodega con WareHouse</Select.Option>
                <Select.Option value="Vehículo en bodega con título pendiente">Vehículo en bodega con título
                  pendiente</Select.Option>
                <Select.Option value="Vehiculo no pagado en subasta" disabled>Vehiculo no pagado en
                  subasta</Select.Option>
                <Select.Option value="Vehiculo pagado en subasta" disabled>Vehiculo pagado en subasta</Select.Option>
                <Select.Option value="Pendiente de prepago" disabled>Pendiente de prepago</Select.Option>
                <Select.Option value="Chofer asignado" disabled>Chofer asignado</Select.Option>
                <Select.Option value="Vehículo de dirección particular" disabled>Vehículo de dirección
                  particular</Select.Option>
                <Select.Option value="Vehículo para corte" disabled>Vehículo para corte</Select.Option>
                <Select.Option value="No pick Up" disabled>No pick Up</Select.Option>
                <Select.Option value="No delivery" disabled>No delivery</Select.Option>
                <Select.Option value="Vehículo Posteado" disabled>Vehículo Posteado</Select.Option>
                <Select.Option value="Vehículo de la cuenta dealer" disabled>Vehículo de la cuenta
                  dealer</Select.Option>
                <Select.Option value="Vehículo para entrega en bodega" disabled>Vehículo para entrega en
                  bodega</Select.Option>
                <Select.Option value="COMPLETADO" disabled>COMPLETADO</Select.Option>
                <Select.Option value="ORDEN CANCELADA" disabled>ORDEN CANCELADA</Select.Option>
              </Select>
            </FormAnt.Item>
          </FormAnt>
        </Spin>
      </Modal>

      {coordinacionSendEmail ? (
        <SendEmail
          visible={true}
          titulo={"Enviar correo electronico"}
          subject={coordinacionSendEmail.subastaId === 571 ? `Confirmación de entrega en bodega  ${coordinacionSendEmail.origen.state}${
            coordinacionSendEmail.cdealer ? " **Cuenta Dealer**" : ""
          } Lote: ${coordinacionSendEmail.lote}`.toUpperCase() : `Confirmación de solicitud de Recolección ${coordinacionSendEmail.origen.state}${
            coordinacionSendEmail.cdealer ? " **Cuenta Dealer**" : ""
          } Lote: ${coordinacionSendEmail.lote}. Subasta: ${
            coordinacionSendEmail.subs
          }. Destino: ${coordinacionSendEmail.aduanaName}`.toUpperCase()}
          to={
            coordinacionSendEmail.coDos && coordinacionSendEmail.coDos !== ""
              ? coordinacionSendEmail.emailCli &&
              coordinacionSendEmail.emailCli !== ""
                ? [
                  ...coordinacionSendEmail.emailCli
                    .replace(/\s/, "")
                    .split(","),
                  ...coordinacionSendEmail.coDos,
                ]
                : [coordinacionSendEmail.coDos]
              : coordinacionSendEmail.emailCli &&
              coordinacionSendEmail.emailCli !== ""
                ? [...coordinacionSendEmail.emailCli.replace(/\s/, "").split(",")]
                : []
          }
          cc={
            currentUser && currentUser.email
              ? [environment.emails.grua, currentUser.email]
              : [environment.emails.grua]
          }
          attachments={[
            {
              nombreArchivo: "notas_importantes.pdf",
              archivo: CoordinacionNotasImportantes,
            },
          ]}
          body={CoordinacionNotificarCliente(coordinacionSendEmail)}
          setVisible={() => setCoordinacionSendEmail(undefined)}
          doSendEmail={handleSendEmail}
          isLoading={false}
          showMessageInput={false}
        />
      ) : (
        <></>
      )}

      <CoordinacionFilter coordinacionFilters={coordinacionFilters}
                          currentTab={currentTab}
                          onFilter={filter => setCoordinacionFilters(filter)} />

      <div className="d-flex align-items-center justify-content-end">
        <Button size="sm" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faDownload} /> Exportar tabla a Excel
        </Button>
      </div>
      <Tabs activeKey={currentTab} items={items} onChange={onChange} centered/>
      <>
        <Drawer
          onClose={onCloseDrawer}
          open={openDrawer}
          title={"Comentarios"}
          footer={
            <>
              <Row>
                &nbsp;&nbsp;&nbsp;{" "}
                <Form.Control
                  readOnly
                  disabled
                  //   style={{ width: "200px" }}
                  value={fechaactual}
                  name="fecha"
                  type="text"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Row>
              <br/>
              <Row>
                <Col xs={24} md={24} lg={24}>
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Escriba su comentario aqui"
                    width="100%"
                  >
                    <Form.Control
                      name="nota"
                      maxLength={1000}
                      value={coment?.nota}
                      onChange={handleChange}
                      as="textarea"
                      style={{height: "150px", width: "100%"}}
                      // style={{ width: "100%" }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <Row className="conte-padre">
                <Button
                  style={{width: "100%"}}
                  onClick={() => insertarComent()}
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faPaperPlane}/>
                  &nbsp;Guardar Comentario
                </Button>{" "}
              </Row>
            </>
          }
        >
          {notes.map((not, index) => (
            <Row key={index}>
              <Col xs={24} md={24} lg={24}>
                <Alert variant="secondary">
                  <p>{not.nota}</p>
                  <small>{not.fecha} by {not.autor}</small>
                </Alert>
              </Col>
            </Row>
          ))}
        </Drawer>
      </>
    </>
  );
}

export default CoordinacionTable;
