import {Button, Form, Select} from "antd";
import {useEffect, useState} from "react";
import Spinner from "../../shared/spinner";
import {showCountries} from "../../Servicios/CombosService";
import {getRoles, saveUserRol} from "../../Servicios/AdminService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {errorMessage, successMessage} from "../../utils/toast-message";

const UsuarioRolForm = ({ rolId, usuarioId, afterSave }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [roleList, setRoleList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    init().then();
  }, [usuarioId]);

  useEffect(() => {
    if (rolId) {
      form.setFieldValue('rolId', rolId);
    } else {
      form.resetFields();
    }
  }, [rolId]);

  const init = async () => {
    const countries = await showCountries();
    const roles = await getRoles();

    countries.unshift({ id: 0, nameCountry: 'TODOS LOS PAISES' });

    setCountryList(countries);
    setRoleList(roles);
  }

  const handleFinish = async (value) => {
    const body = {
      countryId: value.countryId,
      usuarioId: +usuarioId,
      rolId: value.rolId,
    }

    setLoading(true);
    const data = await saveUserRol(body);
    setLoading(false);

    if (data.status) {
      successMessage("El rol fue asignado correctamente");
      form.resetFields();

      if (afterSave) afterSave();
    } else {
      errorMessage(data.message);
    }
  }

  return(
    <>
      <Spinner loading={loading}>
        <Form form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={handleFinish}>
          <Form.Item label={"Rol"}
                     name={"rolId"}
                     rules={[{ required: true, message: 'This field is required' }]}>
            <Select placeholder={"Rol"}
                    defaultValue={rolId}
                    options={roleList.map(e => {
                      return { value: e.id, label: e.name }
                    })} />
          </Form.Item>

          <Form.Item label={"Access Country"} name={"countryId"}
                     rules={[{ required: true, message: 'This field is required' }]}>
            <Select placeholder={"Country"}
                    options={countryList.map(e => {
                      return { value: e.id, label: e.nameCountry }
                    })} />
          </Form.Item>

          <Button htmlType={'submit'} type={'primary'}><FontAwesomeIcon className="me-2" icon={faFloppyDisk}/> Guardar</Button>
        </Form>
      </Spinner>
    </>
  );
}

export default UsuarioRolForm;
