import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Space,
  Table,
  Tooltip,
  Row,
  Col,
  Input,
  Modal,
  Descriptions,
  Spin,
  Card,
  Alert,
  Image, Tabs,
} from "antd";
import Spinner from "../shared/spinner";
import {
  EyeFilled,
  EditOutlined,
  SearchOutlined,
  CopyFilled,
  LoadingOutlined,
  FileTextOutlined, MailFilled,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  showQuotePoridvehi,
} from "../Servicios/SolicitudesService";
import moment from "moment";
import { toast } from "react-toastify";
import "../WereHouse/styleModalPrint.css";
import { useReactToPrint } from "react-to-print";
import MatusColor from "../image/matuscolor.png";
import {
  getListFolderId,
  getMediaListFolderId,
} from "../Servicios/GoogleService";
import copy from "copy-to-clipboard";
import {hasRol, validarPermisos} from "../Servicios/AuthService";
import { permisos } from "../utils/permisos";
import {editCar, getVehicleInput, getVehicleList} from "../Servicios/VehicleService";
import {VehiculoEstadoEnum} from "../shared/enums/vehiculo-estado.enum";
import VehicleReceivingForm from "./VehicleReceivingForm";
import roles from "../utils/roles";
import {StringFormats} from "../utils/string-formats";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import {VehiculoEstadoInspeccionEnum} from "../shared/enums/vehiculo-estado-inspeccion.enum";
import environment from "../environments/environment";
import SendEmail from "../utils/email";
import {errorMessage, successMessage} from "../utils/toast-message";
import {DateService} from "../Servicios/DateService";
import { NotificacionEntregaVehiculoClienteEmail } from "../Resources/HtmlEmailBody/NotificacionEntregaVehiculoCliente.email";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

const ReceivedToday = () => {
  const [recibidosHoy, setRecibidosHoy] = useState([]);
  const [recibidosHoyFlorida, setRecibidosHoyFlorida] = useState([]);
  const [recibidosHoyCalifornia, setRecibidosHoyCalifornia] = useState([]);

  const [cantidadRecibidos, setCantidadRecibidos] = useState();
  const [cantidadRecibidosFlorida, setCantidadRecibidosFlorida] = useState();
  const [cantidadRrecibidosCalifornia, setCantidadRecibidosCalifornia] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingInfoSoli, setLoadingInfoSoli] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState();
  const [infoSoli, setInfoSoli] = useState();
  const [showInputs, setShowInputs] = useState(false);

  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState();
  const [copyLoading, setCopyLoading] = useState(false);
  const [isModalOpenListEvidence, setIsModalOpenListEvidence] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [media, setMedia] = useState([]);
  const [selectedVehicleCopyLink, setSelectedVehicleCopyLink] = useState(undefined);
  const [selectedVehicleSendEmail, setSelectedVehicleSendEmail] = useState(undefined);

  const [numeroLote, setNumeroLote] = useState('');

  const puedeEditarVehiculos = validarPermisos(permisos.EDITAR_VEHICULOS);

  // filtros
  const [estadoVehiculo, setEstadoVehiculo] = useState(VehiculoEstadoEnum.recibidoEnBodega);
  const [startDate, setStartDate] = useState(DateService.currentDate());
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    dataVehicles(estadoVehiculo, startDate, endDate).then();
  }, []);

  const handleOkListEvidence = () => {
    setIsModalOpenListEvidence(false);
  };

  const handleCancelListEvidence = () => {
    setIsModalOpenListEvidence(false);
  };
  const datosPrint = useRef();

  const handleStartDateChange = (event) => {
    setStartDate(new Date(`${event.target.value}T00:00:00`));
  };

  const handleEndDateChange = (event) => {
    setEndDate(new Date(`${event.target.value}T00:00:00`));
  };

  const canViewOnlyOneOrigen = hasRol(roles.RECIVING_CA, roles.CAOFFICE, roles.RECIVING_FL, roles.FLOFFICE);

  const notifyUpdate = () =>
    toast.success("Datos guardados correctamente", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const dataVehicles = async (estado = "", startDate = undefined, endDate = undefined) => {
    let origenId = "";

    if (hasRol(roles.FLOFFICE, roles.RECIVING_FL)) origenId = "1";
    else if (hasRol(roles.CAOFFICE, roles.RECIVING_CA)) origenId = "2";

    const fechaInicio = startDate ? startDate.getTime() : 0;
    const fechaFin = endDate ? endDate.getTime() : 0;

    if (origenId === "1" || origenId === "2") {
      setIsLoading(true);
      const data = await getVehicleList(origenId, estado, "", fechaInicio, fechaFin);
      setIsLoading(false);

      setRecibidosHoy(data);
      setCantidadRecibidos(data.length);
    } else {
      setIsLoading(true);
      const data1 = await getVehicleList("1", estado, "", fechaInicio, fechaFin);
      const data2 = await getVehicleList("2", estado, "", fechaInicio, fechaFin);
      setIsLoading(false);

      setRecibidosHoyFlorida(data1);
      setCantidadRecibidosFlorida(data1.length);

      setRecibidosHoyCalifornia(data2);
      setCantidadRecibidosCalifornia(data2.length);
    }
  };

  const handleChangeEstadoVehicle = () => {
    if (estadoVehiculo === VehiculoEstadoEnum.noRecibido) {
      setEstadoVehiculo(VehiculoEstadoEnum.recibidoEnBodega);
      dataVehicles(VehiculoEstadoEnum.recibidoEnBodega, startDate, endDate).then();
    } else {
      setEstadoVehiculo(VehiculoEstadoEnum.noRecibido);
      dataVehicles(VehiculoEstadoEnum.noRecibido, startDate, endDate).then();
    }
  }

  const infoModal = async (vehi) => {
    infoSolicitud(vehi.vehiculoId).then();
    setVehicle(vehi);
  };

  const infoSolicitud = async (idsoli) => {
    if (idsoli !== null && idsoli !== undefined) {
      setLoadingInfoSoli(true);
      const data = await showQuotePoridvehi(idsoli).finally(() => {
        setLoadingInfoSoli(false);
      });
      setInfoSoli(data);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => datosPrint.current,
  });

  const handleSubmit = () => {
    if (startDate && !endDate) {
      dataVehicles(estadoVehiculo, startDate).then();
      setShowInputs(false);
    } else if (startDate && endDate) {
      dataVehicles(estadoVehiculo, startDate, endDate).then();
      setShowInputs(false);
    }
  };

  const handleButtonClick = () => {
    setShowInputs(true);
  };

  const handleCurrentDate = () => {
    setStartDate(new Date());
    setEndDate(undefined);
    dataVehicles(estadoVehiculo, new Date(), undefined).then();
  }

  const handleSaveVehicle = () => {
    setIsModalEditOpen(false);
    setVehiculoSeleccionado(undefined);
    dataVehicles(estadoVehiculo, startDate, endDate).then();
    notifyUpdate();
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalEdit = () => {
    setIsModalEditOpen(true);
  };

  const handleOkE = () => {
    setIsModalEditOpen(false);
  };

  const handleCancelE = () => {
    setIsModalEditOpen(false);
  };

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSendMail = async (vehicle) => {
    setCopyLoading(true);
    const elements = await getListFolderId(vehicle.lote);
    setCopyLoading(false);

    if (elements.data.length !== 0) {
      setSelectedVehicleCopyLink(`https://drive.google.com/drive/folders/${elements.data}`);
    }

    infoModal(vehicle);
    setSelectedVehicleSendEmail(vehicle);
  }

  const handleSendEmail = async () => {
    setSelectedVehicleCopyLink(undefined);
    setSelectedVehicleSendEmail(undefined);
    await dataVehicles(estadoVehiculo, startDate, endDate);
  }

  const handleCopyLink = async (vehicle) => {
    setCopyLoading(true);
    const elements = await getListFolderId(vehicle.lote);
    setCopyLoading(false);

    if (elements.data.length === 0) {
      errorMessage("No se ha subido evidencia");
    } else {
      copy(`https://drive.google.com/drive/folders/${elements.data}`);
      successMessage("Se ha copiado el link con exito!");
    }
  }

  const titulos = {
    trueTitle: `INGRESO DEL VEHÍCULO EN ${selectedVehicleSendEmail?.origen === "1" ? "FLORIDA" : "CALIFORNIA"
      } ${selectedVehicleSendEmail ? StringFormats.strDate(selectedVehicleSendEmail?.fecha) : ""} - LOTE: ${selectedVehicleSendEmail?.lote
      } ${selectedVehicleSendEmail?.vehiculoName} ${selectedVehicleSendEmail ? `ASESOR: ${selectedVehicleSendEmail?.creador}` : ""
      }`.toUpperCase(),
    falseTitle: `INGRESO DE VEHÍCULO **SIN TITULO** EN ${selectedVehicleSendEmail?.origen === "1" ? "FLORIDA" : "CALIFORNIA"
      } ${selectedVehicleSendEmail ? StringFormats.strDate(selectedVehicleSendEmail?.fecha) : ""} - LOTE: ${selectedVehicleSendEmail?.lote
      } ${selectedVehicleSendEmail?.vehiculoName} ${infoSoli ? `ASESOR: ${infoSoli?.creador}` : ""
      }`.toUpperCase(),
  };

  const handleUpdateTitulo = async (vehiculo, recibidoSinTitulo) => {
    const input = {
      ...getVehicleInput(vehiculo),
      recibidoSinTitulo: recibidoSinTitulo,
      titulo: vehiculo.titulo === recibidoSinTitulo ? vehiculo.titulo : !recibidoSinTitulo
    }

    setIsLoading(true);
    await editCar(vehiculo.vehiculoId, input);
    setIsLoading(false);

    successMessage('Vehículo actualizado correctamente');
    await dataVehicles(estadoVehiculo, startDate, endDate);
  }

  const columns = [
    {
      title: "Date Received",
      dataIndex: "fecha",
      key: "fecha",
      width: 170,
      render: (fecha) => fecha
        ? <div>
          <p className="mb-0">{moment(fecha).format("DD, MMMM yyyy").toUpperCase()}</p>
          <p className="mb-0">{moment(fecha).format("HH:mm")}</p>
      </div>
        : 'No set',
      sorter: (a, b) => a.fecha - b.fecha,
    },
    {
      title: "Lote",
      dataIndex: "lote",
      key: "lote",
      width: 140,
      ...getColumnSearchProps("lote"),
    },
    {
      title: "Vehicle",
      dataIndex: "vehiculoName",
      key: "vehiculoName",
      width: 250,
      ...getColumnSearchProps("vehiculoName"),
    },
    {
      title: "VIN",
      dataIndex: "vin",
      key: "vin",
      width: 200,
      ...getColumnSearchProps("vin"),
    },
    {
      title: "# Key",
      dataIndex: "ubicacion",
      key: "ubicacion",
      width: 80,
    },
    {
      title: "Título",
      dataIndex: "recibidoSinTitulo",
      key: "recibidoSinTitulo",
      width: 150,
      render: (value, element) => <>
        <ShowWhen show={validarPermisos(permisos.VEHICULO_MODIFICAR_TITULO_RECIBIDO)}>
          <FontAwesomeIcon icon={faPencil}
                           onClick={() => handleUpdateTitulo(element, !value)}
                           className="me-3 cursor-pointer"
                           title="Modificar" />
        </ShowWhen>
        {
          value
            ? <div className="badge bg-danger">SIN TÍTULO</div>
            : <div className="badge bg-primary">CON TÍTULO</div>
        }
      </>
    },
    {
      title: "Options",
      key: "option",
      dataIndex: "option",
      width: 240,
      fixed: 'right',
      render: (_, element) => (
        <>
          <Space size="middle" align="center">
            <Tooltip title="See Information">
              <Button
                type="primary"
                shape="circle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  showModal();
                  infoModal(element);
                }}
              >
                <FileTextOutlined style={{ fontSize: 17 }} />
              </Button>
            </Tooltip>
            {puedeEditarVehiculos && (
              <Tooltip title="Edit Information">
                <Button
                  type="primary"
                  shape="circle"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#F9B325",
                    borderColor: "#F9B325",
                  }}
                  onClick={() => {
                    showModalEdit();
                    setVehiculoSeleccionado(element);
                  }}
                >
                  <EditOutlined style={{ fontSize: 17 }} />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="Copy Link">
              {copyLoading ? (
                <LoadingOutlined style={{ fontSize: 17 }} />
              ) : (
                <Button
                  type="primary"
                  shape="circle"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#F9B325",
                    borderColor: "#F9B325",
                  }}
                  onClick={() => handleCopyLink(element)}
                >
                  <CopyFilled style={{ fontSize: 17 }} />
                </Button>
              )}
            </Tooltip>

            <Tooltip title="Ver evidencia">
              <Button
                type="primary"
                shape="circle"
                onClick={async () => {
                  setIsModalOpenListEvidence(true);
                  setIsLoadingImage(true);
                  setNumeroLote(element.lote);
                  // extraer de axios getMediaListFolderId
                  const response = await getMediaListFolderId(
                    element.lote
                  ).finally(() => {
                    setIsLoadingImage(false);
                  });
                  console.log("response", response);
                  setMedia(response.data || []);
                }}
                style={{
                  display: "flex",
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EyeFilled />
              </Button>
            </Tooltip>

            <ShowWhen show={element.estadoInspeccion === VehiculoEstadoInspeccionEnum.inspeccionado}>
              <Tooltip title="Notificar Entrega de Vehículo">
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => handleSendMail(element)}
                  style={{
                    display: "flex",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MailFilled />
                </Button>
              </Tooltip>
            </ShowWhen>
          </Space>
        </>
      ),
    },
  ];

  const items = [
    {
      key: '1',
      label: 'Florida',
      children: (<>
        <Row justify="center">
          <Col span={24}>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0">Total: {cantidadRecibidosFlorida}</h4>
              {
                estadoVehiculo === VehiculoEstadoEnum.recibidoEnBodega &&
                <Button type="primary" onClick={handleChangeEstadoVehicle}>
                  Mostrar No Recibidos
                </Button>
              }
              {
                estadoVehiculo === VehiculoEstadoEnum.noRecibido &&
                <Button type="primary" onClick={handleChangeEstadoVehicle}>
                  Mostrar Recibidos
                </Button>
              }
            </div>
            <Table
              columns={columns}
              dataSource={recibidosHoyFlorida}
              size="medium"
              pagination={{ pageSize: 10 }}
              scroll={{x: 1300}}
            />
          </Col>
        </Row>
      </>),
    },
    {
      key: '2',
      label: 'California',
      children: (<>
        <Row justify="center">
          <Col span={24}>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="m-0">Total: {cantidadRrecibidosCalifornia}</h4>
              {
                estadoVehiculo === VehiculoEstadoEnum.recibidoEnBodega &&
                <Button type="primary" onClick={handleChangeEstadoVehicle}>
                  Mostrar No Recibidos
                </Button>
              }
              {
                estadoVehiculo === VehiculoEstadoEnum.noRecibido &&
                <Button type="primary" onClick={handleChangeEstadoVehicle}>
                  Mostrar Recibidos
                </Button>
              }
            </div>
            <Table
              columns={columns}
              dataSource={recibidosHoyCalifornia}
              size="medium"
              pagination={{pageSize: 10}}
              scroll={{x: 1300}}
            />
          </Col>
        </Row>
      </>),
    }
  ]

  return (
    <>
      <Spinner loading={isLoading}>
        <Row gutter={[16, 24]}>
          <Col span={24} className="d-flex align-items-center justify-content-between">
            <div>
              <h5 className="mb-1">Receiving Vehicles</h5>
              <p className="mb-0">Fecha: {StringFormats.date(startDate)} {endDate ? `- ${StringFormats.date(endDate)}` : ''}</p>
            </div>

            <div className="d-flex align-items-center justify-content-end gap-2">
              <Button onClick={handleCurrentDate} type="primary">
                Current day
              </Button>
              <Button onClick={handleButtonClick} type="primary">
                See other days
              </Button>
            </div>
          </Col>
        </Row>
        <ShowWhen show={showInputs}>
          <Row align="bottom" justify="end" className="mt-3 gap-2">
            <Col>
              <p className="mb-1">Start Date:</p>
              <Input type="date" name="startDate" onChange={handleStartDateChange}/>
            </Col>
            <Col>
              <p className="mb-1">End Date:</p>
              <Input type="date" name="endDate" onChange={handleEndDateChange}/>
            </Col>
            <Col>
              <Button type="primary" onClick={handleSubmit}>Find</Button>
            </Col>
          </Row>
        </ShowWhen>
        <hr/>
        <ShowWhen show={canViewOnlyOneOrigen}>
          <Row justify="center">
            <Col span={24}>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="mb-0">Total: {cantidadRecibidos}</h4>
                <ShowWhen show={estadoVehiculo === VehiculoEstadoEnum.recibidoEnBodega}>
                  <Button type="primary" onClick={handleChangeEstadoVehicle}>
                    Mostrar No Recibidos
                  </Button>
                </ShowWhen>
                <ShowWhen show={estadoVehiculo === VehiculoEstadoEnum.noRecibido}>
                  <Button type="primary" onClick={handleChangeEstadoVehicle}>
                    Mostrar Recibidos
                  </Button>
                </ShowWhen>
              </div>
              <Table
                columns={columns}
                dataSource={recibidosHoy}
                size="small"
                pagination={{pageSize: 10}}
                scroll={{x: 1300}}
              />
            </Col>
          </Row>
        </ShowWhen>
        <ShowWhen show={!canViewOnlyOneOrigen}>
          <Tabs defaultActiveKey="1" items={items}/>
        </ShowWhen>
      </Spinner>

      {/* MODALES DE INFORMACION */}
      <Modal
        title={`General information`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{style: {display: "none"}}}
        okButtonProps={{style: {display: "none"}}}
        width={"70%"}
      >
        <Row ref={datosPrint} style={{padding: "35px"}}>
          <Spinner loading={loadingInfoSoli}>
            <img
              src={MatusColor}
              alt="Logo Matus"
              style={{width: "250px", height: "90px"}} // Estilos para dimensionar la imagen
            />
            <br/>
            <h5>
            Date received:{" "}
              {vehicle ? moment(vehicle.fecha).format("LLL") : ""}
            </h5>
            <h5>
              Destination: {infoSoli?.aduanaName}-{infoSoli?.countryName}
            </h5>
            <Descriptions
              title="Vehicle Inspection Report"
              layout="vertical"
              size="small"
              bordered
            >
              <Descriptions.Item label="Year / Make / Model">
                {vehicle?.vehiculoName}
              </Descriptions.Item>
              <Descriptions.Item label="Lote">
                {vehicle?.lote}
              </Descriptions.Item>
              <Descriptions.Item label="VIN">{vehicle?.vin}</Descriptions.Item>
              <Descriptions.Item label="Color">
                {vehicle?.color}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {vehicle?.estado}
              </Descriptions.Item>
              <Descriptions.Item label="Notes">
                {vehicle?.notas}
              </Descriptions.Item>
              <Descriptions.Item label="Received info">
                Storage: ${vehicle?.storage}
                <br />
                Key Number: {vehicle?.ubicacion}
                <br />
                Key: {vehicle?.llave ? "SI" : "NO"}
                <br />
                KeyInside: {vehicle?.keyinside ? "SI" : "NO"}
                <br />
                Catalitico: {vehicle?.catalitico ? "SI" : "NO"}
                <br />
                Spare Tire: {vehicle?.llantaResp ? "SI" : "NO"}
              </Descriptions.Item>
              <Descriptions.Item label="">
                Title: {vehicle?.titulo ? "SI" : "NO"}
                <br />
                Battery: {vehicle?.bateria ? "SI" : "NO"}
                <br />
                BillOfSale: {vehicle?.billofSale ? "SI" : "NO"}
                <br />
                Radio: {vehicle?.radio ? "SI" : "NO"}
                <br />
                BioHazard?: {vehicle?.bio ? "SI" : "NO"}
                <br />
                Open Door?: {vehicle?.openDoor ? "SI" : "NO"}
              </Descriptions.Item>
              <Descriptions.Item label="">
                Broken glass?
                <br />
                Front: {vehicle?.cristalDelantero ? "SI" : "NO"}
                <br />
                Back: {vehicle?.cristalTrasero ? "SI" : "NO"}
                <br />
                WindowsLeft: {vehicle?.ventanaIzquierda ? "SI" : "NO"}
                <br />
                WindowsRight: {vehicle?.ventanaDerecha ? "SI" : "NO"}
                <br />
                Open Hood?: {vehicle?.openCapo ? "SI" : "NO"}
              </Descriptions.Item>
              <Descriptions.Item label="">
                Received by: {vehicle?.updateBy}
              </Descriptions.Item>
            </Descriptions>
            <hr />
            <Descriptions
              bordered
              title="Order Info"
              layout="vertical"
              size="small"
            >
              <Descriptions.Item label="Buyer">
                {infoSoli?.buyer}
              </Descriptions.Item>
              <Descriptions.Item label="Origin">
                {infoSoli?.nameOrigen}
              </Descriptions.Item>
              <Descriptions.Item label="Destination">
                {infoSoli?.aduanaName}-{infoSoli?.countryName}
              </Descriptions.Item>
              <Descriptions.Item label="Client" span={2}>
                {infoSoli?.clienteName}
              </Descriptions.Item>
              <Descriptions.Item label="Sales executive">
                {infoSoli?.creador}
              </Descriptions.Item>
              <Descriptions.Item label="Import" span={4}>
                {infoSoli?.importadoraName}
              </Descriptions.Item>
              <Descriptions.Item label="Auction" span={4}>
                {infoSoli?.subastaCom}
              </Descriptions.Item>
              <Descriptions.Item label="Charges">
                Freight: $ {infoSoli?.n1}
                <br />
                Towing: $ {infoSoli?.n2}
                <br />
                BL: $ {infoSoli?.n3}
                <br />
                Additional Cost: $ {infoSoli?.n4}
                <br />
                Total: $ {infoSoli?.total}
              </Descriptions.Item>
              <Descriptions.Item label="Notes">
                {infoSoli?.comentarios}
              </Descriptions.Item>
            </Descriptions>
          </Spinner>
        </Row>
        <Button onClick={handlePrint} type="primary">
          Print
        </Button>
      </Modal>
      <Modal
        title={`Update information`}
        open={isModalEditOpen}
        onOk={handleOkE}
        onCancel={handleCancelE}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={"50%"}
      >
        <VehicleReceivingForm vehicle={vehiculoSeleccionado} onSaveVehicle={handleSaveVehicle} />
      </Modal>
      <Modal
        title={`Lista de evidencia - ${numeroLote}`}
        open={isModalOpenListEvidence}
        onOk={handleOkListEvidence}
        onCancel={handleCancelListEvidence}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={"100%"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <hr />
        <br />
        <Spin tip="Cargando Imagenes..." spinning={isLoadingImage}>
          {media.length === 0 ? (
            <Alert
              message="No hay evidencia para este lote"
              description="Porfavor suba evidencia para este lote."
              type="warning"
              showIcon
            />
          ) : (
            <Image.PreviewGroup
              preview={{
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              {media?.map((item) =>
                item.mimeType.includes("image/") ? (
                  //

                  <Space
                    // add espacio entre imagenes
                    direction="vertical"
                    size="middle"
                    style={{
                      padding: 10,
                    }}
                  >
                    {/* add icon image and nombre how title align horizontal and image botton title*/}
                    <Card
                      hoverable
                      style={{ width: "100%", maxWidth: 240 }} // Make the card responsive by setting the max-width
                      cover={
                        <iframe src={`https://drive.google.com/file/d/${item.id}/preview`} width="500" height="400" allow="autoplay"></iframe>

                      }
                    >
                      <Card.Meta
                        title={item.name}
                        description={item.mimeType}
                      />
                    </Card>
                  </Space>
                ) : (
                  <Space
                    // add espacio entre imagenes
                    direction="vertical"
                    size="middle"
                    style={{
                      padding: 10,
                    }}
                  >
                    {/* add icon image and nombre how title align horizontal and image botton title*/}
                    <Card
                      hoverable
                      style={{ width: "100%", maxWidth: 240 }} // Make the card responsive by setting the max-width
                      cover={
                        <iframe src={`https://drive.google.com/file/d/${item.id}/preview`} width="500" height="400" allow="autoplay"></iframe>
                      }
                    >
                      <Card.Meta
                        title={item.name}
                        description={item.mimeType}
                      />
                    </Card>
                  </Space>
                )
              )}
            </Image.PreviewGroup>
          )}
        </Spin>
      </Modal>

      <ShowWhen show={!!selectedVehicleSendEmail}>
        <SendEmail visible={!!selectedVehicleSendEmail}
                   subject={selectedVehicleSendEmail?.titulo ? titulos.trueTitle : titulos.falseTitle}
                   titulo={'Enviar correo electrónico'}
                   to={[environment.emails.grua]}
                   body={NotificacionEntregaVehiculoClienteEmail(
                     selectedVehicleSendEmail,
                     vehiculoSeleccionado?.origen === "1" ? "FLORIDA" : "CALIFORNIA",
                     selectedVehicleCopyLink, 
                      infoSoli
                    )}
                   setVisible={() => {
                     setSelectedVehicleCopyLink(undefined);
                     setSelectedVehicleSendEmail(undefined);
                   }}
                   showMessageInput={false}
                   isLoading={false}
                   doSendEmail={handleSendEmail} />
      </ShowWhen>
    </>
  );
};

export default ReceivedToday;
