import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { filtroPendingTitles } from "../Servicios/SolicitudesService";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import "styled-components";
import "../hojas-de-estilo/style.css";
import { viewOrigen } from "../Servicios/ComponedoresService";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { viewCar } from "../Servicios/SolicitudesService";
import { editCar } from "../Servicios/VehicleService";
import useCurrentUser from "../Hooks/UserHook";
import SpinnerTable from "../Componentes/SpinnerTable";
import { ToastContainer, toast } from "react-toastify";
import { validarPermisos } from "../Servicios/AuthService";
import { permisos } from "../utils/permisos";

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <Form.Control
      className="input-buscar"
      id="search"
      type="text"
      placeholder="Buscar Vehiculo por Lote / Marca / Modelo / Fecha"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

const PendingTitlesVehiculos = () => {

  const currentUser = useCurrentUser();
  const id  = currentUser?.idubc;

  const [filVe, setFilVe] = useState([]);
  const [origen, setOrigen] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const showVehiculosfil = async () => {
      setIsLoading(true);
      if(id != undefined && id != 0){
        const data = await filtroPendingTitles(id).finally(() => {setIsLoading(false)});
        setFilVe(data);
      }  
  };

  const notifyReceiving = () =>
    toast.success("Datos guardados correctamente", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const [vehiculo, setVehiculo] = useState([]);
  const [vehiculoED, setVehiculoED] = useState([]);
  const [idcar, setIdcar] = useState([]);

  const [showE, setShowE] = useState(false);
  const handleShowE = () => setShowE(true);
  const handleCloseE = () => setShowE(false);

  const mostCarEdit = (id) => {
    setIdcar(id);
    if (id != undefined && id != 0)
      viewCar(id).then((car) => {
        setVehiculoED(car);
        handleShowE();
      });
  };

  useEffect(() => {
    mostCarEdit();
  }, []);

  useEffect(() => {
    showVehiculosfil();
  }, [id]);

  useEffect(() => {
    if(id != undefined && id != 0){
    viewOrigen(id).then((origen) => {
      setOrigen(origen);
    });
  }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehiculo({
      ...vehiculo,
      [name]: value,
    });
    setVehiculoED({
      ...vehiculoED,
      [name]: value,
    });
  };

  // ESTE ES EL MOTODO QUE SE ESTA OCUPANDO
  const handleOnChangeCK  = (evt) => {
    const {name, checked} = evt.target
    setVehiculo({
      ...vehiculo,
      [name]: checked
      });
    setVehiculoED({
      ...vehiculoED,
      [name]: checked
      });
  }

  const editVehiculo = async () => {
    try {
      const data = await editCar(idcar, {
        ...vehiculoED
      });
      if (data) {
        handleCloseE();
        showVehiculosfil();
        notifyReceiving();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const columns = [
    {
      name: "Editar",
      width: "80px",
      cell: (row) => (
        <Button onClick={() => mostCarEdit(row?.vehiculoId)} variant="success">
          <FontAwesomeIcon icon={faPenToSquare} />
        </Button>
      ),
    },
    {
      name: "Lote",
      selector: (row) => row.lote,
      width: "120px",
    },
    {
      name: "Vehiculo",
      selector: (row) => row.vehiculoName,
      width: "200px",
    },
    {
      name: "VIN",
      selector: (row) => row.vin,
      width: "185px",
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      width: "150px",
    },
    {
      name: "Fecha Recibido",
      sortable: true,
      selector: (row) => row?.fecha,
      format: (row) => moment(row?.fecha).format("lll"),
      width: "165px",
    },
    {
      name: "Storage",
      selector: (row) => row.storage,
      width: "80px",
    },
    {
      name: "Titulo",
      width: "70px",
      cell: (row) => {
        return (
          <Form.Check
            type="checkbox"
            className="checkbox"
            checked={row.titulo}
            name="titulo"
          />
        );
      },
    },
    {
      name: "Llave",
      width: "70px",
      cell: (row) => {
        return (
          <Form.Check
            type="checkbox"
            className="checkbox"
            checked={row.llave}
            name="llave"
          />
        );
      },
    },
    {
      name: "No.",
      selector: (row) => row.ubicacion,
      width: "60px",
    },
    {
      name: "Bio",
      width: "60px",
      cell: (row) => {
        return (
          <Form.Check
            type="checkbox"
            className="checkbox"
            checked={row.bio}
            name="bio"
          />
        );
      },
    },
    {
      name: "Catalitico",
      width: "85px",
      cell: (row) => {
        return (
          <Form.Check
            type="checkbox"
            className="checkbox"
            checked={row.catalitico}
            name="catalitico"
          />
        );
      },
    },
    {
      name: "Recibido por",
      selector: (row) => row.updateBy,
      width: "150px",
    },
  ];
  const [columnas, setColumnas] = useState(columns);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = filVe.filter((item) => {
    return (
      (item?.lote &&
        item?.lote
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item?.fecha &&
        item?.fecha
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item?.vehiculoName &&
        item?.vehiculoName
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item?.model &&
        item?.model
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase()))
    );
  });

  const puedeEditarVehiculos = validarPermisos(permisos.EDITAR_VEHICULOS);

  useEffect(() => {
    if (!puedeEditarVehiculos) {
        const c = [...columnas];
        c.splice(0, 1);

        setColumnas([...c]);
    } 
}, [puedeEditarVehiculos]);


  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Container>
        <Row>
          <Col xs={12} md={5}>          
            <Form.Label className="input-buscar">
              Seleccione un Vehiculo para actualizar su informacion{" "}
            </Form.Label>
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </Col>
        </Row>
        <br />
      </Container>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container className="reciving">
      <h4>Vehículos pendientes de titulo - {origen?.nameOrigen}</h4>
      <hr />
      <Row>
        <Col xs={12}>
          <DataTable
            columns={columnas}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            progressPending={isLoading}
            progressComponent={<SpinnerTable />}
            persistTableHead
            pagination
          />
        </Col>
        <ToastContainer />
      </Row>

      <>
        <Modal
          show={showE}
          onHide={handleCloseE}
          backdrop="static"
          keyboard={false}
          className="vehiculoedit"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Recibo de Vehiculo {vehiculoED?.make} {vehiculoED?.model} año{" "}
              {vehiculoED?.year}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <br />
              <Col xs={12} lg="6">
                <Form.Label className="text-aling-left">
                  Fecha Recibido
                </Form.Label>
                <Form.Control
                  disabled
                  readOnly
                  onChange={handleChange}
                  value={vehiculoED?.fecha}
                  name="fecha"
                  type="text"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
            </Row>
            <Row>
              <br />
              <Col xs={12} lg="7">
                <Form.Label className="text-aling-left">VIN</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="vin"
                  value={vehiculoED?.vin}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
              <Col xs={12} lg="5">
                <Form.Label className="text-aling-left">LOTE</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="lote"
                  value={vehiculoED?.lote}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg="3">
                <Form.Label className="text-aling-left">Marca</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="make"
                  value={vehiculoED?.make}
                  required
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
              <Col xs={12} lg="6">
                <Form.Label className="text-aling-left">Modelo</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="model"
                  value={vehiculoED?.model}
                  required
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
              <Col xs={12} lg="3">
                <Form.Label className="text-aling-left">Año</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="year"
                  value={vehiculoED?.year}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg="4">
                <Form.Label className="text-aling-left">Color</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="color"
                  value={vehiculoED?.color}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
              <Col xs={12} lg="3">
                <Form.Label className="text-aling-left">Storage</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="storage"
                  value={vehiculoED?.storage}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
              <Col xs={12} lg="3">
                <Form.Label className="text-aling-left">No. de LLave</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="ubicacion"
                  value={vehiculoED?.ubicacion}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={3}>
                <br />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.titulo}
                  label="Titulo"
                  name="titulo"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline1"
                />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.bio}
                  label="BioH"
                  name="bio"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline2"
                />
              </Col>
              <Col xs={6} md={3}>
                <br />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.llave}
                  label="Llave"
                  name="llave"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline3"
                />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.catalitico}
                  label="Catalitico"
                  name="catalitico"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline4"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={3}>
                <Form.Check
                  checked={vehiculoED && vehiculoED?.keyinside}
                  label="KeyInside"
                  name="keyinside"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline5"
                />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.radio}
                  label="Radio"
                  name="radio"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline6"
                />
              </Col>
              <Col xs={6} md={3}>
                <Form.Check
                  checked={vehiculoED && vehiculoED?.bateria}
                  label="Bateria"
                  name="bateria"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline7"
                />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.llantaResp}
                  label="Llanta Resp."
                  name="llantaResp"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline8"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4}>
                <Form.Check
                  checked={vehiculoED && vehiculoED?.billofSale}
                  label="Bill of sale?"
                  name="billofSale"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline9"
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Check
                  checked={vehiculoED && vehiculoED?.openDoor}
                  label="Abre puerta?"
                  name="openDoor"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline10"
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Check
                  checked={vehiculoED && vehiculoED?.openCapo}
                  label="Abre capo?"
                  name="openCapo"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline11"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={6} md={3}>
                <Form.Label className="text-aling-left">
                  Cristales rotos?
                </Form.Label>
                <br />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.cristalDelantero}
                  label="Cristal Front"
                  name="cristalDelantero"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline12"
                />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.ventanaIzquierda}
                  label="Ventana Izquierda"
                  name="ventanaIzquierda"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline13"
                />
              </Col>
              <Col xs={6} md={3}>
                <br />
                <br />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.cristalTrasero}
                  label="Cristal Back"
                  name="cristalTrasero"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline14"
                />
                <Form.Check
                  checked={vehiculoED && vehiculoED?.ventanaDerecha}
                  label="Ventana Derecha"
                  name="ventanaDerecha"
                  onChange={handleOnChangeCK}
                  type="checkbox"
                  id="inline15"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs={12} lg="6">
                <Form.Label className="text-aling-left">
                  Recbido Por:
                </Form.Label>
                <Form.Control
                  disabled
                  readOnly
                  name="updateBy"
                  value={vehiculoED?.updateBy}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="text-aling-left">Notas</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={vehiculoED && vehiculoED?.notas}
                  name="notas"
                  as="textarea"
                  maxLength={300}
                  rows={3}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseE}>
              Cancelar
            </Button>
            <Button onClick={() => editVehiculo()} variant="primary">
              {" "}
              <FontAwesomeIcon icon={faFloppyDisk} /> Actualizar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </Container>
  );
}

export default PendingTitlesVehiculos;
