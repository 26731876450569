import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  allExportations: {},
  exportationsId: {},
};

export const exportationSlice = createSlice({
  name: "exportation",
  initialState,
  reducers: {
    getAllExportations: (state,  {payload} ) => {
     
      state.allExportations = payload;
    },
    getExportationId: (state, { payload }) => {
      state.exportationsId = {...payload};
    },
    loading: (state, { payload }) => {
      state.loading = payload;
    },
    getDeleteExportationId:(state) => {
      state.exportationsId = {}
    },
    addPushAllExportations: (state, { payload }) => {
        state.allExportations.list = [...state.allExportations?.list, payload];
    }, 
    addPushAllDetailExportations: (state, { payload }) => {
      state.exportationsId.detalle = payload;
    }
  },
});

export const { getAllExportations, loading, getExportationId, addPushAllExportations , getDeleteExportationId, addPushAllDetailExportations} =
  exportationSlice.actions;
