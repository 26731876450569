import {Button, Dropdown, Table} from "antd";
import {DetalleExportacionStateEnum} from "../../shared/enums/detalle-exportacion-state.enum";
import {getBillOfLading, getCoverLetter, getItn} from "../../Servicios/ExportationServices";
import {errorMessage, successMessage} from "../../utils/toast-message";
import {useState} from "react";
import useCurrentUser from "../../Hooks/UserHook";
import PdfReport from "../../utils/pdfReport";
import {useNavigate} from "react-router-dom";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {exportationContainerStateEnum} from "../../shared/enums/exportation-container-state.enum";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";

const DetalleExportationTable = ({ detalleExportations, onRefreshTable }) => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataReport, setDataReport] = useState("");

  const items = element => {
    const items = [
      {
        key: '1',
        label: 'Generar Cover Letter',
        onClick: () => handleCoverLetter(element),
        visible: element?.exportationContainer?.state !== exportationContainerStateEnum.creado
      },
      {
        key: '2',
        label: 'Generar Bill of Lading',
        onClick: () => handleBillOfLading(element),
        visible: element?.exportationContainer?.state !== exportationContainerStateEnum.creado
      },
      {
        key: '3',
        label: 'Send to AES',
        onClick: () => navigate(`/sendToAES/${element.codeExpSecuential}`),
        visible: element.stateSend === DetalleExportacionStateEnum.NoEnviado &&
          element.exportationContainer?.state !== exportationContainerStateEnum.creado
      },
      {
        key: '4',
        label: 'Validar ITN',
        onClick: () => handleValidarITN(element),
        visible: element.stateSend === DetalleExportacionStateEnum.Enviado
      }
    ];

    return items.filter(e => e.visible);
  }

  const columns = [
    {
      title: "",
      width: "7%",
      dataIndex: "options",
      key: "options",
      render: (_, element) => (
        <ShowWhen show={items(element).length > 0}>
          <Dropdown
            trigger={['click']}
            menu={{ items: items(element) }}
            placement="bottomLeft"
          >
            <Button shape="circle" type={'primary'}><FontAwesomeIcon size={'sm'} icon={faBars} /></Button>
          </Dropdown>
        </ShowWhen>
      ),
    },
    {
      title: "Shipment #",
      width: "11%",
      dataIndex: "codeExpSecuential",
      key: "codeExpSecuential",
    },
    {
      title: "Warehouse",
      width: "12%",
      dataIndex: "warehouse",
      key: "warehouse",
      render: (_, element) => {
        return(
          <>
            <p className="mb-0">Lote: {element?.werehouse?.lote}</p>
            <p className="mb-0">{element?.werehouse?.codigo}</p>
          </>
        );
      },
    },
    {
      title: "Vehículo",
      width: "17%",
      dataIndex: "vehicle",
      key: "vehicle",
      render: (_, element) => element?.vehiculo?.vehiculoName,
    },
    {
      title: "Importadora",
      width: "14%",
      dataIndex: "carrier",
      key: "carrier",
      render: (_, element) => element?.importadora?.impName,
    },
    {
      title: "#",
      width: "16%",
      dataIndex: "containerNumber",
      key: "containerNumber",
      render: (_, element) => {
        return(
          <>
            <p className="mb-0"><span className="fw-bold"># de Contenedor: </span>{element?.exportationContainer?.containerNumber}</p>
            <p className="mb-0"><span className="fw-bold"># de Sello: </span>{element?.exportationContainer?.sealNumber}</p>
          </>
        );
      },
    },
    {
      title: "ITN",
      width: "13%",
      dataIndex: "itn",
      key: "itn",
      render: (_, element) => element?.itn,
    },
    {
      title: "Estado",
      width: "10%",
      dataIndex: "estado",
      key: "estado",
      render: (_, element) => {
        const bg = element?.stateSend === DetalleExportacionStateEnum.Enviado
          ? 'bg-info'
          : element?.stateSend === DetalleExportacionStateEnum.Aceptado
            ? 'bg-success'
            : 'bg-danger';

        return <div className={'badge ' + bg}>
          {element?.stateSend}
        </div>
      },
    }
  ];

  const handleValidarITN = async (element) => {
    setLoading(true);
    const data = await getItn(element.codeExpSecuential);
    setLoading(false);

    if (data && data.status) {
      successMessage('ITN actualizado exitosamente');
      onRefreshTable();
    } else {
      errorMessage(data.message);
    }
  }

  const handleBillOfLading = async (element) => {
    setVisible(true);
    const res = await getBillOfLading(element.codeExpSecuential);
    setDataReport(res);
  }

  const handleCoverLetter = async (element) => {
    setVisible(true);
    const res = await getCoverLetter(element.codeExpSecuential, currentUser);
    setDataReport(res);
  }

  return(
    <>
      <Table size={'small'}
             loading={loading}
             columns={columns}
             dataSource={detalleExportations}
             pagination={false} />
      <PdfReport visible={visible} setVisible={setVisible} data={dataReport} setData={setDataReport} />
    </>
  );
}

export default DetalleExportationTable;
