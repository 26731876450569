import React, { useEffect, useState } from "react";
import { SmileOutlined, SmileTwoTone, DeleteFilled } from "@ant-design/icons";
import {
  Row,
  Col,
  Result,
  Button,
  Card,
  Tabs,
  Form,
  Input,
  Select,
  Space,
  Checkbox,
  Upload,
  Spin,
} from "antd";
import {Form as FormB} from "react-bootstrap";

import { UploadOutlined, EditOutlined } from "@ant-design/icons";
import {
  sendAllVehicles,
  deleteOneVehicle,
  deleteAllVehicle,
  saveAllVehicles,
  sendAllSolicitud,
  deleteOneSolicitude,
  deleteAllSolicitude,
  saveAllSolicitud,
  addPushAllAttachments,
  deleteAllAttachments,
} from "../store/solicitud";
import { useDispatch, useSelector } from "react-redux";
import { listMake } from "../Servicios/VehicleService";
import { showVehiclesoli, showVehicleLote } from "../Servicios/CombosService";
import {listClients, viewClient} from "../Servicios/ClienteService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import {
  showSubasta,
  showOrigen,
  showAduana,
} from "../Servicios/CombosService";
import { viewAduana } from "../Servicios/ComponedoresService";
import useCurrentUser from "../Hooks/UserHook";
import moment from "moment";
import InputSearchCustomer from "../Componentes/InputSearchCustomer/InputSearchCustomer";
import { Modal as ModalAntD } from "antd/lib";
import ClienteForm from "../Carteracliente/ClienteForm";
import Modal from "react-bootstrap/Modal";
import { addSubasta } from "../Servicios/ComponedoresService";
import { toast } from "react-toastify";
import { showStates } from "../Servicios/WerehouseService";
import { showCountries } from "../Servicios/CombosService";
import { getUserSellers } from "../Servicios/UsuarioService";
import { getCountriesId } from "../Servicios/AuthService";
import { TypePay } from "../shared/enums/coordinacion-entry-type.enum";
import { errorMessage } from "../utils/toast-message";
import { getTitles } from "../Servicios/TitleService";
import {ClienteTypeEnum} from "../shared/enums/cliente-type.enum";

const { TabPane } = Tabs;
const { TextArea } = Input;

const onSearch = (value) => {
  console.log("search:", value);
};

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const MultiplesSolicitudes = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormSolicitud, setShowFormSolicitud] = useState(false);
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [listVehicle, setListVehicle] = useState([]);
  const [listClient, setListClient] = useState([]);
  const [listSubasta, setListSubasta] = useState([]);
  const [listOrigin, setListOrigin] = useState([]);
  const [listAduana, setListAduana] = useState([]);
  const [vehicleName, setVehicleName] = useState("");
  const [lote, setLote] = useState("");
  const dispatch = useDispatch();
  const { allVehicles, allSolicitud, allAttachments, loading} = useSelector(
    (state) => state.solicitud
  );
  const [form] = Form.useForm();
  const [formSolicitud] = Form.useForm();
  const [vehicleSelected, setVehicleSelected] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [importadoraId, setImportadoraId] = useState(null);
  const dateNow = moment().format("DD/MM/YYYY");
  const currentUser = useCurrentUser();
  const [checked, setChecked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [gatePassRequired, setGatePassRequired] = useState(false);
  const [checkedEntryType, setCheckedEntryType] = useState(false);
  const [checkedCutVehicle, setCheckedCutVehicle] = useState(false);
  const [showModalClient, setShowModalClient] = useState(false);
  const [showModalFinalClient, setShowModalFinalClient] = useState(false);
  const [selectedFinalClient, setSelectedFinalClient] = useState(undefined);
  const [selectedImportadora, setSelectedImportadora] = useState(undefined);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoadingSub, setIsLoadingSub] = useState(false);
  const [userList, setUserList] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [canSaveVehicle, setCanSaveVehicle] = useState(true);
  const [titles, setTitles] = useState([]);


  const [showA, setShowA] = useState(false);

  const handleShowA = () => setShowA(true);
  const handleCloseA = () => setShowA(false);
  const [addSubastas, setAddSubastas] = useState({
    subId: "",
    subName: "",
    addressLine1Sub: "",
    addressLine2Sub: "",
    city: "",
    state: null,
    zipCode: null,
    countryId: 6,
    identf: null,
    phoneNumber: null,
  });

  const onSaveVehicle = async (values) => {
    values.estado = "NO_SOLICITUD";
    values.make = values.make[0];
    values.model = values.model[0];
    values.year = Number(values.year);

    const loteVehiculo =  await showVehicleLote(values.lote);
    if (loteVehiculo?.list?.length > 0) {
      
      toast.error("El lote ya existe, por favor verifique el lote ingresado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
    console.log(values);
    dispatch(sendAllVehicles(values));
    form.resetFields();
    setShowForm(false);
    }
  };

  const loadUserSelles = async () => {
    const data = await getUserSellers();
    console.log("data user sellers", data);
    const countriesId = getCountriesId();

    setUserList(
      data.filter(e => {
        const idPais = e.usr ? e.usr.idpais.toString() : "";
        return countriesId === "0" || countriesId.indexOf(idPais) !== -1;
      })
    );  

    
  };
 
  useEffect(() => {
    console.log("userList", userList);
  }, [userList]);
  

  const onChangeCheckbox = (e) => {
    console.log("checked = ", e.target.checked);
    setChecked(e.target.checked);
  };

  const handleSelectChangeClienteFinal = (client) => {
    console.log("client", client);
    setSelectedFinalClient(client);
  };

  const handleSaveCustomer = (customer) => {
    if (showModalClient) {
      setSelectedClient(customer);
      setSelectedImportadora(customer.importadora);
      setShowModalClient(false);
    }

    if (showModalFinalClient) {
      setSelectedFinalClient(customer);
      setShowModalFinalClient(false);
    }
  };

  const handleCancelCustomer = () => {
    if (showModalClient) setShowModalClient(false);
    if (showModalFinalClient) setShowModalFinalClient(false);
  };

  const handleSelectChangeCliente = (client) => {
    console.log("client", client);
    setSelectedClient(client);
    setSelectedImportadora(client.importadora);
    setImportadoraId(client.importadora);
    formSolicitud.setFieldsValue({
      tipoPago: client.classPay
    });
    
  };

  const onSaveSolicitud = (values) => {
    values.fechaQ = moment().format("YYYY-MM-DD");
    values.countryId = countryId?.countryId;
    console.log("importadoraId", importadoraId);
    values.importadoraId = importadoraId?.id;
    values.buyer = Number(values.buyer);
    values.vehicleName = vehicleName;
    values.lote = lote;
    values.dealer = checked;
    values.idClienteFinal = selectedFinalClient?.id;
    values.clienteId = selectedClient?.id;
    values.cutVehicle = checkedCutVehicle;
    values.sendedToReview = false;
    values.pendingToComplete = false;
    values.subastaId = checkedEntryType ? null : values.subastaId;
    // el asignado por el usuario
    values.creador = selectedUser?.fullName;

    // el usuario que esta logueado
    values.registradoBy = currentUser?.fullName;


    console.log(values);
    dispatch(sendAllSolicitud(values));
    formSolicitud.setFieldsValue({
      vehiculoId: "",
      quoted: "",
      estGrua: "",
      bl: "",
      costoQ: "",
      comentarios: "",
      setChecked: false,
      gatePass: "",
    });
    setShowFormSolicitud(false);
  };

  const fetchMake = async () => {
    const list = await listMake();
    setMake(list);
  };

  const fetchTitles = async () => {
    const list = await getTitles();
    setTitles(list);
  };

  const handleFileChange = (fileList, file) => {
    console.log("fileList", fileList);
    setSelectedFiles(fileList);

    // add the files to the addpushallattachments convert to base64
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        resolve(base64);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });

    Promise.all([promise])
      .then((base64) => {
        console.log("base64", base64);
        const data = {
          nombreArchivo: file.name,
          archivo: base64[0],
        };
        dispatch(addPushAllAttachments(data));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // const onFinishFile = (values) => {
  //   console.log("values", values);
  //   // i need convert the files to base64

  //   setUploading(true);
  //   const promises = selectedFiles.map((file) => {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => {
  //         const base64 = reader.result;
  //         resolve(base64);
  //       };
  //       reader.onerror = (error) => {
  //         reject(error);
  //       };
  //     });
  //   });

  //   Promise.all(promises)
  //     .then((base64) => {
  //       console.log("base64", base64);
  //       // i need to save the base64 in the database
  //       base64.map((item) => {
  //         const data = {
  //           nombreArchivo: selectedFiles[base64.indexOf(item)].name,
  //           archivo: item
  //         };
  //         dispatch(addPushAllAttachments(data));
  //       });
  //       setUploading(false);
  //       setSelectedFiles([]);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // };

  const loadStates = async () => {
    const data = await showStates();
    setStates(data);
  };
  const loadCountries = async () => {
    const data = await showCountries();
    setCountries(data);
  };

  const fetchVehicle = async () => {
    const list = await showVehiclesoli("NO_SOLICITUD");
    console.log("list Vehiculos", list);
    setListVehicle(list);
    setVehicle(
      list.map((item) => {
        return {
          value: item.vehiculoId,
          label: `NAME: ${item?.vehiculoName} - VIN: ${item?.vin} - MAKE: ${item?.make} - MODEL: ${item?.model} - LOTE: ${item?.lote} - YEAR: ${item?.year}`,
        };
      })
    );
  };

  const fetchClient = async (idpais) => {
    console.log("idpais", idpais);
    const list = await listClients(idpais);
    console.log("list Client", list);
    setListClient(
      list?.list?.map((item) => {
        return {
          value: item.id,
          label: `${item.nombreCompleto} - ${item.importadoraName}`,
        };
      })
    );
  };

  const fetchSubasta = async () => {
    const list = await showSubasta();
    console.log("list Subasta", list);
    setListSubasta(
      list.map((item) => {
        return {
          value: item.subId,
          label: `${item.subName} - ${item.direccion}`,
        };
      })
    );
  };

  const fetchOrigin = async () => {
    const list = await showOrigen();
    console.log("list Origin", list);
    setListOrigin(
      list.map((item) => {
        return {
          value: item.id,
          label: `${item.nameOrigen}`,
        };
      })
    );
  };

  const fetchAduana = async () => {
    const list = await showAduana();
    console.log("list Aduana", list);
    setListAduana(
      list.map((item) => {
        return {
          value: item.id,
          label: `${item.nameAduana} - ${item.countryName}`,
        };
      })
    );
  };

  useEffect(() => {
    setSelectedUser({
      idUser: currentUser?.idusr,
      fullName: currentUser?.fullName,
    });
   
    formSolicitud.setFieldsValue({
      bl: 20
    });
    if (currentUser?.idpais) {
      Promise.all([
      fetchMake(),
      fetchVehicle(),
      fetchClient(currentUser?.idpais),
      fetchSubasta(),
      fetchOrigin(),
      fetchAduana(),
      loadStates(),
      loadCountries(),
      loadUserSelles(),
      fetchTitles(),
      formSolicitud.setFieldsValue({
        userSellerId: currentUser?.idusr,
      })
    ]);
    }
      
  }, [currentUser]);

  const options = [];
  for (let i = 0; i < make.length; i++) {
    options.push({
      value: make[i].make,
      label: make[i].make,
    });
  }

  const handleChange = (value) => {
    make.map((item) => {
      if (item.make === value[0]) {
        setModel(
          item.models.map((item) => {
            return {
              value: item,
              label: item,
            };
          })
        );
      }
    });
  };

  const onChangeVehicle = (value) => {
    setVehicleSelected(listVehicle.find((item) => item.vehiculoId === value));
  };

  useEffect(() => {
    console.log("vehicleSelected", vehicleSelected);
    setVehicleName(vehicleSelected?.vehiculoName);
    setLote(vehicleSelected?.lote);
  }, [vehicleSelected]);

  const onChange = (value, label) => {
    console.log(`selected ${value}`, label);
    if (label.label.indexOf("COPART") !== -1) {
      setGatePassRequired(true);
    } else {
      setGatePassRequired(false);
    }
  };

  const onChangeAduana = (value) => {
    viewAduana(value).then((adu) => {
      console.log("adu", adu);
      setCountryId(adu);
      formSolicitud.setFieldsValue({
        bl: adu.countryId === 3 ? 0 : 20
      });
    });
  };

  const onChangeCliente = (value) => {
    viewClient(value).then((client) => {
      setImportadoraId(client);
    });
  };

  const onChangeCheckboxEntryType = (e) => {
    setCheckedEntryType(e.target.checked);
  };

  const onChangeCheckboxCutVehicle = (e) => {
    setCheckedCutVehicle(e.target.checked);
  };

  const notifySub = () =>
    toast.success("Se guardo nueva subasta, puede seleccionarla en la lista", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const insertarSubasta = async () => {
    try {
      setIsLoadingSub(true);
      const data = await addSubasta({
        ...addSubastas,
      });
      if (data) {
        notifySub();
        fetchSubasta();
        handleCloseA();
        formSolicitud.setFieldsValue({
          subastaId: data.subId,
        });
      }
      setIsLoadingSub(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleOnSelectTitle = (event) => {
    const title = titles.find((e) => e.idTitle === event);
    setCanSaveVehicle(title.embarkable);

    if (!title.embarkable) {
      errorMessage(
        "LA SOLICITUD NO PUEDE CREARSE POR QUE EL VEHÍCULO NO ES EMBARCABLE"
      );
    }
  };

  return (
    <div>
      <h3>Registro Multiples de Solicitudes y Vehiculos</h3>
      <hr />

      <Tabs
        defaultActiveKey="0"
        onChange={() => {
          fetchVehicle();
        }}
      >
        <TabPane tab="Registro de vehiculos" key="1">
          {!showForm && (
            <Row gutter={24}>
              {
                /* Agregar un box vacio para agregar mas solicitudes */
                allVehicles?.length === 0 ? (
                  <Col span={24}>
                    <Result
                      icon={<SmileTwoTone twoToneColor="#e8e8e8" />}
                      title="No hay vehiculos pendiente."
                      style={{
                        border: "1px solid #e8e8e8",
                        borderRadius: "6px",
                      }}
                    />
                  </Col>
                ) : (
                  // obtener los vehiculos del estado de solicitud
                  allVehicles?.map((item, index) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={8}
                      xl={6}
                      xxl={4}
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      <Card
                        style={{
                          border: "1px solid #e8e8e8",
                          borderRadius: "6px",
                        }}
                      >
                        <p>VIN: {item.vin}</p>
                        <p>Lote: {item.lote}</p>
                        <p>Marca: {item.make}</p>
                        <p>Modelo: {item.model}</p>
                        <p>Año: {item.year}</p>
                        <p>Color: {item.color}</p>

                        <Button
                          type="primary"
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          danger
                          block
                          onClick={() => {
                            console.log("eliminar vehiculo", item.vin);
                            dispatch(deleteOneVehicle(item.vin));
                          }}
                        >
                          <DeleteFilled />
                        </Button>
                      </Card>
                    </Col>
                  ))
                )
              }
            </Row>
          )}

          <Row gutter={24}>
            {!showForm && (
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  href="#"
                  type="primary"
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  Agregar nuevo vehiculo
                </Button>
                {allVehicles?.length > 0 && (
                  <Button
                    type="success"
                    style={{
                      marginLeft: "20px",
                      backgroundColor: "green",
                      color: "#fff",
                    }}
                    onClick={() => {
                      dispatch(saveAllVehicles(allVehicles));
                    }}
                  >
                    Guardar todos los vehiculos agregados
                  </Button>
                )}

                {allVehicles?.length > 1 && (
                  <Button
                    type="primary"
                    style={{
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      dispatch(deleteAllVehicle());
                    }}
                    danger
                  >
                    Eliminar todos los vehiculos agregados
                  </Button>
                )}
              </Col>
            )}
          </Row>

          <Row gutter={24}>
            {/* agregar un Form responsive con ant design que lleve los campos: vin, lote: required, marca: required, modelo:required, select año:required, color  */}
            {showForm && (
              <Col span={24}>
                <Form
                  layout="vertical"
                  size="large"
                  form={form}
                  style={{
                    padding: "20px",
                  }}
                  onFinish={onSaveVehicle}
                  autoComplete="off"
                >
                  <Form.Item label="VIN" name="vin">
                    <Input placeholder="VIN" />
                  </Form.Item>

                  <Form.Item
                    label="Lote"
                    name="lote"
                    rules={[
                      { required: true, message: "Por favor ingrese el lote" },
                    ]}
                  >
                    <Input placeholder="Lote" />
                  </Form.Item>

                  <Form.Item
                    label="Marca"
                    name="make"
                    rules={[
                      { required: true, message: "Por favor ingrese la marca" },
                    ]}
                  >
                    {/* <Input placeholder="Marca" /> */}

                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleChange}
                      tokenSeparators={[","]}
                      options={options}
                      placeholder="Seleccionar marca o agregar una nueva"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Modelo"
                    name="model"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese el modelo",
                      },
                    ]}
                  >
                    {/* <Input placeholder="Modelo" /> */}

                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      onChange={() => {}}
                      tokenSeparators={[","]}
                      options={model}
                      placeholder="Seleccionar modelo o agregar una nueva"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Año"
                    name="year"
                    rules={[
                      { required: true, message: "Por favor ingrese el año" },
                    ]}
                  >
                    <Input placeholder="Año" type="number" />
                  </Form.Item>

                  <Form.Item label="Color" name="color">
                    <Input placeholder="Color" />
                  </Form.Item>

                  <Form.Item label="Título" name="idTitle" 
            rules={[{ required: true, message: "El tipo de titulo es requerido" }]}
          >
            <Select
              showSearch={true}
              placeholder={"Buscar título"}
              filterOption={filterOption}
              onChange={handleOnSelectTitle}
              options={titles.map((e) => ({
                value: e.idTitle,
                label: `${e.state} - ${e.titleName} (${
                  e.embarkable ? "Embarcable" : "No Embarcable"
                })`,
              }))}
            />
          </Form.Item>

                  <Form.Item
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Button type="primary" htmlType="submit" 
                                    disabled={!canSaveVehicle}
                    >
                      Agregar vehiculo
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "green",
                        color: "#fff",
                        marginLeft: "20px",
                      }}
                      onClick={() => {
                        setShowForm(false);
                      }}
                    >
                      Lista pendiente de vehiculos
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </TabPane>
        <TabPane tab="Registro de solicitudes" key="2">
        <Spin spinning={loading} tip="Se estan guardando las coordinaciones..." size='large'>

          {!showFormSolicitud && (
            <Row gutter={24}>
              {
                /* Agregar un box vacio para agregar mas solicitudes */
                allSolicitud?.length === 0 ? (
                  <Col span={24}>
                    <Result
                      icon={<SmileTwoTone twoToneColor="#e8e8e8" />}
                      title="No hay solicitudes pendiente."
                      style={{
                        border: "1px solid #e8e8e8",
                        borderRadius: "6px",
                      }}
                    />
                  </Col>
                ) : (
                  allSolicitud?.map((item, index) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={8}
                      xl={6}
                      xxl={4}
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      <Card
                        style={{
                          border: "1px solid #e8e8e8",
                          borderRadius: "6px",
                        }}
                      >
                        <p>
                          Vehiculo: <strong>{item?.vehicleName}</strong>
                        </p>
                        <p>
                          Lote: <strong>{item?.lote}</strong>
                        </p>
                        <p>Fecha: {item?.fechaQ}</p>
                        <p>Buyer: {item?.buyer}</p>
                        <p>Tipo Pago: {item?.tipoPago}</p>
                        <p>Costo de Flete: ${item?.quoted}</p>
                        <p>Costo de Grua: ${item?.estGrua}</p>
                        <p>Costo de BL: ${item?.bl}</p>
                        <p>Costo Adicional: ${item?.costoQ}</p>
                        <p>Observaciones: {item?.comentarios}</p>

                        <Button
                          type="primary"
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          danger
                          block
                          onClick={() => {
                            dispatch(deleteOneSolicitude(index));
                          }}
                        >
                          <DeleteFilled />
                        </Button>
                      </Card>
                    </Col>
                  ))
                )
              }
            </Row>
          )}
          {allSolicitud?.length > 0 && (
            <Row gutter={24} justify={"center"}>
              <Col lg={24} md={24} sm={24} xs={24}>
                {/* <Form
                  layout="vertical"
                  initialValues={{
                    layout: "vertical",
                    remember: true,
                  }}
                  onFinish={onFinishFile}
                  onFinishFailed={(errorInfo) => {
                    console.log("Failed:", errorInfo);
                  }}
                > */}
                <Form.Item
                  name="Files"
                  rules={[
                    {
                      required: true,
                      message: "Porfavor seleccione una imagen.",
                    },
                  ]}
                >
                  <Upload.Dragger
                    listType="picture"
                    fileList={selectedFiles}
                    onChange={(info) => {
                      console.log("info", info);
                      handleFileChange(info.fileList, info.file);
                    }}
                    onRemove={(file) => {
                      console.log("file FILE", file);
                      console.log("selectedFiles", selectedFiles);
                      const allAttachmentsValue = allAttachments;

                      const index = allAttachmentsValue.indexOf(file);
                      const newFileList = allAttachmentsValue.slice();
                      console.log("new list", allAttachments);
                      newFileList.splice(index, 1);

                      const indexSelect = selectedFiles.indexOf(file);
                      const newFileListSelect = selectedFiles.slice();
                      console.log("new list select", selectedFiles);
                      newFileListSelect.splice(indexSelect, 1);
                      console.log("new list select", newFileListSelect);
                      setSelectedFiles([...newFileListSelect]);

                      dispatch(deleteAllAttachments([...newFileList]));
                    }}
                    accept="image/*,video/*,application/pdf"
                    multiple
                    beforeUpload={() => false}
                  >
                    Arrastre archivos de imagen o video a esta área
                    <br />
                    o
                    <br />
                    <Button icon={<UploadOutlined />}>
                      Haga clic en Cargar
                    </Button>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item>
                  {uploading ? (
                    <Spin tip="Subiendo evidencias.." />
                  ) : (
                    <Row gutter={16}>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        {allAttachments?.length > 0 && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            // disabled={uploading}
                            onClick={() => {
                              dispatch(deleteAllAttachments([]));
                              setSelectedFiles([]);
                            }}
                            danger
                            block
                          >
                            Eliminar adjuntos
                          </Button>
                        )}
                      </Col>
                    </Row>
                  )}
                </Form.Item>
                {/* </Form> */}
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            {!showFormSolicitud && (
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  href="#"
                  type="primary"
                  onClick={() => {
                    setShowFormSolicitud(true);
                  }}
                >
                  Agregar nueva solicitud
                </Button>
                {allSolicitud?.length > 0 && (
                  <Button
                    type="success"
                    style={{
                      marginLeft: "20px",
                      backgroundColor: "green",
                      color: "#fff",
                    }}
                    onClick={() => {
                      console.log("allSolicitud", allSolicitud);
                      console.log("allAttachments", allAttachments);
                      dispatch(
                        saveAllSolicitud(
                          allSolicitud,
                          allAttachments,
                          currentUser
                        )
                      );
                    }}
                    loading={loading}
                  >
                    Guardar todas las solicitudes agregadas
                  </Button>
                )}

                {allSolicitud?.length > 1 && (
                  <Button
                    type="primary"
                    style={{
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      dispatch(deleteAllSolicitude());
                    }}
                    danger
                  >
                    Eliminar todas las solicitudes agregadas
                  </Button>
                )}
              </Col>
            )}
          </Row>

          {/* <Row gutter={24}> */}
          {/* agregar un Form responsive con ant design que lleve los campos: vin, lote: required, marca: required, modelo:required, select año:required, color  */}
          {showFormSolicitud && (
            <Form
              layout="vertical"
              size="large"
              form={formSolicitud}
              style={{
                padding: "20px",
              }}
              onFinish={onSaveSolicitud}
              autoComplete="off"
            >
              {/* DATOS DEL VEHICULO */}
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    name="vehiculoId"
                    label="Vehiculos"
                    rules={[
                      {
                        required: true,
                        message: "Por favor seleccione un vehiculo",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Seleccionar vehiculo"
                      optionFilterProp="children"
                      onChange={onChangeVehicle}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={vehicle}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr />
              <Row gutter={24} style={{ marginBottom: "20px" }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <strong>Fecha Ingreso: </strong> {dateNow}
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <strong>Vehiculo: </strong> {vehicleSelected?.vehiculoName}
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <strong>#Lote:</strong> {vehicleSelected?.lote}
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <strong>#Vin:</strong> {vehicleSelected?.vin}
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <strong>Tipo de Título: </strong> {vehicleSelected?.title ? vehicleSelected?.title?.titleName : "N/D"}
                </Col>
              </Row>
              <hr />
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  {/* ADD checkbox ant design yes delear and not delear */}
                  <Form.Item>
                    <Checkbox checked={checked} onChange={onChangeCheckbox}>
                      {" "}
                      Matus Dealer?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item>
                    <Checkbox
                      checked={checkedCutVehicle}
                      onChange={onChangeCheckboxCutVehicle}
                    >
                      Cortar Vehículo
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item>
                    <Checkbox
                      checked={checkedEntryType}
                      onChange={onChangeCheckboxEntryType}
                    >
                      Entrega en Bodega?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Gate Pass"
                    name="gatePass"
                    rules={[
                      {
                        required: gatePassRequired,
                        message: "Por favor ingrese el gate pass",
                      },
                    ]}
                  >
                    <Input placeholder="Gate pass" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Buyer"
                    name="buyer"
                    rules={[
                      { required: true, message: "Por favor ingrese el buyer" },
                    ]}
                  >
                    <Input placeholder="Buyer" type="number" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  {/* <Form.Item label="Tipo de Pago" name="tipoPago">
                    <Input placeholder="Tipo de pago" />
                  </Form.Item> */}
                  <Form.Item
                    label="Tipo de Pago"
                    name="tipoPago"
                    extra="Seleccionar un cliente"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese el tipo de pago",
                      },
                    ]}
                  >
                    {/* <Input placeholder="Tipo de pago" /> */}
                    <Select
                    disabled
                      showSearch
                      placeholder="Seleccionar un tipo de pago"
                      optionFilterProp="children"
                      //   onChange={onChangeVehicle}
                      //   onSearch={onSearch}
                      //   filterOption={filterOption}
                      options={TypePay}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={checkedEntryType ? 24 : 12}
                  lg={checkedEntryType ? 24 : 12}
                  xl={checkedEntryType ? 24 : 12}
                  xxl={checkedEntryType ? 24 : 12}
                >
                  <Form.Item
                    label="Cliente"
                    name="clienteId"
                    rules={[
                      {
                        required: selectedClient === undefined ? true : false,
                        message: "Por favor seleccione un cliente",
                      },
                    ]}
                  >
                    <Row
                      gutter={24}
                      style={{
                        display: "flex",
                      }}
                    >
                      {/* <p></p> */}
                      <Col xs={24} sm={24} md={14} lg={18} xl={18} xxl={18}>
                        <InputSearchCustomer
                          selectedCustomer={selectedClient}
                          filterByFinalCustomer={false}
                          onSelectedCustomer={handleSelectChangeCliente}
                        />
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={10}
                        lg={6}
                        xl={6}
                        xxl={6}
                        style={{}}
                      >
                        <Button
                          type="primary"
                          icon={<EditOutlined />}
                          size={"sm"}
                          onClick={() => setShowModalClient(true)}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          block
                        >
                          Agregar Cliente
                        </Button>
                      </Col>
                    </Row>
                    {/* <Select
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={onChangeCliente}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={listClient}
                    /> */}
                  </Form.Item>
                </Col>

                {!checkedEntryType && (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Row gutter={24} style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                      <Col xs={24} sm={24} md={14} lg={18} xl={18} xxl={18}>
                        <Form.Item
                          label="Subasta"
                          name="subastaId"

                        >
                          <Select
                            showSearch
                            placeholder="Seleccionar subasta"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={filterOption}
                            style={{ width: "100%" }} // Set width to 100% here
                            options={listSubasta}
                          />

                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={10} lg={6} xl={6} xxl={6}>
                        <Button
                          type="primary"
                          onClick={handleShowA}
                          size={"sm"}
                          style={{
                            marginTop: "20px",  
                          }}
                          block
                        >
                          Agregar Nueva Subasta
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item label="Origen" name="origenId">
                    <Select
                      showSearch
                      placeholder="Seleciona un origen"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={listOrigin}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item label="Aduana destino" name="aduanaId">
                    <Select
                      showSearch
                      placeholder="Seleccione una aduana"
                      optionFilterProp="children"
                      onChange={onChangeAduana}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={listAduana}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Item label="Costo de Flete" name="quoted">
                    <Input placeholder="Costo de flete" />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Item label="Costo de Grua" name="estGrua">
                    <Input placeholder="Costo de grua" />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Item label="Costo de BL" name="bl">
                    <Input placeholder="Costo de bl" />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Item label="Costo Adicional" name="costoQ">
                    <Input placeholder="Costo de adicional" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item label="Comentarios" name="comentarios">
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  {/* <Form.Label className='text-aling-left m-0'>Cliente Final (Opcional)</Form.Label> */}
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Space
                      direction="horizontal"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <p>Cliente Final (Opcional)</p>
                      <Button
                        type="primary"
                        icon={<EditOutlined />}
                        size={"sm"}
                        onClick={() => setShowModalFinalClient(true)}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Agregar Cliente
                      </Button>
                    </Space>

                    <InputSearchCustomer
                      selectedCustomer={selectedFinalClient}
                      filterByFinalCustomer={true}
                      onSelectedCustomer={handleSelectChangeClienteFinal}
                    />
                  </Space>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item label="Registrado por:" name="">
                    {currentUser?.fullName}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item label="Asignado a:" name="userSellerId">
                    <Select
                      placeholder="Seleccionar usuario"
                      onChange={e => {
                        console.log("eeeee", userList);
                        console.log("e.target.value", e);
                        const user = userList.find(user => user.idUser === +e);
                        setSelectedUser({
                          id: user.idUser,
                          fullName: `${user.firstName} ${user.lastName}`,
                        });
                      }}

                      
                    >
                      <Select.Option value={currentUser?.idusr}>
                        {currentUser?.fullName}
                      </Select.Option>
                      {userList.map((user, index) => {
                          
                        return (
                          <Select.Option key={user.idUser} value={user.idUser}>
                            {user.firstName} {user.lastName}
                          </Select.Option>
                        );
                      }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Agregar solicitud
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "green",
                      color: "#fff",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      setShowFormSolicitud(false);
                    }}
                  >
                    Lista pendiente de solicitudes
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          )}
          {/* </Row> */}
          </Spin>
        </TabPane>
      </Tabs>

      <>
        <Modal
          show={showA}
          onHide={handleCloseA}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new auction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormB.Label>Nombre de la Subasta</FormB.Label>
            <FormB.Control
              onChange={handleChange}
              name="subName"
              type="text"
              placeholder="Nombre Subasta"
            />
            <br />
            <FormB.Label>Address line 1</FormB.Label>
            <FormB.Control
              onChange={handleChange}
              name="addressLine1Sub"
              type="text"
              placeholder="Address line 1"
            />
            <br />
            <FormB.Label>Address line 2</FormB.Label>
            <FormB.Control
              onChange={handleChange}
              name="addressLine2Sub"
              type="text"
              placeholder="Address line 2"
            />
            <br />
            <FormB.Label>City</FormB.Label>
            <FormB.Control
              onChange={handleChange}
              name="city"
              type="text"
              placeholder="City"
            />
            <br />
            <FormB.Label>Zip Code</FormB.Label>
            <FormB.Control
              onChange={handleChange}
              name="zipCode"
              type="number"
              placeholder="Zip Code"
            />
            <br />
            <FormB.Label>Phone Number</FormB.Label>
            <FormB.Control
              onChange={handleChange}
              name="phoneNumber"
              type="number"
              placeholder="Phone Number"
            />
            <br />
            <FormB.Label>State</FormB.Label>
            <FormB.Select
              onChange={handleChange}
              name="state"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            >
              <option disabled selected>
                Seleccione
              </option>
              {states &&
                states.length &&
                states.map((ste, index) => {
                  return (
                    <option key={index} value={ste.stateId}>
                      {ste.state}
                    </option>
                  );
                })}
            </FormB.Select>
            <br />
            {/*<FormB.Label>Country</FormB.Label>
            <FormB.Select
              onChange={handleChange}
              name="countryId"
              value={addSubastas?.countryId}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            >
              <option disabled selected>
                Seleccione
              </option>
              {countries &&
                countries?.length &&
                countries.map((countries, index) => {
                  return (
                    <option key={index} value={countries?.id}>
                      {countries?.nameCountry}
                    </option>
                  );
                })}
            </FormB.Select>
            <br /> */}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseA}>
              Cancelar
            </Button>
            <Button onClick={() => insertarSubasta()} type="primary">
              {" "}
              <Space>

              <FontAwesomeIcon icon={faFloppyDisk} />  Guardar

              </Space>
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <ModalAntD
        open={showModalClient || showModalFinalClient}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <ClienteForm
          shortSaved={true}
          countryId={0}
          importadora={selectedImportadora}
          finalClient={showModalFinalClient}
          submitCliente={handleSaveCustomer}
          onCancel={handleCancelCustomer}
        />
      </ModalAntD>
    </div>
  );
};

export default MultiplesSolicitudes;
