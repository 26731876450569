import {Button, Col, Form, Input, Modal, Row, Select, Space} from "antd";
import React, {useState} from "react";
import {DeleteFilled, DollarCircleOutlined} from "@ant-design/icons";
import {deleteQuotationCharge, saveQuotationCharge} from "../../Servicios/QuotationService";
import Spinner from "../../shared/spinner";
import {errorMessage, successMessage} from "../../utils/toast-message";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";

const QuotationChargesForm = ({ idCotizacion, quotationChargesList, onChangeQuotationChargesList }) => {
  const [formChargeModal] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalChargeOpen, setIsModalChargeOpen] = useState(false);

  const deleteCharge = async (item) => {
    if (idCotizacion && item.id !== 0) {
      setIsLoading(true);
      await deleteQuotationCharge(item.id);
      setIsLoading(false);
    }

    // delete dataModal
    const newData = quotationChargesList.filter((element) => element.key !== item.key);
    onChangeQuotationChargesList(newData);

    // show toast success delete
    successMessage("Se elimino correctamente");
  }

  const onFinishModalCharge = async (values) => {
    if (quotationChargesList.some(e => e.description === values.description)) {
      errorMessage('La descripción de pago ya fue agregada');
      return;
    }

    // inicializar id
    values.id = 0;

    if (idCotizacion) {
      setIsLoading(true);
      const id = await saveQuotationCharge(idCotizacion, { ...values, price: +values.price });
      setIsLoading(false);

      values = { ...values, id };
    }

    setIsModalChargeOpen(false);
    values.key = quotationChargesList.length + 1;

    onChangeQuotationChargesList([...quotationChargesList, values]);

    // clear form
    formChargeModal.resetFields();

    // show toast success
    successMessage("Se agrego correctamente");
  };

  const handleChangeOceanFreightPaymentTerms = (event) => {
    const list = [...quotationChargesList];
    const oceanFreight = quotationChargesList[0];

    oceanFreight.paymentTerms = event;
    list[0] = oceanFreight;

    onChangeQuotationChargesList([...list]);
  }

  const handleChangeOceanFreightQuantity = (event) => {
    const value = event.target.value;

    const list = [...quotationChargesList];
    const oceanFreight = quotationChargesList[0];

    oceanFreight.quantity = value;
    list[0] = oceanFreight;

    onChangeQuotationChargesList([...list]);
  }

  const handleChangeOceanFreightPrice = (event) => {
    const value = event.target.value;

    const list = [...quotationChargesList];
    const oceanFreight = quotationChargesList[0];

    oceanFreight.price = value;
    list[0] = oceanFreight;

    onChangeQuotationChargesList([...list]);
  }

  return(
    <>
      <Spinner loading={isLoading}>
        <Row>
          <Col className="gutter-row">
            <Button type="primary"
                    className="mb-3"
                    onClick={() => setIsModalChargeOpen(true)}>
              Agregar descripción de pago
            </Button>
          </Col>
        </Row>
        {
          quotationChargesList.map((item, index) => {
            return(
              <Row id={"quotation-charges-list-" + index} gutter={[16, 24]}>
                <Col className="gutter-row" xs={24} sm={24} md={3} lg={3} xl={3}>
                  <ShowWhen show={index !== 0}>
                    <div className="d-flex h-100 align-items-center">
                      <Button type="primary"
                              shape="circle"
                              className="d-flex align-items-center justify-content-center"
                              style={{backgroundColor: "red"}}
                              onClick={() => deleteCharge(item)}>
                        <DeleteFilled style={{fontSize: 17}}/>
                      </Button>
                    </div>
                  </ShowWhen>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={7} lg={7} xl={7}>
                  <Form.Item name={"chargeDescription" + item.key} label="Description" initialValue={item.description}>
                    <Input disabled/>
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item label="Payment terms">
                  <Select placeholder="Select payment terms"
                            value={item.paymentTerms}
                            disabled={index !== 0}
                            onChange={handleChangeOceanFreightPaymentTerms}
                            options={[
                              { value: "Prepaid",     label: "Prepaid" },
                              { value: "Postpaid",    label: "Postpaid" },
                              { value: "Deposit",     label: "Deposit" },
                              { value: "CreditCard",  label: "CreditCard" },
                              { value: "Check",       label: "Check" },
                            ]} />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                  <Form.Item label="Quantity">
                    <Input value={item.quantity}
                           disabled={index !== 0}
                           onChange={handleChangeOceanFreightQuantity} />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} sm={24} md={3} lg={4} xl={4}>
                  <Form.Item label="Price">
                    <Input value={item.price}
                           disabled={index !== 0}
                           prefix={<DollarCircleOutlined />}
                           onChange={handleChangeOceanFreightPrice} />
                  </Form.Item>
                </Col>
              </Row>
            )
          })
        }
      </Spinner>

      <Modal
        title="Charge Information"
        open={isModalChargeOpen}
        onOk={() => setIsModalChargeOpen(false)}
        onCancel={() => setIsModalChargeOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Form
          layout={"vertical"}
          form={formChargeModal}
          initialValues={{ layout: "vertical", remember: true }}
          style={{ padding: 20 }}
          onFinish={onFinishModalCharge}
          autoComplete="off"
        >
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Payment Terms:"
                         name="paymentTerms">
                <Select placeholder="Select payment terms"
                        optionFilterProp="children"
                        options={[
                          { value: "Prepaid", label: "Prepaid" },
                          { value: "Postpaid", label: "Postpaid" },
                          { value: "Deposit", label: "Deposit" },
                          { value: "CreditCard", label: "CreditCard" },
                          { value: "Check", label: "Check" },
                        ]}/>
              </Form.Item>
              <Form.Item label="Payment Description:"
                         name="description"
                         rules={[{required: true, message: "Por favor ingrese la descripcion"}]}
                         help={'ex: Documentacion, Grua, etc.'}>
                <Input placeholder="Description" />
              </Form.Item>
              <Form.Item label="Quantity"
                         name="quantity"
                         rules={[{required: true, message: "Por favor ingrese la cantidad"}]}>
                <Input placeholder="Quantity" />
              </Form.Item>
              <Form.Item label="$ Amount:"
                         name="price"
                         rules={[{required: true, message: "Por favor ingrese el monto"}]}>
                <Input placeholder="$ 00.00" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                {quotationChargesList.length < 6 && (
                  <Button block
                          style={{ backgroundColor: "green", color: "white" }}
                          htmlType="submit">
                    Agregar
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default QuotationChargesForm;
