import {useForm} from "antd/es/form/Form";
import Spinner from "../../shared/spinner";
import React, {useEffect, useState} from "react";
import {Button, Form, InputNumber, Switch} from "antd";
import {addCarrier, updateCarrier} from "../../Servicios/ExportationServices";
import {errorMessage, successMessage} from "../../utils/toast-message";

const CarrierForm = ({ carrrier, onSave }) => {
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [applyShippingInstruction, setApplyShippingInstruction] = useState(false);

  useEffect(() => {
    if (carrrier) {
      form.setFieldsValue({
        oceanCarrierCode: carrrier.oceanCarrierCode,
        oceanCarrierSacCode: carrrier.oceanCarrierSacCode,
        oceanCarrierName: carrrier.oceanCarrierName,
        applyShippingInstruction: carrrier.applyShippingInstruction,
      });

      setApplyShippingInstruction(carrrier.applyShippingInstruction);
    } else {
      form.resetFields();
      setApplyShippingInstruction(false);
    }
  }, [carrrier]);

  const handleSubmit = async (values) => {
    const input = {
      oceanCarrierCode: values.oceanCarrierCode,
      oceanCarrierSacCode: values.oceanCarrierSacCode,
      oceanCarrierName: values.oceanCarrierName,
      applyShippingInstruction: applyShippingInstruction,
    }

    setLoading(true);
    const result = carrrier
      ? await updateCarrier(carrrier.carrierId, input)
      : await addCarrier(input);
    setLoading(false);

    if (result && result.status) {
      successMessage('CARRIER SAVED SUCCESSFULLY');
      if (onSave) onSave();
    } else {
      errorMessage(result.message);
    }
  }

  return(
    <Spinner loading={loading}>
      <Form form={form}
            layout={"vertical"}
            autoComplete="off"
            onFinish={handleSubmit}>
        <Form.Item label="Code" name="oceanCarrierCode">
          <InputNumber placeholder={'Code'} className="w-100" />
        </Form.Item>

        <Form.Item label="SAC Code" name="oceanCarrierSacCode">
          <InputNumber placeholder={'SAC Code'} className="w-100" />
        </Form.Item>

        <Form.Item label="Name" name="oceanCarrierName">
          <InputNumber placeholder={'Name'} className="w-100" />
        </Form.Item>

        <Form.Item label="Apply for Shipping Instruction" name="applyShippingInstruction">
          <Switch checked={applyShippingInstruction}
                  onChange={(value) => {
                    setApplyShippingInstruction(value);
                  }} />
        </Form.Item>

        <div className="text-end">
          <Button htmlType={'submit'} type={'primary'}>Save</Button>
        </div>
      </Form>
    </Spinner>
  );
}

export default CarrierForm;
