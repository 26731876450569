import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faCirclePlus,
  faEye,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { showQuote } from "../Servicios/SolicitudesService";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import "styled-components";
import "../hojas-de-estilo/style.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SpinnerTable from "../Componentes/SpinnerTable";
import {Tabs,

  DatePicker
} from "antd";
import {SolicitudEstadoEnum} from "../shared/enums/solicitud-estado.enum";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import useCurrentUser from "../Hooks/UserHook";
import {getCountriesId, isSeller} from "../Servicios/AuthService";

const TablaSolicitud = () => {
  const currentUser = useCurrentUser();
  const [quote, setQuote] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [currentDay, setCurrentDay] = useState(0); // Obtén el día actual
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); // Obtén el mes actual (agregamos +1 ya que los meses van de 0 a 11)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Obtén el año actual
  const [mesesDelAnio, setMesesDelAnio] = useState([]);

  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [estado, setEstado] = useState("");

  const navigate = useNavigate();

  var XLSX = require("xlsx");

  const showSolicitudes = async (currentPage, pageCount, search, estado = "") => {
    let creador = "";
    if (isSeller() && estado !== SolicitudEstadoEnum.PendienteCompletar) {
      creador = currentUser.fullName;
    }

    setIsLoading(true);
    if (!currentMonth || !currentYear) {
      console.error("El mes y/o el año están vacíos o nulos.");
      setIsLoading(false); // Asegúrate de detener la animación de carga
      return; // Detenemos la ejecución de la función
    }

    let countriesId = getCountriesId();

    if (estado === SolicitudEstadoEnum.EntregasEnBodega) {
      creador = "";
      countriesId = "";
    }

    const data = await showQuote(currentDay, currentMonth, currentYear, currentPage, pageCount, search, estado, creador, 0, countriesId);
    setIsLoading(false);

    if (data.status) {
      setQuote(data.list);
      setTotalElements(data.totalElements);
    }
  };

  useEffect(() => {
    const meses = Array.from({ length: 12 }, (_, index) => ({
      value: index + 1,
      label: obtenerNombreMes(index + 1),
    }));

    setMesesDelAnio(meses); // Almacenar los meses en el estado
  }, []);

  useEffect(() => {
    if (currentUser) {
      showSolicitudes(currentPage, pageCount, filterText, estado).then();
    }
  }, [currentUser, currentMonth, currentYear, currentDay]);

  const obtenerNombreMes = (mes) => {
    const nombresMeses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return nombresMeses[mes - 1];
  };

  // Obtener el año actual
  const anioActual = new Date().getFullYear();

  // Obtener una lista de años desde un año antes del actual hasta un año después
  const aniosPosibles = Array.from(
    { length: 3 }, // Cambia el número si deseas más años hacia el futuro
    (_, index) => anioActual - 1 + index
  );

  // Si el año actual no está en la lista, agrégalo
  if (!aniosPosibles.includes(anioActual)) {
    aniosPosibles.push(anioActual);
  }

  const columns = [
    {
      name: "Ver",
      width: "70px",
      visible: true,
      cell: (row) => (
        <Button
          as={Link}
          to={`/sendprintRequest/${row?.quoteId}`}
          variant="success"
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ),
    },
    {
      name: "Editar",
      width: "70px",
      visible: estado === "" && !isSeller(),
      cell: (row) => (
        <Button as={Link} to={`/editQuotes/${row?.quoteId}`} variant="primary">
          <FontAwesomeIcon icon={faPenToSquare} />
        </Button>
      ),
    },
    {
      name: "Fecha",
      visible: true,
      selector: (row) => row.fechaQ,
      format: (row) => moment(row?.fechaQ).format("l"),
      width: "93px",
    },
    {
      name: "Lote",
      sortable: true,
      visible: true,
      selector: (row) => row?.lote,
      width: "100px",
    },
    {
      name: "Vehiculo",
      sortable: true,
      visible: true,
      selector: (row) => row?.vehiculoName,
      width: "200px",
    },
    {
      name: "Cliente",
      sortable: true,
      visible: true,
      selector: (row) => row?.clienteName,
      width: "270px",
    },
    {
      name: "Importadora",
      sortable: true,
      visible: true,
      selector: (row) => row?.import,
      width: "300px",
    },
    {
      name: "Origen",
      sortable: true,
      visible: true,
      selector: (row) => row?.nameOrigen,
      width: "110px",
    },
    {
      name: "Destino",
      sortable: true,
      visible: true,
      selector: (row) => row?.aduanaName,
      width: "150px",
    },
    {
      name: "Tipo de Pago",
      sortable: true,
      visible: true,
      selector: (row) => row?.tipoPago,
      width: "150px",
    },
    {
      name: "Asesor",
      sortable: true,
      visible: true,
      selector: (row) => row?.creador,
      width: "150px",
    },
  ];

  const cancelColumns = [
    {
      name: "Ver",
      width: "70px",
      cell: (row) => (
        <Button
          as={Link}
          to={`/sendprintRequest/${row?.quoteId}?cancelada=true`}
          variant="success"
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ),
    },
    {
      name: "Fecha",
      selector: (row) => row.fechaQ,
      format: (row) => moment(row?.fechaQ).format("l"),
      width: "93px",
    },
    {
      name: "Lote",
      sortable: true,
      selector: (row) => row?.lote,
      width: "100px",
    },
    {
      name: "Vehiculo",
      sortable: true,
      selector: (row) => row?.vehiculoName,
      width: "200px",
    },
    {
      name: "Cliente",
      sortable: true,
      selector: (row) => row?.clienteName,
      width: "270px",
    },
    {
      name: "Importadora",
      sortable: true,
      selector: (row) => row?.import,
      width: "300px",
    },
    {
      name: "Origen",
      sortable: true,
      selector: (row) => row?.nameOrigen,
      width: "110px",
    },
    {
      name: "Destino",
      sortable: true,
      selector: (row) => row?.aduanaName,
      width: "150px",
    },
    {
      name: "Motivo",
      sortable: true,
      selector: (row) => row?.comentarios,
      width: "250px",
    },
  ];

  const exportToExcel = () => {
    // necesito solo dejar las columnas quoteId	fechaQ	buyer	fechaQPdf	fechaRecibido	lote	nameOrigen	year	make	model	vin	color	titulo	llave	aduanaName	importadoraName	clienteName	n1	n2	n3	n4	total	creador	registradoBy
    const newquote = quote.map((q) => {
      return {
        quoteId: q.quoteId,
        fechaQ: moment(q.fechaQ).format("l"),
        buyer: q.buyer,
        fechaQPdf: q.fechaQPdf,
        fechaRecibido: q.fechaRecibido,
        lote: q.lote,
        nameOrigen: q.nameOrigen,
        year: q.year,
        make: q.make,
        model: q.model,
        vin: q.vin,
        color: q.color,
        titulo: q.titulo,
        llave: q.llave,
        aduanaName: q.aduanaName,
        importadoraName: q.importadoraName,
        clienteName: q.clienteName,
        n1: q.n1,
        n2: q.n2,
        n3: q.n3,
        n4: q.n4,
        total: q.total,
        creador: q.creador,
        registradoBy: q.registradoBy,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(newquote);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Solicitudes");
    // Genera el archivo Excel
    XLSX.writeFile(workbook, "solicitudes.xlsx", {
      bookType: "xlsx",
      type: "blob",
    });
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);

    if (currentUser) {
      showSolicitudes(newPage, pageCount, filterText, estado).then();
    }
  };

  const handlePerRowsChange = (newPerPage) => {
    setPageCount(newPerPage);
    setCurrentPage(1);

    if (currentUser) {
      showSolicitudes(1, newPerPage, filterText, estado).then();
    }
  };

  const handleFilterText = (value) => {
    setFilterText(value);

    if (currentUser) {
      showSolicitudes(currentPage, pageCount, value, estado).then();
    }
  }

  const items = [
    {
      key: '1',
      label: 'Solicitudes',
      children: (<>
        <DataTable
          columns={columns.filter(e => e.visible)}
          data={quote}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable />}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    },
    {
      key: '2',
      label: 'Solicitudes en Revisión',
      children: (<>
        <DataTable
          columns={columns}
          data={quote}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable />}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    },
    {
      key: '3',
      label: 'Solicitudes Pendiente de Completar',
      children: (<>
        <DataTable
          columns={columns}
          data={quote}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable />}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    },
    {
      key: '4',
      label: 'Solicitudes Canceladas',
      children: (<>
        <DataTable
          columns={cancelColumns}
          data={quote}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable />}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    },
    {
      key: '5',
      label: 'Entregas en Bodega',
      children: (<>
        <DataTable
          columns={columns}
          data={quote}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable />}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    },
  ];

  const handleTabChange = (key) => {
    const keys = {
      '1': "",
      '2': SolicitudEstadoEnum.EnRevision,
      '3': SolicitudEstadoEnum.PendienteCompletar,
      '4': SolicitudEstadoEnum.Cancelado,
      '5': SolicitudEstadoEnum.EntregasEnBodega,
    };

    const newEstado = keys[key];
    setCurrentPage(1);
    setEstado(newEstado);

    showSolicitudes(1, pageCount, filterText, newEstado).then();
  }

  return (
    <Container fluid={true}>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="m-0">Solicitudes realizadas</h4>
        <Button onClick={() => { navigate("/request") }}>
          <FontAwesomeIcon icon={faCirclePlus}/> Nueva solicitud
        </Button>
      </div>
      <hr/>

      <Row>
        <Col xs={12} md={4} lg={4}>
          <Form.Label>Mes:</Form.Label>
          <Form.Select
            value={currentMonth}
            onChange={(e) => setCurrentMonth(Number(e.target.value))}
          >
            {mesesDelAnio.map((mes) => (
              <option key={mes.value} value={mes.value}>{ mes.label }</option>
            ))}
          </Form.Select>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <Form.Label>Año:</Form.Label>
          <Form.Select
            value={currentYear}
            onChange={(e) => setCurrentYear(Number(e.target.value))}
          >
            {aniosPosibles.map((year) => (
              <option key={year} value={year}>{ year }</option>
            ))}
          </Form.Select>
        </Col>
        <Col xs={12} md={4} lg={4}>
        {/* agregar filtro por dia por defecto vacio */}
        <Form.Label>Fecha:</Form.Label> 
        <DatePicker
          placeholder="Seleccione una fecha"
          format="YYYY-MM-DD"
          style={{ width: "100%", height: "38px" }}
          onChange={(date) => {
            if (date) {
              setCurrentPage(1);
              setEstado("");
              setFilterText("");
              // setCurrentMonth(date.format("MM"));
              // setCurrentYear(date.format("YYYY"));
              setCurrentDay(date.format("DD"));
              // showSolicitudes(1, pageCount, date.format("YYYY-MM-DD"), "").then();
            } else {
              setCurrentDay(0);
              // showSolicitudes(1, pageCount, "", "").then();
            }
          }}
        />

        </Col>
      </Row>
      <br/>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Form.Label className="input-buscar">
            Seleccione una Solicitud para Imprimir / Enviar / Editar
          </Form.Label>
          <InputSearch placeholder={'Buscar Solicitud por Lote / Marca / Modelo / Importadora / Fecha ejm: 2023-mm-dd'}
                       doChange={handleFilterText} />
        </Col>
      </Row>
      <br/>

      <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange} />

      <Col xs={12} md={2} lg={2} className="d-grid">
        <Form.Label className="input-buscar"></Form.Label>
        <Button size="sm" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faDownload} /> Exportar a Excel
        </Button>
      </Col>
    </Container>
  );
};

export default TablaSolicitud;
