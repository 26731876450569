import React, { useEffect, useState } from "react";
import "../hojas-de-estilo/style.css";
import ClientTable from "./CCComp/DataTable";
import { useParams } from "react-router-dom";
import { viewCountries } from "../Servicios/ComponedoresService";
import { Row, Col } from "antd";
import { Content } from "antd/es/layout/layout";

const FindCliente = () => {
  const [countries, setCountries] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    viewCountries(id).then((pais) => {
      setCountries(pais);
    });
  }, [id]);

  return (
    <Content>
      <Row>
        <Col xs={24} md={24}>
          <div className="d-flex align-items-center justify-content-between">
            <h1>Cartera de Clientes</h1>
            <h1>{ countries?.nameCountry }</h1>
          </div>
          <hr/>
        </Col>
      </Row>
      <ClientTable />
    </Content>
  );
};

export default FindCliente;
