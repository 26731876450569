import React, {useEffect, useState} from "react";
import ClienteForm from "./ClienteForm";
import '../hojas-de-estilo/style.css';
import {useNavigate, useParams} from "react-router-dom";
import {viewClient} from "../Servicios/ClienteService";
import LoadingInside from "../Componentes/LoadingInside";

const ClienteFormPage = () => {
  const {id} = useParams();
  const {countryId} = useParams();

  const navigate = useNavigate();

  const [cliente, setCliente] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      findCustomer(id);
    }
  }, [id]);

  const findCustomer = (id) => {
    setIsLoading(true);

    viewClient(id).then((cliente) => {
      setCliente(cliente);
      setIsLoading(false);
    });
  }

  const postSubmitCliente = nuevoCliente => {
    return navigate(`/seeProfileClient/${nuevoCliente.id}`);
  }

  const postCancel = () => {
    return navigate(`/portfolio/country/${cliente.countryId}`);
  }

  if (isLoading) {
    return (<LoadingInside />);
  }

  return(
    <div className='general'>
      <div className='conte-padre'>
        <div className='conte-itemb'>
          <div className='conte-cat'>
            <h2>{cliente ? 'Modificar' : 'Agregar'} Cliente</h2>
          </div>

          <ClienteForm cliente={cliente}
                       shortSaved={false}
                       countryId={countryId ?? 0}
                       submitCliente={postSubmitCliente}
                       onCancel={postCancel}
                       onDeleteCliente={postCancel}
                       showDeleteClient={true} />
        </div>
      </div>
    </div>
  );
}

export default ClienteFormPage;
