import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSelectedWareHouse } from "../whareHouse";
import {
  getExportations,
  getExportationIdData,
  saveDetailExportation,
  saveExportation,
  getExportationShipmentName,
  updateExportation
} from "../../Servicios/ExportationServices";
import {
  getAllExportations,
  loading,
  getExportationId,
  addPushAllExportations,
} from "./";
import {ExportationStateEnum} from "../../shared/enums/exportation-state.enum";

export const getFetchAllExportation = (id, currentPage, pageCount, search) => {
  return async (dispatch) => {
    dispatch(loading(true));
    // metodo del server
    const data = await getExportations(id, currentPage, pageCount, search, ExportationStateEnum.Creado);

    await dispatch(getAllExportations({
      list:data?.list,
      currentPage:data?.currentPage,
      pageCount:data?.pageCount,
      totalElements:data?.totalElements
     }));
    await dispatch(loading(false));
  };
};

export const getLoading = () => {
  return async (dispatch) => {
    dispatch(loading(true));
  };
};

export const saveFetchExportation = (params) => {
  return async (dispatch) => {
    console.log("PARAMS", params);
    await dispatch(loading(true));
    const respexport = await saveExportation(params);
    const { data } = respexport;
    console.log("RESPUESTA", respexport);
    if (respexport.status) {
      await dispatch(getFetchAllExportation());
      await dispatch(getExportationId(respexport?.data));

      toast.success(
        `Se ha creado el numero ${respexport.data.shipmentName} con exito!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } else {
      toast.error(`Ha ocurrido un error al guardar la exportación `, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    await dispatch(loading(false));
  };
};


export const updateFetchExportation = (params) => {
  return async (dispatch) => {
    
    await dispatch(loading(true));
    const respexport =  await updateExportation(params);
      console.log("RESPUESTA", respexport);
    if(respexport.status){
      await dispatch(getFetchAllExportation());
      await dispatch(getExportationId(respexport?.data));

      toast.success(
        `Se ha actualizado la exportacion con numero ${respexport.data.shipmentName} con exito!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } else {
      toast.error(`Ha ocurrido un error al guardar la exportación `, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    await dispatch(loading(false));
  }
}


export const getFetchExportationShipmentName = (shipmentName) => {
  return async (dispatch) => {
    const respExpShipmentName = await getExportationShipmentName(shipmentName);
    await dispatch(getExportationId(respExpShipmentName)); 
    console.log('detalle del warehouse',respExpShipmentName?.exportDetails);
    await respExpShipmentName?.exportDetails.map( (element) => {
       
        dispatch(getSelectedWareHouse({...element?.werehouse,  letter: element?.exportationContainer?.letter, ...element?.werehouse?.vehiculo, ...element?.werehouse?.subasta, ...element}));
      });
  }
}

export const saveFetchDetailExport = () => {
  return async (dispatch, getStatus) => {
    // validar si existe algo seleccionado
    await dispatch(loading(true));
    // sacamos el exp
    const { exportationsId } = getStatus().exportation;
    const { selectedWareHouse } = getStatus().wareHouse;

    const promises = selectedWareHouse?.map((item, index) => {

      
      console.log("DETALLES",exportationsId?.exportDetails);

      const exist = exportationsId?.exportDetails?.find(
        (element) => element.werehouse.codigo === item.codigo
      );
      console.log("EXISTE", exist);
      console.log("wherehouse",exportationsId?.exportDetails?.werehouse?.codigo, "codigo",  item.codigo);
      if(exist) {
        return false;
      } else {
        console.log("ENTRO A GUARDAR");
      const body = {
        idExport: parseInt(exportationsId?.exportationId),
        werehouseId: parseInt(item?.werehouseId),
        letter: item?.letter,
        state: true,
      };

     return saveDetailExportation(body);
    }
    });

    await Promise.all(promises);

    const resp = await getExportationIdData(exportationsId?.exportationId);
    await dispatch(getExportationId(resp));
    await dispatch(loading(false));
  };
};
