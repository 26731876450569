import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Space,
  theme,
  Form,
  Input,
  Select,
  Table,
  Modal,
} from "antd";
import Spinner from "../../shared/spinner";
import useCurrentUser from "../../Hooks/UserHook";
import moment from "moment";
import { getNumeroExportacion } from "../../Servicios/ExportationServices";
import {DeleteFilled, DollarCircleOutlined} from "@ant-design/icons";
import { showOrigen } from "../../Servicios/CombosService";
import { getPortCode } from "../../Servicios/ExportationServices";
import { showAduana } from "../../Servicios/CombosService";
import { getContainer } from "../../Servicios/ExportationServices";
import { getModeTransportation } from "../../Servicios/ExportationServices";
import {
  saveQuotation,
  getQuotationById,
  updateQuotation,
  deleteQuotationDetail, saveQuotationDetail, updateQuotationCharge
} from "../../Servicios/QuotationService";
import ClienteForm from "../../Carteracliente/ClienteForm";
import TextArea from "antd/lib/input/TextArea";
import PortCodeForm from "../PortCode/PortCodeForm";
import InputSearchCustomer from "../../Componentes/InputSearchCustomer/InputSearchCustomer";
import {errorMessage, successMessage} from "../../utils/toast-message";
import QuotationChargesForm from "./QuotationChargesForm";

const KEY_OCEAN_FREIGHT_DESCRIPTION = "Ocean Freight";

const QuotationForm = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();

  const user = useCurrentUser();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [codigo, setCodigo] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPortCodeModalOpen, setPortCodeModalOpen] = useState(false);
  const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);

  const [quotationDetailList, setQuotationDetailList] = useState([]);
  const [quotationChargesList, setQuotationChargesList] = useState([]);

  const [total, setTotal] = useState(0);
  const [idCotizacion, setIdCotizacion] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);

  const [origen, setOrigen] = useState([]);
  const [destination, setDestination] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [portUnLoading, setPortUnLoading] = useState([]);
  const [containerType, setContainerType] = useState([]);
  const [transportationMode, setTransportationMode] = useState([]);

  const [idOceanFreight, setIdOceanFreight] = useState(0);

  const contentStyle = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const onFinish = async (values) => {
    try {
      if (quotationDetailList.length === 0) {
        errorMessage("Agregar al menos una descripción antes de guardar");
        return;
      }

      if (!selectedCustomer) {
        errorMessage("Seleccionar un cliente antes de guardar");
        return;
      }

      setIsLoading(true);

      values.idEmployee = parseInt(user?.idusr);
      // idOrigen: parseInt(user?.idubc),
      values.dateCreate = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      //dateExpiration is thirty days after the date of creation
      values.dateExpiration = moment()
        .add(30, "days")
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      delete values.userName;
      delete values.local;

      // set charges
      const charges = quotationChargesList.map(e => {
        return { ...e, price: +e.price, quantity: +e.quantity }
      });

      const details = quotationDetailList.map(e => {
        return { ...e, pcs: e.pcs.toString() }
      });

      values.idCliente = selectedCustomer.id;
      values.total = total.toString();

      // save data to database
      let data;
      if (id) {
        if (idOceanFreight !== 0) {
          // update ocean freight
          let oceanFreight = quotationChargesList.find(e => e.description === KEY_OCEAN_FREIGHT_DESCRIPTION);
          oceanFreight.price = +oceanFreight.price;
          oceanFreight.quantity = +oceanFreight.quantity;

          await updateQuotationCharge(idOceanFreight, oceanFreight);
        }

        data = await updateQuotation(values, idCotizacion);
      } else {
        data = await saveQuotation(values, details, charges);
      }

      // show toast success
      if (typeof data === "undefined") {
        errorMessage("Ocurrio un error al guardar");
        setIsLoading(false);
        return;
      }

      successMessage(id ? "Se actualizo correctamente la cotización" : "Se guardo correctamente");

      // navigate to list
      navigate("/quotation/list");
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      errorMessage(ex.toString());
    }
  };

  const onFinishModal = async (values) => {
    if (idCotizacion) {
      setIsLoading(true);
      const data = await saveQuotationDetail(idCotizacion, { ...values, pcs: values.pcs.toString()});
      setIsLoading(false);

      values = { ...values, id: data.idDetalleCot };
    }

    setIsModalOpen(false);
    values.key = quotationDetailList.length + 1;
    // validate if dataModal is > 6
    // add values to setDataModal
    const newList = [...quotationDetailList, values];
    setQuotationDetailList(newList);

    // clear form
    formModal.resetFields();

    // show toast success
    successMessage("Se agrego correctamente");
  };

  const onFinishFailedModal = () => {};

  const onChange = () => {};

  const onSearch = () => {};

  useEffect(() => {
    setIsLoading(true);
    getNumeroExportacion("COTIZACION")
      .then((data) => {
        setCodigo(data + 1);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // get Origen to select
    showOrigen().then((data) => {
      setOrigen(
        data.map((element) => {
          return {
            value: element.id,
            label: element.nameOrigen,
          };
        })
      );
    });

    // get loading Port and uploadin Port to select
    getPortCode().then((data) => {
      setPortUnLoading(
        data.map((element) => {
          return {
            value: element.portId,
            label: element.name,
          };
        })
      );

      setPortLoading(
        data.map((element) => {
          return {
            value: element.portId,
            label: element.name,
          };
        })
      );
    });

    // get destination to select
    showAduana().then((data) => {
      setDestination(
        data.map((element) => {
          return {
            value: element.id,
            label: element.nameAduana,
          };
        })
      );
    });

    // get container type to select
    getContainer().then((data) => {
      setContainerType(
        data.map((element) => {
          return {
            value: element.containerId,
            label: element.nameContainer,
          };
        })
      );
    });

    // get transportation mode to select
    getModeTransportation().then((data) => {
      setTransportationMode(
        data.map((element) => {
          return {
            value: element.modeTransportationId,
            label: element.name,
          };
        })
      );
    });

    if (!quotationChargesList.some(e => e.description === KEY_OCEAN_FREIGHT_DESCRIPTION)) {
      quotationChargesList.push({
        paymentTerms: '',
        description: KEY_OCEAN_FREIGHT_DESCRIPTION,
        quantity: 1,
        price: 0,
      });
    }
  }, []);

  form.setFieldsValue({
    userName: user?.fullName,
    // Direccion
    local: user?.ubication,
    originCoti: user?.address,
    codigo: id ? id : `MT-Q-${codigo}`,
  });

  const columns = [
    {
      title: "Quantity",
      dataIndex: "pcs",
      key: "pcs",
    },
    {
      title: "Package Type",
      dataIndex: "packageType",
      key: "packageType",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    // add column to option delete
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
              type="primary"
              shape="circle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "red",
              }}
              onClick={() => deleteDetail(record)}
            >
              {/* icon trash */}
              <DeleteFilled style={{fontSize: 17}} />
          </Button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    formModal.setFieldValue('pcs', 1);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelCustomer = () => {
    setCustomerModalOpen(false);
  };

  const handleSaveCustomer = (customer) => {
    setCustomerModalOpen(false);
    setSelectedCustomer(customer);
    form.setFieldsValue({ idCliente: customer.id });
  };

  const handleSavePortCode = (portCode) => {
    setPortCodeModalOpen(false);
    const unloadingPorts = [...portUnLoading];

    unloadingPorts.push({
      value: portCode.portId,
      label: `${portCode.name}, ${portCode.country}`
    });

    setPortUnLoading([...unloadingPorts]);
    form.setFieldsValue({ idPortUnloading: portCode.portId });
  };

  const calculateTotalQuotation = (detailList, chargeList) => {
    const total = chargeList.reduce((a, b) => a + +(b.quantity ?? "0") * +(b.price ?? "0"), 0);

    setTotal(total);
    form.setFieldValue('total', total);
  }

  const deleteDetail = async (item) => {
    if (idCotizacion) {
      setIsLoading(true);
      await deleteQuotationDetail(item.id);
      setIsLoading(false);
    }

    // delete dataModal
    const newData = quotationDetailList.filter((element) => element.key !== item.key);
    setQuotationDetailList(newData);

    // show toast success delete
    successMessage("Se elimino correctamente");
  }

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      // get data to edit
      getQuotationById(id)
        .then((data) => {
          // set idCotizacion
          setIdCotizacion(data.idCotizacion);

          // set values to form
          form.setFieldsValue({
            idCotizacion: data.idCotizacion,
            idCliente: data.idCliente,
            idOrigen: data.idOrigen,
            idAduana: data.idAduana,
            idPortLoading: data.idPortLoading,
            idPortUnloading: data.idPortUnloading,
            idContainer: data.idContainer,
            modeTransp: data.modeTransp,
            commodity: data.commodity,
            transitTime: data.transitTime,
            carrier: data.carrier,
            paymentOF: data.paymentOF,
            paymentD: data.paymentD,
            paymentCD: data.paymentCD,
            paymentCL: data.paymentCL,
            qtyOF: data.qtyOF,
            qtyD: data.qtyD,
            qtyCD: data.qtyCD,
            qtyCL: data.qtyCL,
            priceOF: data.priceOF,
            priceD: data.priceD,
            priceCD: data.priceCD,
            priceCL: data.priceCL,
            amountOF: data.amountOF,
            amountD: data.amountD,
            amountCD: data.amountCD,
            amountCL: data.amountCL,
            total: data.total,
            notes: data.notes,
          });

          setSelectedCustomer(data.clientes);
          calculateTotalQuotation(data.detailCotizaciones, data.cotizacionCharges);

          setQuotationDetailList(
            data.detailCotizaciones.map((element, index) => {
              return { ...element, id: element.idDetalleCot, key: index };
            })
          );

          // find ocean freight
          const oceanFreight = data.cotizacionCharges.find(e => e.description === KEY_OCEAN_FREIGHT_DESCRIPTION);
          if (oceanFreight) {
            setIdOceanFreight(oceanFreight.id);
          }

          const list = data.cotizacionCharges
            .map((item, index) => {
              return { ...item, key: index }
            });

          setQuotationChargesList(list);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      <Spinner loading={isLoading}>
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
             {
              id ? (
                <h4>Editar cotización</h4>
              ) : (
                <h4>Registro de cotización</h4>
              )
             } 
            <Button type="primary" onClick={() => navigate("/quotation/list")}>
              Lista de cotizaciones
            </Button>
          </Col>
        </Row>

        <hr />
        {/* Form ant design to save the quote that is adaptable for any device */}
        <div style={contentStyle}>
          <Form
            layout={"vertical"}
            form={form}
            initialValues={{
              layout: "vertical",
              remember: true,
            }}
            style={{
              padding: 20,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            {/* HEADER */}
            <Row gutter={[16, 24]}>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Form.Item label="Codigo:" name="codigo">
                  <Input
                    disabled
                    style={{
                      color: "#001529",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  />
                </Form.Item>

                <Form.Item label="Local:" name="local">
                  <Input
                    placeholder="Local"
                    disabled
                    style={{
                      color: "#001529",
                      textAlign: "center",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Form.Item label="User:" name="userName">
                  <Input
                    placeholder="User Name"
                    disabled
                    style={{
                      color: "#001529",
                      textAlign: "center",
                    }}
                  />
                </Form.Item>

                <Form.Item label="Origin:" name="originCoti">
                  <Input
                    placeholder="Origin"
                    disabled
                    style={{
                      color: "#001529",
                      textAlign: "center",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* CUSTOMER */}
            <Row gutter={[16, 24]}>
              <Col
                className="gutter-row mb-3"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <p className="mb-0">Customer</p>
                  <Button type="primary"
                          onClick={() => setCustomerModalOpen(true)}>New Customer</Button>
                </div>
                <InputSearchCustomer selectedCustomer={selectedCustomer}
                                     onSelectedCustomer={customer => setSelectedCustomer(customer)}/>
              </Col>
            </Row>

            {/* Shipment Information */}

            <Row gutter={[16, 24]}>
              <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label="Origin:"
                  name="idOrigen"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el origen",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Selecione un Origen"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={origen}
                  />
                </Form.Item>

                <Form.Item
                  label="Destination:"
                  name="idAduana"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el destino",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Selecione una aduana de destino"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={destination}
                  />
                </Form.Item>

                <Form.Item
                  label="Commodity:"
                  name="commodity"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el commodity",
                    },
                  ]}
                >
                  <Input placeholder="Commodity" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label="Loading Port:"
                  name="idPortLoading"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el puerto de carga",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Selecione el puerto de carga"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={portLoading}
                  />
                </Form.Item>

                <Form.Item
                  label="Container Type:"
                  name="idContainer"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el tipo de contenedor",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Selecione el puerto de carga"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={containerType}
                  />
                </Form.Item>

                <Form.Item
                  label="Transit Time:"
                  name="transitTime"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el tiempo de transito",
                    },
                  ]}
                >
                  <Input placeholder="Transit Time" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <div className="flex-grow-1">
                    <Form.Item
                      label="Unloading Port:"
                      name="idPortUnloading"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el puerto de descarga",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Selecione el puerto de descarga"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={portUnLoading}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex-grow-0">
                    <Button type="primary"
                            onClick={() => setPortCodeModalOpen(true)}>New</Button>
                  </div>
                </div>

                <Form.Item
                  label="Transportation Mode:"
                  name="modeTransp"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el modo de transporte",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Selecione el modo de transporte"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={transportationMode}
                  />
                </Form.Item>

                <Form.Item
                  label="Carrier:"
                  name="carrier"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el carrier",
                    },
                  ]}
                >
                  <Input placeholder="Carrier" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Notes:" name="notes">
                  <TextArea placeholder="Notes" />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <Button type="primary" style={{backgroundColor: "", color: "white", marginBottom: 10}}
                        block
                        onClick={showModal}>
                  Agregar Descripciones
                </Button>

                {/* add table ant design whit three columns where el headers is Pcs, Package Type, Description */}

                <Table
                  columns={columns}
                  dataSource={quotationDetailList}
                  pagination={false}
                />
              </Col>
            </Row>
            <br />
            <QuotationChargesForm idCotizacion={idCotizacion}
                                  quotationChargesList={quotationChargesList}
                                  onChangeQuotationChargesList={list => {
                                    setQuotationChargesList(list);
                                    calculateTotalQuotation(quotationDetailList, list);
                                  }}/>
            <Row gutter={[16, 24]}>
              <Col className="gutter-row" xs={24} sm={24} md={20} lg={20} xl={20}></Col>
              <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                <Form.Item name="total" label="Total" initialValue={0}>
                  <Input disabled prefix={<DollarCircleOutlined />} />
                </Form.Item>
              </Col>
            </Row>
            <br />

            <Row gutter={[16, 24]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {
                    // if id is null then show button save else show button update
                    id ? (
                      <Button
                        block
                        style={{ backgroundColor: "green", color: "white" }}
                        htmlType="submit"
                      >
                        Actualizar{" "}
                      </Button>
                    ) : (
                      <Button
                        block
                        style={{ backgroundColor: "green", color: "white" }}
                        htmlType="submit"
                      >
                        Guardar{" "}
                      </Button>
                    )
                  }
                </Space>
              </Col>
            </Row>
          </Form>

          <Modal
            title="Cargo Information"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
          >
            <Form
              layout={"vertical"}
              form={formModal}
              initialValues={{ layout: "vertical", remember: true }}
              style={{ padding: 20 }}
              onFinish={onFinishModal}
              onFinishFailed={onFinishFailedModal}
              autoComplete="off"
            >
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label="Package Type:"
                    name="packageType"
                  >
                    <Select
                      showSearch
                      placeholder="Selecione un tipo"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      options={[
                        { value: "Vehiculo",    label: "Vehículo" },
                        { value: "Contenedor",  label: "Contenedor" },
                        { value: "Paquete",     label: "Paquete" },
                        { value: "Repuestos",   label: "Repuestos" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Description:"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese la descripcion",
                      },
                    ]}
                  >
                    <Input placeholder="Description" />
                  </Form.Item>
                  <Form.Item
                    label="Quantity:"
                    name="pcs"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese la cantidad",
                      },
                    ]}
                  >
                    <Input placeholder="Pcs" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {quotationDetailList.length < 6 && (
                      <Button
                        block
                        style={{ backgroundColor: "green", color: "white" }}
                        htmlType="submit"
                      >
                        Agregar
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            open={isCustomerModalOpen}
            closable={false}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
          >
            <ClienteForm shortSaved={true} submitCliente={handleSaveCustomer} onCancel={handleCancelCustomer} />
          </Modal>

          <PortCodeForm open={isPortCodeModalOpen}
                        forDestination={true}
                        onSave={handleSavePortCode}
                        onCancel={() => setPortCodeModalOpen(false)} />
        </div>
      </Spinner>
    </>
  );
};

export default QuotationForm;
