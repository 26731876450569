import React, { useState, useEffect } from 'react';
import {faEye, faLeftLong, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import '../hojas-de-estilo/style.css';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { viewTransp } from '../Servicios/ComponedoresService';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {notifyCustomerCoordinacion, viewCoordi} from '../Servicios/CoordService';
import { permisos } from "../utils/permisos";
import { validarPermisos } from "../Servicios/AuthService";
import moment from 'moment';
import { Descriptions } from "antd";
import LoadingInside from '../Componentes/LoadingInside';
import {useNavigate, useParams} from "react-router-dom";
import {CoordinacionNotificarCliente} from "../Resources/HtmlEmailBody/CoordinacionNotificarCliente";
import SendEmail from "../utils/email";
import {CoordinacionNotasImportantes} from "../Resources/PdfBase64/CoordinacionNotasImportantes";
import environment from "../environments/environment";
import useCurrentUser from "../Hooks/UserHook";

function VisualizarCoor() {
  const { id } = useParams();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const [viewCoor, setViewCoor] = useState(null);
  const [transporte, setTransporte] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);

  const puedeModificarCoordinacion = validarPermisos(permisos.MODIFICAR_COORDINACION);

  useEffect(() => {
    init();
  }, [id]);

  const init = () => {
    if (id) {
      setIsLoading(true);

      viewCoordi(id).then((coor) => {
        setIsLoading(false);
        setViewCoor(coor);
        console.log(coor , 'coor' );

        if (coor.transporteId) {
          viewTransp(coor.transporteId).then((trans) => {
            setTransporte(trans);
          });
        }
      });
    }
  }

  const handleShowEmailModal = () => {
    setShowSendEmail(true);
  }

  const handleSendEmail = async () => {
    setIsLoading(true);
    await notifyCustomerCoordinacion(viewCoor.id);
    setIsLoading(false);

    setShowSendEmail(undefined);
    init();
  }

  if (isLoading) {
    return (
      <LoadingInside />
    )
  }

  return (
    <>
      {
        showSendEmail ?
          <SendEmail visible={true}
                     subject={viewCoor.subastaId === 571 ? `Confirmación de entrega en bodega ${viewCoor.origen.state}${viewCoor.cdealer ? ' **Cuenta Dealer**' : ''} Lote: ${viewCoor.lote}`.toUpperCase() :`Confirmación de solicitud de Recolección ${viewCoor.origen.state}${viewCoor.cdealer ? ' **Cuenta Dealer**' : ''} Lote: ${viewCoor.lote}. Subasta: ${viewCoor.subs}. Destino: ${viewCoor.aduanaName}`.toUpperCase()}
                     titulo={'Enviar correo electrónico'}
                     to={viewCoor.coDos && viewCoor.coDos !== ""
                       ? viewCoor.emailCli && viewCoor.emailCli !== ""
                         ? [...viewCoor.emailCli.replace(/\s/, "").split(","), ...viewCoor.coDos]
                         : [viewCoor.coDos]
                       : viewCoor.emailCli && viewCoor.emailCli !== ""
                         ? [...viewCoor.emailCli.replace(/\s/, "").split(",")]
                         : []}
                     cc={currentUser && currentUser.email
                       ? [environment.emails.grua, currentUser.email]
                       : [environment.emails.grua]}
                     body={CoordinacionNotificarCliente(viewCoor)}
                     attachments={[
                       { nombreArchivo: 'notas_importantes.pdf', archivo: CoordinacionNotasImportantes }
                     ]}
                     setVisible={() => setShowSendEmail(false)}
                     showMessageInput={false}
                     isLoading={false}
                     doSendEmail={handleSendEmail} /> : <></>
      }

      <Container>
        <Row>
          <h4>Coordinación de {viewCoor?.import}</h4>
        </Row>
        <hr />
        <h5>Fecha de ingreso a Domestic: {moment(viewCoor?.fechacor).format('ll')}</h5>
        <strong>Información general</strong>
        <Descriptions
          layout="horizontal"
          size="small"
          bordered
        >
          <Descriptions.Item label="LOTE">
            <strong>{viewCoor?.lote}</strong>
          </Descriptions.Item>
          <Descriptions.Item label="VEHICULO">
            {viewCoor?.vehiculoName}
          </Descriptions.Item>
          <Descriptions.Item label="ORIGEN">
            {viewCoor?.origenName}
          </Descriptions.Item>
          <Descriptions.Item label="VIN">
            {viewCoor?.vin}
          </Descriptions.Item>
          <Descriptions.Item label="COLOR">
            {viewCoor?.color}
          </Descriptions.Item>
          <Descriptions.Item label="DESTINO">
            {viewCoor?.aduanaName} - {viewCoor?.countryName}
          </Descriptions.Item>
          <Descriptions.Item label="BUYER">
            {viewCoor?.buyer}
          </Descriptions.Item>
          <Descriptions.Item label="GATEPASS">
            {viewCoor?.gatePassPin}
          </Descriptions.Item>
          <Descriptions.Item label="DEALER">
            {viewCoor?.cdealer ? 'SI' : 'NO'}
          </Descriptions.Item>
          <Descriptions.Item label="SUBASTA" span={3}>
            {viewCoor?.ubicacion}
          </Descriptions.Item>
          <Descriptions.Item label="IMPORTADORA" span={3}>
            {viewCoor?.importadoraName}
          </Descriptions.Item>
          <Descriptions.Item label="CLIENTE" span={3}>
            {viewCoor?.clienteName}
          </Descriptions.Item>
          <Descriptions.Item label="TIPO INGRESO">
            {viewCoor?.entryType.toUpperCase()}
          </Descriptions.Item>
        </Descriptions>
        <hr />
        <Row>
          <Col xs={6} md={6}>
            <strong>Información de pago</strong>
            <Descriptions
              layout="vertical"
              size="small"
              bordered
            >
              <Descriptions.Item label="TIPO DE PAGO">
                {viewCoor?.type_pay}
              </Descriptions.Item>
              <Descriptions.Item label="FLETE">
                $ {viewCoor?.flete}
              </Descriptions.Item>
              <Descriptions.Item label="GRUA COTIZADA">
                $ {viewCoor?.rate_Quoted}
              </Descriptions.Item>
              <Descriptions.Item label="BL">
                $ {viewCoor?.bl}
              </Descriptions.Item>
              <Descriptions.Item label="COST. ADD">
                $ {viewCoor?.costAdditional}
              </Descriptions.Item>
              <Descriptions.Item label="TOTAL">
                $ {viewCoor?.total}
              </Descriptions.Item>
            </Descriptions>
            <br />
          </Col>
          <Col xs={6} md={6}>
            <br />
            <Descriptions
              layout="vertical"
              size="small"
              bordered
            >
              <Descriptions.Item label="FECHA DE PAGO">
                {viewCoor?.paid ? moment(viewCoor?.paid).format('l') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="COSTO GRUA">
                $ {viewCoor?.cost}
              </Descriptions.Item>
              <Descriptions.Item label="PROFIT GRUA">
                $ {viewCoor?.profit}
              </Descriptions.Item>
              <Descriptions.Item label="ESTADO DE COORDINACIÓN">
                {viewCoor?.state}
              </Descriptions.Item>
            </Descriptions>
            <br />
          </Col>
          <hr />
        </Row>
        <Row>
          <Col xs={6} md={6}>
            <strong>Fechas y Storage</strong>
            <Descriptions
              layout="vertical"
              size="small"
              bordered
            >
              <Descriptions.Item label="PRIMER DIA DISPONIBLE">
                {viewCoor?.firstBusDay ? moment(viewCoor?.firstBusDay).format('l') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="EST. PICK UP">
                {viewCoor?.estPickUp ? moment(viewCoor?.estPickUp).format('l') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="EST. DELIVERY">
                {viewCoor?.estDelivery ? moment(viewCoor?.estDelivery).format('l') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="ULTIMO DIA DISPONIBLE">
                {viewCoor?.lastFreeDay ? moment(viewCoor?.lastFreeDay).format('l') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="ACT. PICK UP">
                {viewCoor?.actPickUp ? moment(viewCoor?.actPickUp).format('l') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="ACT. DELIVERY">
                {viewCoor?.actDelivery ? moment(viewCoor?.actDelivery).format('l') : '-'}
              </Descriptions.Item>
            </Descriptions>
            <br />
          </Col>
          <Col xs={6} md={6}>
            <br />
            <Descriptions
              layout="vertical"
              size="small"
              bordered
            >
              <Descriptions.Item label="DEPARTING">
                {viewCoor?.estArrival ? moment(viewCoor?.estArrival).format('l') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="ARRIVAL">
                {viewCoor?.actArrival ? moment(viewCoor?.actArrival).format('l') : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="STORAGE CLIENT">
                $ {viewCoor?.st_Cliente}
              </Descriptions.Item>
              <Descriptions.Item label="STORAGE DRIVER">
                $ {viewCoor?.st_Driver}
              </Descriptions.Item>
              <Descriptions.Item label="STORAGE MATUS">
                $ {viewCoor?.st_Matus}
              </Descriptions.Item>
              <Descriptions.Item label="STORAGE PAGADO">
                $ {viewCoor?.st_Paid}
              </Descriptions.Item>
            </Descriptions>
            <br />
          </Col>
          <hr />
        </Row>
        <Row>
          <strong>Información transportista</strong>
          <Descriptions
            layout="vertical"
            size="small"
            bordered
          >
            <Descriptions.Item label="COMPAÑIA DE TRANSPORTE">
              {transporte?.nomComp}
            </Descriptions.Item>
            <Descriptions.Item label="CONDUCTOR">
              {transporte?.contacto}
            </Descriptions.Item>
            <Descriptions.Item label="NUMERO">
              {transporte?.telefono}
            </Descriptions.Item>
          </Descriptions>
          <Col><br /></Col>
        </Row>
        <Row>
          <Col>
            <FloatingLabel controlId="floatingTextarea2" label="Comentario">
              <Form.Control
                disabled readOnly
                name='nota'
                value={viewCoor?.nota}
                placeholder="Leave a comment here"
                style={{ height: '100px' }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <hr />
        <Row className='conte-padre'>
          <Col className="d-flex align-items-center justify-content-center gap-2">
            <Button
              type='button'
              onClick={() => navigate(`/coordinations/country/${viewCoor.origenId}`)}
              variant="warning">
              <FontAwesomeIcon icon={faLeftLong} /> Regresar
            </Button>
            {
              puedeModificarCoordinacion &&
              <Button
                type='button'
                onClick={() => navigate(`/coordinations/${viewCoor.id}/edit`)}
                variant="success">
                <FontAwesomeIcon icon={faEye} /> Editar
              </Button>
            }
            <Button
              type='button'
              onClick={() => handleShowEmailModal()}
              variant="primary">
              <FontAwesomeIcon icon={faPaperPlane} /> Enviar Correo
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default VisualizarCoor;
