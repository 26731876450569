import React, {useEffect, useState} from "react";
import {Col, Row, Tabs} from "antd";
import CoordinacionSinTituloTable from "./CoordinacionSinTituloTable";
import {showCoord} from "../../Servicios/CoordService";
import {hasRol} from "../../Servicios/AuthService";
import CoordinacionSinTituloFilter from "./CoordinacionSinTituloFilter";
import moment from "moment/moment";
import roles from "../../utils/roles";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import {updateVehiculoTituloEstado} from "../../Servicios/VehicleService";

const XLSX = require("xlsx");

interface CoordinacionFilters {
  origen: string;
  pageCount: number;
  currentPage: number;
  vehiculoTituloEstado: string;
  search: string;
  aduanaId: number;
  year: number;
  month: number;
  day: Date | undefined;
  transporteId: number;
  countriesId: string;
  currentTab: string;
  subastaId: number;
  vendedorId: number;
}

const localStorageFilter = (key = undefined, isDate = false) => {
  if (!localStorage.getItem('coordinacionRecibidosSinTitulo:filter')) return undefined;
  if (!key) return JSON.parse(localStorage.getItem('coordinacionRecibidosSinTitulo:filter'));
  if (!JSON.parse(localStorage.getItem('coordinacionRecibidosSinTitulo:filter'))[key]) return undefined;

  if (isDate) {
    return new Date(JSON.parse(localStorage.getItem('coordinacionRecibidosSinTitulo:filter'))[key]);
  }

  return JSON.parse(localStorage.getItem('coordinacionRecibidosSinTitulo:filter'))[key];
}

const CoordinacionSinTituloPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);

  const [coordi, setCoordi] = useState([]);

  const [coordinacionFilters, setCoordinacionFilters] = useState({
    origen: localStorageFilter('origen') ?? '1',
    pageCount: localStorageFilter('pageCount') ?? 100,
    currentPage: localStorageFilter('currentPage') ?? 1,
    vehiculoTituloEstado: localStorageFilter('vehiculoTituloEstado') ?? "",
    search: localStorageFilter('search') ?? "",
    aduanaId: localStorageFilter('aduanaId') ?? 0,
    year: localStorageFilter('year') ?? 0,
    month: localStorageFilter('month') ?? 0,
    day: localStorageFilter('day', true),
    transporteId: localStorageFilter('transporteId') ?? 0,
    countriesId: localStorageFilter('countriesId') ?? "",
    currentTab: localStorageFilter('currentTab') ?? "0",
    subastaId: localStorageFilter('subastaId') ?? 0,
    vendedorId: localStorageFilter('vendedorId') ?? 0,
  });

  useEffect(() => {
    showCoordinaciones(coordinacionFilters).then();

    const filters = localStorageFilter();
    if (JSON.stringify(coordinacionFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('coordinacionRecibidosSinTitulo:filter', JSON.stringify(coordinacionFilters));
    }
  }, [coordinacionFilters]);

  const showCoordinaciones = async (filters: CoordinacionFilters) => {
    let origenId = filters.origen;
    if (hasRol(roles.FLOFFICE)) origenId = '1';
    else if (hasRol(roles.CAOFFICE)) origenId = '2';

    setIsLoading(true);
    const data = await showCoord(
      origenId,
      "",
      filters.year,
      filters.month,
      filters.currentPage,
      filters.pageCount,
      filters.search,
      "",
      filters.aduanaId,
      filters.day ? filters.day.getDate() : 0,
      0,
      0,
      0,
      0,
      filters.countriesId,
      filters.subastaId,
      0,
      filters.transporteId,
      1,
      1,
      filters.vehiculoTituloEstado,
      +filters.currentTab,
      filters.vendedorId
    );

    setIsLoading(false);

    if (data.status) {
      setCoordi(data.list);
      setTotalElements(data.totalElements);
    }
  };

  const exportToExcel = async () => {
    setIsLoading(true);
    const data = await showCoord(
      coordinacionFilters.origen,
      coordinacionFilters.state,
      coordinacionFilters.year,
      coordinacionFilters.month,
      0,
      0,
      coordinacionFilters.search,
      "",
      coordinacionFilters.aduanaId,
      coordinacionFilters.day ? coordinacionFilters.day.getDate() : 0,
      0,
      0,
      0,
      0,
      coordinacionFilters.countriesId,
      coordinacionFilters.subastaId,
      0,
      0,
      1,
      1,
      coordinacionFilters.vehiculoTituloEstado,
      +coordinacionFilters.currentTab,
    );
    setIsLoading(false);

    if (data && data.status) {
      const list = data.list.map(e => {
        return {
          'Lote': e.lote,
          'Fecha': e.vehiculo && e.vehiculo.fecha ? moment(e.vehiculo.fecha).format('l') : '---',
          'Vehiculo': e.vehiculoName,
          '# Buyer': e.buyer,
          'Ubic./Subasta': e.subs,
          'Importadora': e.import,
          'Vendedor': e.vendedor,
          'Estado': e.vehiculo.tituloEstado ?? '---',
          'Destino': e.aduanaName,
          'Transporte': e.transporteName ?? 'No Asignado',
          '# Telefonico': e.transporte ? e.transporte.telefono : 'No Asignado',
        }
      });

      const worksheet = XLSX.utils.json_to_sheet(list);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Coordinaciones Sin Titulo");

      // Genera el archivo Excel
      XLSX.writeFile(workbook, "coordinaciones.xlsx", {
        bookType: "xlsx",
        type: "blob",
      });
    }
  }

  const items = [
    { key: '1', label: 'Florida' },
    { key: '2', label: 'California' }
  ];

  const handleChangeTab = tab => {
    setCoordinacionFilters({
      ...coordinacionFilters,
      origen: tab
    })
  };

  const titulo = () => {
    if (!coordinacionFilters.origen) return "";
    return coordinacionFilters.origen === '1' ? ' - Florida' : ' - California';
  }

  const handleUpdateVehiculoTituloEstado = async (input) => {
    setIsLoading(true);
    const data = await updateVehiculoTituloEstado(input.id, input);
    setIsLoading(false);

    if (data && data.status) {
      await showCoordinaciones(coordinacionFilters);
    }
  }

  return(
    <>
      <Row>
        <Col xs={24} md={24} lg={24} className="">
          <h5 className="mb-2">Historial de Coordinaciones Recibidas sin Título {titulo()}</h5>
          <ShowWhen show={!hasRol(roles.FLOFFICE, roles.CAOFFICE)}>
            <Tabs activeKey={coordinacionFilters.origen} items={items} onChange={handleChangeTab}></Tabs>
          </ShowWhen>
        </Col>
      </Row>
      <CoordinacionSinTituloFilter coordinacionFilters={coordinacionFilters}
                                   onFilter={filter => setCoordinacionFilters(filter)} />
      <CoordinacionSinTituloTable totalElements={totalElements}
                                  coordi={coordi}
                                  isLoading={isLoading}
                                  exportToExcel={exportToExcel}
                                  coordinacionFilters={coordinacionFilters}
                                  setCoordinacionFilters={filter => setCoordinacionFilters(filter)}
                                  onUpdateVehiculoTituloEstado={handleUpdateVehiculoTituloEstado} />
    </>
  );
}

export default CoordinacionSinTituloPage;
