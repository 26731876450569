import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faDownload, faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { showQuote } from "../Servicios/SolicitudesService";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import "styled-components";
import "../hojas-de-estilo/style.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import moment from "moment";
import SpinnerTable from "../Componentes/SpinnerTable";
import {DatePicker, Tabs} from "antd";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import useCurrentUser from "../Hooks/UserHook";
import {getCountriesId} from "../Servicios/AuthService";
import SelectYear from "../Componentes/SelectYear/SelectYear";
import SelectMonth from "../Componentes/SelectMonth/SelectMonth";
import dayjs from "dayjs";

const TablaSolicitudHistorial = () => {
  const currentUser = useCurrentUser();

  const [quote, setQuote] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterDate, setFilterDate] = useState(undefined);

  const [tab, setTab] = useState("1");
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const XLSX = require("xlsx");

  const showSolicitudes = async (currentPage, pageCount, search, tab, year, month, filterDate) => {
    const countriesId = getCountriesId();

    const currYear = filterDate ? filterDate.getFullYear() : year;
    const currMonth = filterDate ? (filterDate.getMonth() + 1) : month;
    const day = filterDate ? filterDate.getDate() : 0;

    setIsLoading(true);
    const data = await showQuote(day, currMonth, currYear, currentPage, pageCount, search, "", "", 0, countriesId, tab === "1", tab === "2");
    setIsLoading(false);

    if (data.status) {
      setQuote(data.list);
      setTotalElements(data.totalElements);
    }
  };

  useEffect(() => {
    if (currentUser) {
      showSolicitudes(currentPage, pageCount, filterText, tab, year, month, filterDate).then();
    }
  }, [currentUser]);

  // Obtener el año actual
  const anioActual = new Date().getFullYear();

  // Obtener una lista de años desde un año antes del actual hasta un año después
  const aniosPosibles = Array.from(
    { length: 3 }, // Cambia el número si deseas más años hacia el futuro
    (_, index) => anioActual - 1 + index
  );

  // Si el año actual no está en la lista, agrégalo
  if (!aniosPosibles.includes(anioActual)) {
    aniosPosibles.push(anioActual);
  }

  const columnasPendientesCompletar = [
    {
      name: "Ver",
      width: "70px",
      cell: (row) => (
        <Button
          as={Link}
          to={`/sendprintRequest/${row?.quoteId}`}
          variant="success"
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ),
    },
    {
      name: "Fecha",
      selector: (row) => row.fechaQ,
      format: (row) => moment(row?.fechaQ).format("l"),
      width: "93px",
    },
    {
      name: "Lote",
      sortable: true,
      selector: (row) => row?.lote,
      width: "100px",
    },
    {
      name: "Vehiculo",
      sortable: true,
      selector: (row) => row?.vehiculoName,
      width: "200px",
    },
    {
      name: "Cliente",
      sortable: true,
      selector: (row) => row?.clienteName,
      width: "270px",
    },
    {
      name: "Importadora",
      sortable: true,
      selector: (row) => row?.import,
      width: "300px",
    },
    {
      name: "Origen",
      sortable: true,
      selector: (row) => row?.nameOrigen,
      width: "110px",
    },
    {
      name: "Destino",
      sortable: true,
      selector: (row) => row?.aduanaName,
      width: "150px",
    },
    {
      name: "Asesor",
      sortable: true,
      selector: (row) => row?.creador,
      width: "150px",
    },
  ];

  const columnasEnRevision = [
    {
      name: "Ver",
      width: "70px",
      cell: (row) => (
        <Button
          as={Link}
          to={`/sendprintRequest/${row?.quoteId}`}
          variant="success"
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ),
    },
    {
      name: "Fecha",
      selector: (row) => row.fechaQ,
      format: (row) => moment(row?.fechaQ).format("l"),
      width: "93px",
    },
    {
      name: "Lote",
      sortable: true,
      selector: (row) => row?.lote,
      width: "90px",
    },
    {
      name: "Vehiculo",
      sortable: true,
      selector: (row) => row?.vehiculoName,
      width: "200px",
    },
    {
      name: "Cliente",
      sortable: true,
      selector: (row) => row?.clienteName,
      width: "250px",
    },
    {
      name: "Motivo",
      sortable: true,
      selector: (row) => row?.proceso,
      width: "350px",
    },
    {
      name: "Importadora",
      sortable: true,
      selector: (row) => row?.import,
      width: "300px",
    },
    {
      name: "Origen",
      sortable: true,
      selector: (row) => row?.nameOrigen,
      width: "110px",
    },
    {
      name: "Destino",
      sortable: true,
      selector: (row) => row?.aduanaName,
      width: "150px",
    },
    {
      name: "Asesor",
      sortable: true,
      selector: (row) => row?.creador,
      width: "150px",
    },
  ];

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(quote);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Solicitudes");
    // Genera el archivo Excel
    XLSX.writeFile(workbook, "solicitudes.xlsx", {
      bookType: "xlsx",
      type: "blob",
    });
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);

    if (currentUser) {
      showSolicitudes(newPage, pageCount, filterText, tab, year, month, filterDate).then();
    }
  };

  const handlePerRowsChange = (newPerPage) => {
    setPageCount(newPerPage);
    setCurrentPage(1);

    if (currentUser) {
      showSolicitudes(1, newPerPage, filterText, tab, year, month, filterDate).then();
    }
  };

  const handleFilterText = (value) => {
    setFilterText(value);

    if (currentUser) {
      showSolicitudes(currentPage, pageCount, value, tab, year, month, filterDate).then();
    }
  }

  const items = [
    {
      key: '1',
      label: 'Solicitudes Enviadas a Revisión',
      children: (<>
        <DataTable
          columns={columnasEnRevision}
          data={quote}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable />}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    },
    {
      key: '2',
      label: 'Solicitudes Pendientes de Completar',
      children: (<>
        <DataTable
          columns={columnasPendientesCompletar}
          data={quote}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={<SpinnerTable />}
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={totalElements}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </>),
    }
  ];

  const handleTabChange = (key) => {
    setTab(key);
    setCurrentPage(1);

    showSolicitudes(1, pageCount, filterText, key, year, month, filterDate).then();
  }

  const getSelectedDateFormat = () => {
    if (filterDate) return moment(filterDate).format("yyyy-MM-DD");
    return "";
  };

  const handleChangeDate = (evt) => {
    const date = evt ? evt.$d : undefined;

    if (!date || date === "") {
      showSolicitudes(currentPage, pageCount, filterText, tab, year, month, undefined).then();
      setFilterDate(undefined);
      setYear(new Date().getFullYear());
      setMonth(new Date().getMonth() + 1);
    } else {
      showSolicitudes(currentPage, pageCount, filterText, tab, year, month, date).then();
      setFilterDate(date);
      setYear(date.getFullYear());
      setMonth(date.getMonth() + 1);
    }
  };

  const handleChangeYearMonth = (year, month) => {
    setYear(year);
    setMonth(month);

    showSolicitudes(currentPage, pageCount, filterText, tab, year, month, filterDate).then();
  }

  return (
    <Container fluid={true} className="solicitudes">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="m-0">Solicitudes realizadas</h4>

        <Button size="sm" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faDownload}/> Exportar a Excel
        </Button>
      </div>
      <hr/>
      <Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Año</Form.Label>
          </div>
          <SelectYear year={year} selectYear={year => handleChangeYearMonth(year, month)} />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Mes</Form.Label>
          </div>
          <SelectMonth month={month} selectMonth={month => handleChangeYearMonth(year, month)} />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={3}>
          <div className="d-flex align-items-start justify-content-between">
            <Form.Label>Filtrar por Fecha</Form.Label>
            {
              getSelectedDateFormat() !== "" && <div className="text-danger me-1 cursor-pointer"
                                                     onClick={() => handleChangeDate(undefined)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            }
          </div>
          <DatePicker className="w-100"
                      value={filterDate ? dayjs(filterDate) : ''}
                      onChange={handleChangeDate} />
        </Col>
        <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Label className="input-buscar">
            Buscar Solicitud
          </Form.Label>
          <InputSearch placeholder={'Buscar Solicitud por Lote / Marca / Modelo / Importadora'}
                       doChange={handleFilterText}/>
        </Col>
      </Row>
      <br/>
      <h5 className="m-0">Total: {totalElements} {totalElements === 1 ? 'solicitud' : 'solicitudes'}</h5>
      <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange}/>
    </Container>
  );
};

export default TablaSolicitudHistorial;
