import {Select} from "antd";
import React, {useEffect, useState} from "react";

const SelectMonth = ({ month, selectMonth }) => {
  const [monthsPerYear, setMonthsPerYear] = useState([]);

  useEffect(() => {
    initMonthsPerYear();
  }, []);

  const obtenerNombreMes = (mes) => {
    const nombresMeses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return nombresMeses[mes - 1];
  };

  const initMonthsPerYear = () => {
    const meses = Array.from({length: 12}, (_, index) => ({
      value: index + 1,
      label: obtenerNombreMes(index + 1),
    }));

    setMonthsPerYear(meses);
  }

  return(
    <Select value={month}
            className="w-100"
            onChange={value => selectMonth(value)}
            options={monthsPerYear}/>
  );
}

export default SelectMonth;
