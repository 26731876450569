export const VehiculoTituloEstadoEnum = {
    subLoteGestionarEnvio: 'Sub Lote - Gestionar Envio',
    otraDireccionGestionarEnvio: 'En otra Direccion - Gestionar Envio',
    pendienteInstrucciones: 'Pendientes de Instrucciones',
    trackingListo: 'Tracking Listo',
    duplicadoEnProceso: 'Duplicado en Proceso',
    tituloConDriver: 'Titulo con Driver',
    recibidoSinTitulo: 'Recibido sin Titulo',
    tituloRecibido: 'Titulo Recibido',
    tituloEnCalifornia: 'Titulo en California',
    billOfSale: 'BILL OF SALE',
    fedexToday: 'FEDEX TODAY',
    tituloDealer: 'TITULO DEALER',
    dmvTituloPendiente: 'DMV - Titulo Pendiente',
}
