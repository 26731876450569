import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import '../hojas-de-estilo/login.css';
import Button from 'react-bootstrap/Button';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import queryString from 'query-string';
import { changePass } from '../Servicios/AuthService';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import campass from '../image/campass.png';

const CambiarPass = (props) => {

    const location = useLocation();
    const [ctx, setCtx] = useState(queryString.parse(location.search).ctx);

    console.log("este es CTX",ctx);
    const [pass, setPass] = useState();
    const [confPass, setConfPass] = useState();
    const [error, setError] = useState('');
    const [message, setMessage] = useState("");



    const submit = (e) => {
        changePass(ctx, pass, confPass).then(() => {
            if (pass !== confPass) {
                e.preventDefault();
                setMessage("Las contraseñas no coinciden");
                setTimeout(() => {
                    setMessage(" ");
                }, 2200);
            }else {
                setMessage("Su contraseña se ha actualizado correctamente, inicie sesión nuevamente");
            }
            
          }).catch(error => {
            console.log(error.message);
            setMessage(error.message);
          });
        
    }

  return (
    <Container className='modal-dialog text-center bodylogin'>
        <Row className="justify-content-md-center modal-content">
            <Col className='resetpassword' xs lg="4">
            <br/>
            <img src={campass} />
            <h4>Cambiar Contraseña</h4>
            <Form.Control type="password" 
            name="pass" 
            id="email_field" 
            className="form-control input-block" 
            autofocus="autofocus" 
            onChange={(event) => setPass(event.target.value)}
            placeholder="Nueva Contraseña"/>
            <br/>
            <Form.Control type="password" 
            name="passconf" 
            id="email_field" 
            className="form-control input-block" 
            autofocus="autofocus" 
            onChange={(event) => setConfPass(event.target.value)}
            placeholder="Confirmar Contraseña"/>
            <br/>
            <p className='text-danger mt-3'>
                  { error }
            </p>
            {
              message != "" ? <p>{message}</p> : ''
            }
            <Row style={{justifyContent: "center"}}>
            <Button type="submit" onClick={submit} style={{width: "200px"}}>Cambiar</Button>
            </Row>
            <hr/>
            <Row style={{justifyContent: "right"}}>
                <Button as={Link} to='/' variant="success" onClick={submit} style={{width: "200px"}}><FontAwesomeIcon icon={faRightToBracket}/> Iniciar Sesión</Button>
            </Row>
            </Col>
        </Row>
    </Container>
    );
}

export default CambiarPass;
