import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    allWareHouseAduana: [],
    selectedWareHouse: []
}

export const wareHouseSlice = createSlice({
    name: 'wareHouse',
    initialState,
    reducers: {
        getAllWareHouse: ( state, { payload } ) => {
             state.allWareHouseAduana =  payload;
        },
        getSelectedWareHouse: (state, { payload }) => {
            state.selectedWareHouse = [...state.selectedWareHouse, payload];
        },
        loading: ( state, { payload } ) => {
            state.loading = payload;
        },

        deleteWareHouse: ( state, {payload}) => {
            state.selectedWareHouse = payload;
        }, 

        deleteAllWareHouse: (state, {payload}) => {
            state.selectedWareHouse = payload;
        }
    }
});


export const { getAllWareHouse, getSelectedWareHouse, deleteWareHouse, deleteAllWareHouse, loading } = wareHouseSlice.actions;
