import { server } from "../environments/environment";
import { ApiService } from "./ApiService";

export const getVehiculos = async (origen) => {
  const response = await ApiService.get(
    `${server}Vehiculo/OrdenOnlyFecha?origen=${Number(origen)}`
  );
  const { list } = await response.json();
  return list;
};

export const getUpdateInspeccion = async (id) => {
  const response = await ApiService.put(
    `${server}Vehiculo/updateInspeccion/${id}`
  );
  console.log(response, "response updateInspeccion");
  const { list } = await response.json();
  return list;
};

export const redirectGoogleUrl = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URL_API_NODE_GOOGLE}/auth/google/url`
    );
    const data = await response.json();

    // Redirect to Google authentication
    // window.location.href = data.url;

     const width = Math.min(window.innerWidth * 0.8, 500); 
     const height = Math.min(window.innerHeight * 0.8, 600); 
 
     const left = (window.innerWidth / 2) - (width / 2) + window.screenX;
     const top = (window.innerHeight / 2) - (height / 2) + window.screenY;
 
     const authWindow = window.open(
       data.url,
       '_blank',
       `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,resizable=yes`
     );
 
     if (authWindow) {
       authWindow.focus();
     }
 
     const pollTimer = window.setInterval(() => {
       if (authWindow.closed) {
         window.clearInterval(pollTimer);
         console.log("Authentication window closed");
       }
     }, 1000);

  } catch (error) {
    console.error("Error logging in:", error);
  }
};




export const refreshToken = async (refreshToken) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_URL_API_NODE_GOOGLE}/auth/google/refresh_token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    if (response.ok) {
      const newTokens = await response.json();
      return newTokens;
    } else {
      console.error("Failed to refresh token");
      return null;
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    return null;
  }
};
