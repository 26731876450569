import axios from 'axios';

export const getListAllClientsPreRegister = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL_API_NODE_GOOGLE}/pre_registro/listAllClientPreRegistro`);
    return response;
}
export const getaListAllVehiclePreRegister = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL_API_NODE_GOOGLE}/pre_registro/listAllVehiclePreRegistro`);
    return response;
}


export const getOneVehiclePreRegister = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_URL_API_NODE_GOOGLE}/pre_registro/listGetOneVehiclePreRegistro/${id}`);
    return response;
}


