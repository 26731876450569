import React, { useEffect, useState, useRef } from "react";
import Spinner from "../../shared/spinner";
import {
  Button,
  Space,
  Table,
  Tooltip,
  Row,
  Col,
  Modal,
  Form,
  Input,
} from "antd";
import { useNavigate } from "react-router-dom";
// add icon antd design
import { EyeFilled, EditFilled, MailFilled, PrinterFilled } from "@ant-design/icons";
import {
  getQuotation,
  getQuotationById,
  getPDFQuote,
} from "../../Servicios/QuotationService";
import { enviarEmail } from "../../Servicios/SolicitudesService";
import useCurrentUser from "../../Hooks/UserHook";
import QuoteToPrint from "./quoteToPrint";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { toast } from "react-toastify";
import { set } from "rsuite/esm/utils/dateUtils";
import getPdf from "../../Servicios/Base64ToPdf";

function formatFullName(name) {
  const formattedFirstName = name
    ? name.charAt(0).toUpperCase() +
      name.slice(1).toLowerCase() +
      name.slice(2).toLowerCase() +
      name.slice(3).toLowerCase()
    : "";

  return [formattedFirstName].filter(Boolean).join(" ");
}

const { TextArea } = Input;

const QuotationList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [data, setData] = useState([]);
  const [dataImprimir, setDataImprimir] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEmail, setIsModalOpenEmail] = useState(false);
  const currentUser = useCurrentUser();
  const [idCodigoImprimir, setIdCotizacionImprimir] = useState(null);
  const [idCotizacionEmail, setIdCotizacionEmail] = useState(null);
  // extramoos el codigo de la url para accionar la edicion de la cotizacion
  const [to, setTo] = useState("");
  const [toC, setToC] = useState([]);

  const [cc, setCc] = useState("");
  const [ccC, setCcC] = useState([]);

  const [cco, setCco] = useState("");
  const [ccoC, setCcoC] = useState([]);

  const [attach, setAttach] = useState([]);

  const [sendCo, setSendCo] = useState({
    to: toC,
    cc: ccC,
    cco: ccoC,
    subject: "",
    body: "",
    attachedFile: attach,
  });

  const agregarTo = (event) => {
    event.preventDefault();
    setToC((currentToc) => [...currentToc, to]);
    setSendCo({ ...sendCo, to: [...sendCo.to, to] });
    setTo("");
  };

  const handleDeleteTO = (deletingItem) => {
    const lisTo = toC.filter((item) => item !== deletingItem);
    setSendCo({ ...sendCo, to: [...lisTo] });
    setToC(lisTo);
  };

  const agregarCC = (event) => {
    event.preventDefault();
    setCcC((currentCC) => [...currentCC, cc]);
    setSendCo({ ...sendCo, cc: [...sendCo.cc, cc] });
    setCc("");
  };

  const handleDeleteCC = (deletingItem) => {
    const lisCC = ccC.filter((item) => item !== deletingItem);
    setSendCo({ ...sendCo, cc: [...lisCC] });
    setCcC(lisCC);
  };

  const agregarCCO = (event) => {
    event.preventDefault();
    setCcoC((currentCCO) => [...currentCCO, cco]);
    setSendCo({ ...sendCo, cco: [...sendCo.cco, cco] });
    setCco("");
  };

  const handleDeleteCCO = (deletingItem) => {
    const lisCCO = ccoC.filter((item) => item !== deletingItem);
    setSendCo({ ...sendCo, cco: [...lisCCO] });
    setCcoC(lisCCO);
  };

  // useEffect(() => {
  //   setSendCo({ ...sendCo, to: toC });
  // }, [toC]);

  // useEffect(() => {
  //   setSendCo({ ...sendCo, cc: ccC });
  // }, [ccC]);

  useEffect(() => {
    setSendCo({ ...sendCo, cco: ccoC });
  }, [ccoC]);

  useEffect(() => {
    setSendCo({ ...sendCo, attachedFile: attach });
  }, [attach]);

  let componentRef = useRef();


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalEmail = () => {
    setIsModalOpenEmail(true);
  };

  const handleOkEmail = () => {
    setIsModalOpenEmail(false);
  };

  const handleCancelEmail = () => {
    setIsModalOpenEmail(false);
    setToC([]);
    setCcC([]);
    setCcoC([]);
    setAttach([]);
    setSendCo({
      to: [],
      cc: [],
      cco: [],
      subject: "",
      body: "",
      attachedFile: [],
    });
  };

  const [formModal] = Form.useForm();


  const addEmailForm = async (cotizacion) => {
    if (cotizacion.idcoti !== undefined && cotizacion.idcoti !== 0) {
      getPDFQuote(cotizacion.idcoti).then((pdf) => {
        const temp = [...attach];
        temp.push({
          nombreArchivo: "Quotation.pdf",
          archivo: "data:aplication/pdf;base64," + pdf,
        });
        setAttach([...temp]);
      });
    setSendCo({
      ...sendCo,
      to: [...sendCo.to, cotizacion?.mail], 
      cc: [...sendCo.cc, currentUser?.email],
      subject: `Cotización de Flete Maritimo Codigo#${cotizacion.codigo}`,
      body: `<!DOCTYPE html>
        <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width,initial-scale=1">
        <meta name="x-apple-disable-message-reformatting">
        <title>Quotation</title>
        <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
        <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
        <style>
        table, td, div, h1, p {font-family: Arial, sans-serif;}
        </style>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" integrity="sha384-rHo5X8KBX+Tp0Rv+324RPtpFy0+hXrjem7u1g1k/8/xJ2a4iB+xtY32afFw2I5P7" crossorigin="anonymous">
        </head>
        <body style="margin:0;padding:0;">
        <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
        <tr>
        <td align="center" style="padding:0;">
        
          <table role="presentation" style="width:602px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
              <tr>
              <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
                <img src="https://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
              </td>
            </tr>
            <tr>
              <td style="padding:36px 30px 42px 30px;">
                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                  <tr>
                    <td style="padding:0;">
                      <h1 style="text-align: center">Cotización de Flete Maritimo<br/> Matus International.</h1>
                      <h3 style="text-align: center">Codigo de cotización ${cotizacion.codigo}</h3>
        
                      <p>Estimado cliente ${cotizacion?.cliente},<br/><br/> Es un placer presentarle nuestra cotizacion del flete de embarque, hemos considerado cuidadosamente sus requisitos y hemos preparado una oferta que esperamos sea de su interes.<br/><br/>
                      Encontrara los detalles de nuestra propuesta en el archivo adjunto a este correo. <br/>
                      </p>
                      
                      <p>
                      Por favor, tenga en cuenta que esta cotización es válida por 30 dias. Para confirmar su pedido o realizar cualquier consulta adicional, no dude en ponerse en contacto con nosotros. Estaremos encantados de brindarle cualquier información adicional que necesite.
                      **Estos precios no incluyen cargo adicional en destino.
                      </p>
                      
                      <p>
                      Agradecemos su interés en nuestro servicio y esperamos tener la oportunidad de atenderle. Quedamos a su disposición para cualquier pregunta o solicitud adicional.
                      </p>
                      
                      <p>
                      Saludos Cordiales.
                      </p>
        
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style="padding:30px;background:#1C2E51;">
                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                  <tr>
                    <td style="padding:0;width:50%;" align="left">
                      <p style="color:white;">&reg; Matus International, 2023</p>
                    </td>
                    <td style="padding:0;width:50%;" align="right">
                      <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                        <tr>
                          <td style="padding:0 0 0 10px;width:38px;">
                          <a href="https://www.facebook.com/matusintl/"><img src="https://portalmatus.com/facebook-white.png" alt="Instagram" width="38" style="height:auto;display:block;border:0;" /></a>
                          </td>
                          <td style="padding:0 0 0 10px;width:38px;">
                            <a href="https://www.instagram.com/matusintl/?hl=en"><img src="https://portalmatus.com/instagram-white.png" alt="Facebook" width="38" style="height:auto;display:block;border:0;" /></a>
                          </td>
                          <td style="padding:0 0 0 10px;width:38px;">
                            <a href="https://www.matusintl.com/"><img src="https://portalmatus.com/web-white.png" alt="Web" width="28" style="height:auto;display:block;border:0;" /></a>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
        </tr>
        </table>
        </body>
        </html>`,
    });

      setToC((currentToc) => [...currentToc, cotizacion?.mail]);
      setCcC((element) => [ ...element, currentUser?.email]);

    }
  };

  const onFinishModal = (value) => {
    enviarEmail(sendCo).then(({data}) => {
      // add toast success
      toast.success("Correo enviado correctamente", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        theme: "colored",
      });
      handleCancelEmail();
    });
  };
  const onFinishFailedModal = () => {};

  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e) => {
    const files = e.target.files;

    const temp = [...attach];
    for (let i = 0; i < files.length; i++) {
      const base64 = await convertFileToBase64(files[i]);
      const nombre = files[i].name;

      temp.push({ nombreArchivo: nombre, archivo: base64 });
    }

    setAttach([...temp]);
  };

  useEffect(() => {
    setLoading(true);
    getQuotation()
      .then((data) => {

        // convert data to table
        const dataQuotation = data.map((element, index) => {
          return {
            key: index,
            codigo: element.codigo,
            fechaCreacion: moment(element.dateCreation).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            fechaExpiracion: moment(element.dateExpiration).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            cliente: (element.clienteName ?? "")
              .split(" ")
              .map(
                (name) =>
                  name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
              )
              .join(" "),
            mail: element.email,
            idcoti: element.idCotizacion,
          };
        });

        setData(dataQuotation);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // mandar a extraer los datos de la cotizacion por el idCodigoImprimir
  useEffect(() => {
    if (idCodigoImprimir) {
      setLoadingImprimir(true);
      getQuotationById(idCodigoImprimir)
        .then((data) => {
          setDataImprimir(data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoadingImprimir(false);
        });
    }
  }, [idCodigoImprimir]);

  const columns = [
    {
      title: "Codigo",
      dataIndex: "codigo",
      key: "codigo",
    },
    {
      title: "Fecha de creacion",
      dataIndex: "fechaCreacion",
      key: "fechaCreacion",
    },
    {
      title: "Fecha de expiracion",
      dataIndex: "fechaExpiracion",
      key: "fechaExpiracion",
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
    },
    {
      title: "Correo electronico",
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: "Opciones",
      dataIndex: "opcion",
      key: "opcion",
      // add option to edit and view
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Enviar">
            <Button
              type="primary"
              onClick={() => {
                showModalEmail();
                setIdCotizacionEmail(record.codigo);
                addEmailForm(record);
              }}
              shape="circle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MailFilled style={{ fontSize: 17 }} />
            </Button>
          </Tooltip>
          <Tooltip title="Ver">
            <Button
              type="primary"
              onClick={() => {
                // showModal();
                // ESTE ES EL CODIGO QUE SE DEBE DE MANDAR PARA IMPRIMIR
                  if (record.idcoti !== undefined && record.idcoti !== 0)
                  getPDFQuote(record.idcoti).then((pdf) => {
                  getPdf('Quotation', pdf)  
                })
              }}
              shape="circle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F9B325",
                borderColor: "#F9B325", 
              }}
            >
              <PrinterFilled style={{ fontSize: 17 }} />
            </Button>
          </Tooltip>
          <Tooltip title="Editar">
            <Button
              type="primary"
              onClick={() => {
                navigate(`/quotation/edit/${record.codigo}`);
              }}
              shape="circle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "green",
                borderColor: "green",
              }}
            >
              <EditFilled style={{ fontSize: 17 }} />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Spinner loading={false}>
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h4>Lista de Cotizaciones</h4>

            <Button
              type="primary"
              onClick={() => {
                navigate("/quotation/create");
              }}
            >
              Registrar nueva cotizacion
            </Button>
          </Col>
        </Row>

        <hr />
        <Table
          columns={columns}
          dataSource={data}
          size="middle"
          pagination={{ pageSize: 20 }}
          loading={loading}
        />
      </Spinner>
      <Modal
        title={`Imprimir Cotizacion ${idCodigoImprimir}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={"80%"}
      >
        <Spinner loading={loadingImprimir}>
          <QuoteToPrint
            ref={(componet) => (componentRef = componet)}
            dataImprimir={dataImprimir}
          />
          <br />

          <Row gutter={[16, 24]} style={{ marginBottom: 10 }}>
            <Col span={7}></Col>
            <ReactToPrint
              trigger={() => (
                <Col span={10}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Button
                      block
                      style={{ backgroundColor: "green", color: "white" }}
                    >
                      Imprimir{" "}
                    </Button>
                  </Space>
                </Col>
              )}
              content={() => componentRef}
            />
            <Col span={7}></Col>
          </Row>
        </Spinner>
      </Modal>

      <Modal
        title={`Send Quote ${idCotizacionEmail}`}
        open={isModalOpenEmail}
        onOk={handleOkEmail}
        onCancel={handleCancelEmail}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={"40%"}
      >
        <Form
          layout={"vertical"}
          form={formModal}
          initialValues={{ layout: "vertical", remember: true }}
          style={{ padding: 20 }}
          onFinish={onFinishModal}
          onFinishFailed={onFinishFailedModal}
          autoComplete="off"
        >
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="TO:" name="to">
                {toC?.map((item, index) => {
                  return (
                    <li key={index}>
                      {item}{" "}
                      <Button 
                      type="primary" 
                      danger
                      shape="circle"
                      size="small"
                      style={{marginBottom: 10}}
                      onClick={() => handleDeleteTO(item)} >x</Button>
                    </li>
                  );
                })}
                <Input
                  placeholder="ADD NEW EMAIL"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
                <Button
                  style={{ backgroundColor: "blue", color: "white", marginTop: 10 }}
                  onClick={agregarTo}
                  block
                >
                  Add{" "}
                </Button>
              </Form.Item>
              <Form.Item label="CC:" name="cc">
                {ccC?.map((item, index) => {
                  return (
                    <li key={index}>
                      {item}{" "}
                      <Button
                      type="primary"
                      danger
                      shape="circle"
                      size="small"
                      style={{marginBottom: 10}}
                       onClick={() => handleDeleteCC(item)}>x</Button>
                    </li>
                  );
                })}
                <Input
                  placeholder="ADD NEW EMAIL"
                  onChange={(e) => setCc(e.target.value)}
                  value={cc}
                />
                <Button
                  style={{ backgroundColor: "blue", color: "white", marginTop: 10 }}
                  onClick={agregarCC}
                  block
                >
                  Add{" "}
                </Button>
              </Form.Item>
              <Form.Item label="CCO:" name="cco">
                {ccoC?.map((item, index) => {
                  return (
                    <li key={index}>
                      {item}{" "}
                      <Button
                      type="primary"
                      danger
                      shape="circle"
                      size="small"
                      style={{marginBottom: 10}}
                      onClick={() => handleDeleteCCO(item)}>x</Button>
                    </li>
                  );
                })}
                <Input
                  placeholder="ADD NEW EMAIL"
                  value={cco}
                  onChange={(e) => setCco(e.target.value)}
                />
                <Button
                  style={{ backgroundColor: "blue", color: "white", marginTop: 10 }}
                  onClick={agregarCCO}
                  block
                >
                  Add{" "}
                </Button>
              </Form.Item>
              <Form.Item label="Attached files:">
                {attach?.map((attach, key) => {
                  return (
                    <li key={key}>
                      {attach.id}
                      {attach.nombreArchivo}
                    </li>
                  );
                })}
              </Form.Item>
              <Form.Item label="Message">
                <TextArea
                  disabled
                  readOnly
                  rows={3}
                  value="**El mensaje esta predeterminado por el sistema con los datos ingresados en la cotizacion** 
                      - Si agrega nuevos correos asegurarse esten bien escritos y aparezcan en la lista de agregados"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Button
                  block
                  style={{ backgroundColor: "green", color: "white" }}
                  htmlType="submit"
                >
                  Send Quotation{" "}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default QuotationList;
