import React, {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faComment,
  faPaperPlane,
  faEye, faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import "styled-components";
import "../../hojas-de-estilo/style.css";
import useCurrentUser from "../../Hooks/UserHook";
import {
  showComments,
  addComent,
} from "../../Servicios/ComponedoresService";
import Alert from "react-bootstrap/Alert";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import moment from "moment";
import {
  Badge,
  Drawer,
  Row,
  Col,
  Space,
  Table,
  Tabs
} from "antd";
import {validarPermisos} from "../../Servicios/AuthService";
import {permisos} from "../../utils/permisos";
import '../DataTableCo/CoordinacionTable.css'
import {VehiculoTituloEstadoEnum} from "../../shared/enums/vehiculo-titulo-estado.enum";
import {StringFormats} from "../../utils/string-formats";
import './CoordinacionSinTituloTable.css';
import EditableCell from "../../Componentes/EditableCell/EditableCell";

const CoordinacionSinTituloTable = ({totalElements, coordi, exportToExcel, coordinacionFilters, isLoading, setCoordinacionFilters, onUpdateVehiculoTituloEstado}) => {
  const ITEMS_PER_PAGE = 5;
  const navigate = useNavigate();
  const puedeModificarStatusTituloPendiente = validarPermisos(permisos.VER_TITULOS_PENDIENTES);

  const [idCor, setIdCor] = useState(0);
  const [notes, setNotes] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const mostComent = async () => {
    if (idCor !== 0) {
      const data = await showComments(idCor);
      setNotes(data.slice(0, ITEMS_PER_PAGE));

      showDrawer();
    }
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setIdCor(0);
    setOpenDrawer(false);
    setCoordinacionFilters({...coordinacionFilters});
  };

  const onChangeTab = tab => {
    setCoordinacionFilters({
      ...coordinacionFilters,
      currentTab: tab,
      currentPage: 1,
      vehiculoTituloEstado: tab === '1'
        ? ''
        : coordinacionFilters.vehiculoTituloEstado
    });
  }

  const fechaactual = StringFormats.dateTime(new Date());
  const currentUser = useCurrentUser();

  const [coment, setComent] = useState({
    id: "",
    fecha: "",
    nota: "",
    autor: "",
    coordId: "",
  });

  useEffect(() => {
    mostComent().then();
  }, [idCor]);

  useEffect(() => {
    if (currentUser)
      setComent({
        ...coment,
        coordId: idCor,
        autor: currentUser?.fullName,
        nota: "",
      });
  }, [currentUser, idCor]);

  const insertarComent = async () => {
    try {
      const data = await addComent({
        ...coment,
        fecha: StringFormats.dateTimeDB(new Date()),
        coordId: +coment.coordId,
      });
      if (data) {
        mostComent().then();
        setComent({
          ...coment,
          nota: "",
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setComent({
      ...coment,
      [name]: value,
    });
  };

  const coordinacionStateClassName = {
    [VehiculoTituloEstadoEnum.subLoteGestionarEnvio]: 'cell-bg-brown',
    [VehiculoTituloEstadoEnum.otraDireccionGestionarEnvio]: 'cell-bg-red',
    [VehiculoTituloEstadoEnum.pendienteInstrucciones]: 'cell-bg-lima',
    [VehiculoTituloEstadoEnum.trackingListo]: 'cell-bg-yellow-light',
    [VehiculoTituloEstadoEnum.duplicadoEnProceso]: 'cell-bg-pink',
    [VehiculoTituloEstadoEnum.tituloConDriver]: 'cell-bg-gold',
    [VehiculoTituloEstadoEnum.recibidoSinTitulo]: 'cell-bg-white',
    [VehiculoTituloEstadoEnum.tituloRecibido]: 'cell-bg-green-dark',
    [VehiculoTituloEstadoEnum.tituloEnCalifornia]: 'cell-bg-gray',
    [VehiculoTituloEstadoEnum.billOfSale]: 'cell-bg-cyan',
    [VehiculoTituloEstadoEnum.fedexToday]: 'cell-bg-green',
    [VehiculoTituloEstadoEnum.tituloDealer]: 'cell-bg-yellow',
    [VehiculoTituloEstadoEnum.dmvTituloPendiente]: 'cell-bg-purple',
  }

  const columnActionTemplate = (value, row) => {
    return (
      <Space>
        <Button
          onClick={() => navigate(`/coordinations/${row?.id}/view`)}
          variant="info"
          size="sm"
        >
          <FontAwesomeIcon icon={faEye}/>
        </Button>
        <Badge count={row?.totalComentarios}>
          <Button
            onClick={() => setIdCor(row?.id)}
            value={row?.id}
            variant="warning"
            size="sm"
          >
            <FontAwesomeIcon icon={faComment}/>
          </Button>
        </Badge>
      </Space>
    );
  }

  const handleSave = async (value, item) => {
    const input = {
      id: item.vehiculoId,
      tituloEstado: value
    }

    onUpdateVehiculoTituloEstado(input);
  }

  const antdColumns = [
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'left',
      width: 100,
      render: columnActionTemplate,
    },
    { key: 'lote', dataIndex: 'lote', title: 'Lote', width: 130, fixed: 'left' },
    {
      key: 'fechaVehiculo', dataIndex: 'fechaVehiculo', title: 'Fecha de Vehículo Recibido', width: 150,
      render: (_, item) => <>{item.vehiculo && item.vehiculo.fecha ? moment(item.vehiculo.fecha).format("l") : '---'}</>
    },
    { key: 'vehiculoName', dataIndex: 'vehiculoName', title: 'Vehículo', width: 220 },
    { key: 'buyer', dataIndex: 'buyer', title: 'Buyer', width: 120 },
    {
      key: 'subs', dataIndex: 'subs', title: 'Ubic./Subasta', width: 240,
      render: value => <div className={value ? '' : 'cell-bg-yellow'}>{value.toUpperCase() ?? "VEHICLE BY CLIENT"}</div>,
    },
    { key: 'import', dataIndex: 'import', title: 'Importadora', width: 250 },
    {
      key: 'vendedor', dataIndex: 'vendedor', title: 'Vendedor', width: 220,
      render: (_, item) => item.quote && item.quote.creador
        ? item.quote.creador.toUpperCase()
        : 'SIN ASIGNAR'
    },
    {
      key: 'tituloEstado',
      dataIndex: 'tituloEstado',
      title: 'Estado',
      width: 280,
      render: (_, item) => <div className={coordinacionStateClassName[item.vehiculo.tituloEstado]}>
        <EditableCell type={'select'}
                      editable={puedeModificarStatusTituloPendiente}
                      value={item.vehiculo.tituloEstado ?? '---'}
                      onSubmit={(newValue) => handleSave(newValue, item)}
                      selectList={Object.keys(VehiculoTituloEstadoEnum).map(e => ({
                        value: VehiculoTituloEstadoEnum[e],
                        label: VehiculoTituloEstadoEnum[e]
                      }))}>
          {item.vehiculo.tituloEstado ?? '---'}
        </EditableCell>
      </div>
    },
    {key: 'aduanaName', dataIndex: 'aduanaName', title: 'Destino', width: 180},
    {
      key: 'transporteName', dataIndex: 'transporteName', title: 'Transporte', width: 140,
      render: value => <>{value ?? "NO ASIGNADO"}</>
    },
    {
      key: 'transporteTelefono', dataIndex: 'transporteTelefono', title: '# Telefonico', width: 130,
      render: (_, item) => <>{item && item.transporte ? item?.transporte?.telefono : "NO ASIGNADO"}</>
    }
  ];

  const handlePageChange = (page, pageSize) => {
    const filters = {
      ...coordinacionFilters,
      currentPage: page,
      pageCount: pageSize,
    };

    // add validation of compare prevState with newState
    // to avoid useEffect execute twice
    if (JSON.stringify(coordinacionFilters) !== JSON.stringify(filters))
      setCoordinacionFilters(filters);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-1">
        <h5 className="mb-0">Total: {totalElements}</h5>
        <Button size="sm" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faDownload} /> Exportar tabla a Excel
        </Button>
      </div>

      <Tabs activeKey={coordinacionFilters.currentTab}
            onChange={onChangeTab}
            items={[
              { key: '0', label: 'Títulos pendientes' },
              { key: '1', label: 'Títulos recibidos' }
            ]}/>

      <Table columns={antdColumns}
             dataSource={coordi}
             size="small"
             loading={isLoading}
             scroll={{x: 1300, y: 600}}
             pagination={{
               position: ["bottomRight"],
               total: totalElements,
               showSizeChanger: true,
               showTotal: total => `${total} elementos`,
               current: coordinacionFilters.currentPage,
               pageSize: coordinacionFilters.pageCount,
               pageSizeOptions: [20, 50, 100, 150, 200, 500],
               onChange: handlePageChange
             }}/>

      <Drawer
        onClose={onCloseDrawer}
        open={openDrawer}
        title={"Comentarios"}
        footer={
          <>
            <Row>
              &nbsp;&nbsp;&nbsp;{" "}
              <Form.Control
                readOnly
                disabled
                //   style={{ width: "200px" }}
                value={fechaactual}
                name="fecha"
                type="text"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </Row>
            <br/>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Escriba su comentario aqui"
                  width="100%"
                >
                  <Form.Control
                    name="nota"
                    maxLength={1000}
                    value={coment?.nota}
                    onChange={handleChange}
                    as="textarea"
                    style={{height: "150px", width: "100%"}}
                    // style={{ width: "100%" }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Row className="conte-padre">
              <Button
                style={{width: "100%"}}
                onClick={() => insertarComent()}
                variant="primary"
              >
                <FontAwesomeIcon icon={faPaperPlane}/>
                &nbsp;Guardar Comentario
              </Button>{" "}
            </Row>
          </>
        }
      >
        {
          notes.map((note, index) => (
            <Row key={index}>
              <Col xs={24} md={24} lg={24}>
                <Alert variant="secondary">
                  <p>{note.nota}</p>
                  <small>{moment(note.fecha).format('LLL')} by {note.autor}</small>
                </Alert>
              </Col>
            </Row>
          ))
        }
      </Drawer>
    </>
  );
}

export default CoordinacionSinTituloTable;
