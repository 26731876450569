import { server } from "../environments/environment";
import {ApiService} from "./ApiService";

const URL = `${server}Werehouse`;

export const addWR = async (wereh) => {
    return await ApiService.post(`${server}Werehouse`,wereh).then(async (response) => {
            const data = await response.json()
            if (data.status){
                return data.data;
            } else {
                throw Error(data.message)
            }
        }).catch(error => {
            throw Error(error);
        });
}  

export const viewWR = async (id) => {
    try {
        const response = await ApiService.get(`${URL}/${id}`);

        const data = await response.json()
        const wereh = data.data;

        if (wereh.titulo == true) {
            wereh.titulo.toString();
        } else {
            false.toString();
        }

        return wereh;
        
    } catch (ex) {
        throw new Error(ex);
    }
}

export const getPDFWRId = async (id) => {
    const URL = `${server}Werehouse/reporte`
    try {
        const response = await ApiService.get(URL+"/"+id);
        const data = await response.json()
        const wr = data.data;
        return wr;
        
    } catch (ex) {
        throw new Error(ex);
    }
}

export const editWerehouse = async (id, whr) => {
    try {
        const response = await ApiService.put(URL+"/"+id, whr);
        const data = await response.json()
        return data.data;
    } catch (ex) {
        throw new Error(ex);
    }
}

export const showWR = async (idori = 0, mes = 0, anio = 0, currentPage = 0, pageCount = 0, search = "") => {
    const URL = `${server}Werehouse`;
    
    // Construir la URL de la solicitud
    let requestURL = `${URL}?idOrigen=${idori}`;
    if (mes !== 0 && anio !== 0) {
        requestURL += `&mes=${mes}&anio=${anio}`;
    }

    requestURL += `&currentPage=${currentPage}&pageCount=${pageCount}&search=${search}`;
    
    // Realizar la solicitud
    const response = await ApiService.get(requestURL);
    return response.json();
}

export const showCodeExport = async () => {
    const URL = `${server}CodeExport`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const showLicenceType = async () => {
    const URL = `${server}LicenceType`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const showOrigin = async () => {
    const URL = `${server}Origin`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const showSchedule = async () => {
    const URL = `${server}ScheduleB`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}
export const showStates = async () => {
    const URL = `${server}States`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const showTypeMerch = async () => {
    const URL = `${server}TypeMerch`
    const response = await ApiService.get(URL);
    const data = await response.json()
    return data.list;
}

export const getWarehouseByCoordination = async id => {
    const response = await ApiService.get(`${server}werehouse/coordinacion/${id}`);
    return response.json();
}
