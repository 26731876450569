import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";

const InputSearch = ({ initialValue, placeholder, doChange}) => {
  const [filterText, setFilterText] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filterText != null)
        doChange(filterText);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filterText, 500]);

  const handleChange = (event) => {
    const value = event.target.value;

    setFilterText(value);
  }

  return (
    <>
      <Form.Control
        className="input-buscar"
        id="search"
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        aria-label="Search Input"
        value={filterText ?? initialValue}
        onChange={handleChange}
      />
    </>
  );
}

export default InputSearch;
