import React, { useEffect, useState } from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";
import { totalWeeklytotals } from "../../../../Servicios/Home";
const RequestAnalysisWeekly = ({ user }) => {
  const [stateLinealWeek, setStateLinealWeek] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "week",
        categories: [
          "Domingo",
          "Lunes",
          "Martes",
          "Miercoles",
          "Jueves",
          "Viernes",
          "Sabado",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const weeklyTotalsData = async () => {
    const data = await totalWeeklytotals();
    setStateLinealWeek({
      series: data,
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "week",
          categories: [
            "Domingo",
            "Lunes",
            "Martes",
            "Miercoles",
            "Jueves",
            "Viernes",
            "Sabado",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 350,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (user) weeklyTotalsData();
  }, [user]);

  return (
    <Card
      title={`Analisis de Solicitudes - Semanal`}
      style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}
    >
      <div
        id="chart"
        style={{
          width: "100%",
        }}
      >
        <ReactApexChart
          options={stateLinealWeek.options}
          series={stateLinealWeek.series}
          type="area"
          height={350}
        />
      </div>
    </Card>
  );
};

export default RequestAnalysisWeekly;
