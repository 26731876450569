import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const getUsers = async () => {
  const response = await ApiService.get(`${server}usuarios`);
  const data = await response.json();

  if (data.status) {
    return data.list;
  }

  throw new Error(data.message);
}

export const getUserById = async (id: number) => {
  const response = await ApiService.get(`${server}usuarios/${id}`);
  const data = await response.json();

  if (data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const getLocationUsers = async () => {
  const response = await ApiService.get(`${server}ubication`);
  const data = await response.json();

  if (data.status) {
    return data.list;
  }

  throw new Error(data.message);
}

export const getRoles = async () => {
  const response = await ApiService.get(`${server}rol`);
  const data = await response.json();

  if (data.status) {
    return data.list;
  }

  throw new Error(data.message);
}

export const saveUserRol = async (userRol) => {
  const response = await ApiService.post(`${server}usuarios/usuarioRol`, userRol);
  return response.json();
}

export const deleteUserRol = async (id) => {
  const response = await ApiService.delete(`${server}usuarios/usuarioRol/${id}`);
  return response.json();
}

export const createUser = async (body) => {
  const response = await ApiService.post(`${server}authentication/sigin`, body);
  const data = await response.json();

  if (data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const updateUser = async (id, body) => {
  const response = await ApiService.put(`${server}usuarios/${id}/`, body);
  const data = await response.json();

  if (data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const getRoleById = async (id) => {
  const response = await ApiService.get(`${server}rol/${id}`);
  const data = await response.json();

  if (data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const createRole = async (role) => {
  const response = await ApiService.post(`${server}rol`, role);
  return response.json();
}

export const updateRole = async (id, role) => {
  const response = await ApiService.put(`${server}rol/${id}`, role);
  return response.json();
}

export const deleteRole = async (id) => {
  const response = await ApiService.delete(`${server}rol/${id}`);
  return response.json();
}

export const getPermissions = async () => {
  const response = await ApiService.get(`${server}permiso`);
  const data = await response.json();

  if (data.status) {
    return data.list;
  }

  throw new Error(data.message);
}

export const createPermiso = async (permiso) => {
  const response = await ApiService.post(`${server}permiso`, permiso);
  return response.json();
}

export const updatePermiso = async (id, permiso) => {
  const response = await ApiService.put(`${server}permiso/${id}`, permiso);
  return response.json();
}

export const deletePermiso = async (id) => {
  const response = await ApiService.delete(`${server}permiso/${id}`);
  return response.json();
}

export const asignarRolPermiso = async (permiso) => {
  const response = await ApiService.post(`${server}rol/rolPermiso/asignarPermiso`, permiso);
  return response.json();
}

export const removerRolPermiso = async (permiso) => {
  const response = await ApiService.post(`${server}rol/rolPermiso/removerPermiso`, permiso);
  return response.json();
}

export const asignarUsuarioPermiso = async (permiso) => {
  const response = await ApiService.post(`${server}usuarios/usuarioPermiso/asignarPermiso`, permiso);
  return response.json();
}

export const removerUsuarioPermiso = async (permiso) => {
  const response = await ApiService.post(`${server}usuarios/usuarioPermiso/removerPermiso`, permiso);
  return response.json();
}
