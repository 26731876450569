import React, {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faEye, faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import "styled-components";
import "../../hojas-de-estilo/style.css";
import useCurrentUser from "../../Hooks/UserHook";
import {
  showComments,
  addComent,
} from "../../Servicios/ComponedoresService";
import Alert from "react-bootstrap/Alert";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import moment from "moment";
import {
  Drawer,
  Row,
  Col,
  Space,
  Table,
  Tabs
} from "antd";
import {validarPermisos} from "../../Servicios/AuthService";
import {permisos} from "../../utils/permisos";
import '../../Coordinaciones/DataTableCo/CoordinacionTable.css';
import {StringFormats} from "../../utils/string-formats";
import './PayTransportTable.css';
import EditableCell from "../../Componentes/EditableCell/EditableCell";

const TAB_PAGOS_DEL_DIA = 'PAGOS DEL DIA';
const TAB_PAGOS_PENDIENTES = 'PENDIENTE DE PAGO';

const PayTransportTable = ({totalElements, coordi, exportToExcel, coordinacionFilters, isLoading, setCoordinacionFilters, onUpdateVehiculoTituloEstado}) => {
  const ITEMS_PER_PAGE = 5;
  const navigate = useNavigate();
  const puedeModificarStatusTituloPendiente = validarPermisos(permisos.PAGOS_TRANSPORTISTAS);

  const [idCor, setIdCor] = useState(0);
  const [notes, setNotes] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [totalDriver, setTotalDriver] = useState(0);
  const [totalPagado, setTotalPagado] = useState(0);
  const [totalValorGrua, setTotalValorGrua] = useState(0);

  const mostComent = async () => {
    if (idCor !== 0) {
      const data = await showComments(idCor);
      setNotes(data.slice(0, ITEMS_PER_PAGE));

      showDrawer();
    }
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setIdCor(0);
    setOpenDrawer(false);
    setCoordinacionFilters({...coordinacionFilters});
  };

  const onChangeTab = tab => {
    setCoordinacionFilters({
      ...coordinacionFilters,
      currentTab: tab,
      currentPage: 1,
      vehiculoTituloEstado: tab === '1'
        ? ''
        : coordinacionFilters.vehiculoTituloEstado
    });
  }

  const fechaactual = StringFormats.dateTime(new Date());
  const currentUser = useCurrentUser();

  const [coment, setComent] = useState({
    id: "",
    fecha: "",
    nota: "",
    autor: "",
    coordId: "",
  });

  useEffect(() => {
    mostComent().then();
  }, [idCor]);

  useEffect(() => {
    if (currentUser)
      setComent({
        ...coment,
        coordId: idCor,
        autor: currentUser?.fullName,
        nota: "",
      });
  }, [currentUser, idCor]);

  useEffect(() => {
    setTotalDriver(getTotal('st_Driver'));
    setTotalPagado(getTotal('st_Paid'));
    setTotalValorGrua(getTotal('cost'));
  }, [coordi]);

  const insertarComent = async () => {
    try {
      const data = await addComent({
        ...coment,
        fecha: StringFormats.dateTimeDB(new Date()),
        coordId: +coment.coordId,
      });
      if (data) {
        mostComent().then();
        setComent({
          ...coment,
          nota: "",
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setComent({
      ...coment,
      [name]: value,
    });
  };

  const columnActionTemplate = (value, row) => {
    return (
      <Space>
        <Button
          onClick={() => navigate(`/coordinations/${row?.id}/view`)}
          variant="info"
          size="sm"
        >
          <FontAwesomeIcon icon={faEye}/>
        </Button>
      </Space>
    );
  }

  const handleSave = async (newValue, item, key) => {
    const input = {
      ...item,
      [key]: newValue.toString()
    }

    onUpdateVehiculoTituloEstado(input);
  }

  const antdColumns = [
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'left',
      width: 70,
      render: columnActionTemplate,
    },
    { key: 'lote', dataIndex: 'lote', title: 'Lote', width: 100, fixed: 'left' },
    {
      key: 'fechaVehiculo', dataIndex: 'fechaVehiculo', title: 'Fecha de Vehículo Recibido', width: 140,
      render: (_, item) => <>{item.vehiculo && item.vehiculo.fecha ? moment(item.vehiculo.fecha).format("l") : '---'}</>
    },
    { key: 'vehiculoName', dataIndex: 'vehiculoName', title: 'Vehículo', width: 220 },
    { title: 'Destino', dataIndex: 'aduanaCom', key: 'aduanaCom', width: 180 },
    {
      title: 'Titulo', dataIndex: 'titulo', key: 'titulo', width: 100,
      render: value => value ? <div className="badge bg-success">SI</div> : <div className="badge bg-danger">NO</div>
    },
    { key: 'st_Driver', dataIndex: 'st_Driver', title: 'Storage Driver', width: 120 },
    {
      key: 'st_Paid', dataIndex: 'st_Paid', title: 'Storage Pagado', width: 120,
      render: (value, item) => <EditableCell type={'number'}
                                             editable={puedeModificarStatusTituloPendiente}
                                             value={value ? StringFormats.currency(value) : "--"}
                                             onSubmit={(newValue) => handleSave(newValue, item, 'stPaid')}>
        {value ? StringFormats.currency(value) : "--"}
      </EditableCell>,
    },
    {
      key: 'cutVehicle',
      dataIndex: 'cutVehicle',
      title: '',
      width: 140,
      render: (_, element) => element?.quote?.cutVehicle
        ? <div className="badge bg-warning">Vehículo para corte</div> : ''
    },
    { key: 'cost', dataIndex: 'cost', title: 'Valor grua', width: 140 },
    {
      key: 'transporteName', dataIndex: 'transporteName', title: 'Transporte', width: 140,
      render: value => <>{value ?? "NO ASIGNADO"}</>
    },
    {
      key: 'subs', dataIndex: 'subs', title: 'Ubic./Subasta', width: 240,
      render: value => <div className={value ? '' : 'cell-bg-yellow'}>{value.toUpperCase() ?? "VEHICLE BY CLIENT"}</div>,
    },
    { key: 'vin', dataIndex: 'vin', title: 'Vin', width: 220 },
    {
      key: 'estadoPagoTransp', dataIndex: 'estadoPagoTransp', title: 'Estado', width: 180,
      render: (value, item) => <EditableCell type={'select'}
                                             value={value}
                                             editable={puedeModificarStatusTituloPendiente}
                                             onSubmit={(newValue) => handleSave(newValue, item, 'estadoPagoTransp')}
                                             selectList={[
                                               {label: 'Pagado', value: 'PAGADO'},
                                               {label: 'No Pagado', value: 'NO PAGADO'},
                                               {label: 'No se paga', value: 'NO SE PAGA'},
                                               {label: 'Pendiente de Pago', value: 'PENDIENTE DE PAGO'},
                                             ]}>
        <div className={'badge ' + (badgeClass[value] ?? 'bg-primary')}>{value}</div>
      </EditableCell>,
      fixed: 'right'
    },
  ];

  const badgeClass = {
    'PAGADO': 'bg-success',
    'NO PAGADO': 'bg-danger',
    'NO SE PAGA': 'bg-warning',
    'PENDIENTE DE PAGO': 'bg-info',
  }

  const getTotal = key => {
    return coordi.reduce((total, item) => {
      total += item[key] && item[key] !== ''
        ? typeof item[key] === 'string'
          ? +item[key]
          : item[key]
        : 0;

      return total;
    }, 0);
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-1">
        <div className="d-flex align-items-center gap-3">
          <p className="mb-0 fw-bold">Total Vehículos: {totalElements}</p> |
          <p className="mb-0 fw-bold">Total St Driver: {totalDriver}</p> |
          <p className="mb-0 fw-bold">Total St Pagado: {totalPagado}</p> |
          <p className="mb-0 fw-bold">Total Valor Grua: {totalValorGrua}</p>
        </div>
        <Button size="sm" onClick={exportToExcel}>
        <FontAwesomeIcon icon={faDownload} /> Exportar tabla a Excel
        </Button>
      </div>

      <Tabs activeKey={coordinacionFilters.currentTab}
            onChange={onChangeTab}
            items={[
              { key: TAB_PAGOS_DEL_DIA, label: 'Control de pagos del dia' },
              { key: TAB_PAGOS_PENDIENTES, label: 'Pendientes de Pago a Transportistas' }
            ]}/>

      <Table columns={antdColumns}
             dataSource={coordi}
             size="small"
             loading={isLoading}
             scroll={{x: 1300, y: 600}}
             pagination={{
               position: ["bottomRight"],
               showSizeChanger: true,
               showTotal: total => `${total} elementos`,
               pageSizeOptions: [20, 50, 100, 150, 200, 500],
             }}/>

      <Drawer
        onClose={onCloseDrawer}
        open={openDrawer}
        title={"Comentarios"}
        footer={
          <>
            <Row>
              &nbsp;&nbsp;&nbsp;{" "}
              <Form.Control
                readOnly
                disabled
                //   style={{ width: "200px" }}
                value={fechaactual}
                name="fecha"
                type="text"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </Row>
            <br/>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Escriba su comentario aqui"
                  width="100%"
                >
                  <Form.Control
                    name="nota"
                    maxLength={1000}
                    value={coment?.nota}
                    onChange={handleChange}
                    as="textarea"
                    style={{height: "150px", width: "100%"}}
                    // style={{ width: "100%" }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Row className="conte-padre">
              <Button
                style={{width: "100%"}}
                onClick={() => insertarComent()}
                variant="primary"
              >
                <FontAwesomeIcon icon={faPaperPlane}/>
                &nbsp;Guardar Comentario
              </Button>{" "}
            </Row>
          </>
        }
      >
        {
          notes.map((note, index) => (
            <Row key={index}>
              <Col xs={24} md={24} lg={24}>
                <Alert variant="secondary">
                  <p>{note.nota}</p>
                  <small>{moment(note.fecha).format('LLL')} by {note.autor}</small>
                </Alert>
              </Col>
            </Row>
          ))
        }
      </Drawer>
    </>
  );
}

export default PayTransportTable;
