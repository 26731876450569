import React, { useEffect } from "react";
import { Table, Select } from "antd";
import { listNote, listClient } from "../../Servicios/Notas";
import useCurrentUser from "../../Hooks/UserHook";
import { MessageOutlined, CalendarOutlined } from "@ant-design/icons";
import { validarPermisos } from "../../Servicios/AuthService";
import { permisos } from "../../utils/permisos";
import moment from "moment";
const columns = [
  {
    title: "Nombre del Cliente",
    dataIndex: "nombreCliente",
    key: "nombreCliente",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Numero de Contacto",
    dataIndex: "contactNumber",
    key: "contactNumber",
  },
];

const columns2 = [
  {
    title: (
      <label
        style={{
          fontWeight: "bold",
          fontSize: "16px",
          color: "#000000",
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Nota{" "}
        <MessageOutlined
          style={{
            marginLeft: "10px",
            color: "green",
          }}
        />
      </label>
    ),
    dataIndex: "nota",
    width: "50%",
    key: "nota",
    render: (nota) => {
      return (
        <div
          style={{
            fontSize: "16px",
            color: "#000000",
            textAlign: "center",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {nota}
        </div>
      );
    },
  },
  {
    title: (
      <label
        style={{
          fontWeight: "bold",
          fontSize: "16px",
          color: "#000000",
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Fecha{" "}
        <CalendarOutlined
          style={{
            marginLeft: "10px",
            color: "blue",
          }}
        />
      </label>
    ),
    dataIndex: "fecha",
    width: "50%",
    key: "fecha",
    render: (fecha) => {
      return (
        <div
          style={{
            fontSize: "16px",
            color: "#000000",
            textAlign: "center",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {moment(fecha).format("DD/MM/YYYY")}
        </div>
      );
    },
  },
];

const ReportComments = () => {
  const [data, setData] = React.useState([]);
  const [clientes, setClientes] = React.useState([]);
  const [nameUser, setNameUser] = React.useState("");
  const currentUser = useCurrentUser();

  // console.log("validar", validarPermisos(permisos.REPORT_COMENT_BY_USERS));
  const fetchNotas = async (nombre) => {
    try {
      const data = await listNote(nombre);
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClientes = async () => {
    try {
      const data = await listClient();
      //console.log("Cliente", data);
      setClientes(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentUser?.fullName) {
      fetchClientes();
      fetchNotas(currentUser?.fullName);
      setNameUser(currentUser?.fullName);
    }
  }, [currentUser?.fullName]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>Reporte de Comentarios: {nameUser}</h3>

        <div>
         {
          validarPermisos(permisos.REPORT_COMENT_BY_USERS) && (<>
            <strong>Usuario</strong>
            <Select
              style={{ width: 200 }}
              defaultValue={nameUser}
              onChange={(value) => {
                setNameUser(value);
                fetchNotas(value);
              }}
              options={clientes.map((cliente) => ({
                label: cliente.firstName + " " + cliente.lastName,
                value: cliente.firstName + " " + cliente.lastName,
              }))}
            />
            </>
          )
         }
        </div>
      </div>

      <hr />

      <Table
        pagination={false}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <div
              style={{
                margin: 0,
              }}
            >
              <Table
                columns={columns2}
                dataSource={record.notas}
                pagination={false}
              />
            </div>
          ),
          //   rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={data}
      />
    </div>
  );
};

export default ReportComments;
