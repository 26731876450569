import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import React, {useEffect, useState} from "react";
import {showVehicleWr} from "../Servicios/CombosService";
import {VehiculoEstadoEnum} from "../shared/enums/vehiculo-estado.enum";
import Button from "react-bootstrap/Button";
import Loadingsmall from "../Componentes/Loadingsmall";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {vinDecode} from "../Servicios/VehicleService";
import {errorMessage} from "../utils/toast-message";
import {editCar} from "../Servicios/VehicleService";
import Spinner from "../shared/spinner";

const AddVehicleWarehouseForm = ({ origenId, onCancelar, afterSaveVehiculo }) => {
  const [vehiculos, setVehiculos] = useState([]);

  const [vin, setVin] = useState("");
  const [vehiculo, setVehiculo] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadVehiculos().then();
  }, [origenId]);

  const loadVehiculos = async () => {
    if (origenId !== undefined && origenId !== 0) {
      const data = await showVehicleWr(origenId, VehiculoEstadoEnum.recibidoEnBodega);
      setVehiculos(data);
    }
  }

  const handleSelectChangeVehiculo = ({value}) => {
    const vehiculo = vehiculos.find(e => e.vehiculoId === value);

    setVehiculo(vehiculo);
    setVin(vehiculo.vin ?? "");
  }

  const handleChange = e => {
    const {name, value} = e.target;

    setVehiculo({
      ...vehiculo,
      [name]: value
    });

    setVin(value);
  }

  const handleDecodeVin = async () => {
    setIsLoading(true);
    const data = await vinDecode(vin);
    setIsLoading(false);

    if (data && data.status) {
      setVehiculo({
        ...vehiculo,
        width: data.data.width,
        height: data.data.height,
        length: data.data.length,
        weight: data.data.weight
      })
    } else {
      errorMessage(data.message);
    }
  }

  const handleOnChangeVH  = (evt) => {
    const {name, checked} = evt.target
    setVehiculo({
      ...vehiculo,
      [name]: checked
    });
  }

  const isNull = data => data === null || data === '';

  const handleModificarVehiculo = async () => {
    if (isNull(vehiculo.width) ||
      isNull(vehiculo.height) ||
      isNull(vehiculo.length) ||
      isNull(vehiculo.weight) ||
      isNull(vehiculo.vin) ||
      isNull(vehiculo.lote)) {
      errorMessage('Aun faltan por completar campos');
      return;
    }

    const data = await editCar(vehiculo.vehiculoId, {
      ...vehiculo,
      year: +vehiculo.year,
      width: vehiculo.width.toString(),
      height: vehiculo.height.toString(),
      length: vehiculo.length.toString(),
      weight: vehiculo.weight.toString()
    });

    if (data && data.status && afterSaveVehiculo) {
      afterSaveVehiculo(vehiculo);
    }
  }

  return(
    <Spinner loading={isLoading}>
      <Row>
        <Col xs={12} lg="12">
          <h5>Paso 1.</h5>
          <Form.Label className='text-aling-left'>Selecione Vehiculo</Form.Label>
          <Select
            onChange={handleSelectChangeVehiculo}
            defaultValue={{ label: "Seleccionar", value: "empty" }}
            name='vehiculoId'
            options={vehiculos && vehiculos.length ? vehiculos.map(vehi => ({
              label: 'Lote: ' + vehi.lote + ' - ' + vehi.vehiculoName, value: vehi.vehiculoId
            })) : []}
          />
          <hr/>
        </Col>
        <Col xs={12} lg="12">
          <h5>Paso 2.</h5>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom04">
                <Form.Label>Usar decodificador de VIN para completar información</Form.Label>
                <Form.Control type="text"
                              name="vin"
                              value={vin}
                              onChange={handleChange}
                              placeholder="Ingrese el numero VIN"
                              required />
                <Form.Control.Feedback type="invalid">
                  Este campo es requerido para la consulta
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Col lg="7">
                <Button onClick={handleDecodeVin} variant='success' type="button">Consultar VIN</Button>
                { isLoading ? <Loadingsmall/> : <></> }
              </Col>
            </Row>
          </Form>
          <hr/>
        </Col>
        <Col xs={12} lg="12">
          <Row>
            <h5>Paso 3.</h5>
            <Form.Label className='text-aling-left'>Verificar información</Form.Label>
            <Col xs={12} lg="7" className="mb-3">
              <Form.Label className='text-aling-left'>VIN</Form.Label>
              <Form.Control onChange={handleChange} name='vin' value={vehiculo?.vin} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="5" className="mb-3">
              <Form.Label className='text-aling-left'>LOTE</Form.Label>
              <Form.Control onChange={handleChange} name='lote' value={vehiculo?.lote} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg="4" className="mb-3">
              <Form.Label className='text-aling-left'>Marca</Form.Label>
              <Form.Control onChange={handleChange} name='make' value={vehiculo?.make} required aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="5" className="mb-3">
              <Form.Label className='text-aling-left'>Modelo</Form.Label>
              <Form.Control onChange={handleChange} name='model' value={vehiculo?.model} required aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3" className="mb-3">
              <Form.Label className='text-aling-left'>Año</Form.Label>
              <Form.Control onChange={handleChange} name='year' value={vehiculo?.year} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs={6} md={6} className="mb-3">
              <Form.Check
                checked={vehiculo && vehiculo?.titulo}
                label="Titulo"
                name='titulo'
                onChange={handleOnChangeVH}
                type="checkbox"
                id="inline"/>
            </Col>
            <Col xs={6} md={6} className="mb-3">
              <Form.Check
                checked={vehiculo && vehiculo?.llave}
                label="Llave"
                name='llave'
                onChange={handleOnChangeVH}
                type="checkbox"
                id="inline"/>
            </Col>
            <Col xs={12} lg="6" className="mb-3">
              <Form.Label className='text-aling-left'>Color</Form.Label>
              <Form.Control onChange={handleChange} name='color' value={vehiculo?.color} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="6" className="mb-3">
              <Form.Label className='text-aling-left'>Storage</Form.Label>
              <Form.Control onChange={handleChange} name='storage' value={vehiculo?.storage} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs={12} lg="3" className="mb-3">
              <Form.Label className='text-aling-left'>Peso</Form.Label>
              <Form.Control onChange={handleChange} name='weight' value={vehiculo?.weight} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3" className="mb-3">
              <Form.Label className='text-aling-left'>Largo</Form.Label>
              <Form.Control onChange={handleChange} name='length' value={vehiculo?.length} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3" className="mb-3">
              <Form.Label className='text-aling-left'>Alto</Form.Label>
              <Form.Control onChange={handleChange} name='height' value={vehiculo?.height} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3" className="mb-3">
              <Form.Label className='text-aling-left'>Ancho</Form.Label>
              <Form.Control onChange={handleChange} name='width' value={vehiculo?.width} aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg="6">
          <Button variant="secondary" className="w-100" onClick={onCancelar}>
            Cancelar
          </Button>
        </Col>
        <Col xs={12} lg="6">
          <Button onClick={handleModificarVehiculo} className="w-100" variant="primary">
            <FontAwesomeIcon icon={faFloppyDisk} /> Actualizar
          </Button>
        </Col>
      </Row>
    </Spinner>
  );
}

export default AddVehicleWarehouseForm;
