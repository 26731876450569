import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { showCoordPorCliente } from "../../Servicios/CoordService";
import { SolicitudesByClient } from "../../Servicios/SolicitudesService";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import "styled-components";
import "../../hojas-de-estilo/style.css";
// import Container from "react-bootstrap/esm/Container";
// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";
import SpinnerTable from "../../Componentes/SpinnerTable";
import moment from "moment";
import { Content } from "antd/es/layout/layout";
import { Col, Row } from "antd";

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <Form.Control
      className="input-buscar"
      id="search"
      type="text"
      placeholder="Buscar Coordinacion por Lote"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      style={{
        textAlign: "center",
      }}
    />
  </>
);

const CoordiByClient = () => {
  const { id } = useParams();

  const [solicitudes, setSolicitudes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const showSolicitudes = async () => {
    setIsLoading(true);
    const data = await SolicitudesByClient(id).finally(() => {
      setIsLoading(false);
    });
    setSolicitudes(data);
    console.log(data);
  };

  useEffect(() => {
    showSolicitudes();
  }, [id]);

  const columns = [
    {
      name: "Fecha",
      selector: (row) => row.fechaQ,
      format: (row) => moment(row.fechaQ).format("l"),
    //   width: "100px",
    },
    {
      name: "Lote",
      selector: (row) => row.lote,
    //   width: "100px",
    },
    {
      name: "Vehiculo",
      selector: (row) => row.vehiculoName,
    //   width: "200px",
    },
    {
      name: "Origen",
      selector: (row) => row.nameOrigen,
    //   width: "90px",
    },
    {
      name: "Destino",
      selector: (row) => row.aduanaName,
    //   width: "100px",
    },
    {
      name: "Comprado en",
      selector: (row) => row.subastaCom,
    //   width: "250px",
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = solicitudes.filter(
    (item) =>
      item.lote &&
      item.lote.toString().toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Content>
        <Row gutter={[16,24]}>
          {/* <Row>
            <h4>Solicitudes Realizadas</h4>
          </Row> */}
          <Col 
            span={12}
            />

          <Col 
            span={12}
          >
            {/* <Form.Label className="input-buscar">
              Buscar solicitud para "Ver" su información
            </Form.Label> */}
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </Col>
        </Row>
      </Content>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Content>
      <Row gutter={[16, 24]}>
        <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
        >
          <DataTable
            columns={columns}
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            progressPending={isLoading}
            progressComponent={<SpinnerTable />}
            persistTableHead
            pagination
            responsive={true}   
          />
        </Col>
      </Row>
    </Content>
  );
};

export default CoordiByClient;
