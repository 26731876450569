import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Divider, Space, Image } from "antd";
import { SmileOutlined, CloudOutlined, MoonOutlined } from "@ant-design/icons";
import moment from "moment";
// import 'moment/locale/es'; // Import Spanish locale

const { Title } = Typography;

const WeatherDisplay = () => {
    // moment.locale('es');
  const [timeOfDay, setTimeOfDay] = useState("");

  useEffect(() => {
    const currentTime = moment();
    const currentHour = currentTime.hour();
    if (currentHour >= 5 && currentHour < 12) {
      setTimeOfDay("morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      setTimeOfDay("afternoon");
    } else {
      setTimeOfDay("night");
    }
  }, []);

  const renderWeatherIcon = () => {
    if (timeOfDay === "morning") {
      return (
        <Image
          src="https://img.icons8.com/color/96/000000/sun--v1.png"
          preview={false}
          width={50}
        />
      );
    } else if (timeOfDay === "afternoon") {
      return (
        <Image
          src="https://img.icons8.com/color/96/000000/partly-cloudy-day--v1.png"
          preview={false}
          width={50}
        />
      );
    } else {
      return (
        <Image
          src="https://img.icons8.com/color/96/000000/clouds.png"
          preview={false}
          width={50}
        />
      );
    }
  };

  return (
    <div>
      <Row style={{
        flex: 1,
      }}>
        <Title level={2}>
          {timeOfDay === "morning"
            ? "Buenos Días"
            : timeOfDay === "afternoon"
            ? "Buenas Tardes"
            : "Buenas Noches"}
        </Title>
          {renderWeatherIcon()}
          {/* You'll replace these icons with actual weather icons from your API */}
      </Row>
      <Row>
        <Col>
          <p>{moment().format('LLLL')}</p>
          
        </Col>
      </Row>
    </div>
  );
};

export default WeatherDisplay;
