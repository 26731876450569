import styled from "styled-components";

export const LayoutStyles = styled.div`
.invoice-box {
	max-width: 850px;
	margin: auto;
	font-size: 12px;
	font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
	color: #555;
}

.invoice-box table {
	width: 100%;
	line-height: inherit;
	text-align: left;
}

.invoice-box table td {
	padding: 5px;
	vertical-align: top;

}

.invoice-box table tr.top td.title {
	text-align: left;
}
	
.invoice-box table tr.top td.basic {
	text-align: right;
}

.invoice-box table tr.heading td {
	background: #eee;
	border-bottom: 1px solid #ddd;
	font-weight: bold;
	text-align: center;
	border: 0px solid black;
	width: 25%;
}
	
.invoice-box table tr.heading td.descharge {
	background: #eee;
	border-bottom: 1px solid #ddd;
	font-weight: bold;
	border: 0px solid black;
	width: 500px;
}

.invoice-box tr.headinginfo td {
	background: #eee;
	font-weight: bold;
	width: 500px;
}
.invoice-box tr.headinginfo td.shipper {
	background: #eee;
	font-weight: bold;
	width: 500px;
}

.invoice-box table tr.details td {
	padding-bottom: 20px;
	text-align: left;
}
	
.invoice-box table tr.details td.shipper {
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	border-top: 1px solid black;
	border-right: 1px solid black;
	border-collapse: collapse;
	width: 25%;
}
.invoice-box table tr.details td.pay {
	border-bottom: 1px solid black;
	border-top: 1px solid black;
	border-right: 1px solid black;
	border-collapse: collapse;
	width: 20%;
}


.invoice-box table tr.detailsinfo td.label {
	text-align: left;
	width: 20%;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
	border-collapse: collapse;
}
.invoice-box table tr.detailsinfo td.label1 {
	text-align: left;
	width: 20%;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px solid black;
	border-collapse: collapse;
}

.invoice-box table tr.detailsinfo td.topel {
	text-align: left;
	width: 20%;
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	border-top: 1px solid black;
	border-collapse: collapse;
}
	
.invoice-box table tr.detailsinfo td.topcenl {
	text-align: left;
	width: 25%;
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	border-top: 1px solid black;
	border-collapse: collapse;
}
	
.invoice-box table tr.detailsinfo td.topcenr {
	text-align: left;
	width: 20%;
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	border-top: 1px solid black;
	border-right: 1px solid black;
	border-collapse: collapse;
}

.invoice-box table tr.detailsinfo td.info {
	text-align: left;
	width: 25%;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
	border-collapse: collapse;
}
	
.invoice-box table tr.detailsinfo td.pcs {
	text-align: left;
	width: 70px;
	border-right: 1px solid black;
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	border-top: 1px solid black;
	border-collapse: collapse;
}
.invoice-box table tr.detailsinfo td.pacage {
	text-align: left;
	width: 250px;
	border-bottom: 1px solid black;
	border-top: 1px solid black;
	border-right: 1px solid black;
	border-collapse: collapse;
}
.invoice-box table tr.detailsinfo td.desc {
	text-align: left;
	width: 400px;
	border-bottom: 1px solid black;
	border-top: 1px solid black;
	border-right: 1px solid black;
	border-collapse: collapse;
}

/** RTL **/
.invoice-box.rtl {
	direction: rtl;
	font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box table .vehiculoinfo {
	table-layout: fixed;
	width: 250px;
}

.invoice-box th, td .vehiculoinfo {
	word-wrap: break-word;
}
`;
