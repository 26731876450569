import { useState, useEffect } from "react";
import { Row, Col, Button, Table } from "antd";
import {
  getListAllClientsPreRegister,
  getOneVehiclePreRegister,
} from "../../Servicios/ClientRequestService";
import {
  EditOutlined,
  // icono de check para confirmar
  CheckOutlined,
} from "@ant-design/icons";

const CustomerRequest = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDataClientPreRegister = async () => {
    const response = await getListAllClientsPreRegister();
    console.log(response.data);
    setData(response.data?.map((item) => ({ ...item, key: item.id_cliente_pre })) || []);
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombres_pre",
      key: "nombres_pre",
    },
    {
      title: "Apellido",
      dataIndex: "apellidos_pre",
      key: "apellidos_pre",
    },
    {
      title: "Telefono",
      dataIndex: "telefono_pre",
      key: "telefono_pre",
    },
    {
      title: "Correo",
      dataIndex: "correo_pre",
      key: "correo_pre",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Pais",
      dataIndex: "pais_pre",
      key: "pais_pre",
    },
    {
      title: "Importadora",
      dataIndex: "import_pre",
      key: "import_pre",
    },
    {
      title: "Aduana",
      dataIndex: "aduana_pre",
      key: "aduana_pre",
    },
    {
      title: "Notas",
      dataIndex: "notas_pre",
      key: "notas_pre",
    },
    {
      title: "Opciones",
      dataIndex: "options",
      key: "options",
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => {}}
          shape="circle"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "green",
          }}
          icon={<CheckOutlined />}
        ></Button>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    console.log(record, "record");

    const columns = [
      { title: "Lote", dataIndex: "lote_pre", key: "lote_pre" },
      { title: "Marca", dataIndex: "make_pre", key: "make_pre" },
      { title: "Modelo", dataIndex: "model_pre", key: "model_pre" },
      { title: "Año", dataIndex: "year_pre", key: "year_pre" },
      { title: "Subasta", dataIndex: "subasta_pre", key: "subasta_pre" },
      { title: "Gate Pass", dataIndex: "gate_pass_pre", key: "gate_pass_pre" },
      { title: "Sublote", dataIndex: "sublote", key: "sublote" },
      { title: "Fecha de pago", dataIndex: "fechapago", key: "fechapago" },
      { title: "Link", dataIndex: "link_pre", key: "link_pre" },
    ];

    const data = [];
    record?.vehiculos?.map((vehicle) => {
      data.push({
        key: vehicle.id_vehiculo_pre,
        lote_pre: (
          <div
            style={{
              backgroundColor: "#001529",
              color: "white",
              padding: "10",
              textAlign: "center",
            }}
          >
            {vehicle.lote_pre}{" "}
          </div>
        ),
        make_pre: (
            <div
                style={{
                    backgroundColor: "#001529",
                    color: "white",
                    padding: "10",
                    textAlign: "center",
                }}
            >
                {vehicle.make_pre}{" "}
            </div>
        ),
        model_pre: (
            <div 
                style={{
                    backgroundColor: "#001529",
                    color: "white",
                    padding: "10",
                    textAlign: "center",
                }}>
            {vehicle.model_pre}</div>
        ),
        year_pre: (
            <div 
                style={{
                    backgroundColor: "#001529",
                    color: "white",
                    padding: "10",
                    textAlign: "center",
                }}>
            {vehicle.year_pre}</div>
        ),
        subasta_pre: (
            <div 
                style={{
                    backgroundColor: "#001529",
                    color: "white",
                    padding: "10",
                    textAlign: "center",
                }}>
            {vehicle.subasta_pre}</div>
        ),
        gate_pass_pre: (
            <div 
                style={{
                    backgroundColor: "#001529",
                    color: "white",
                    padding: "10",
                    textAlign: "center",
                }}>
            {vehicle.gate_pass_pre}</div>
        ),
        sublote: (
            <div 
                style={{
                    backgroundColor: "#001529",
                    color: "white",
                    padding: "10",
                    textAlign: "center",
                }}>
            {vehicle.sublote ? 'SI':'NO'}</div>
        ),
        fechapago: (
            <div 
                style={{
                    backgroundColor: "#001529",
                    color: "white",
                    padding: "10",
                    textAlign: "center",
                }}>
            {vehicle.fechapago}</div>
        ),
        link_pre: (
            <a href={vehicle.link_pre} target="_blank">
                Ver link
            </a>
        ),
      });
    });

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  useEffect(() => {
    setIsLoading(true);
    getDataClientPreRegister().finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <Row gutter={[16, 24]}>
        <Col
          span={24}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h4>Solicitudes de clientes</h4>
        </Col>
      </Row>
      <hr />
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
            rowExpandable: (record) => record?.vehiculos?.length > 0,
        }}
        dataSource={data}
        loading={isLoading}
        pagination={false}
      />
    </div>
  );
};

export default CustomerRequest;
