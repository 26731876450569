import {
  Button,
  Steps,
  theme,
  Form,
  Input,
  Col,
  Row,
  DatePicker,
  Modal,
  Space,
  Divider,
  Select,
} from "antd";
import React, {useEffect, useState, useRef} from "react";
import {
  getSubastas,
  getImportadora,
  getServiceType,
  getModeTransportation,
  getPortCode,
  getExportationPdf,
  updateExportation,
  getExportationIdData,
  exportationContainerAsignar,
  exportationContainerRemover,
} from "../../../Servicios/ExportationServices";
import "react-toastify/dist/ReactToastify.css";
import {PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import useCurrentUser from "../../../Hooks/UserHook";
import Spinner from "../../../shared/spinner";
import ComponentToPrint from "../pdf";
import {useNavigate, useParams} from "react-router";
import {errorMessage, successMessage} from "../../../utils/toast-message";
import PdfReport from "../../../utils/pdfReport";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import PortCodeForm from "../../PortCode/PortCodeForm";
import ImportadoraForm from "../../../Componentes/ImportadoraForm/ImportadoraForm";
import ExportationBookingForm from "./ExportationBookingForm";
import AsignacionContenedor from "./AsignacionContenedor";
import {enviarEmail} from "../../../Servicios/SolicitudesService";
import environment from "../../../environments/environment";
import {
  NotificacionProgramacionCargaExportation
} from "../../../Resources/HtmlEmailBody/SolicitudRecoleccionVehiculo";

const {TextArea} = Input;

const onChange = (value) => {
};

const onSearch = (value) => {
};

const ExportationFormPage = (props) => {
  const {id} = useParams();
  const navigate = useNavigate();
  let componentRef = useRef();
  const currentUser = useCurrentUser();
  const navigator = useNavigate();
  const [loading, setIsLoading] = useState(!!id);
  const [form] = Form.useForm();
  const [exportation, setExportation] = useState(undefined);
  form.setFieldsValue({
    userName: currentUser?.fullName,
    userId: parseInt(currentUser?.idusr),
    placeExId: parseInt(currentUser?.idubc),
    shipmentName: exportation,
  });
  const [optionSelectSubasta, setSelectSubasta] = useState([]);
  const [optionSelectPortCode, setSelectPortCode] = useState([]);
  const [optionSelectImportadora, setSelectImportadora] = useState([]);
  const [optionSelectServiceType, setSelectServiceType] = useState([]);
  const [optionSelectModeTransportation, setSelectModeTransportation] = useState([]);
  const [optionSelectPortCodeDesrtination, setSelectPortCodeDestination] = useState([]);
  const [optionSelectDisableForwading, setOptionSelectDisableForwading] = useState(false);
  const [optionSelectDisableNotifyParty, setOptionSelectDisableNotifyParty] = useState(false);

  const [portCode, setPortCode] = useState(undefined);
  const [tipSpinner, setTipSpinner] = useState("");
  const [showAddConsignee, setShowAddConsignee] = useState(false);

  const onSelectedPort = (value, option) => {
    console.log("option", option);
    form.setFieldsValue({
      portOriginCode: option.port,
    });
  };

  const onSelectPortDestination = (value, option) => {
    console.log("option", option);
    form.setFieldsValue({
      portDestinationCode: option.port,
    });
  };

  const updateAndSaveInformationAditional = async (values) => {
    try {
      setTipSpinner("Actualizando Información Adicional");

      values.dateNotShipment = values.dateNotShipment
        ? moment(values.dateNotShipment.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateNotArrive = values.dateNotArrive
        ? moment(values.dateNotArrive.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateNotEmtied = values.dateNotEmtied
        ? moment(values.dateNotEmtied.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateReceivingRTU = values.dateReceivingRTU
        ? moment(values.dateReceivingRTU.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateUpdateRTU = values.dateUpdateRTU
        ? moment(values.dateUpdateRTU.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateNotZarpe = values.dateNotZarpe
        ? moment(values.dateNotZarpe.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.containerId = exportation?.containerId;
      values.countryId = exportation?.countryId;
      values.createDate = exportation?.createDate;
      values.state = exportation?.state;
      values.isActive = exportation?.isActive;
      values.amountAditional = exportation?.amountAditional;
      values.countryName = exportation?.countryName;
      values.customerReference = exportation?.customerReference;
      values.dateLoading = exportation?.dateLoading;
      values.approvalNumber = exportation?.approvalNumber;
      values.sealNumber = exportation?.sealNumber;
      values.userName = exportation?.userName;
      values.descriptionAditional = exportation?.descriptionAditional;
      values.noteLoading = exportation?.noteLoading;

      values.exportationId = exportation?.exportationId;

      const input = {
        ...exportation,
        ...values
      };

      setIsLoading(true);
      await updateExportation(input);
      setIsLoading(false);

      successMessage('Exportacion actualizada con exito');
      navigate('/exportation/list');
    } catch (ex) {
      errorMessage(`${ex.toString()}`);
    }
  };

  const fetch = async (exportation) => {
    // CAPTURAMOS SUBASTAS
    const dataSubasta = await getSubastas();
    const optionSubasta = dataSubasta
      .filter((e) => !!e.shipper)
      .map((element) => ({
        value: element.subId,
        label: `${element.subName} - ${element.codeState}`,
      }));
    setSelectSubasta(optionSubasta);

    // CAPTURAMOS LA IMPORTADORA
    const dataImportadora = await getImportadora();
    const optionImportadora = dataImportadora
      .filter((e) => !!e.consignee)
      .map((element) => ({
        value: element.id,
        label: element.impName,
      }));
    setSelectImportadora(optionImportadora);

    // CAPUTARAMOS EL TIPO DE SERVICIO
    const dataServiceType = await getServiceType();
    const optionServiceType = dataServiceType.map((element) => ({
      value: element.serviceId,
      label: element.name,
    }));
    setSelectServiceType(optionServiceType);

    //CAPTURAMOS EL MODO DE TRASNPORTACION
    const dataModeTransportation = await getModeTransportation();
    const optionModeTransportation = dataModeTransportation.map((element) => ({
      value: element.modeTransportationId,
      label: element.name,
    }));
    setSelectModeTransportation(optionModeTransportation);

    //CAPTURAMOS EL PORTCODE
    const dataPortCode = await getPortCode();

    const optionPortCode = dataPortCode
      .filter((element) => element.typeState === "Origin")
      .map((element) => ({
        value: element.portId,
        label: element.name,
        typeState: element.typeState,
        port: element.port,
      }));
    setSelectPortCode(optionPortCode);
    const optionPortCodeDestination = dataPortCode
      .filter(
        (element) =>
          element.typeState === "Destination" &&
          element.country === exportation?.codeCountry
      )
      .map((element) => ({
        value: element.portId,
        label: element.name,
        typeState: element.typeState,
        port: element.port,
      }));

    setSelectPortCodeDestination(optionPortCodeDestination);
    form &&
    form.setFieldsValue({
      portUnloadingId: optionPortCodeDestination.filter(
        (element) => element.port === exportation?.portDestinationCode
      )[0]?.value
        ? optionPortCodeDestination.filter(
          (element) => element.port === exportation?.portDestinationCode
        )[0]?.value
        : null,
    });
  };

  useEffect(() => {
    if (currentUser && id) {
      initExportation().then();
    } else {
      fetch().then();
    }
  }, [currentUser, id]);

  const initExportation = async () => {
    setIsLoading(true);
    const data = await getExportationIdData(id);
    setIsLoading(false);

    setExportation({...data});
    await fetch(data);
    await setExportationForm(data);
  }

  const [visible, setVisible] = useState(false);
  const [dataReport, setDataReport] = useState("");

  const {token} = theme.useToken();
  const [current, setCurrent] = useState(0);

  const handleSelectPortCode = (portCode) => setPortCode(portCode);

  const setExportationForm = async (exportation) => {
    if (!exportation) return;

    form.setFieldsValue({
      shipmentName: exportation?.shipmentName,
      billLadingNum: exportation?.billLadingNum,
      bookingNum: exportation?.bookingNum,
      containerId: exportation?.containerId,
      amountAditional: exportation?.amountAditional,
      idAduana: exportation?.idAduana,
      countryId: exportation?.countryId,
      countryName: exportation?.country?.nameCountry,
      customerReference: exportation?.customerReference,
      colorId: exportation?.colorId,
      approvalNumber: exportation?.approvalNumber,
      sealNumber: exportation?.sealNumber,
      userId: exportation?.userId,
      placeExId: exportation?.placeExId,
      descriptionAditional: exportation?.descriptionAditional,
      dateLoading: exportation?.dateLoading
        ? moment(exportation?.dateLoading)
        : null,
      noteLoading: exportation?.noteLoading,

      // datos adicionales

      departureDate: exportation?.departureDate
        ? moment(exportation?.departureDate)
        : null,
      arrivalDate: exportation?.arrivalDate
        ? moment(exportation?.arrivalDate)
        : null,
      dateNotArrive: exportation?.dateNotArrive
        ? moment(exportation?.dateNotArrive)
        : null,
      dateNotShipment: exportation?.dateNotShipment
        ? moment(exportation?.dateNotShipment)
        : null,
      dateNotEmtied: exportation?.dateNotEmtied
        ? moment(exportation?.dateNotEmtied)
        : null,
      dateReceivingRTU: exportation?.dateReceivingRTU
        ? moment(exportation?.dateReceivingRTU)
        : null,
      dateUpdateRTU: exportation?.dateUpdateRTU
        ? moment(exportation?.dateUpdateRTU)
        : null,
      dateNotZarpe: exportation?.dateNotZarpe
        ? moment(exportation?.dateNotZarpe)
        : null,
      containerDesc: exportation?.containerDesc,
      descriptionGoods: exportation?.descriptionGoods,
      idSubShip: exportation?.idSubShip,
      idImpNp: exportation?.idImpNp,
      idSubFa: exportation?.idSubFa,
      idImpUc: exportation?.idImpUc,
      serviceId: exportation?.serviceId,
      modeTransportationId: exportation?.modeTransportationId,
      pointOrigenFtzNum: exportation?.pointOrigenFtzNum,
      placeReceiptCarrier: exportation?.placeReceiptCarrier,
      portLoadingId: exportation?.portLoadingId,
      vesselNameFlag: exportation?.vesselNameFlag,
      preCarriageBy: exportation?.preCarriageBy,
      loadingPierTerminal: exportation?.loadingPierTerminal,
      idCarrier: exportation?.idCarrier,
      voyageIdenticication: exportation?.voyageIdenticication,
      portUnloadingId: exportation?.portUnloadingId,
      portOriginCode: exportation?.portOriginCode,
      onCarriageBy: exportation?.onCarriageBy,
      portDestinationCode: exportation?.portDestinationCode,
      domesticRouting: exportation?.domesticRouting,
      placeDelivery: exportation?.placeDelivery
    });
  }

  const onSavePortCode = (values) => {
    fetch().then(() => {
      form.setFieldsValue({
        portUnloadingId: values.portId,
      });
    });

    setPortCode(undefined);
  };

  const handleUpdateImportadora = (values) => {
    console.log("VALUES IMPORTADORA", values);
    fetch().then(() => {
      form.setFieldsValue({
        idImpUc: values.id,
        idImpNp: values.id,
      });
    });
    setOptionSelectDisableNotifyParty(true);
    setShowAddConsignee(false);
  };

  const handleSaveExportationBooking = async (newExportation) => {
    if (exportation) {
      setCurrent(1);
      setExportation(newExportation);
      await setExportationForm(newExportation);
    } else {
      navigate('/exportation/list');
    }
  }

  const handleSaveExportationContainer = async () => {
    await initExportation();
  }

  const handleSelectContainer = async (exportationContainer) => {
    try {
      const data = {
        idExportationContainer: exportationContainer.idExportationContainer,
        containerNumber: exportationContainer.containerNumber,
        sealNumber: exportationContainer.sealNumber,
        letter: exportationContainer.letter,
      };

      setIsLoading(true);
      await exportationContainerAsignar(exportation.exportationId, data).finally(() => setIsLoading);
      setIsLoading(false);

      successMessage('Contenedor asignado correctamente');
      await initExportation();
    } catch (ex) {
      errorMessage(`${ex.toString()}`)
    }
  }

  const handleDeleteContainer = async (exportationContainer) => {
    try {
      const data = {
        idExportationContainer: exportationContainer.idExportationContainer,
        containerNumber: exportationContainer.containerNumber,
        sealNumber: exportationContainer.sealNumber,
        letter: exportationContainer.letter,
      };

      setIsLoading(true);
      await exportationContainerRemover(exportation.exportationId, data).finally(() => setIsLoading);
      setIsLoading(false);

      successMessage('Contenedor eliminado correctamente');
      await initExportation();
    } catch (ex) {
      errorMessage(`${ex.toString()}`)
    }
  }

  const enviarCorreo = async (exportation) => {
    try {
      const date = moment().format("DD-MM-YYYY");
      await enviarEmail({
        to: [environment.emails.grua],
        cc: [currentUser?.email],
        subject: `${date} - VEHÍCULO PROXIMOS A CARGARSE CON ${exportation?.carrier?.oceanCarrierName} CON DESTINO ${exportation?.aduana?.nameAduana}`,
        body: NotificacionProgramacionCargaExportation(exportation),
        attachedFile: [],
      });

      successMessage("Programa de carga agregado con exito");
    } catch (error) {
      errorMessage(error.toString());
    }
  };

  const handleImprimir = async (exportation) => {
    setIsLoading(true);
    setTipSpinner("Cargando PDF....");
    const data = await getExportationPdf(exportation.exportationId)
      .finally(() => setIsLoading(false));

    if (data.status) {
      setVisible(true);
      setDataReport(data.data);
      await enviarCorreo(exportation);
    } else {
      setVisible(false);
      errorMessage(data.message);
    }
  }

  const steps = [
    {
      title: "Control de Booking",
      content: () => {
        return (
          <div className="p-3">
            <ExportationBookingForm exportation={exportation}
                                    onSubmit={handleSaveExportationBooking}/>
          </div>
        );
      },
    },
    {
      title: 'Asignación de contenedores',
      content: () => {
        return (
          <div className="p-3">
            <AsignacionContenedor exportation={exportation}
                                  onSelectContainer={handleSelectContainer}
                                  onDeleteContainer={handleDeleteContainer}
                                  onUpdate={handleSaveExportationContainer}/>

            <div className="row">
              <div className="col-6">
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button block onClick={() => setCurrent(0)}>
                    Atras
                  </Button>
                </Space>
              </div>

              <div className="col-6">
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button type="primary" block onClick={() => setCurrent(2)}>
                    Continuar
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Imprimir Documento",
      content: () => {
        return (
          <div>
            <PdfReport
              visible={visible}
              setVisible={setVisible}
              data={dataReport}
              setData={setDataReport}
            />
            <ComponentToPrint
              ref={(component) => (componentRef = component)}
              exportation={exportation}
            />
            <Row gutter={[16, 24]} style={{marginBottom: 10}}>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button
                    block
                    style={{backgroundColor: "green", color: "white"}}
                    onClick={() => handleImprimir(exportation)}>
                    Imprimir
                  </Button>
                </Space>
              </Col>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button block onClick={() => setCurrent(current - 1)}>
                    {" "}
                    Atras{" "}
                  </Button>
                </Space>
              </Col>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      setCurrent(current + 1);
                      setExportationForm(exportation).then();
                    }}>
                    Continuar
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: "Información Adicional",
      content: () => {
        return (
          <div className="p-3">
            <Form
              layout={"vertical"}
              form={form}
              onFinish={updateAndSaveInformationAditional}
              autoComplete="off">
              <Row gutter={[16, 24]}>
                <Divider>Aviso</Divider>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Fecha de Notificacion arribo:"
                    name="dateNotArrive"
                  >
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{width: "100%"}}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Fecha Aviso de Embarque:"
                    name="dateNotShipment"
                  >
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{width: "100%"}}
                    />
                  </Form.Item>

                  <Form.Item label="Fecha de vaciado:" name="dateNotEmtied">
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{width: "100%"}}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item label="Fecha recibir RTU:" name="dateReceivingRTU">
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{width: "100%"}}
                    />
                  </Form.Item>

                  <Form.Item label="Fecha Modificación RTU:" name="dateUpdateRTU">
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{width: "100%"}}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Fecha Notificacion atraso Zarpe:"
                    name="dateNotZarpe"
                  >
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{width: "100%"}}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item label="Description Container:" name="containerDesc">
                    <TextArea rows={5} placeholder="Description Container"/>
                  </Form.Item>
                  <Form.Item
                    label="Description of Goods:"
                    name="descriptionGoods"
                  >
                    <TextArea rows={6}/>
                  </Form.Item>
                </Col>
                <Divider>Entities</Divider>
                <Col className="gutter-row" span={12}>
                  <Form.Item label="Shipper" name="idSubShip">
                    <Select
                      showSearch
                      placeholder="Shipper"
                      optionFilterProp="children"
                      onChange={(value) => {
                        form.setFieldsValue({
                          idSubFa: value,
                        });
                        setOptionSelectDisableForwading(true);
                      }}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionSelectSubasta}
                    />
                  </Form.Item>

                  <Form.Item label="Forwarding Agent" name="idSubFa">
                    <Select
                      disabled={optionSelectDisableForwading}
                      showSearch
                      placeholder="Forwarding Agent"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionSelectSubasta}
                    />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={12}>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={20}>
                      <Form.Item label="Ultimate Consignee" name="idImpUc">
                        <Select
                          showSearch
                          placeholder="Ultimate Consignee"
                          optionFilterProp="children"
                          onChange={(value) => {
                            form.setFieldsValue({
                              idImpNp: value,
                            });
                            setOptionSelectDisableNotifyParty(true);
                          }}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={optionSelectImportadora}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item label=" ">
                        <Button
                          block
                          type="primary"
                          icon={<PlusOutlined style={{fontSize: 17}}/>}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setShowAddConsignee(true);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label="Notify Party" name="idImpNp">
                    <Select
                      disabled={optionSelectDisableNotifyParty}
                      showSearch
                      placeholder="Notify Party"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionSelectImportadora}
                    />
                  </Form.Item>
                </Col>
                <Divider>Routing</Divider>
                <Col className="gutter-row" span={12}>
                  <Form.Item label="Service Type" name="serviceId">
                    <Select
                      showSearch
                      placeholder="Select Service Type"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionSelectServiceType}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Mode of Transportation"
                    name="modeTransportationId"
                  >
                    <Select
                      showSearch
                      placeholder="Select Mode of Transportation"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionSelectModeTransportation}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Domestic Routing/Export Instructions"
                    name="domesticRouting"
                  >
                    <Select
                      showSearch
                      placeholder="Select Domestic Routing"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          label: "RELEASE ORIGINALS UNRATED @ ORIGIN",
                          value: "RELEASE ORIGINALS UNRATED @ ORIGIN",
                        },
                        {
                          label: "RELEASE ORIGINALS UNRATED @ DESTINATION",
                          value: "RELEASE ORIGINALS UNRATED @ DESTINATION",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Divider>Origin</Divider>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Point of origin or FTZ Number:"
                    name="pointOrigenFtzNum"
                  >
                    <Input placeholder="Point of origin or FTZ Number"/>
                  </Form.Item>

                  <Form.Item
                    label="Place of Receipt by Pre-Carrier:"
                    name="placeReceiptCarrier"
                  >
                    <Input placeholder="Place of Receipt by Pre-Carrier"/>
                  </Form.Item>

                  <Form.Item label="Port of Loading" name="portLoadingId">
                    <Select
                      showSearch
                      placeholder="Port of Loading"
                      optionFilterProp="children"
                      onSelect={onSelectedPort}
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionSelectPortCode}
                    />
                  </Form.Item>

                  <Form.Item label="Vessel Name and Flag:" name="vesselNameFlag">
                    <Input placeholder="Vessel Name and Flag"/>
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={12}>
                  <Form.Item label="Pre-Carriage by:" name="preCarriageBy">
                    <Input placeholder="Pre-Carriage by"/>
                  </Form.Item>

                  <Form.Item
                    label="Loading Pier or Terminal:"
                    name="loadingPierTerminal"
                  >
                    <Input placeholder="Loading Pier or Terminal"/>
                  </Form.Item>

                  <Form.Item
                    label="Voyage Identification:"
                    name="voyageIdenticication"
                  >
                    <Input placeholder="Voyage Identificationer"/>
                  </Form.Item>
                </Col>

                <Divider>Destination</Divider>
                <Col className="gutter-row" span={12}>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={20}>
                      <Form.Item label="Port of Unloading" name="portUnloadingId">
                        <Select
                          showSearch
                          placeholder="Port of Unlading"
                          optionFilterProp="children"
                          onSelect={onSelectPortDestination}
                          onChange={onChange}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={optionSelectPortCodeDesrtination}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item label=" ">
                        <Button
                          type="primary"
                          onClick={() => handleSelectPortCode({})}
                          block
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{color: "white"}}
                          />
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label="Origin:" name="portOriginCode">
                    <Input placeholder="Origin" disabled/>
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={12}>
                  <Form.Item label="On Carriage by:" name="onCarriageBy">
                    <Input placeholder="On Carriage by"/>
                  </Form.Item>

                  <Form.Item label="Destination:" name="portDestinationCode">
                    <Input placeholder="Destination" disabled/>
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={12}>
                  <Form.Item label="Place of Delivery" name="placeDelivery">
                    <Input placeholder="Place of Delivery by on Carrier"/>
                  </Form.Item>
                </Col>
              </Row>
              <Divider></Divider>

              <Form.Item>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={12}>
                      <Button
                        onClick={() => setCurrent(current - 1)}
                        block
                        // style={{ backgroundColor: "green" }
                      >
                        Atras
                      </Button>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        style={{backgroundColor: "green"}}
                      >
                        Actualizar
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Form.Item>
            </Form>
          </div>
        );
      },
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    // lineHeight: "260px",
    // textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  return (
    <>
      <PortCodeForm
        open={!!portCode}
        forDestination={true}
        country={exportation?.codeCountry}
        onSave={onSavePortCode}
        onCancel={() => setPortCode(undefined)}
      />
      <Modal
        open={showAddConsignee}
        title={'Consignee'}
        onCancel={() => {
          setShowAddConsignee(false);
        }}
        footer={null}
      >
        <ImportadoraForm titulo={'Consignee'}
                         esConsignee={true}
                         country={exportation?.countryId?.toString()}
                         afterSubmit={handleUpdateImportadora}
                         onCancel={() => {
                           setShowAddConsignee(false);
                         }}/>
      </Modal>
      <Spinner
        loading={loading}
        // agregar texto de carga
        tip={tipSpinner ? tipSpinner : "Cargando..."}
      >
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{display: "flex", justifyContent: "space-between"}}
          >
            {id ? (
              <h4>Actualizar datos de exportación</h4>
            ) : (
              <h4>Registro de exportaciones</h4>
            )}
            <Button
              type="primary"
              onClick={() => navigator("/exportation/list")}
            >
              Lista de exportaciones
            </Button>
          </Col>
        </Row>

        <hr/>
        <Steps current={current} items={items}/>
        <div style={contentStyle}>{steps[current].content()}</div>

        {/* <ToastContainer /> */}
      </Spinner>
    </>
  );
};

export default ExportationFormPage;
