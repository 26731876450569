const getPdf = (title, base64) => {
    const newBase64 = `data:application/pdf;base64,${base64}`;

    let pdfWindow = window.open("", "_blank");
    let html = '';

    html += '<html>';
    html += '<title>' + title + '</title>'
    html += '<body style="margin:0 !important; overflow: hidden;">';
    html += '<embed width="100%" height="100%" src="' + newBase64 + '" type="application/pdf" />';
    html += '</body>';
    html += '</html>';

    setTimeout(() => {
        pdfWindow.document.write(html);
    }, 0);
};

export default getPdf;
