import React, {useEffect, useState} from "react";
import './styleformaes.css';
import {useParams} from "react-router-dom";
import Spinner from '../../shared/spinner';
import {
  getPortCodeporId,
  getItn, getDetalleExportById
} from '../../Servicios/ExportationServices';
import moment from "moment";
import Form from "react-bootstrap/Form";
import {v4 as uuidv4} from 'uuid';
import {Switch, Tag, Button} from "antd";
import {sendGsf} from '../../Servicios/ExportationServices';
import {country} from "../../utils/country";
import {ArrowLeftOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getFetchAllExportation} from "../../store/exportation";
import useCurrentUser from "../../Hooks/UserHook";
import {errorMessage, successMessage} from "../../utils/toast-message";
import {subastaIdentfTypeEnum} from "../../shared/enums/subasta-identf-type.enum";

const SendToAES = () => {

  const {id} = useParams();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [childExpoDta, setChildExpoDta] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [idLoad, setIdLoad] = useState(0);
  const [idUnload, setIdUnload] = useState(0);

  const [infoPortLoad, setInfoPortLoad] = useState({});
  const [infoPortUnload, setInfoPortUnload] = useState({});
  const [valueRCC, setValueRCC] = useState(false);
  const [valueHazmatIndicator, setValueHazmatIndicator] = useState(false);
  const [valueRoutedTransactionIndicator, setValueRoutedTransactionIndicator] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onChangeValueRCC = (e) => {
    console.log("VALUE:", e);
    setValueRCC(e);
  };

  const getCodPais = (name) => {
    // validar que el name repete las mayusculas y minusculas del pais
    // por el ejemplo si viene "EL SALVADOR" debe convertir y ser igual a "El Salvador"
    const countryName = name.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
    return country[countryName];

  };
  const onChangeValueHazmatIndicator = (e) => {
    console.log("VALUE:", e);
    setValueHazmatIndicator(e);
  };

  const onChangeValueRoutedTransactionIndicator = (e) => {
    console.log("VALUE:", e);
    setValueRoutedTransactionIndicator(e);
  };

  const infoEXPChil = async () => {
    setIsLoading(true);
    const data = await getDetalleExportById(id).finally(() => setIsLoading(false));
    setChildExpoDta(data.list);
    console.log("DETALLE", data.list);
  };

  const sendGsfPost = async (data) => {
    setIsLoading(true);
    const data1 = await sendGsf(id, data);
    setIsLoading(false);

    if (data1 && data1.status) {
      successMessage('Datos enviados correctamente, espere el correo de confirmación para validar que el ITN se encuentre generado');
      await dispatch(getFetchAllExportation(currentUser?.idubc));
      navigate('/exportation/list')
    } else {
      errorMessage('Error al enviar los datos');
    }
  }

  const handleEdit = (itemId, field, value) => {
    setEditedData({...editedData, [itemId]: {...editedData[itemId], [field]: value}});
  };

  const infoPortExport = async () => {
    if (idLoad !== 0) {
      const data1 = await getPortCodeporId(idLoad);
      setInfoPortLoad(data1);
      console.log(data1);
    }

    if (idUnload !== 0) {
      const data2 = await getPortCodeporId(idUnload);
      setInfoPortUnload(data2);
      console.log(data2);
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('YYYY-MM-DD');
  };

  useEffect(() => {
    if (idLoad && idUnload) {
      infoPortExport();
    }
  }, [idLoad, idUnload]);

  useEffect(() => {
    if (id) {
      infoEXPChil();
    }
  }, [id]);

  useEffect(() => {
    // This will be executed when childExpoDta is updated
    setIdLoad(childExpoDta[0]?.exportation?.portLoadingId);
    setIdUnload(childExpoDta[0]?.exportation?.portUnloadingId);
  }, [childExpoDta]);

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      alert("Complete los campos requeridos");
    }

    e.preventDefault();
    const formData = new FormData(e.target);
    console.log("Form data:", new FormData(e.target));

    const data = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    if (!childExpoDta[0].subasta.identf) {
      errorMessage('El campo Identificador de la subasta es requerido');
      return;
    }

    if (!childExpoDta[0].subasta.identfType) {
      errorMessage('El campo Tipo de Identificador de la subasta es requerido');
      return;
    }

    const shippingParty = [
      {
        partyCode: uuidv4(),
        partyType: data.AD0_3 ? data.AD0_3[0] : 'E',
        name: data.AD0_1,
        partyIDType: 'E',
        partyID: data.AD0_2,
        address: [data.AD0_4, data.AD0_5],
        city: data.AD0_6,
        state: data.AD0_7, // REVISAR ESTO SOLO ESPERA EL NAME DEL CODIGO DEL ESTADO
        zip: data.AD0_8,
        country: data.countryCod,
        contactName: data.AD0_9,
        contactLastName: data.AD0_11,
        phone: data.AD0_12,
      }

      ,
      {
        partyCode: uuidv4(),
        name: data.AD1_3,
        partyType: 'C',
        address: [data.AD1_8, data.AD1_9],
        city: data.AD1_10,
        // state: data.AD1_11,
        country: getCodPais(data.AD1_12),
        // zip: data.AD1_13,
        ultimateConsigneeType: data.AD1_14,
        // soIdEnRoute: data.AD1_6,
        // contactName: data.AD1_5,
        // phone: data.AD1_7,
      },
      {
        partyCode: uuidv4(),
        name: data.AD3_3,
        partyID: data.AD3_4,
        partyType: data.AD3_2 ? data.AD3_2[0] : 'F',
        partyIDType: 'E',
        address: [data.AD3_8, data.AD3_9],
        city: data.AD3_10,
        state: data.AD3_11,
        zip: data.AD3_13,
        contactName: data.AD3_5,
        country: data.AD3_12,
        phone: data.AD3_7,
        contactLastName: "MATUS", // agregar este al formulario

      }
    ];
    const {
      AD0_1,
      AD0_2,
      AD0_3,
      AD0_4,
      AD0_5,
      AD0_6,
      AD0_7,
      AD0_8,
      AD0_9,
      AD0_11,
      AD0_12,
      AD1_3,
      AD1_5,
      AD1_7,
      AD1_8,
      AD1_9,
      AD1_10,
      AD1_11,
      AD1_12,
      AD1_13,
      AD1_14,
      AD1_6,
      AD3_3,
      AD3_4,
      AD3_2,
      AD3_5,
      AD3_7,
      AD3_8,
      AD3_9,
      AD3_10,
      AD3_11,
      AD3_12,
      AD3_13,
      AD4_3,
      AD4_5,
      AD4_7,
      AD4_8,
      AD4_9,
      AD4_10,
      AD4_11,
      AD4_12,
      AD4_13,
      ...rest
    } = data;
    const newdata = {
      // ...rest,
      // orig_itn: rest.ORIG_ITN,
      shipmentRef: rest.SRN,
      booking: rest.BN,
      stateofOriginCode: rest.ST,
      loadingPortCode: rest.POE,
      ultimateCountryCode: rest.COD,
      ultimateCountryName: rest.CODNAME,
      loadingDate: /* convert moment to date YYYYMMDD */ formatDate(rest.EDA),
      dischargePortCode: rest.POU,
      transportationCode: rest.MOT,
      carrierCode: rest.SCAC,
      carrierName: rest.SCACNAME,
      vesselName: rest.VN,
      relatedCompany: valueRCC,
      hazmatIndicator: valueHazmatIndicator,
      routedTransactionIndicator: valueRoutedTransactionIndicator,
      usppiRefCode: shippingParty[0]?.partyCode,
      ultimateConsigneeRefCode: shippingParty[1]?.partyCode,
      forwardingAgentRefCode: shippingParty[2]?.partyCode,
      masterBillNumber: rest.BN,
      submitBy: currentUser.fullName,
      exportCode: rest.IT1_1,
      valueOfGood: rest.IT1_2,
      quantity: rest.IT1_4,
      grossWeight: rest.IT1_7,
      licenseType: rest.IT1_8,
      licenseValue: rest.IT1_10,
      commodityDescription: rest.IT1_12,
      scheduleB: rest.IT1_13,
      type: rest.IT1_16 ? rest.IT1_16[0] : 'V',
      vin: rest.IT1_17,
      titleNumber: +rest.IT1_18,
      titleStateCode: rest.IT1_19,
      originofGoodsCode: rest.IT1_21,
      subastaIdentification: childExpoDta[0].subasta.identf,
      subastaIdentificationType: childExpoDta[0].subasta.identfType === subastaIdentfTypeEnum.ein
        ? 'E' : 'T',
      shippingParty
    };

    sendGsfPost(newdata).then();
    console.log("Data: Nueva", newdata);
  }

  const handleValidarAES = async () => {
    const response = await getItn(childExpoDta[0]?.codeExpSecuential);

    if (response && response.status) {
      successMessage('ITN generado con exito');
    } else {
      errorMessage('No existe ITN');
    }
  };

  return (
    <Spinner loading={isLoading}>
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'
        }}>

          <Button type="primary" icon={
            <ArrowLeftOutlined/>
          } style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
                  onClick={() => navigate('/exportation/list')}
          >Regresar al listado</Button>


          <h5>

            EEI Information Summary - Web EEI - {id}
          </h5>

          <Tag color={
            childExpoDta[0]?.stateSend === "ENVIADO" ? "skyblue" : childExpoDta[0]?.stateSend === "ACEPTADO" ? "gree" : "red"

          }>{
            childExpoDta[0]?.stateSend === "ENVIADO" ? "ENVIADO" : childExpoDta[0]?.stateSend === "ACEPTADO" ? "ACEPTADO" : "NO ENVIADO"
          }</Tag>

        </div>
        <Form className='formAES' onSubmit={handleSubmit}>
          <table>
            <thead>
            <tr>
              <th className='header' colSpan="2"> General Information - Required By AES</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className='reference'><label>ITN Number:</label></td>
              <td className='option'><input className='infoAES' type="text" id="ORIG_ITN" name="ORIG_ITN"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Shipment Number:</label></td>
              <td className='option'>
                <input required className='infoAES' type="text" id="SRN" name="SRN"
                       value={childExpoDta[0]?.codeExpSecuential}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Filing Option:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="FO" name="FO" value="2"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Booking Number:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="BN" name="BN"
                                            value={childExpoDta[0]?.exportation?.bookingNum}/></td>
            </tr>
            <tr>
              <td className='reference'><label>State of Origin:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="ST" name="ST"
                                            value={childExpoDta[0]?.werehouse?.state?.smallState}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Foreign Trade Zone:</label></td>
              <td className='option'><input className='infoAES' type="text" id="FTZ" name="FTZ"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Port of Export:</label></td>
              <td className='option'>
                <input className='infoAES' type="hidden" id="POE" name="POE" value={infoPortLoad?.port}/>
                <input required className='infoAES' type="text"
                       value={infoPortLoad?.name + '(' + infoPortLoad?.port + ')'}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Country of Destination:</label></td>
              <td className='option'>
                <input required className='infoAES' type="hidden" id="COD" name="COD"
                       value={childExpoDta[0]?.exportation?.country?.code}/>
                <input required className='infoAES' type="text" name="CODNAME"
                       value={childExpoDta[0]?.exportation?.country?.nameCountry}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Departure Date:</label></td>
              <td className='option'>
                <input required className='infoAES' type="hidden" id="EDA" name="EDA"
                       value={formatDate(childExpoDta[0]?.exportation?.departureDate)}/>
                <input required className='infoAES' type="text"
                       value={moment(childExpoDta[0]?.exportation?.departureDate).format('ll')}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Port of Unloading:</label></td>
              <td className='option'>
                <input className='infoAES' type="hidden" id="POU" name="POU" value={infoPortUnload?.port}/>
                <input required className='infoAES' type="text"
                       value={infoPortUnload?.name + '(' + infoPortUnload?.port + ')'}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Mode of Transport:</label></td>
              <td className='option'>
                <input className='infoAES' type="hidden" id="MOT" name="MOT" value={childExpoDta[0]?.modeExport?.code}/>
                <input required className='infoAES' type="text" value={childExpoDta[0]?.modeExport?.name}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Carrier:</label></td>
              <td className='option'>
                {/****** REVISAR ESTO ********/}
                <input className='infoAES' type="hidden" id="SCAC" name="SCAC"
                       value={childExpoDta[0]?.carrier?.oceanCarrierCode}/>
                <input required className='infoAES' type="text" id="SCACNAME" name="SCACNAME"
                       value={childExpoDta[0]?.carrier?.oceanCarrierName}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Conveyance Name:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="VN" name="VN"
                                            value={childExpoDta[0]?.exportation?.vesselNameFlag}/></td>
            </tr>
            {/*<tr>
						<td className='reference'><label>Vessel Flag:</label></td>
						<td className='option'><input required className='infoAES' type="text" id="VN" name="VN" /></td>
                    </tr>	*/}
            <tr>
              <td className='reference'><label>Related Companies:</label></td>
              <td className='option'>
                {/* <input required className='infoAES' type="text" id="RCC" name="RCC" value={valueRCC} onChange={onChangeValueRCC} /> */}
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false}
                        onChange={onChangeValueRCC}/>
              </td>
            </tr>
            {/*<tr>
						<td className='reference'><label>Waiver of Prior Notitice:</label></td>
						<td className='option'><input required className='infoAES' type="text" id="typeA_1" name="carrier_info_1_B" /></td>
                    </tr>*/}
            <tr>
              <td className='reference'><label>Hazardous cargo:</label></td>
              <td className='option'>
                {/* <input required className='infoAES' type="text" id="HAZ" name="HAZ" value="No"/> */}
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false}
                        onChange={onChangeValueHazmatIndicator}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Routed Transaction Flag:</label></td>
              <td className='option'>
                {/* <input required className='infoAES' type="text" id="RT" name="RT" value="Yes" /> */}
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false}
                        onChange={onChangeValueRoutedTransactionIndicator}/>

              </td>
            </tr>
            <tr>
              <td className='reference'><label>Inbond Type:</label></td>
              <td className='option'><input className='infoAES' type="text" id="IBT" name="IBT"/></td>
            </tr>
            </tbody>
            <thead>
            <tr>
              <th className='header' colSpan="2">U.S Principal Party in Interest - Required By AES</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className='reference'><label>Name:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_1" name="AD0_1"
                                            value="MATUS INTERNATIONAL INC"/></td>
            </tr>
            <tr>
              <td className='reference'><label>ID Number:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_2" name="AD0_2"
                                            value="462329112"/></td>
            </tr>
            <tr>
              <td className='reference'><label>ID Type:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_3" name="AD0_3"
                                            value="EIN"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Address Line 1:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_4" name="AD0_4"
                                            value={childExpoDta[0]?.origen?.addressLine1}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Address Line 2:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD0_5" name="AD0_5"
                                            value={childExpoDta[0]?.origen?.addressLine2}/></td>
            </tr>
            <tr>
              <td className='reference'><label>City:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_6" name="AD0_6"
                                            value={childExpoDta[0]?.origen?.city}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Country:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="countryCod" name="countryCod"
                                            value={childExpoDta[0]?.origen?.countries?.code}/></td>
            </tr>
            <tr>
              <td className='reference'><label>State:</label></td>
              <td className='option'>
                {/************ REVISAR EL CODIGO DEL ESTADO */}
                <input className='infoAES' type="hidden" id="AD0_7" name="AD0_7"
                       value={childExpoDta[0]?.origen?.state}/>
                <input required className='infoAES' type="text" value={childExpoDta[0]?.origen?.nameOrigen}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Zip Code:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_8" name="AD0_8"
                                            value={childExpoDta[0]?.origen?.zipCode}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact First Name:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_9" name="AD0_9"
                                            value="ALLAN"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact Last Name:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_11" name="AD0_11"
                                            value="MATUS"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact Phone:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD0_12" name="AD0_12"
                                            value="5624355200"/></td>
            </tr>
            </tbody>
            <thead>
            <tr>
              <th className='header' colSpan="2">Ultimated Consignee - Required By AES</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className='reference'><label>Name:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD1_3" name="AD1_3"
                                            value={childExpoDta[0]?.importadora?.impName}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact Name:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD1_5" name="AD1_5"
                                            value={childExpoDta[0]?.clientes?.nombreCompleto}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact Phone:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD1_7" name="AD1_7"
                                            value={childExpoDta[0]?.clientes?.contNum}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Address Line 1:</label></td>
              <td className='option'><input required className='infoAES' onChange={handleEdit} type="text" id="AD1_8"
                                            name="AD1_8" value={childExpoDta[0]?.importadora?.addressLine1}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Address Line 2:</label></td>
              <td className='option'><input className='infoAES' onChange={handleEdit} type="text" id="AD1_9"
                                            name="AD1_9" value={childExpoDta[0]?.importadora?.addressLine2}/></td>
            </tr>
            <tr>
              <td className='reference'><label>City:</label></td>
              <td className='option'><input required className='infoAES' onChange={handleEdit} type="text" id="AD1_10"
                                            name="AD1_10" value={childExpoDta[0]?.importadora?.city}/></td>
            </tr>
            <tr>
              <td className='reference'><label>State:</label></td>
              <td className='option'><input required className='infoAES' onChange={handleEdit} type="text" id="AD1_11"
                                            name="AD1_11" value={childExpoDta[0]?.importadora?.state}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Country:</label></td>
              <td className='option'><input required className='infoAES' onChange={handleEdit} type="text" id="AD1_12"
                                            name="AD1_12" value={childExpoDta[0]?.importadora?.country}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Postal Code:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD1_13" name="AD1_13"
                                            value={childExpoDta[0]?.importadora?.postalCode}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Ultimate Consignee Type:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD1_14" name="AD1_14"
                                            value="O"/></td>
            </tr>
            <tr>
              <td className='reference'><label>SoId En Route:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD1_6" name="AD1_6"
                                            value="N No"/></td>
            </tr>
            </tbody>
            <thead>
            <tr>
              <th className='header' colSpan="2">Intermediate Consignee</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className='reference'><label>Name:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_3" name="AD4_3"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact Name:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_5" name="AD4_5"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact Phone:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_7" name="AD4_7"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Address Line 1:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_8" name="AD4_8"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Address Line 2:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_9" name="AD4_9"/></td>
            </tr>
            <tr>
              <td className='reference'><label>City:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_10" name="AD4_10"/></td>
            </tr>
            <tr>
              <td className='reference'><label>State:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_11" name="AD4_11"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Country:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_12" name="AD4_12"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Postal Code:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD4_13" name="AD4_13"/></td>
            </tr>
            </tbody>
            <thead>
            <tr>
              <th className='header' colSpan="2">Freight Forwarder</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className='reference'><label>Name:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD3_3" name="AD3_3"
                                            value="MATUS INTERNATIONAL INC"/></td>
            </tr>
            <tr>
              <td className='reference'><label>ID Number:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD3_4" name="AD3_4"
                                            value="462329112"/></td>
            </tr>
            <tr>
              <td className='reference'><label>ID Type:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD3_2" name="AD3_2"
                                            value="FIN"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact Name:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD3_5" name="AD3_5"
                                            value="ALLAN MATUS"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Contact Phone:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD3_7" name="AD3_7"
                                            value="5624355200"/></td>
            </tr>
            <tr>
              <td className='reference'><label>Address Line 1:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD3_8" name="AD3_8"
                                            value={childExpoDta[0]?.origen?.addressLine1}/></td>
            </tr>
            <tr>
              <td className='reference'><label>Address Line 2:</label></td>
              <td className='option'><input className='infoAES' type="text" id="AD3_9" name="AD3_9"
                                            value={childExpoDta[0]?.origen?.addressLine2}/></td>
            </tr>
            <tr>
              <td className='reference'><label>City:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD3_10" name="AD3_10"
                                            value={childExpoDta[0]?.origen?.city}/></td>
            </tr>
            <tr>
              <td className='reference'><label>State:</label></td>
              <td className='option'>
                <input className='infoAES' type="hidden" id="AD3_11" name="AD3_11"
                       value={childExpoDta[0]?.origen?.state}/>
                <input required className='infoAES' type="text" value={childExpoDta[0]?.origen?.nameOrigen}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Country:</label></td>
              <td className='option'>
                <input className='infoAES' type="hidden" id="AD3_12" name="AD3_12"
                       value={childExpoDta[0]?.origen?.countries?.code}/>
                <input required className='infoAES' type="text"
                       value={childExpoDta[0]?.origen?.countries?.nameCountry}/>
              </td>
            </tr>
            <tr>
              <td className='reference'><label>Zip Code:</label></td>
              <td className='option'><input required className='infoAES' type="text" id="AD3_13" name="AD3_13"
                                            value={childExpoDta[0]?.origen?.zipCode}/></td>
            </tr>
            </tbody>
          </table>
          <table>
            <thead>
            <tr>
              <th className='header' colSpan="7">List Items</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className='itemslist'><label>Number:</label></td>
              <td className='itemslist'><label>Export Code:</label></td>
              <td className='itemslist'><label>US Dollar:</label></td>
              <td className='itemslist'><label>First Unit:</label></td>
              <td className='itemslist'><label>First Quantity:</label></td>
              <td className='itemslist'><label>Second Unit:</label></td>
              <td className='itemslist'><label>Second Quantity:</label></td>
            </tr>
            <tr>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="isLine1" name="isLine1"
                                               value={childExpoDta[0]?.werehouse?.quantity}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_1" name="IT1_1"
                                               value={childExpoDta[0]?.werehouse?.codExp}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_2" name="IT1_2"
                                               value={childExpoDta[0]?.werehouse?.valorV}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_3" name="IT1_3"
                                               value="NO"/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_4" name="IT1_4"
                                               value="1"/></td>
              <td className='itemsinfo'><input className='infoAESItem' type="text" id="IT1_5" name="IT1_5"/></td>
              <td className='itemsinfo'><input className='infoAESItem' type="text" id="IT1_6" name="IT1_6"/></td>
            </tr>
            <tr>
              <td className='itemslist'><label>Gross Weight Kg:</label></td>
              <td className='itemslist'><label>License Type:</label></td>
              <td className='itemslist'><label>License Number:</label></td>
              <td className='itemslist'><label>License Value:</label></td>
              <td className='itemslist'><label>Description:</label></td>
              <td className='itemslist'><label>Commodity Code:</label></td>
              <td className='itemslist'><label>Marks and Number:</label></td>
            </tr>
            <tr>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_7" name="IT1_7"
                                               value={childExpoDta[0]?.werehouse?.weightKg}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_8" name="IT1_8"
                                               value={childExpoDta[0]?.werehouse?.tipoLic}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_9" name="IT1_9"
                                               value={childExpoDta[0]?.werehouse?.licenceNum}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_10" name="IT1_10"
                                               value="0"/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_12" name="IT1_12"
                                               value={childExpoDta[0]?.werehouse?.vehiculoName}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_13" name="IT1_13"
                                               value={childExpoDta[0]?.werehouse?.scheduleBname}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="typeA_1"
                                               name="carrier_info_1_B" value={childExpoDta[0]?.werehouse?.codigo}/></td>
            </tr>
            <tr>
              <td className='itemslist'><label>Vehicle:</label></td>
              <td className='itemslist'><label>Vehicle ID Type:</label></td>
              <td className='itemslist'><label>Vehicle ID:</label></td>
              <td className='itemslist'><label>Vehicle Title:</label></td>
              <td className='itemslist'><label>Vehicle Title State:</label></td>
              <td className='itemslist'><label>ECCN:</label></td>
              <td className='itemslist'><label>Origin:</label></td>
            </tr>
            <tr>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_15" name="IT1_15"
                                               value="Yes"/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_16" name="IT1_16"
                                               value="VIN"/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_17" name="IT1_17"
                                               value={childExpoDta[0]?.werehouse?.vin}/></td>
              <td className='itemsinfo'><input required className='infoAESItem' type="text" id="IT1_18" name="IT1_18"
                                               value={childExpoDta[0]?.werehouse?.numTitle}/></td>
              <td className='itemsinfo'><input className='infoAESItem' type="text" id="IT1_19" name="IT1_19"
                                               value={childExpoDta[0]?.werehouse?.smallState}/></td>
              <td className='itemsinfo'><input className='infoAESItem' type="text" id="IT1_20" name="IT1_20"/></td>
              <td className='itemsinfo'>
                <input className='infoAESItem' type="hidden" id="IT1_21" name="IT1_21"
                       value={childExpoDta[0]?.werehouse?.origin?.codeOrigin}/>
                <input required className='infoAESItem' type="text" value={childExpoDta[0]?.werehouse?.originName}/>
              </td>
            </tr>
            </tbody>
          </table>
          <div className="footer-estatico">
            {childExpoDta[0]?.stateSend === "NO ENVIADO" ?
              <button className='ButtonSend' type='submit'>Send to AES</button> :
              childExpoDta[0]?.stateSend === "ENVIADO" ?
                <button className='ButtonValidate' type='button' onClick={handleValidarAES}>Validar AES</button> : null
            }


          </div>
        </Form>

      </div>
    </Spinner>
  )
}

export default SendToAES;
