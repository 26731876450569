import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../shared/spinner";
import {
  Row,
  Col,
  Table,
  Modal,
  Space,
  Tooltip,
  Button,
  Badge,
  Input,
} from "antd";
import {
  EyeFilled,
  UploadOutlined,
  SearchOutlined,
  CopyFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  getVehiculos,
} from "../../Servicios/UploadService";
import { useNavigate } from "react-router-dom";
import {getListFolderId} from "../../Servicios/GoogleService.js";
import useCurrentUser from "../../Hooks/UserHook";
import Highlighter from "react-highlight-words";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import UploadGoogleDrive from "../../Componentes/UploadGoogleDrive/UploadGoogleDrive";
import VehicleEvidenceList from "../../Componentes/VehicleEvidenceList/VehicleEvidenceList";

const UploadEvidenceReceipts = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [isModalOpenEvidence, setIsModalOpenEvidence] = useState(false);
  const [isModalOpenListEvidence, setIsModalOpenListEvidence] = useState(false);
  const [vehiculo, setVehiculo] = useState(undefined);
  const [lote, setLote] = useState("");
  const [fechaRecibido, setFechaRecibido] = useState("");
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  // buscar por lote y fecha
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [copyLoading, setCopyLoading] = useState(false);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleOkEvidence = () => {
    setIsModalOpenEvidence(false);
  };

  const handleCancelEvidence = () => {
    setIsModalOpenEvidence(false);
  };

  const handleOkListEvidence = () => {
    setIsModalOpenListEvidence(false);
  };

  const handleCancelListEvidence = () => {
    setIsModalOpenListEvidence(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (currentUser) {
      getVehiculos(currentUser?.idubc)
        .then((data) => {
          setData(
            data.map((element, index) => {
              return {
                key: index,
                vehiculoId: element.vehiculoId,
                vehiculoName: element.vehiculoName,
                lote: element.lote,
                fecha: element.fecha,
                origen: element.origen,
              };
            })
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentUser]);

  const columns = [
    {
      title: "Lote",
      dataIndex: "lote",
      key: "lote",
      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
      width: "10%",
      ...getColumnSearchProps("lote"),
    },
    {
      title: "Nombre de vehiculo",
      dataIndex: "vehiculoName",
      key: "vehiculoName",
      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
      ...getColumnSearchProps("vehiculoName"),
    },
    {
      title: "Fecha de recibido",
      dataIndex: "fecha",
      key: "fecha",
      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
      ...getColumnSearchProps("fecha"),
    },
    {
      title: "ID de vehiculo",
      dataIndex: "vehiculoId",
      width: 100,
      key: "vehiculoId",
      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
    },
    {
      title: "Opciones",
      dataIndex: "opciones",
      key: "opciones",
      // add option to upload evidence and list evidence
      render: (text, record) =>
        record.fecha ? (
          <Space size="middle" align="center">
            <Tooltip title="Subir evidencia">
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  setIsModalOpenEvidence(true);
                  setLote(record.lote);
                  setVehiculo({
                    ...record,
                    vehiculoName: `${record.year ?? ""} ${record.make ?? ""} ${record.model ?? ""} ${record.color ?? ""}`.trim().toUpperCase()
                  });
                  setFechaRecibido(record.fecha);
                }}
                style={{
                  display: "flex",
                  backgroundColor: "#52c41a",
                  borderColor: "#52c41a",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <UploadOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Ver evidencia">
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  setIsModalOpenListEvidence(true);
                  setLote(record.lote);
                  setVehiculo(record);
                }}
                style={{
                  display: "flex",
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EyeFilled />
              </Button>
            </Tooltip>
            <Tooltip title="Copy Link">
              {copyLoading ? (
                <LoadingOutlined style={{ fontSize: 17 }} />
              ) : (
                <Button
                  type="primary"
                  shape="circle"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#F9B325",
                    borderColor: "#F9B325",
                  }}
                  onClick={async () => {
                    setCopyLoading(true);
                    await getListFolderId(record.lote).then((element) => {
                      if (element.data.length === 0) {
                        toast.error("No se ha subido evidencia", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });

                        setCopyLoading(false);
                        return;
                      } else {
                        copy(
                          `https://drive.google.com/drive/folders/${element.data}`
                        );

                        toast.success(`Se ha copiado el link con exito!`, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                        setCopyLoading(false);
                      }
                    });
                  }}
                >
                  <CopyFilled style={{ fontSize: 17 }} />
                </Button>
              )}
            </Tooltip>
          </Space>
        ) : (
          <Badge status="warning" text="Sin ser recibido" />
        ),

      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
      fixed: "right",
      width: 150,
    },
  ];

  const handleRedirect = () => {
    navigate(`/vehicleReceipts/${currentUser?.idubc}`);
  };

  return (
    <>
      <Spinner loading={false}>
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4>Ingreso de evidencia de vehiculos</h4>
            <Button
                  type="primary"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#188EFC",
                    borderColor: "#188EFC",
                  }}
                  onClick={handleRedirect}
            >
              Lista para recibir vehiculo
            </Button>
          </Col>
        </Row>
        <hr />
        

        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 50 }}
          loading={isLoading}
          scroll={{ x: 1300 }}
        />

        <Modal
          title={`Evidencia para el lote ${lote}`}
          open={isModalOpenEvidence}
          onOk={handleOkEvidence}
          onCancel={handleCancelEvidence}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <UploadGoogleDrive vehiculo={vehiculo} fechaRecibido={fechaRecibido} open={isModalOpenEvidence} />
        </Modal>

        <Modal
          title="Lista de evidencia"
          open={isModalOpenListEvidence}
          onOk={handleOkListEvidence}
          onCancel={handleCancelListEvidence}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          width={"100%"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <hr />
          <VehicleEvidenceList lote={lote} />
        </Modal>
        <div id="iframeContainer"></div>
      </Spinner>
    </>
  );
};

export default UploadEvidenceReceipts;
