import React from 'react';
import { Card, Avatar, List, Steps, Typography, Row, Col } from 'antd';
import { UserOutlined, PhoneOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons';

const { Step } = Steps;

const UserCard = () => (
  <Card style={{ width: '100%', marginBottom: 20 }}>
    <Card.Meta
      avatar={<Avatar size={64} icon={<UserOutlined />} />}
      title="2020 DODGE CHARGER SRT"
      description="JD54D2S5D5D4A5D47"
    />
    <div style={{ marginTop: 20 }}>
      <Typography.Paragraph>Lote# 88888888</Typography.Paragraph>
      <Typography.Paragraph>Vehiculo: 2020 DODGE CHARGER SRT</Typography.Paragraph>
      <Typography.Paragraph>VIN: JD54D2S5D5D4A5D47</Typography.Paragraph>
      <Typography.Paragraph>Fotos: Link de fotos / No hay fotos disponibles</Typography.Paragraph>
      <hr />
      <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography.Paragraph><strong>Datos de origen</strong></Typography.Paragraph>
      </div>
      <Typography.Paragraph>Oficina: CALIFORNIA</Typography.Paragraph>
      <Typography.Paragraph>Direccion: 1120 DE FOREST AVE</Typography.Paragraph>
      <Typography.Paragraph>           LONG BEACH, CA 90813</Typography.Paragraph>
      <hr />
      <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography.Paragraph><strong>Datos de destino</strong></Typography.Paragraph>
      </div>
      <Typography.Paragraph>Pais: EL SALVADOR</Typography.Paragraph>
      <Typography.Paragraph>Aduana: NEJAPA</Typography.Paragraph>
      <Typography.Paragraph>Cliente: IMPORTADORA DE AUTOS CHARLY</Typography.Paragraph>
      <Typography.Paragraph>Direccion: VILLA CARMELA MIXCO, SAN SALVADOR</Typography.Paragraph>
    </div>
  </Card>
);

const ItineraryItem = ({ item }) => (
  <Step
    title={item.title}
    description={
      <div>
        <Typography.Text>{item.date}</Typography.Text>
        <Typography.Paragraph>{item.description}</Typography.Paragraph>
      </div>
    }
  />
);

const NotificationItem = ({ item }) => (
  <List.Item>
    <List.Item>
        <Typography.Text>{item.text}</Typography.Text>
        <Typography.Text>{item.time}</Typography.Text>
    </List.Item>
  </List.Item>
);

const Tracking = () => {
  const itineraryData = Array(5).fill({
    title: 'Meeting with partners',
    date: '10/11/2018',
    description: 'Discuss the new partnership agreement',
  });

  const notifications = Array(5).fill({
    text: 'You have a new message',
    time: '10/11/2018',
  });

  console.log('Itinerary Data:', itineraryData);
  console.log('Notifications:', notifications);
  const description = 'This is a description.';

  const items = [
    {
      title: 'Fecha de solicitud ' ,  
      description: '07-07-2024'
    },
    {
      title: 'Fecha de confirmacion',
      description: '07-07-2024'
    },
    {
      title: 'Fecha estimada de retiro de subasta',
      description: 'COPART - LAS VEGAS, NV',
      date: '07-07-2024'
    },
    
    {
      title: 'Fecha de salida de puerto',
      description: 'MATUS INTERNATIONAL - LONG BEACH, CA'
    },
    {
      title: 'Fecha de llegada a destino',
      description
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <Typography.Title level={2}>
        Tracking Matus
      </Typography.Title>
      <Typography.Text>Gracias por confiar tu carga con Matus International, te compartimos toda la informacion del estado de tu vehiculo.</Typography.Text>

      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col xs={24} md={8}>
          <UserCard />
        </Col>
        <Col xs={24} md={8}>
          <Card title="Itinerary">
          <Steps
    direction="vertical"
    current={2}
    items={items}
  />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card title="Notifications">
            <List
              dataSource={notifications}
              renderItem={(item, index) => <NotificationItem key={index} item={item} />}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Tracking;
