import {Form, Input, Modal, Select} from "antd";
import {useEffect, useState} from "react";
import {createUser, getLocationUsers, updateUser} from "../../Servicios/AdminService";
import {errorMessage, successMessage} from "../../utils/toast-message";
import LoadingInside from "../../Componentes/LoadingInside";

const UsuarioForm = ({open, user, doCancel, doSubmit}) => {
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        ubicationId: user.ubicationId,
      });
    }
  }, [user]);

  useEffect(() => {
    if (open) {
      getLocationUsers().then(data => {
        setLocations(data);
      });
    }
  }, [open]);

  const handleCancel = () => {
    form.resetFields();
    if (doCancel) doCancel();
  }

  const handleSubmit = async () => {
    form.submit();

    if (!user) {
      const password = form.getFieldValue('password');
      const repeatPassword = form.getFieldValue('repeatPassword');

      if (repeatPassword !== password) {
        errorMessage('Password\'s does not match');
        return;
      }
    }

    const values = form.getFieldsValue();

    setLoading(true);
    if (user) {
      await updateUser(user.idUser, values);
      successMessage('User updated sucessfully');
    } else {
      await createUser(values);
      successMessage('User created sucessfully');
    }
    setLoading(false);
    form.resetFields();

    if (doSubmit) doSubmit();
  }

  return(
    <>
      <Modal open={open}
             title={'Crear Usuario'}
             onCancel={handleCancel}
             onOk={handleSubmit}
             okText={'Save'}>
        {
          loading ? <LoadingInside small={true} /> :
            <Form form={form}
                  layout="vertical"
                  autoComplete="off">
              <Form.Item label={"Username"} name={"username"}
                         rules={[{ required: true, message: 'This field is required' }]}>
                <Input placeholder={"Username"}/>
              </Form.Item>

              <Form.Item label={"First Name"} name={"firstName"}
                         rules={[{ required: true, message: 'This field is required' }]}>
                <Input placeholder={"First Name"}/>
              </Form.Item>

              <Form.Item label={"Last Name"} name={"lastName"}
                         rules={[{ required: true, message: 'This field is required' }]}>
                <Input placeholder={"Last Name"}/>
              </Form.Item>

              <Form.Item label={"Email"} name={"email"}
                         rules={[{ required: true, message: 'This field is required' }]}>
                <Input placeholder={"Email"}/>
              </Form.Item>

              {
                user ? <></> :
                  <Form.Item label={"Password"} name={"password"}
                             rules={[{ required: true, message: 'This field is required' }]}>
                    <Input.Password placeholder={"Password"}/>
                  </Form.Item>
              }

              {
                user ? <></> :
                  <Form.Item label={"Repeat Password"} name={"repeatPassword"}
                             rules={[{ required: true, message: 'This field is required' }]}>
                    <Input.Password placeholder={"Repeat Password"}/>
                  </Form.Item>
              }

              <Form.Item label={"Location"} name={"ubicationId"}
                         rules={[{ required: true, message: 'This field is required' }]}>
                <Select placeholder={"Location"}
                        options={locations.map(e => ({ value: e.id, label: e.city }))}/>
              </Form.Item>
            </Form>
        }
      </Modal>
    </>
  );
}

export default UsuarioForm;
