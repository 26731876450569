import * as React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ProvideAuth from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// Redux
import { Provider as ReduxProvide } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import * as Sentry from "@sentry/react";
import environment from "./environments/environment";

if (environment.production) {
  Sentry.init({
    dsn: "https://b82843666fffd15c30f180b454a9b157@o4507652389339136.ingest.us.sentry.io/4507652473749504",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/portalmatus\.com\/webapisimi/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ProvideAuth>
    <ReduxProvide store={store}>
      <PersistGate loading={false} persistor={persistor}>
        <BrowserRouter basename={'web'}>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </PersistGate>
    </ReduxProvide>
  </ProvideAuth>
);
