import {Button, Form, Input, Modal, Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {addTitle, updateTitle} from "../../Servicios/TitleService";
import {showStates} from "../../Servicios/WerehouseService";
import Spinner from "../../shared/spinner";

const TitleForm = ({open, title, onCancel, onSave}) => {
  const [form] = Form.useForm();

  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (title) {
      form.setFieldsValue({
        state: title.state,
        titleName: title.titleName,
        embarkable: title.embarkable,
      });

      setChecked(title.embarkable);
    } else {
      form.setFieldsValue({
        state: '',
        titleName: '',
        embarkable: true,
      });
      setChecked(true);
    }
  }, [form, title]);

  useEffect(() => {
    showStates().then(data => {
      setStates(data);
    });
  }, []);

  const handleOk = async (values) => {
    setLoading(true);

    const input = {
      ...values,
      embarkable: checked
    }

    let data;
    if (title) {
      data = await updateTitle(title.idTitle, input);
    } else {
      data = await addTitle(input);
    }

    setLoading(false);
    form.setFieldsValue({
      state: '',
      titleName: '',
      embarkable: true,
    });
    setChecked(true);

    if (onSave) onSave(data);
  };

  const handleCancel = () => {
    form.resetFields();

    if (onCancel) {
      onCancel();
    }
  };

  const onFinishFailed = () => {
    handleCancel();
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return(
    <Modal title={"New Title"}
           open={open}
           okButtonProps={{style: {display: "none"}}}
           cancelButtonProps={{style: {display: "none"}}}>
      <Spinner loading={loading}>
        <Form form={form}
              layout={"vertical"}
              autoComplete="off"
              onFinish={handleOk}>
          <Form.Item label="Title Description:" name="titleName"
                     rules={[{required: true, message: 'This field is required'}]}>
            <Input placeholder={'Description'} className="w-100"/>
          </Form.Item>

          <Form.Item label="State:" name="state" rules={[{required: true, message: 'This field is required'}]}>
            <Select showSearch={true}
                    filterOption={filterOption}
                    options={states.map(e => {
                      return {
                        value: e.smallState,
                        label: e.state
                      }
                    })}/>
          </Form.Item>

          <Form.Item label="Embarcable:" name="embarkable">
            <Switch checked={checked}
                    onChange={(value) => {
                      form.setFieldValue('embarkable', value);
                      setChecked(value);
                    }}/>
          </Form.Item>

          <div className="text-end">
            <Button htmlType={"button"} className={"me-3"} onClick={onFinishFailed}>Cancel</Button>
            <Button type={"primary"} htmlType={"submit"}>Save</Button>
          </div>
        </Form>
      </Spinner>
    </Modal>
  );
}

export default TitleForm;
