import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SpinnerDiamond } from 'spinners-react';
import './styleloading.css';

export default function LoadingInside ({small}) {
  
  return (
    <Container className={small ? 'conte-sm' : 'conte'}>
      <div className='iconoload'>
          <SpinnerDiamond size={90} thickness={180} speed={139} color="rgba(28, 46, 81, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
      </div>
    </Container>
  );
};