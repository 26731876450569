import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {showStates} from "../../Servicios/WerehouseService";
import {showOrigen} from "../../Servicios/CombosService";
import {addSubasta, editSub} from "../../Servicios/ComponedoresService";
import {errorMessage, successMessage} from "../../utils/toast-message";
import Spinner from "../../shared/spinner";

const SubastaForm = ({ subasta, onCancel, afterSave, titulo }) => {
  const [states, setStates] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const initSubasta ={
    subId: '',
    subName: '',
    addressLine1Sub: '',
    addressLine2Sub: '',
    city: '',
    state: '0',
    zipCode: '',
    countryId: '0',
    identf: '',
    phoneNumber: '',
    origen: null,
    identfType: '0',
  };

  const [newSubasta, setNewSubasta] = useState(initSubasta);

  useEffect(() => {
    if (subasta) {
      setNewSubasta({
        ...subasta,
        countryId: +subasta.countryId
      });
    } else {
      setNewSubasta(initSubasta);
    }
  }, [subasta]);

  useEffect(() => {
    init().then();
  }, []);

  const init = async () => {
    const states = await showStates();
    setStates(states);

    const origenes = await showOrigen();
    setOrigenes(origenes);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewSubasta({
      ...newSubasta,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (newSubasta.subName === "") {
      errorMessage('El campo Nombre no puede estar vacio');
      return;
    }

    if (subasta) {
      editarSubasta().then();
    } else {
      insertarSubasta().then();
    }
  }

  const insertarSubasta = async () => {
    try {
      setLoading(true);

      const data = await addSubasta({
        ...newSubasta,
        state: +newSubasta.state,
        zipCode: +newSubasta.zipCode,
        countryId: +newSubasta.countryId,
        origen: +newSubasta.origen,
      });
      setLoading(false);

      if (data) {
        successMessage(`${titulo ?? 'Subasta'} agregado correctamente`);
        if (afterSave) afterSave(data);
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  };

  const editarSubasta = async () => {
    try {
      setLoading(true);
      const data = await editSub(subasta.subId, {
        ...newSubasta,
        state: +newSubasta.state,
        zipCode: +newSubasta.zipCode,
        countryId: +newSubasta.countryId,
        origen: +newSubasta.origen,
      });
      setLoading(false);

      if (data) {
        successMessage(`${titulo ?? 'Subasta'} modificado correctamente`);
        if (afterSave) afterSave(data);
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  return(
    <>
      <Spinner loading={isLoading}>
        <Form.Label>Nombre {titulo ?? 'Subasta'}</Form.Label>
        <Form.Control
          onChange={handleChange}
          name="subName"
          type="text"
          value={newSubasta ? newSubasta.subName : ''}
          placeholder={`Nombre ${(titulo ?? 'Subasta')}`}
        />
        <br/>
        <div className="row">
          <div className="col-4">
            <Form.Label>Tipo de Identificación</Form.Label>
            <Form.Select onChange={handleChange}
                         name="identfType"
                         value={newSubasta ? (newSubasta?.identfType ?? "0") : '0'}>
              <option disabled value={"0"}>
                Seleccione
              </option>
              <option value={"# EIN"}># EIN</option>
              <option value={"# Pasaporte"}># de Pasaporte</option>
            </Form.Select>
          </div>
          <div className="col-8">
            <Form.Label>Identificación</Form.Label>
            <Form.Control
              onChange={handleChange}
              name="identf"
              type="text"
              value={newSubasta ? newSubasta.identf : ''}
              placeholder="Identifitacion"
            />
          </div>
        </div>
        <br/>
        <Form.Label>Address line 1</Form.Label>
        <Form.Control
          onChange={handleChange}
          name="addressLine1Sub"
          type="text"
          value={newSubasta ? newSubasta.addressLine1Sub : ''}
          placeholder="Address line 1"
        />
        <br/>
        <Form.Label>Address line 2</Form.Label>
        <Form.Control
          onChange={handleChange}
          name="addressLine2Sub"
          type="text"
          value={newSubasta ? newSubasta.addressLine2Sub : ''}
          placeholder="Address line 2"
        />
        <br/>
        <div className="row">
          <div className="col-6">
            <Form.Label>City</Form.Label>
            <Form.Control
              onChange={handleChange}
              name="city"
              type="text"
              value={newSubasta ? newSubasta.city : ''}
              placeholder="City"
            />
          </div>
          <div className="col-6">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              onChange={handleChange}
              name="zipCode"
              type="number"
              value={newSubasta ? newSubasta.zipCode : ''}
              placeholder="Zip Code"
            />
          </div>
        </div>
        <br/>
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          onChange={handleChange}
          name="phoneNumber"
          type="number"
          value={newSubasta ? newSubasta.phoneNumber : ''}
          placeholder="Phone Number"
        />
        <br/>
        <div className="row">
          <div className="col-6">
            <Form.Label>State</Form.Label>
            <Form.Select
              onChange={handleChange}
              name="state"
              aria-label="Small"
              value={newSubasta ? newSubasta.state : '0'}
              aria-describedby="inputGroup-sizing-sm"
            >
              <option disabled value={"0"}>
                Seleccione
              </option>
              {states &&
                states.length &&
                states.map((ste, index) => {
                  return (
                    <option key={index} value={ste.stateId}>
                      {ste.state}
                    </option>
                  );
                })}
            </Form.Select>
          </div>
          <div className="col-6">
            <Form.Label>Origen - Office Matus</Form.Label>
            <Form.Select
              onChange={handleChange}
              name="origen"
              value={newSubasta ? (newSubasta?.origen ?? "0") : '0'}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            >
              <option disabled value={"0"}>
                Seleccione
              </option>
              {origenes &&
                origenes?.length &&
                origenes.map((origen, index) => {
                  return (
                    <option key={index} value={origen?.id}>
                      {origen?.nameOrigen}
                    </option>
                  );
                })}
            </Form.Select>
          </div>
        </div>
        <br/>
        <br/>
        <div className="text-end mt-3">
          <Button variant="secondary" className="me-3" onClick={onCancel}>Cancelar</Button>
          <Button variant="primary" onClick={handleSubmit}><FontAwesomeIcon
            icon={faFloppyDisk}/> {subasta ? 'Actualizar' : 'Guardar'}</Button>
        </div>
      </Spinner>
    </>
  );
}

export default SubastaForm;
