import {Button, Form, Input, InputNumber, Modal, Select} from "antd";
import React, {useEffect, useState} from "react";
import {addPortCode, updatePortCode} from "../../Servicios/ExportationServices";
import Spinner from "../../shared/spinner";

const PortCodeForm = ({open, portCode, forDestination, country, onCancel, onSave}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (portCode) {
      form.setFieldsValue({
        port: portCode.port,
        name: portCode.name,
        country: country ?? portCode.country,
        typeState: forDestination ? 'Destination' : portCode.typeState,
        portUnlocode: portCode.portUnlocode,
      });
    } else {
      if (forDestination) {
        form.setFieldValue('typeState', 'Destination');
      } 
      if (country) {
        form.setFieldValue('country', country);
      }
    }
  }, [form, portCode, forDestination, country]);

  const handleOk = async (values) => {
    setLoading(true);

    const input = {
      ...values,
      port: values.port.toString(),
    }

    let data;
    if (portCode) {
      data = await updatePortCode(portCode.portId, input);
    } else {
      data = await addPortCode(input);
    }

    setLoading(false);
    form.resetFields();

    if (forDestination) {
      form.setFieldValue('typeState', 'Destination');
    }

    if (onSave) onSave(data);
  };

  const handleCancel = () => {
    form.resetFields();

    if (forDestination) {
      form.setFieldValue('typeState', 'Destination');
    }

    if (onCancel) {
      onCancel();
    }
  };

  const onFinishFailed = () => {
    handleCancel();
  }

  return(
    <Modal title={"New Port Code"}
           open={open}
           okButtonProps={{style: {display: "none"}}}
           cancelButtonProps={{style: {display: "none"}}}>
      <Spinner loading={loading}>
        <Form form={form}
              layout={"vertical"}
              autoComplete="off"
              onFinish={handleOk}>
          <Form.Item label="Port:" name="port">
            <InputNumber placeholder={'Port'} className="w-100" />
          </Form.Item>

          <Form.Item label="Country:" name="country" rules={[ { required: true, message: 'This field is required' } ]}
           
          >
            <Select options={[
              { label: 'United States', value: 'US' },
              { label: 'Guatemala', value: 'GT' },
              { label: 'El Salvador', value: 'SV' },
              { label: 'Honduras', value: 'HN' },
              { label: 'Nicaragua', value: 'NI' },
              { label: 'Costa Rica', value: 'CR' },
              { label: 'Panama', value: 'PA' },
              { label: 'Puerto Rico', value: 'PR' },
              { label: 'Perú', value: 'PE' },
            ]}  disabled={!!country}/>
          </Form.Item>

          <Form.Item label="Name:" name="name" rules={[ { required: true, message: 'This field is required' } ]}>
            <Input placeholder={'Port Name'} />
          </Form.Item>

          <Form.Item label="Type:" name="typeState" rules={[ { required: true, message: 'This field is required' } ]}>
            <Select disabled={forDestination} options={[
              { label: 'Origin', value: 'Origin' },
              { label: 'Destination', value: 'Destination' }
            ]} />
          </Form.Item>

          <Form.Item label="Port Un/Location Code:"
                     name="portUnlocode"
                     rules={[ { required: true, message: 'This field is required' } ]}>
            <Input placeholder={'Port Un/Location Code'} />
          </Form.Item>

          <div className="text-end">
            <Button htmlType={"button"} className={"me-3"} onClick={onFinishFailed}>Cancel</Button>
            <Button type={"primary"} htmlType={"submit"}>Save</Button>
          </div>
        </Form>
      </Spinner>
    </Modal>
  );
}

export default PortCodeForm;
