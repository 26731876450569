
import { PoweroffOutlined, UserOutlined, LogoutOutlined, HomeOutlined} from "@ant-design/icons";
import { Dropdown, Space, Avatar, Layout} from "antd";
import useCurrentUser from '../../Hooks/UserHook';
import { useNavigate } from "react-router-dom";
import { logout } from '../../Servicios/AuthService';
const { Header } = Layout;




const HeaderComponent = () => {

    const currentUser = useCurrentUser();
    const navigate = useNavigate();

    const items = [
        {
          key: "1",
          label: (
            <a style={{justifyContent:'center', display:'flex', alignItems:'center'}}>
              <UserOutlined /> &nbsp;&nbsp;&nbsp; <strong>{currentUser?.fullName}</strong>
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a >
              <HomeOutlined /> &nbsp;&nbsp;&nbsp; <strong>{`${currentUser?.ref} - ${currentUser?.ubication}`}</strong>
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <a onClick={() =>  {
                logout();
                navigate('/login');
            }}>
              <LogoutOutlined />  &nbsp;&nbsp;&nbsp; <strong> Cerrar Sesion</strong>
            </a>
          ),
        },
      ];

    return (
        <Header style={{ padding: 0, display:'flex',justifyContent:'space-between' }} theme="dark">
        <div></div>
        <div>
          <div style={{
            marginRight: 25,
          }}>
          <Dropdown
            menu={{
              items
            }}
            trigger={["click"]}
            
          >
            <a onClick={(e) => e.preventDefault()} style={{
              textDecoration: 'none', color:'white'
            }}>
              <Space>
              <Avatar size={40} icon={<PoweroffOutlined />} style={{
                lineHeight: '0px !important',
                border: '1px solid white'
              }}/>

                {/* <DownOutlined /> */}
              </Space>
            </a>
          </Dropdown>
          </div>

        </div>
      </Header>
    );
}

export default HeaderComponent;