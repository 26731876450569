const ExportacionInfo = ({exportacion}) => {

  const labels = (label, value) => {
    return(
      <>
        <p className="fw-bold mb-0">{label}</p>
        <p className="mb-0">{value}</p>
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Shipment Name', exportacion?.shipmentName)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Customer Reference', exportacion?.customerReference)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Bill of Lading Number', exportacion?.billLadingNum)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Loading Date', exportacion?.dateLoading)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Booking Number', exportacion?.bookingNum)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Country', exportacion?.countryName)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Aduana', exportacion?.aduanaName)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Loader', exportacion?.captain)}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          {labels('Decripción adicional', exportacion?.descriptionAditional)}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          {labels('Notas de carga', exportacion?.noteLoading)}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr/>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Departure Date/Time', exportacion?.departureDate)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Arrival Date/Time', exportacion?.arrivalDate)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Fecha de Notificación del arribo', exportacion?.dateNotArrive)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Fecha Aviso de Embarque', exportacion?.dateNotShipment)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Fecha de vaciado', exportacion?.dateNotEmtied)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Fecha recibir RTU', exportacion?.dateReceivingRTU)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Fecha Modificación RTU', exportacion?.dateUpdateRTU)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Fecha Notificación atraso Zarpe', exportacion?.dateNotZarpe)}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          {labels('Descripción del Contenedor', exportacion?.containerDesc)}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          {labels('Descripción de Bienes', exportacion?.descriptionGoods)}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr/>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
          <p className="mb-0 fw-bold">Shipper</p>
          <p className="mb-0">{exportacion?.shipper?.subName}</p>
          <p className="mb-0">{exportacion?.shipper?.addressLine1Sub}</p>
          <p
            className="mb-0">{exportacion?.shipper?.addressLine2Sub}, {exportacion?.shipper?.codeState} {exportacion?.shipper?.city} {exportacion?.shipper?.zipCode}</p>
          <p className="">{exportacion?.shipper?.countryName}</p>
          {labels('Telefono', exportacion?.shipper?.phoneNumber)}
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
          <p className="mb-0 fw-bold">Forwarding Agent</p>
          <p className="mb-0">{exportacion?.forwardingAgent?.subName}</p>
          <p className="mb-0">{exportacion?.forwardingAgent?.addressLine1Sub}</p>
          <p
            className="mb-0">{exportacion?.forwardingAgent?.addressLine2Sub}, {exportacion?.forwardingAgent?.codeState} {exportacion?.forwardingAgent?.city} {exportacion?.forwardingAgent?.zipCode}</p>
          <p className="">{exportacion?.forwardingAgent?.countryName}</p>
          {labels('Telefono', exportacion?.forwardingAgent?.phoneNumber)}
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
          <p className="mb-0 fw-bold">Ultimate Consignee</p>
          <p className="mb-0">{exportacion?.ultimateConsignee?.impName}</p>
          <p className="mb-0">{exportacion?.ultimateConsignee?.addressLine1}</p>
          <p className="mb-0">{exportacion?.ultimateConsignee?.addressLine2}</p>
          <p className="">{exportacion?.ultimateConsignee?.city}, {exportacion?.ultimateConsignee?.country}</p>
          {labels('Telefono', exportacion?.ultimateConsignee?.phoneNumber)}
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
          <p className="mb-0 fw-bold">Notify Party</p>
          <p className="mb-0">{exportacion?.notifyParty?.impName}</p>
          <p className="mb-0">{exportacion?.notifyParty?.addressLine1}</p>
          <p className="mb-0">{exportacion?.notifyParty?.addressLine2}</p>
          <p className="">{exportacion?.notifyParty?.city}, {exportacion?.notifyParty?.country}</p>
          {labels('Telefono', exportacion?.notifyParty?.phoneNumber)}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <hr/>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Service Type', exportacion?.servic?.name)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Mode of Transportation', exportacion?.modeExport?.name)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Domestic Routing/Export Instructions', exportacion?.domesticRouting)}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <hr/>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Point of origin or FTZ Number', exportacion?.pointOrigenFtzNum)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Pre-Carriage by', exportacion?.preCarriageBy)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Place of Receipt by Pre-Carrier', exportacion?.placeReceiptCarrier)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Loading Pier or Terminal', exportacion?.loadingPierTerminal)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Vessel Name and Flag', exportacion?.vesselNameFlag)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Voyage Identification', exportacion?.voyageIdenticication)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Port of Loading', `${exportacion?.portCodeLoading?.name} (${exportacion?.portCodeLoading?.portUnlocode})`)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Port of Unlading', `${exportacion?.portCodeUnloading?.name} (${exportacion?.portCodeUnloading?.portUnlocode})`)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Carrier', `${exportacion?.carrier?.oceanCarrierName} (${exportacion?.carrier?.oceanCarrierSacCode})`)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('On Carriage by', exportacion?.onCarriageBy)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Origin', exportacion?.portOriginCode)}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
          {labels('Destination', exportacion?.portDestinationCode)}
        </div>
      </div>
    </>
  );
}

export default ExportacionInfo;
