import React, {useState, useEffect} from 'react';
import {
  addWR,
  showCodeExport,
  showLicenceType,
  showOrigin,
  showSchedule,
  showStates,
  showTypeMerch
} from '../Servicios/WerehouseService';
import {useParams, Link, useNavigate} from "react-router-dom";
import {showSubasta, showImportadora, showAduana, showOrigen} from '../Servicios/CombosService';
import {Container} from 'react-bootstrap';
import useCurrentUser from '../Hooks/UserHook';
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {ToastContainer, toast} from 'react-toastify';
import {faCar, faFloppyDisk, faLeftLong} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LoadingInside from '../Componentes/LoadingInside';
import {listClients} from '../Servicios/ClienteService';
import AddVehicleWarehouseForm from "./AddVehicleWarehouseForm";
import {Modal} from "antd";
import {StringFormats} from "../utils/string-formats";
import Spinner from "../shared/spinner";
import {ClienteTypeEnum} from "../shared/enums/cliente-type.enum";

const NewWROrigin = () => {
  let {id} = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [message, setMessage] = useState('');
  const [cargando, setCargando] = useState(false);

  const notify = () => toast.success("Se regitro correctamente", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const [subastas, setSubastas] = useState([]);
  const [subasta, setSubasta] = useState([]);

  const [importadoras, setImportadoras] = useState([]);
  const [importadora, setImportadora] = useState([]);

  const [clientes, setClientes] = useState([]);

  const [showE, setShowE] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseE = () => setShowE(false);
  const handleShowE = () => setShowE(true);

  const [typemerch, setTypemerch] = useState([]);
  const [codeexport, setCodeexport] = useState([]);
  const [licencetype, setLicencetype] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [states, setStates] = useState([]);
  const [scheduleb, setScheduleb] = useState([]);
  const [origen, setOrigen] = useState([]);

  const [vehiculo, setVehiculo] = useState([]);

  const [aduanas, setAduanas] = useState([]);
  const [isSubmitted, setSubmitted] = useState(false);

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const fechaactual = (`${day}/${month}/${year}`);

  const [infoCoor, setInfoCoor] = useState([]);
  const [newWR, setNewWR] = useState({
    werehouseId: "",
    codigo: "",
    fecha: StringFormats.dateDB(new Date()),
    recivedBy: "",
    auction: "",
    numTitle: "",
    numLlave: "",
    fotos: false,
    linkFotos: "",
    valorV: "",
    notas: "",
    quantity: "",
    licenceNum: "",
    idCoordi: id,
    schedulebId: "",
    stateId: "",
    typeMerchId: "",
    originId: "",
    licenceTypeId: "",
    expoCodeId: "",
    subastaId: "",
    importadoraId: "",
    vehiculoId: "",
    aduanaId: "",
    clienteId: "",
    idOrigen: "",
    shipped: "",
    poaW: false,
    flete: "",
  });

  const formularioEsValido = () => {
    return newWR.numTitle !== 0 && newWR.stateId !== 0 && newWR.valorV !== 0 && newWR.auction !== '' && newWR.importadoraId !== 0 && newWR.subastaId !== 0 && newWR.clienteId !== 0 && newWR.flete !== '';
  }

  useEffect(() => {

  }, []);

  const origenId = currentUser?.idubc;

  const loadSubastas = async () => {
    const data = await showSubasta();
    setSubastas(data);
  }

  const loadImports = async () => {
    const data = await showImportadora();
    setImportadoras(data.list);
  }
  const loadClientes = async () => {
    const data = await listClients();
    setClientes(data);
  }
  const loadAduanas = async () => {
    const data = await showAduana();
    setAduanas(data);
  }
  const loadCodeExp = async () => {
    const data = await showCodeExport();
    setCodeexport(data);
  }
  const loadLicType = async () => {
    const data = await showLicenceType();
    setLicencetype(data);
  }
  const loadOrigin = async () => {
    const data = await showOrigin();
    setOrigin(data);
  }
  const loadSchedule = async () => {
    const data = await showSchedule();
    setScheduleb(data);
  }
  const loadStates = async () => {
    const data = await showStates();
    setStates(data);
  }
  const loadTypeMerch = async () => {
    const data = await showTypeMerch();
    setTypemerch(data);
  }

  const loadOrigen = async () => {
    const data = await showOrigen();
    setOrigen(data);
  }

  const handleSelectChangeSubs = ({value}) => {
    setNewWR({...newWR, subastaId: value})
  }
  const handleSelectChangeImp = ({value}) => {
    setNewWR({...newWR, importadoraId: value})
  }
  const handleSelectChangeCli = ({value}) => {
    setNewWR({...newWR, clienteId: value})
  }

  const handleSelectChangeAdu = e => {
    const {value} = e.target;
    setNewWR({...newWR, aduanaId: value})
  }

  const handleChange = e => {
    const {name, value} = e.target;

    setNewWR({
      ...newWR,
      [name]: value,
    });
  }

  const handleSelectChange = e => {
    const {name, value} = e.target;
    setNewWR({
      ...newWR,
      [name]: value,
    });
  }

  const handleOnChangeWR = (evt) => {
    const {name, checked} = evt.target
    setNewWR({
      ...newWR,
      [name]: checked
    });
  }

  useEffect(() => {
    loadAduanas();
    loadClientes();
    loadOrigen();
    loadAduanas();
    loadCodeExp();
    loadOrigin();
    loadStates();
    loadTypeMerch();
    loadLicType();
    loadSchedule();
    loadSubastas();
    loadImports();
    if (currentUser)
      setNewWR({
        ...newWR,
        recivedBy: currentUser?.fullName,
        idOrigen: currentUser?.idubc,
        quantity: "1",
        licenceNum: "NLR",
        originId: "1",
        typeMerchId: "1",
        schedulebId: "1",
        licenceTypeId: "1",
        expoCodeId: "1",
        shipped: "1",
      })
  }, [currentUser]);

  const insertarWR = async () => {
    setSubmitted(true);
    if (!formularioEsValido()) {
      return;
    }

    try {
      setIsLoading(true);
      addWR({
        ...newWR,
        importadoraId: +newWR.importadoraId,
        subastaId: +newWR.subastaId,
        typeMerchId: +newWR.typeMerchId,
        originId: +newWR.originId,
        schedulebId: +newWR.schedulebId,
        licenceTypeId: +newWR.licenceTypeId,
        expoCodeId: +newWR.expoCodeId,
        stateId: +newWR.stateId,
        quantity: +newWR.quantity,
      }).then((data) => {
        setIsLoading(false);
        notify();
        setSubasta("");
        setImportadora("");
        setTimeout(() => {
          return navigate(`/viewWereHouse/${data.werehouseId}`);
        }, 4000);
      }).catch(error => {
        setIsLoading(false);
        setMessage(error.message);
        setTimeout(() => {
          setMessage(" ");
        }, 4000);
      });
    } catch (ex) {
      console.log(ex)
    }
  }

  if (!newWR) {
    return (<></>);
  }
  if (cargando) {
    return (
      <LoadingInside/>
    )
  } else {

    return (
      <Container>
        <Spinner loading={isLoading}>
          <Row>
            <h4>Creando nuevo Werehouse</h4>
            <hr/>
          </Row>
          <Row>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>Fecha Recibido</Form.Label>
              <Form.Control readOnly disabled required value={fechaactual} type="text" aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} md={{span: 3, offset: 6}}>
              <Form.Label className='text-aling-left'>Creado por:</Form.Label>
              <Form.Control readOnly disabled required name='revicedBy' value={newWR?.recivedBy} type="text"
                            aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
              <br/>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg="6">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.subastaId === '0' || newWR.subastaId === '') ? 'required' : ''}`}>Shipper</Form.Label>
              <Select
                onChange={handleSelectChangeSubs}
                defaultValue={{label: "Seleccionar Shipper", value: "empty"}}
                name='subastaId'
                options={subastas && subastas.length && subastas.map(subs => ({
                  label: subs.subName + "-" + subs.direccion, value: subs.subId
                }))}
              />
              {
                isSubmitted && (newWR.subastaId === '0' || newWR.subastaId === '') &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
            <Col xs={12} lg="6">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.importadoraId === '0' || newWR.importadoraId === '') ? 'required' : ''}`}>Consignee</Form.Label>
              <Select
                onChange={handleSelectChangeImp}
                defaultValue={{label: "Seleccionar Consignee", value: "empty"}}
                name='importadoraId'
                options={importadoras && importadoras.length && importadoras.map(imp => ({
                  label: imp.impName + "-" + imp.direccion, value: imp.id
                }))}
              />
              {
                isSubmitted && (newWR.importadoraId === '0' || newWR.importadoraId === '') &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg="4">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.aduanaId === '0' || newWR.aduanaId === '') ? 'required' : ''}`}>Aduana
                destino</Form.Label>
              <Form.Select onChange={handleSelectChangeAdu} name='aduanaId' aria-label="Small"
                           aria-describedby="inputGroup-sizing-sm">
                <option disabled selected>Seleccione</option>
                {
                  aduanas && aduanas.length && aduanas.map((adu, index) => {
                    return <option key={index} value={adu.id}>{adu.nameAduana} - {adu.countryName}</option>
                  })
                }
              </Form.Select>
              {
                isSubmitted && (newWR.aduanaId === '0' || newWR.aduanaId === '') &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
            <Col xs={12} lg="2">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.idOrigen === '0' || newWR.idOrigen === '') ? 'required' : ''}`}>Origen</Form.Label>
              <Form.Select disabled readOnly onChange={handleChange} name='idOrigen' value={newWR?.idOrigen}
                           aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                <option disabled selected>Seleccione</option>
                {
                  origen && origen.length && origen.map((ori, index) => {
                    return <option key={index} value={ori.id}>{ori.nameOrigen}</option>
                  })
                }
              </Form.Select>
              {
                isSubmitted && (newWR.idOrigen === '0' || newWR.idOrigen === '') &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
            <Col xs={12} lg="4">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.clienteId === '0' || newWR.clienteId === '') ? 'required' : ''}`}>Cliente</Form.Label>
              <Select
                onChange={handleSelectChangeCli}
                defaultValue={{label: "Seleccionar cliente", value: "empty"}}
                name='clienteId'
                options={clientes && clientes.length && clientes.map(cli => ({
                  label: cli.nombreCompleto, value: cli.id
                }))}
              />
              {
                isSubmitted && (newWR.clienteId === '0' || newWR.clienteId === '') &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
            <Col xs={12} lg="2">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.flete === '0' || newWR.flete === '') ? 'required' : ''}`}>Flete</Form.Label>
              <Form.Control name='flete' onChange={handleChange} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
              {
                isSubmitted && (newWR.flete === '0' || newWR.flete === '') &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
              <br/>
            </Col>
            <hr/>
          </Row>
          <Row>
            <Col xs={12} lg="3">
              <Button onClick={handleShowE} variant="info"> <FontAwesomeIcon icon={faCar}/> Igresar Info de
                vehiculo</Button>
            </Col>
            <Col xs={6} md={1}>
              <br/>
              <Form.Check
                readOnly disabled
                checked={vehiculo?.titulo}
                label="Titulo"
                name='titulo'
                type="checkbox"
              />
            </Col>
            <Col xs={6} md={1}>
              <br/>
              <Form.Check
                readOnly disabled
                checked={vehiculo?.llave}
                label="Llave"
                name='llave'
                type="checkbox"
              />
            </Col>
          </Row>
          <br/>
          <Row>
            <br/>

            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>VIN</Form.Label>
              <Form.Control readOnly disabled value={vehiculo?.vin} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>LOTE</Form.Label>
              <Form.Control readOnly disabled value={vehiculo?.lote} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>Vehiculo</Form.Label>
              <Form.Control readOnly disabled value={vehiculo?.vehiculoName} required aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>Color</Form.Label>
              <Form.Control readOnly disabled value={vehiculo?.color} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
              <br/>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>Peso</Form.Label>
              <Form.Control readOnly disabled value={vehiculo?.weight} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>Largo</Form.Label>
              <Form.Control readOnly disabled value={vehiculo?.length} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>Alto</Form.Label>
              <Form.Control readOnly disabled value={vehiculo?.height} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'>Ancho</Form.Label>
              <Form.Control readOnly disabled value={vehiculo?.width} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
              <br/>
            </Col>

          </Row>
          <Row>
            <Col xs={12} lg="3">
              <Form.Label className='text-aling-left'><strong>Nota:</strong> Para guardar el Warehouse Receipt todos los
                datos del vehiculo tienen que estar completos.</Form.Label>
            </Col>
            <hr/>
          </Row>

          <Row>
            <Col xs={12} lg="1">
              <Form.Label className='text-aling-left'>Quantity</Form.Label>
              <Form.Control onChange={handleChange} name='quantity' value={newWR?.quantity} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col md={{span: 3, offset: 1}}>
              <br/>
              <Form.Check
                label="Tiene POA?"
                name='poaW'
                onChange={handleOnChangeWR}
                type="checkbox"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg="2">
              <Form.Label className='text-aling-left'>Tipo de Vehiculo</Form.Label>
              <Form.Select onChange={handleSelectChange} name='typeMerchId' value={newWR?.typeMerchId} aria-label="Small"
                           aria-describedby="inputGroup-sizing-sm">
                <option disabled selected>Seleccione</option>
                {
                  typemerch && typemerch.length && typemerch.map((tyme, index) => {
                    return <option key={index} value={tyme.typeMerchId}>{tyme.typeMerchName}</option>
                  })
                }
              </Form.Select>
            </Col>
            <Col xs={12} lg="2">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.numTitle === '' || newWR.numTitle === 0) ? 'required' : ''}`}>Numero
                de Titulo</Form.Label>
              <Form.Control name='numTitle'
                            onChange={handleChange}
                            className={isSubmitted && (newWR.numTitle === '' || newWR.numTitle === 0) ? 'is-invalid' : ''}
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
              {
                isSubmitted && (newWR.numTitle === '' || newWR.numTitle === 0) &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
            <Col xs={12} lg="2">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.stateId === '' || newWR.stateId === 0) ? 'required' : ''}`}>Estado
                del Titulo</Form.Label>
              <Form.Select onChange={handleSelectChange} name='stateId' aria-label="Small"
                           aria-describedby="inputGroup-sizing-sm">
                <option disabled selected>Seleccione</option>
                {
                  states && states.length && states.map((ste, index) => {
                    return <option key={index} value={ste.stateId}>{ste.smallState}-{ste.state}</option>
                  })
                }
              </Form.Select>
              {
                isSubmitted && (newWR.stateId === '' || newWR.stateId === 0) &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
            <Col xs={12} lg="2">
              <Form.Label
                className={`text-aling-left ${isSubmitted && (newWR.valorV === '' || newWR.valorV === 0) ? 'required' : ''}`}>Valor
                del Vehiculo</Form.Label>
              <Form.Control name='valorV'
                            onChange={handleChange}
                            className={isSubmitted && (newWR.valorV === '' || newWR.valorV === 0) ? 'is-invalid' : ''}
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
              {
                isSubmitted && (newWR.valorV === '' || newWR.valorV === 0) &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
            <Col xs={12} lg="4">
              <Form.Label
                className={`text-aling-left ${isSubmitted && newWR.auction === '' ? 'required' : ''}`}>Auction</Form.Label>
              <Form.Control name='auction'
                            onChange={handleChange}
                            className={isSubmitted && newWR.auction === '' ? 'is-invalid' : ''}
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
              {
                isSubmitted && newWR.auction === '' &&
                <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
              }
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg="2">
              <Form.Label className='text-aling-left'>Origen</Form.Label>
              <Form.Select onChange={handleSelectChange} name='originId' value={newWR?.originId} aria-label="Small"
                           aria-describedby="inputGroup-sizing-sm">
                <option disabled selected>Seleccione</option>
                {
                  origin && origin.length && origin.map((orn, index) => {
                    return <option key={index} value={orn.originId}>{orn.originName}</option>
                  })
                }
              </Form.Select>
            </Col>
            <Col xs={12} lg="2">
              <Form.Label className='text-aling-left'>Schedule B</Form.Label>
              <Form.Select onChange={handleSelectChange} name='schedulebId' value={newWR?.schedulebId} aria-label="Small"
                           aria-describedby="inputGroup-sizing-sm">
                <option disabled selected>Seleccione</option>
                {
                  scheduleb && scheduleb.length && scheduleb.map((schb, index) => {
                    return <option key={index} value={schb.schedulebId}>{schb.codigo}</option>
                  })
                }
              </Form.Select>
            </Col>
            <Col xs={12} lg="2">
              <Form.Label className='text-aling-left'>Tipo de Licencia</Form.Label>
              <Form.Select onChange={handleSelectChange} name='licenceTypeId' value={newWR?.licenceTypeId}
                           aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                <option disabled selected>Seleccione</option>
                {
                  licencetype && licencetype.length && licencetype.map((lict, index) => {
                    return <option key={index} value={lict.licenceTypeId}>{lict.typeCode}</option>
                  })
                }
              </Form.Select>
            </Col>
            <Col xs={12} lg="2">
              <Form.Label className='text-aling-left'>Codigo de Exportacion</Form.Label>
              <Form.Select onChange={handleSelectChange} name='expoCodeId' value={newWR?.expoCodeId} aria-label="Small"
                           aria-describedby="inputGroup-sizing-sm">
                <option disabled selected>Seleccione</option>
                {
                  codeexport && codeexport.length && codeexport.map((expco, index) => {
                    return <option key={index} value={expco.expoCodeId}>{expco.code}</option>
                  })
                }
              </Form.Select>
            </Col>
            <Col xs={12} lg="2">
              <Form.Label className='text-aling-left'>Numero de Licencia</Form.Label>
              <Form.Control name='licenceNum' onChange={handleChange} value={newWR?.licenceNum} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
            <Col xs={12} lg="2">
              <Form.Label className='text-aling-left'># Numero de Llave</Form.Label>
              <Form.Control name='numLlave' onChange={handleChange} aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"/>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={1}>
              <br/>
              <Form.Check
                label="Fotos"
                name='fotos'
                onChange={handleOnChangeWR}
                type="checkbox"
                id="inline"/>
            </Col>
            <Col xs={12} lg="5">
              <Form.Label className='text-aling-left'>Link de Fotos</Form.Label>
              <Form.Control name='linkFotos' onChange={handleChange} as="textarea" rows={4}/>
              <br/>
            </Col>
            <Col xs={12} lg="6">
              <Form.Label className='text-aling-left'>Notas</Form.Label>
              <Form.Control value={newWR?.notas} name='notas' onChange={handleChange} as="textarea" rows={4}/>
              <br/>
            </Col>
            <hr/>
          </Row>
          <Row style={{justifyContent: "center", color: "#ff0000"}}>
            {
              message !== "" ? <p>{message}</p> : ''
            }
          </Row>

          <Row style={{justifyContent: "center"}}>
            <Button style={{width: '120px'}} as={Link} to={`/coordinations/country/${infoCoor?.origenId}`}
                    variant="warning">
              <FontAwesomeIcon icon={faLeftLong}/>
              &nbsp;Cancelar
            </Button>
            {' '}&nbsp;
            <Button type="button" onClick={() => insertarWR()} style={{width: "200px"}}> <FontAwesomeIcon
              icon={faFloppyDisk}/> Guardar WR</Button>
            <ToastContainer/>
          </Row>
        </Spinner>

        <>
          <Modal title={'Agregando vehiculo al Warehouse'}
                 open={showE}
                 width={600}
                 onCancel={handleCloseE}
                 okButtonProps={{style: {display: "none"}}}
                 cancelButtonProps={{style: {display: "none"}}}>
            <AddVehicleWarehouseForm origenId={origenId}
                                     onCancelar={handleCloseE}
                                     afterSaveVehiculo={vehiculo => {
                                       setNewWR({
                                         ...newWR,
                                         notas: vehiculo.notas,
                                         vehiculoId: vehiculo.vehiculoId,
                                       });

                                       setVehiculo(vehiculo);
                                       handleCloseE();
                                     }}/>
          </Modal>
        </>
      </Container>
    )
  }
}

export default NewWROrigin;
