import React from "react";
import { Image } from "react-bootstrap";
import { LayoutStyles } from "./style";
import { Space, Table, Tag } from "antd";
import moment from "moment";

const columns1 = [
  {
    dataIndex: "name",
    key: "name",
    // haacer un render de la imagen pero centrada horizontalmente y verticalmente
    render: (text) => (
      //CENTER THE IMAGE
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Image
          src={require("../../image/matuscolor.png")}
          width="185"
          height="75"
        />
      </div>
    ),

    onCell: (_, index) => {
      if (index === 0) {
        return {
          rowSpan: 3,
        };
      }
      // These two are merged into above cell
      if (index === 1) {
        return {
          rowSpan: 0,
        };
      }
      if (index === 2) {
        return {
          colSpan: 0,
        };
      }
      return {};
    },
  },
  {
    dataIndex: "age",
    key: "age",
  },
  {
    dataIndex: "address",
    key: "address",
    render: (_, { address }) => (
      <>
        {address?.map((element, index) => (
          <div key={index}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
];

const columns2 = [
  {
    dataIndex: "col1",
    key: "col1",
    title: <div style={{ textAlign: "center" }}>Customer</div>,
    colSpan: 2,
    width: "60%",
    render: (_, { col1 }) => (
      <>
        {col1?.map((element, index) => (
          <div key={index}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
  {
    dataIndex: "col2",
    key: "col2",
    colSpan: 0,
    render: (_, { col2 }) => (
      <>
        {col2?.map((element, index) => (
          <div key={index}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
];

const columns3 = [
  {
    dataIndex: "col1",
    key: "col1",
    title: <div style={{ textAlign: "center" }}>Shipment Information</div>,
    colSpan: 3,
    render: (_, { col1 }) => (
      <>
        {col1?.map((element, index) => (
          <div key={index}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
  {
    dataIndex: "col2",
    key: "col2",
    colSpan: 0,
    render: (_, { col2 }) => (
      <>
        {col2?.map((element, index) => (
          <div key={index}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
  {
    dataIndex: "col3",
    key: "col3",
    colSpan: 0,
    render: (_, { col3 }) => (
      <>
        {col3?.map((element, index) => (
          <div key={index}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
];

const columns4 = [
  {
    title: <div style={{ textAlign: "center" }}>Cargo Information</div>,
    children: [
      {
        dataIndex: "col1",
        key: "col1",
        title: <div style={{ textAlign: "center" }}>Pcs</div>,
        render: (_, { col1 }) => (
          <>
            {col1?.map((element, index) => (
              <div key={index} style={{ textAlign: 'center' }}>
                <strong>{element.titulo}</strong> {element.description}
              </div>
            ))}
          </>
        ),
      },
      {
        dataIndex: "col2",
        key: "col2",
        title: <div style={{ textAlign: "center" }}>Pacage Type</div>,
        render: (_, { col2 }) => (
          <>
            {col2?.map((element, index) => (
              <div key={index} style={{ textAlign: 'center' }}>
                <strong>{element.titulo}</strong> {element.description}
              </div>
            ))}
          </>
        ),
      },
      {
        dataIndex: "col3",
        key: "col3",
        title: <div style={{ textAlign: "center" }}>Description</div>,
        render: (_, { col3 }) => (
          <>
            {col3?.map((element, index) => (
              <div key={index} style={{ textAlign: 'center' }}>
                <strong>{element.titulo}</strong> {element.description}
              </div>
            ))}
          </>
        ),
      },
    ],
  },
];

const columns5 = [
  {
    dataIndex: "col1",
    key: "col1",
    title: <div style={{ textAlign: "center" }}>Desc. of charges	</div>,
    render: (_, { col1 }) => (
      <>
        {col1?.map((element, index) => (
          <div key={index}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
  {
    dataIndex: "col2",
    key: "col2",
    title: <div style={{ textAlign: "center" }}>Payment</div>,
    render: (_, { col2 }) => (
      <>
        {col2?.map((element, index) => (
          <div key={index} style={{
            textAlign: 'center',
          }}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
  {
    dataIndex: "col3",
    key: "col3",
    title: <div style={{ textAlign: "center" }}>Qty</div>,
    render: (_, { col3 }) => (
      <>
        {col3?.map((element, index) => (
          <div key={index} style={{
            textAlign: 'center',
          }}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
  {
    dataIndex: "col4",
    key: "col4",
    title: <div style={{ textAlign: "center" }}>Price</div>,
    render: (_, { col4 }) => (
      <>
        {col4?.map((element, index) => (
          <div key={index} style={{
            textAlign: 'center',
          }}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
  {
    dataIndex: "col5",
    key: "col5",
    title: <div style={{ textAlign: "center" }}>Amount</div>,
    render: (_, { col5 }) => (
      <>
        {col5?.map((element, index) => (
          <div key={index} style={{
            textAlign: 'center',
          }}>
            <strong>{element.titulo}</strong> {element.description}
          </div>
        ))}
      </>
    ),
  },
];

const columns6 = [
  {
    dataIndex: "col1",
    key: "col1",
    title: <div style={{ textAlign: "center" }}>Customer</div>,
    render: (_, { col1 }) => (
      <>
        {col1[0].description?.map((element, index) => {
          return <div key={index} >{element}</div>;
        })}
      </>
    ),
  },
];

const data6 = [
  {
    key: "1",
    col1: [
      {
        titulo: "",
        description: [
          "*This quote is provided as a convenience to our customers and presents nothing more than an approximation of freight charges which is not binding either on Carrier, Shipper or Forwarder.",
          "*Rate quote is valid for 30 days only and is subject to change without notice due to operationional cost.",
          "*By accepting this rate, you understand and accept terms and conditions",
        ],
      },
    ],
  },
];

class QuoteToPrint extends React.Component {
  render() {
    console.log("data componet", this.props.dataImprimir);

    const data1 = [
      {
        key: "1",
        age: "CALIFORNIA",
        address: [
          {
            titulo: "Quotation Number",
            description: `${this.props.dataImprimir?.codigo}`,
          },
        ],
      },
      {
        key: "2",
        age: `${this.props.dataImprimir?.originCoti}`,
        address: [
          {
            titulo: "Expiration Date:",
            description: `${moment(
              this.props.dataImprimir?.dateExpiration
            ).format("DD/MM/YYYY")}`,
          },
        ],
      },
      {
        key: "3",
        age: `Date/Time: ${moment(this.props.dataImprimir?.dateCreate).format(
          "DD/MM/YYYY HH:mm:ss"
        )}`,
        address: [
          {
            titulo: "Employee:",
            description: `${this.props.dataImprimir?.employeeName}`,
          },
        ],
      },
    ];

    const data2 = [
      {
        key: "1",
        col1: [
          {
            titulo: "",
            description: `${this.props.dataImprimir?.clientes?.country?.nameCountry}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.clientes?.nombreCompleto}`,
          },
          {
            titulo: "",
            description: "",
          },
        ],
        col2: [
          {
            titulo: "Email:",
            description: `${this.props.dataImprimir?.clientes?.email}`,
          },
          {
            titulo: "Phone:",
            description: `+${this.props.dataImprimir?.clientes?.contNum}`,
          },
          {
            titulo: "",
            description: "",
          },
        ],
      },
    ];

    const data3 = [
      {
        key: "1",
        col1: [
          {
            titulo: "Origin:",
            description: `${this.props.dataImprimir?.origen?.nameOrigen}`,
          },
          {
            titulo: "Destination:",
            description: `${this.props.dataImprimir?.aduana?.nameAduana}`,
          },
          {
            titulo: "Commodity:",
            description: `${this.props.dataImprimir?.commodity}`,
          },
        ],
        col2: [
          {
            titulo: "Loading Port:",
            description: `${this.props.dataImprimir?.portCode?.name}`,
          },
          {
            titulo: "Container Type:",
            description: `${this.props.dataImprimir?.container?.nameContainer}`,
          },
          {
            titulo: "Transit Time:",
            description: `${this.props.dataImprimir?.transitTime}`,
          },
        ],
        col3: [
          {
            titulo: "Unloading Port:",
            description: `${this.props.dataImprimir?.portCodeDes?.name}`,
          },
          {
            titulo: "Transportation Mode:",
            description: `${this.props.dataImprimir?.modeExport?.name} - ${this.props.dataImprimir?.modeExport?.method}`,
          },
          {
            titulo: "Carrier:",
            description: `${this.props.dataImprimir?.carrier}`,
          },
        ],
      },
    ];
    let data4 = [];

    this.props.dataImprimir?.detailCotizaciones?.map((element, index) => {
      console.log("element", element);

      data4.push({
        key: index,
        col1: [
          {
            description: `${element.pcs}`,
          },
        ],
        col2: [
          {
            description: `${element.packageType}`,
          },
        ],
        col3: [
          {
            description: `${element.description}`,
          },
        ],
      });
    });

    console.log("data4", data4);

    const data5 = [
      {
        key: "1",
        col1: [
          {
            titulo: "Ocean Freight",
            description: "",
          },
          {
            titulo: "Documentation",
            description: "",
          },
          {
            titulo: "Container Drayage",
            description: "",
          },
          {
            titulo: "Container Loading",
            description: "",
          },
          {
            titulo: "",
            description: "",
          },
        ],
        col2: [
          {
            titulo: "",
            description: `${this.props.dataImprimir?.paymentOF}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.paymentD}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.paymentCD}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.paymentCL}`,
          },
          {
            titulo: "",
            description: "",
          },
        ],
        col3: [
          {
            titulo: "",
            description: `${this.props.dataImprimir?.qtyOF}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.qtyD}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.qtyCD}`,
          },
          {
            titulo: "",
            description:  `${this.props.dataImprimir?.qtyCL}`,
          },
          {
            titulo: "",
            description: "",
          },
        ],
        col4: [
          {
            titulo: "",
            description: `${this.props.dataImprimir?.priceOF}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.priceD}`,
          },
          {
            titulo: "",
            description:  `${this.props.dataImprimir?.priceCD}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.priceCL}`,
          },
          {
            titulo: "",
            description: "",
          },
        ],
        col5: [
          {
            titulo: "",
            description: `${this.props.dataImprimir?.amountOF}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.amountD}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.amountCD}`,
          },
          {
            titulo: "",
            description: `${this.props.dataImprimir?.amountCL}`,
          },
          {
            titulo: "Toltal: ",
            description: `${this.props.dataImprimir?.total}`,
          },
        ],
      },
    ];

    return (
      <LayoutStyles>
        <div className="invoice-box row">
          <Table
            columns={columns1}
            dataSource={data1}
            pagination={false}
            showHeader={false}
            bordered={false}
          />
          <Table
            columns={columns2}
            dataSource={data2}
            pagination={false}
            bordered={true}
          />
          <Table
            columns={columns3}
            dataSource={data3}
            pagination={false}
            bordered={true}
          />

          <Table
            columns={columns4}
            dataSource={data4}
            pagination={false}
            bordered={true}
          />

          <Table
            columns={columns5}
            dataSource={data5}
            pagination={false}
            bordered={true}
          />

          <Table
            columns={columns6}
            dataSource={data6}
            pagination={false}
            bordered={true}
          />
        </div>
      </LayoutStyles>
    );
  }
}

export default QuoteToPrint;
