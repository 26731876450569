import React, {useEffect, useState} from "react";
import {createPermiso, updatePermiso} from "../../Servicios/AdminService";
import {Button, Form, Input} from "antd";
import {successMessage} from "../../utils/toast-message";
import Spinner from "../../shared/spinner";

const PermisoForm = ({permiso, doSubmit}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (permiso) {
      form.setFieldValue('nombre', permiso.name);
      form.setFieldValue('codigo', permiso.code);
    } else {
      form.resetFields();
    }
  }, [permiso]);

  const handleSubmit = async (values) => {
    setLoading(true);
    if (permiso) {
      await updatePermiso(permiso.id, values);
      successMessage('Permisoe updated sucessfully');
    } else {
      await createPermiso(values);
      successMessage('Permisoe created sucessfully');
    }
    setLoading(false);
    form.resetFields();

    if (doSubmit) doSubmit();
  }

  return(
    <>
      <Spinner loading={loading}>
        <Form form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}>
          <Form.Item label={"Name"}
                     name={"nombre"}
                     rules={[{ required: true, message: 'This field is required' }]}>
            <Input placeholder={"Permiso Name"}/>
          </Form.Item>
          <Form.Item label={"Code"}
                     name={"codigo"}
                     rules={[{ required: true, message: 'This field is required' }]}>
            <Input placeholder={"Code"}/>
          </Form.Item>
          <Button type={'primary'} htmlType={'submit'}>Guardar</Button>
        </Form>
      </Spinner>
    </>
  );
}

export default PermisoForm;
