export const CoordinacionEntryTypeEnum = {
  entryTypeByOwn: "Gestionado por Matus",
  entryTypeByClient: "Gestionado por Cliente",
  entryTypePendingByClient: "Pendiente de Entrega por Cliente",
}


export const TypePay = [
  {
    value: "PREPAGO",
    label: "PREPAGO",
  },
  {
    value: "POSPAGO",
    label: "POSPAGO",
  },
  {
    value: "PREPAGO GRUA",
    label: "PREPAGO GRUA",
  },
  {
    value: "PREPAGO FLETE",
    label: "PREPAGO FLETE",
  },
  {
    value: "PREPAGO 25%",
    label: "PREPAGO 25%",
  },
  {
    value: "PREPAGO 50%",
    label: "PREPAGO 50%",
  },
]