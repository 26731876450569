import {decodeToken} from "react-jwt";
import {server} from "../environments/environment";
import roles from "../utils/roles";
import {AuthModel, AuthPermission, AuthRole} from "../shared/models/auth.model";

export async function login(username, password) {
    return await fetch(
        `${server}Authentication/login`,
        {
            method: 'POST',
            headers: {
                "accept": '*/*',
                "Content-type": 'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password,
            })
        }).then(async (response) => {
        const data = await response.json();

        // TENES QUE VALIDAR ESTE RESPONSE 'DATA'
        // TODOS VIENEN CON UNA PROP LLAMADA 'SUCCESS'
        if (data.hasOwnProperty('token')) {  //ESTA PARTE COMO SERIA EN ESTE OTRO METODO
            return await data;
        } else {
            // SI SUCCESS ES FALSE ENTONCES VOS MANDAR EL MENSAJE DE ERROR
            // SI QUERES MOSTRA EL ERROR QUE VIENE DEL API CON data.message
            // SINO QUITALO Y MANDA TU PROPIO ERROR PERONALIZADO CON: throw Error("aqui tu mensaje de error");
            throw Error(data.message);
        }
    }).catch(error => {
        throw Error(error);
    });
}

export const logout = () => {
    localStorage.clear();
};

export async function forgot(email) {
    return await fetch(
        `${server}Authentication/forgot-password`,
        {
            method: 'POST',
            headers: {
                "accept": '*/*',
                "Content-type": 'application/json',
            },
            body: JSON.stringify({
                email: email,
            })
        }).then(async (response) => {
        const data = await response.json();
        if (data.status) {
            return await data;
        } else {
            throw Error("Correo no registrado en nuestra base de datos");
        }
    }).catch(error => {
        throw Error(error);
    });
}

export async function changePass(token, password, confirmPassword) {
    return await fetch(
        `${server}Authentication/reset-password`,
        {
            method: 'POST',
            headers: {
                "accept": '*/*',
                "Content-type": 'application/json',
            },
            body: JSON.stringify({
                token: token,
                password: password,
                confirmPassword: confirmPassword,
            })
        }).then(async (response) => {
        const data = await response.json();
        if (data.status) {
            return await data;
        } else {
            throw Error(data.message);
        }

    }).catch(error => {
        throw Error(error);
    });
}

export const getToken = () => {
  if (localStorage.getItem('token'))
    return localStorage.getItem('token');
  return "";
}

export const getDecodedToken = (): AuthModel | null => {
  const token = getToken();
  if (!token) return null;

  const decodedToken = decodeToken(token);
  if (!decodedToken) return null;

  return new AuthModel(decodedToken);
}

export const getUserRoles = (): AuthRole[] => {
  const decodedToken = getDecodedToken();
  if (!decodedToken) return [];

  return decodedToken.roles.map(e => e.role);
}

export const getCountriesId = (): string => {
  const decodedToken = getDecodedToken();
  if (!decodedToken) return "0";

  if (decodedToken.roles.some(e => e.countryId === 0)) return "0";

  if (decodedToken.roles.length === 1 && decodedToken.roles.some(e => e.countryId === null)) {
    return "0";
  }

  return decodedToken.roles
    .map(e => e.countryId)
    .filter(e => e !== null)
    .filter((e, i, arr) => arr.indexOf(e) === i)
    .filter(e => e !== undefined)
    .map(e => e.toString())
    .join(",");
}

export const isAdmin = (): boolean => {
  return getUserRoles().some(e => e.name === roles.ADMIN);
}

export const isSeller = (): boolean => {
  return getUserRoles().some(e => e.name === roles.VENTAS ||
    e.name === roles.VENTAS_SV ||
    e.name === roles.VENTAS_CR ||
    e.name === roles.VENTAS_HN ||
    e.name === roles.VENTAS_GT) && getUserRoles().length === 1;
}

export const hasRol = (...roles): boolean => {
  return getUserRoles().some(e => roles.some(rol => rol === e.name));
}

/**
 * retornar una lista de permisos
 */
export const getPermisos = (): AuthPermission[] => {
  const decodedToken = getDecodedToken();
  if (!decodedToken) return [];

  return decodedToken.permission;
}

export const validarPermisos = (...permisos): boolean => {
  return getPermisos().some(e => permisos.some(permiso => permiso === e.code));
}
