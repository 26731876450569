import React, {useState, useEffect} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";
import Select from 'react-select';
import {Col, Container, Row, Form, Button} from 'react-bootstrap';
import {viewWR} from '../Servicios/WerehouseService';
import {showSubasta, showImportadora} from '../Servicios/CombosService';
import {
  editWerehouse,
  showCodeExport,
  showLicenceType,
  showOrigin,
  showSchedule,
  showStates,
  showTypeMerch
} from '../Servicios/WerehouseService';
import {faFloppyDisk, faLeftLong, faPencil, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {toast} from 'react-toastify';
import {showAduana} from '../Servicios/CombosService';
import {Modal} from "antd";
import SubastaForm from "../Componentes/SubastaForm/SubastaForm";
import ImportadoraForm from "../Componentes/ImportadoraForm/ImportadoraForm";
import Spinner from "../shared/spinner";

const EditWR = () => {

  const {id} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const notify = () => toast.success("Se actualizo informacion correctamente", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const [editWR, setEditWR] = useState([]);

  const [subastas, setSubastas] = useState([]);
  const [subasta, setSubasta] = useState([]);

  const [importadoras, setImportadoras] = useState([]);
  const [importadora, setImportadora] = useState([]);
  const [idcoor, setIdcoor] = useState([]);

  const [typemerch, setTypemerch] = useState([]);
  const [codeexport, setCodeexport] = useState([]);
  const [licencetype, setLicencetype] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [states, setStates] = useState([]);
  const [scheduleb, setScheduleb] = useState([]);

  const [aduanas, setAduanas] = useState([]);

  const [editSubasta, setEditSubasta] = useState(undefined);
  const [addSubasta, setAddSubasta] = useState(false);

  const [editImportadora, setEditImportadora] = useState(undefined);
  const [addImportadora, setAddImportadora] = useState(false);

  const handleSelectChangeAdu = e => {
    const {value} = e.target;
    setEditWR({
      ...editWR,
      aduanaId: value
    });
  }

  const loadAduanas = async () => {
    const data = await showAduana();
    setAduanas(data);
  }

  const loadSubastas = async () => {
    const data = await showSubasta();
    setSubastas(data);
  }
  const loadImports = async () => {
    const data = await showImportadora();
    setImportadoras(data.list);
  }
  const loadCodeExp = async () => {
    const data = await showCodeExport();
    setCodeexport(data);
  }
  const loadLicType = async () => {
    const data = await showLicenceType();
    setLicencetype(data);
  }
  const loadOrigin = async () => {
    const data = await showOrigin();
    setOrigin(data);
  }
  const loadSchedule = async () => {
    const data = await showSchedule();
    setScheduleb(data);
  }
  const loadStates = async () => {
    const data = await showStates();
    setStates(data);
  }
  const loadTypeMerch = async () => {
    const data = await showTypeMerch();
    setTypemerch(data);
  }

  const handleSelectChangeSubs = ({value}) => {
    const subasta = subastas.find(e => e.subId === value);
    setSubasta(subasta);
    setEditWR({
      ...editWR,
      subasta: subasta,
      subastaId: value
    })
  }
  const handleSelectChangeImp = ({value}) => {
    const importadora = importadoras.find(e => e.id === value);
    setImportadora(importadora);
    setEditWR({
      ...editWR,
      importadoraId: value,
      importadora: importadora,
    })
  }

  const handleChange = e => {
    const {name, value} = e.target;
    setEditWR({
      ...editWR,
      [name]: value,
    });
  }

  const handleSelectChange = e => {
    const {name, value} = e.target;
    setEditWR({
      ...editWR,
      [name]: value,
    });
  }

  const handleOnChangeWR = (evt) => {
    const {name, checked} = evt.target
    setEditWR({
      ...editWR,
      [name]: checked
    });
  }

  const editarWR = async () => {
    try {
      setIsLoading(true);
      const data = await editWerehouse(id, {
        ...editWR,
        subastaId: +editWR.subastaId,
        importadoraId: +editWR.importadoraId,
        quantity: +editWR.quantity,
        schedulebId: +editWR.schedulebId,
        stateId: +editWR.stateId,
        typeMerchId: +editWR.typeMerchId,
        originId: +editWR.originId,
        licenceTypeId: +editWR.licenceTypeId,
        expoCodeId: +editWR.expoCodeId,
        aduanaId: +editWR.aduanaId,
      });
      setIsLoading(false);
      if (data) {
        notify();
        setTimeout(() => {
          return navigate(`/viewWereHouse/${id}`);
        }, 4000);
      }
    } catch (ex) {
      setIsLoading(false);
      console.log(ex);
    }
  }

  const handleUpdateSubasta = async subasta => {
    await loadSubastas();

    setSubasta(subasta);
    setEditWR({
      ...editWR,
      subasta: subasta,
      subastaId: subasta.subId,
    });

    setAddSubasta(false);
    setEditSubasta(undefined);
  }

  const handleUpdateImportadora = async importadora => {
    await loadImports();

    setImportadora(importadora);
    setEditWR({
      ...editWR,
      importadora: importadora,
      importadoraId: importadora.id,
    });

    setAddImportadora(false);
    setEditImportadora(undefined);
  }

  const visualizarwr = () => {
    setIsLoading(true);
    viewWR(id).then((whr) => {
      setEditWR(whr);
      setSubasta(whr.subasta);
      setImportadora(whr.importadora);
      setIdcoor(whr.idCoordi);
    }).finally(() => {
      setIsLoading(false)
    });
  };

  useEffect(() => {
    loadAduanas();
    loadTypeMerch();
    loadStates();
    loadSchedule();
    loadOrigin();
    loadLicType();
    loadCodeExp();
    loadSubastas();
    loadImports();
    visualizarwr();
  }, [id]);

  return (
    <Spinner loading={isLoading}>
      <Container>
        <Row>
          <h4>Editando {editWR?.codigo} de {editWR?.impName} </h4>
        </Row>
        <hr/>
        <Row>
          <h5>Información del vehículo.</h5>
          <Col xs={12} lg="4">
            <Form.Label className='text-aling-left'><strong>CODIGO:</strong> {editWR?.codigo}</Form.Label> <br/>
            <Form.Label className='text-aling-left'><strong>Lote:</strong> {editWR?.lote}</Form.Label> <br/>
            <Form.Label className='text-aling-left'><strong>VIN:</strong> {editWR?.vin}</Form.Label> <br/>
            <Form.Label className='text-aling-left'><strong>Origen:</strong> {editWR?.origenName}</Form.Label> <br/>
            <Form.Label className='text-aling-left'><strong>Aduana destino:</strong> {editWR?.aduanaCom}</Form.Label>
          </Col>
          <Col xs={2} lg="2">

          </Col>
          <Col xs={2} lg="4">
            <Form.Label className='text-aling-left'><strong>VEHICULO:</strong> {editWR?.vehiculoName} {editWR?.color}
            </Form.Label> <br/>
            <Form.Label className='text-aling-left'><strong>Titulo:</strong> {editWR?.titulo}</Form.Label> <br/>
            <Form.Label className='text-aling-left'><strong>Llave:</strong> {editWR?.llave}</Form.Label> <br/>
            <Form.Label className='text-aling-left'><strong>Catalitico:</strong> {editWR?.catalitico}</Form.Label> <br/>
            <Form.Label className='text-aling-left'><strong>Bio-H:</strong> {editWR?.bioH}</Form.Label>
          </Col>
        </Row>
        <hr/>
        <Row className="mb-3">
          <Col xs={12} lg="6">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <p className="mb-0">Shipper</p>
              <div>
                <Button size={'sm'} variant={'link'} className={'text-decoration-none'}
                        onClick={() => setAddSubasta(true)}>
                  <FontAwesomeIcon className="me-2" icon={faPlus} /> Agregar
                </Button>
                <Button size={'sm'} variant={'link'} className={'text-decoration-none'}
                        onClick={() => setEditSubasta(subasta)}>
                  <FontAwesomeIcon className="me-2" icon={faPencil} /> Modificar
                </Button>
              </div>
            </div>
            <Select
              onChange={handleSelectChangeSubs}
              name='subastaId'
              value={editWR && editWR.subasta ? {value: editWR.subastaId, label: editWR.subasta.subName + ' - ' + editWR.subasta.direccion} : ''}
              options={subastas && subastas.length && subastas.map(subs => ({
                label: subs.subName + " - " + subs.direccion, value: subs.subId
              }))}
            />
          </Col>
          <Col xs={12} lg="6">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <p className="mb-0">Consignee</p>
              <div>
                <Button size={'sm'} variant={'link'} className={'text-decoration-none'}
                        onClick={() => setAddImportadora(true)}>
                  <FontAwesomeIcon className="me-2" icon={faPlus}/> Agregar
                </Button>
                <Button size={'sm'} variant={'link'} className={'text-decoration-none'}
                        onClick={() => setEditImportadora(importadora)}>
                  <FontAwesomeIcon className="me-2" icon={faPencil}/> Modificar
                </Button>
              </div>
            </div>
            <Select
              onChange={handleSelectChangeImp}
              value={editWR && editWR.importadora ? {value: editWR.importadoraId, label: editWR.importadora.impName + ' - ' + editWR.importadora.direccion} : ''}
              name='importadoraId'
              options={importadoras && importadoras.length && importadoras.map(imp => ({
                label: imp.impName + " - " + imp.direccion, value: imp.id
              }))}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} lg="1">
            <Form.Label className='text-aling-left'>Quantity</Form.Label>
            <Form.Control onChange={handleChange} name='quantity' value={editWR?.quantity} aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
          <Col md={{span: 3, offset: 1}}>
            <br/>
            <Form.Check
              label="Tiene POA?"
              name='poaW'
              checked={editWR?.poaW}
              onChange={handleOnChangeWR}
              type="checkbox"
              id="inline"/>
          </Col>
          <Col md={{span: 3, offset: 1}}>
            <Form.Label className='text-aling-left'>Aduana Destino </Form.Label>
            <Form.Select name='aduanaId' onChange={handleSelectChangeAdu} value={editWR?.aduanaId} aria-label="Small"
                         aria-describedby="inputGroup-sizing-sm">
              <option disabled selected>Seleccione</option>
              {
                aduanas && aduanas.length && aduanas.map((aduanas, index) => {
                  return <option key={index} value={aduanas.id}>{aduanas.nameAduana + '-' + aduanas.countryName}</option>
                })
              }
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'>Tipo de Vehiculo</Form.Label>
            <Form.Select onChange={handleSelectChange} name='typeMerchId' value={editWR?.typeMerchId} aria-label="Small"
                         aria-describedby="inputGroup-sizing-sm">
              <option disabled selected>Seleccione</option>
              {
                typemerch && typemerch.length && typemerch.map((tyme, index) => {
                  return <option key={index} value={tyme.typeMerchId}>{tyme.typeMerchName}</option>
                })
              }
            </Form.Select>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className={`text-aling-left`}>Numero de Titulo</Form.Label>
            <Form.Control name='numTitle'
                          value={editWR?.numTitle}
                          onChange={handleChange}
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className={`text-aling-left`}>Estado del Titulo</Form.Label>
            <Form.Select onChange={handleSelectChange} name='stateId' value={editWR?.stateId} aria-label="Small"
                         aria-describedby="inputGroup-sizing-sm">
              <option disabled selected>Seleccione</option>
              {
                states && states.length && states.map((ste, index) => {
                  return <option key={index} value={ste.stateId}>{ste.smallState}-{ste.state}</option>
                })
              }
            </Form.Select>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className={`text-aling-left`}>Valor del Vehiculo</Form.Label>
            <Form.Control name='valorV'
                          onChange={handleChange}
                          value={editWR?.valorV}
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
          <Col xs={12} lg="3">
            <Form.Label className={`text-aling-left`}>Auction</Form.Label>
            <Form.Control name='auction'
                          onChange={handleChange}
                          value={editWR?.auction}
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'>Origen</Form.Label>
            <Form.Select onChange={handleSelectChange} name='originId' value={editWR?.originId} aria-label="Small"
                         aria-describedby="inputGroup-sizing-sm">
              <option disabled selected>Seleccione</option>
              {
                origin && origin.length && origin.map((orn, index) => {
                  return <option key={index} value={orn.originId}>{orn.originName}</option>
                })
              }
            </Form.Select>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'>Schedule B</Form.Label>
            <Form.Select onChange={handleSelectChange} name='schedulebId' value={editWR?.schedulebId} aria-label="Small"
                         aria-describedby="inputGroup-sizing-sm">
              <option disabled selected>Seleccione</option>
              {
                scheduleb && scheduleb.length && scheduleb.map((schb, index) => {
                  return <option key={index} value={schb.schedulebId}>{schb.codigo}</option>
                })
              }
            </Form.Select>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'>Tipo de Licencia</Form.Label>
            <Form.Select onChange={handleSelectChange} name='licenceTypeId' value={editWR?.licenceTypeId}
                         aria-label="Small" aria-describedby="inputGroup-sizing-sm">
              <option disabled selected>Seleccione</option>
              {
                licencetype && licencetype.length && licencetype.map((lict, index) => {
                  return <option key={index} value={lict.licenceTypeId}>{lict.typeCode}</option>
                })
              }
            </Form.Select>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'>Codigo de Exportacion</Form.Label>
            <Form.Select onChange={handleSelectChange} name='expoCodeId' value={editWR?.expoCodeId} aria-label="Small"
                         aria-describedby="inputGroup-sizing-sm">
              <option disabled selected>Seleccione</option>
              {
                codeexport && codeexport.length && codeexport.map((expco, index) => {
                  return <option key={index} value={expco.expoCodeId}>{expco.code}</option>
                })
              }
            </Form.Select>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'>Numero de Licencia</Form.Label>
            <Form.Control name='licenceNum' onChange={handleChange} value={editWR?.licenceNum} aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
          <Col xs={12} lg="2">
            <Form.Label className='text-aling-left'># Numero de Llave</Form.Label>
            <Form.Control name='numLlave' onChange={handleChange} value={editWR?.numLlave} aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"/>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6} md={1}>
            <br/>
            <Form.Check
              label="Fotos"
              name='fotos'
              checked={editWR?.fotos}
              onChange={handleOnChangeWR}
              type="checkbox"
              id="inline"/>
          </Col>
          <Col xs={12} lg="5">
            <Form.Label className='text-aling-left'>Link de Fotos</Form.Label>
            <Form.Control name='linkFotos' onChange={handleChange} value={editWR?.linkFotos} as="textarea" rows={4}/>
            <br/>
          </Col>
          <Col xs={12} lg="6">
            <Form.Label className='text-aling-left'>Notas</Form.Label>
            <Form.Control name='notas' onChange={handleChange} value={editWR?.notas} as="textarea" rows={4}/>
            <br/>
          </Col>
        </Row>
        <Row className="mb-3" style={{justifyContent: "center"}}>
          <Col xs={12} lg="3">
            <Button style={{width: '120px'}} as={Link} to={`/warehouseReceipt/${editWR?.idOrigen}`} variant="warning">
              <FontAwesomeIcon icon={faLeftLong}/>
              &nbsp;Regresar
            </Button> &nbsp;
            <Button onClick={editarWR} variant="primary"> <FontAwesomeIcon icon={faFloppyDisk}/> Actualizar</Button>

          </Col>
          <br/>
        </Row>
        <hr/>

        <Modal open={addSubasta || !!editSubasta}
               title={addSubasta ? 'Crear Nuevo' : 'Modificar'}
               onCancel={() => {
                 setAddSubasta(false);
                 setEditSubasta(undefined);
               }}
               okButtonProps={{style: {display: "none"}}}
               cancelButtonProps={{style: {display: "none"}}}>
          <SubastaForm titulo={'Shipper'}
                       subasta={editSubasta}
                       afterSave={handleUpdateSubasta}
                       onCancel={() => {
                         setAddSubasta(false);
                         setEditSubasta(undefined);
                       }} />
        </Modal>

        <Modal open={addImportadora || !!editImportadora}
               title={addImportadora ? 'Crear Nuevo' : 'Modificar'}
               onCancel={() => {
                 setAddImportadora(false);
                 setEditImportadora(undefined);
               }}
               okButtonProps={{style: {display: "none"}}}
               cancelButtonProps={{style: {display: "none"}}}>
          <ImportadoraForm titulo={'Consignee'}
                           importadora={editImportadora}
                           afterSubmit={handleUpdateImportadora}
                           onCancel={() => {
                             setAddImportadora(false);
                             setEditImportadora(undefined);
                           }} />
        </Modal>
      </Container>
    </Spinner>
  );
}

export default EditWR;

