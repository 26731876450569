import { Spin } from "antd";
import "./style.css"

const SpinnerTable = () => {
    return (
  <div className="example">
    <Spin />
  </div>);
};

export default SpinnerTable;
