import React, {useState, useEffect} from "react";
import {Col, Container, Row, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import '../hojas-de-estilo/style.css';
import '../hojas-de-estilo/styleWR.css';
import logocolor from '../image/matuscolor.png';
import {useParams, Link} from "react-router-dom";
import {viewWR} from "../Servicios/WerehouseService";
import {faEnvelopeCircleCheck, faPrint, faLeftLong, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import {getPDFWRId} from "../Servicios/WerehouseService";
import getPdf from "../Servicios/Base64ToPdf";
import {enviarEmail} from "../Servicios/SolicitudesService";
import {ToastContainer, toast} from 'react-toastify';
import Spinner from "../shared/spinner";
import moment from "moment";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";

const VisualizarWR = () => {
  let {id} = useParams();

  const [infowere, setInfowere] = useState([]);
  const [showT, setShowT] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseT = () => setShowT(false);
  const handleShowT = () => {
    setShowT(true);
    if (id !== undefined && id !== 0)
      getPDFWRId(id).then((pdf) => {
        const temp = [...attach]
        temp.push({nombreArchivo: 'Warehouse Receipt.pdf', archivo: "data:application/pdf;base64," + pdf})
        setAttach([...temp])
      });
    setSendCo({
      ...sendCo, subject: `Vehiculo recibido en bodega Matus International -Lot#${infowere?.lote}`,
      body: `<!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta name="x-apple-disable-message-reformatting">
    <title>Solicitud de recoleccion</title>
    <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
    <style>
    table, td, div, h1, p {font-family: Arial, sans-serif;}
    </style>
    </head>
    <body style="margin:0;padding:0;">
    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
    <tr>
    <td align="center" style="padding:0;">
    
        <table role="presentation" style="width:602px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
            <tr>
                <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
                    <img src="https://portalmatus.com/web/static/media/logoblanco.827dc793290383d30762.png" alt="" width="300" style="height:auto;display:block;" />
                </td>
            </tr>
            <tr>
                <td style="padding:10px 30px 10px 30px;">
                    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                        <tr>
                            <td style="padding:0;">
                                <h1 align="center">Vehiculo recibido en bodega<br/> Matus International</h1>
                                <p>Hola Estimado cliente,<br/><br/> Le escribo para informarle que ya tenemos su <strong> ${infowere?.vehiculoName} </strong> en nuestra bodega y se va embarcar lo mas pronto posible.<br/><br/>
                                Por favor tome nota que su vehiculo tiene:<br/></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding:0;">
                                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                                    <tr>
                                        <td style="width:260px;padding:0;vertical-align:top;">
                                            <strong>Titulo:</strong> ${infowere?.titulo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:260px;padding:0;vertical-align:top;">
                                            <strong>Llave:</strong> ${infowere?.llave}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:260px;padding:0;vertical-align:top;">
                                            <strong>Catalitico:</strong> ${infowere?.catalitico}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:260px;padding:0;vertical-align:top;">
                                            <strong>Poa:</strong> ${infowere?.poaWR}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding:0;">
                                <p>Si desea conocer el estado en el que hemos recibido su vehiculo favor darle click en el siguiente enlace:<br/><br/>${infowere?.linkFotos}
                                <br/>
                                <br/>
                                    Gracias por escoger a Matus International como su embarcador preferido.<br/>
                                <br/>
                                    Saludos cordiales,</p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style="padding:30px;background:#1C2E51;">
                    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                        <tr>
                            <td style="padding:0;width:50%;" align="left">
                                <p style="color:white;">&reg; Matus International, 2023</p>
                            </td>
                            <td style="padding:0;width:50%;" align="right">
                                <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                                    <tr>
                                        <td style="padding:0 0 0 10px;width:38px;">
                                        <a href="https://www.facebook.com/matusintl/"><img src="https://portalmatus.com/facebook-white.png" alt="Instagram" width="38" style="height:auto;display:block;border:0;" /></a>
                                        </td>
                                        <td style="padding:0 0 0 10px;width:38px;">
                                            <a href="https://www.instagram.com/matusintl/?hl=en"><img src="https://portalmatus.com/instagram-white.png" alt="Facebook" width="38" style="height:auto;display:block;border:0;" /></a>
                                        </td>
                                        <td style="padding:0 0 0 10px;width:38px;">
                                            <a href="https://www.matusintl.com/"><img src="https://portalmatus.com/web-white.png" alt="Web" width="28" style="height:auto;display:block;border:0;" /></a>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </td>
    </tr>
    </table>
    </body>
    </html>`
    })
    setToC(currentToc => [...currentToc, infowere?.emailCli]);
  };

  const notify = () => toast.success("Correo EnProceso", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const [to, setTo] = useState("");
  const [toC, setToC] = useState([]);

  const [cc, setCc] = useState("");
  const [ccC, setCcC] = useState([]);

  const [cco, setCco] = useState("");
  const [ccoC, setCcoC] = useState([]);

  const [attach, setAttach] = useState([]);

  const [sendCo, setSendCo] = useState({
    to: toC,
    cc: ccC,
    cco: ccoC,
    subject: "",
    body: "",
    attachedFile: attach,
  });

  const agregarTo = (event) => {
    event.preventDefault();
    setToC(currentToc => [...currentToc, to]);
    setTo('');
  }

  const handleDeleteTO = (deletingItem) => {
    const lisTo = toC.filter((item) => item !== deletingItem);
    setToC(lisTo);
  }

  const agregarCA = () => {
    setCcC(currentCA => [...currentCA, "trilce@matusintl.com", "crystal@matusintl.com", "gabriel@matusintl.com", "emercado@matusintl.com", "celeste@matusintl.com"]);
  }
  const agregarFL = () => {
    setCcC(currentFL => [...currentFL, "brandon@matusintl.com", "kay@matusintl.com", "henry@matusintl.com", "carolina@matusintl.com", "andres@matusintl.com", "mbrito@matusintl.com", "elizabeth@matusintl.com", "diana@matusintl.com"]);
  }
  const agregarCR = () => {
    setCcC(currentCR => [...currentCR, 'pbarrantes@matusintl.com', 'osilva@matusintl.com', 'merilyn@matusintl.com']);
  }
  const agregarGT = () => {
    setCcC(currentGT => [...currentGT, 'andrea@matusintl.com', 'elliott@matusintl.com', 'ahernandez@matusintl.com', 'christian@matusintl.com', 'fabian@matusintl.com']);
  }
  const agregarSV = () => {
    setCcC(currentSV => [...currentSV, 'karla@matusintl.com', 'cesar@matusintl.com', 'nelson@matusintl.com', 'yeymy@matusintl.com', 'norma@matusintl.com', 'brenda@matusintl.com']);
  }

  const agregarCC = (event) => {
    event.preventDefault();
    setCcC(currentCC => [...currentCC, cc]);
    setCc('');
  }

  const handleDeleteCC = (deletingItem) => {
    const lisCC = ccC.filter((item) => item !== deletingItem);
    setCcC(lisCC);
  }

  const agregarCCO = (event) => {
    event.preventDefault();
    setCcoC(currentCCO => [...currentCCO, cco]);
    setCco('');
  }

  const handleDeleteCCO = (deletingItem) => {
    const lisCCO = ccoC.filter((item) => item !== deletingItem);
    setCcoC(lisCCO);
  }

  useEffect(() => {
    setSendCo({...sendCo, to: toC})
  }, [toC])

  useEffect(() => {
    setSendCo({...sendCo, cc: ccC})
  }, [ccC])

  useEffect(() => {
    setSendCo({...sendCo, cco: ccoC})
  }, [ccoC])

  useEffect(() => {
    setSendCo({...sendCo, attachedFile: attach})
  }, [attach])

  useEffect(() => {
    viewWR(id).then((were) => {
      setInfowere(were);
    })
  }, []);

// metodo para convertir files a base64
  const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

// guardar los files en un arreglo
  const handleFileChange = async (e) => {
    const files = e.target.files;

    const temp = [...attach];
    for (let i = 0; i < files.length; i++) {
      const base64 = await convertFileToBase64(files[i]);
      const nombre = files[i].name;

      temp.push({nombreArchivo: nombre, archivo: base64});
    }

    setAttach([...temp]);
  }
  const handleChange = e => {
    const {name, value} = e.target;
    setSendCo({
      ...sendCo,
      [name]: value
    });
  }

  const enviarCorreo = async () => {
    enviarEmail(sendCo).then(() => {
      notify();
      handleCloseT();
      setAttach([]);
    })
  }

  const eliminarUP = () => {
    setAttach([]);
  }

  const mostrarPDF = () => {
    if (id !== undefined && id !== 0)
      setLoading(true);
      getPDFWRId(id).then((pdf) => {
        setLoading(false);
        getPdf('Warehouse Receipt', pdf);
      });
  }

  return (
    <Spinner loading={loading}>
      <Container>
        <h4>Vehicle Receipt Format</h4>
        <hr/>
        <Row>
          <Button style={{width: '120px'}} as={Link} to={`/warehouseReceipt/${infowere?.idOrigen}`} variant="warning">
            <FontAwesomeIcon icon={faLeftLong}/>
            &nbsp;Back
          </Button>
        </Row>
        <Row style={{justifyContent: 'space-evenly'}}>
          <Col xs={12} lg="2">
            <Button variant="success" onClick={handleShowT}>
              <FontAwesomeIcon icon={faEnvelopeCircleCheck}/>
              &nbsp;Send Email
            </Button>
          </Col>
          <Col xs={12} lg="2">
            <Button onClick={mostrarPDF} variant="primary">
              <FontAwesomeIcon icon={faPrint}/>
              &nbsp;Print
            </Button>
          </Col>
        </Row>
        <hr/>
        <Row className="invoice-box">
          <table>
            <tr>
              <td className="title">
                <img width="300" height="100" alt="logo" src={logocolor}/> <br/>
                {infowere?.origenName} <br/>
                {infowere?.direcOri}
              </td>
              <td>
                <Row>
                  <Col xs={12} lg={{span: 8, offset: 4}}>
                    <h4>Vehicle Receipt / Inspection</h4>
                    <tr>
                      <td className="topinfol"><strong>Receipt Number:</strong></td>
                      <td className="topinfor">{infowere?.codigo}</td>
                    </tr>
                    <tr>
                      <td className="topinfol"><strong>Received Date/Time:</strong></td>
                      <td className="topinfor">{infowere?.fecha ? moment(infowere?.fecha).format('MM-DD-yyyy hh:mm:ss') : '---'}</td>
                    </tr>
                    <tr>
                      <td className="topinfol"><strong>Received by:</strong></td>
                      <td className="topinfor">{infowere?.recivedBy}</td>
                    </tr>
                  </Col>
                </Row>
              </td>
            </tr>
            <br/>
          </table>
          <table className="vehiculoinfo">
            <tr className="heading">
              <td>Shipper Information</td>
              <td>Consignee Information</td>
            </tr>
            <tr className="details">
              <td>
                {infowere?.subName} <br/>
                {infowere?.direcSub}
              </td>
              <td>
                {infowere?.impName} <br/>
                {infowere?.direcImp}
              </td>
            </tr>
          </table>
          <table>
            <tr className="heading">
              <td>Inland Carrier and Supplier information</td>
            </tr>
          </table>
          <table>
            <tr className="detailsinfo">
              <td className="label">AUCTION</td>
              <td className="info">{infowere?.auction}</td>
              <td className="label">POA</td>
              <td className="info">{infowere?.poaWR}</td>
            </tr>
            <tr className="detailsinfo">
              <td className="label">LOTE</td>
              <td className="info">{infowere?.lote}</td>
              <td className="label">PHOTOS</td>
              <td className="info">{infowere?.fotosR}</td>
            </tr>
            <tr className="detailsinfo">
              <td className="label">TITULO</td>
              <td className="info">{infowere?.titulo}</td>
              <td className="label">COLOR</td>
              <td className="info">{infowere?.color}</td>
            </tr>
            <tr className="detailsinfo">
              <td className="label">KEY</td>
              <td className="info">{infowere?.numLlave}</td>
              <td className="label">DESTINATION</td>
              <td className="info">{infowere?.aduanaCom}</td>
            </tr>
            <tr className="detailsinfo">
              <td className="label">FACTURA</td>
              <td className="info">{infowere?.tieneFactura ? "SI" : "NO"}</td>
              <td className="label">BIO-H</td>
              <td className="info">{infowere?.bioH}</td>
            </tr>
          </table>
          <table className="vehiculoinfo">
            <tr className="heading">
              <td>
                Notes
              </td>
              <td>Applicable Charges</td>
            </tr>
            <tr className="details">
              <td>
                {infowere?.notas}
              </td>
              <td>
                <Row>
                  <Col lg="6">
                    <p className="mb-0">FLETE:</p>
                    <p className="mb-0">GRUA:</p>
                    <ShowWhen show={infowere?.costoAdicional && infowere?.costoAdicional !== 0}>
                      <p className="mb-0">{infowere?.descripcionCostoAdicional && infowere?.descripcionCostoAdicional !== "" ? infowere?.descripcionCostoAdicional.toUpperCase() : "Costo Adicional"}:</p>
                    </ShowWhen>
                  </Col>
                  <Col lg="6">
                    <p className="mb-0">USD {infowere?.flete} {infowere.fleteOrigen}</p>
                    <p className="mb-0">USD {infowere?.grua}</p>
                    <ShowWhen show={infowere?.costoAdicional && infowere?.costoAdicional !== 0}>
                      <p className="mb-0">USD {infowere?.costoAdicional}</p>
                    </ShowWhen>
                  </Col>
                </Row>
              </td>
            </tr>
          </table>
          <table className="vehiculoinfo">
            <tr className="headinginfo">
              <td style={{width: "200px"}}>Quantity</td>
              <td>Dimensions</td>
              <td>Description</td>
              <td style={{width: "150px"}}>Weight</td>
              <td style={{width: "150px"}}>Volume</td>
            </tr>
            <tr className="details">
              <td>{infowere?.quantity}-{infowere?.tipoMerc}</td>
              <td>
                Largo: {infowere?.length} <br/>
                Ancho: {infowere?.width} <br/>
                Alto: {infowere?.heigth}
              </td>
              <td>
                Vehiculo: {infowere?.vehiculoName} <br/>
                VIN: {infowere?.vin}
              </td>
              <td>
                lb: {infowere?.weight} <br/>
                kg: {infowere?.weightKg}
              </td>
              <td>
                ft3: {infowere?.volume}
              </td>
            </tr>

          </table>
          <ToastContainer/>
        </Row>
        <>
          <Modal
            show={showT}
            onHide={handleCloseT}
            backdrop="static"
            keyboard={false}
            className="emailsoli"
          >
            <Modal.Header closeButton>
              <Modal.Title>Enviando WR</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Row>
                <Col xs={12} lg="2">
                  <Button onClick={agregarCA} variant="secondary">+CC, CA</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarFL} variant="secondary">+CC, FL</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarSV} variant="secondary">+CC, SV</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarGT} variant="secondary">+CC, GT</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarCR} variant="secondary">+CC, CR</Button>
                </Col>
              </Row>

              <Form.Label><strong>Para:</strong></Form.Label>
              {toC?.map((item, index) => {
                return <li key={index}>{item}
                  <button onClick={() => handleDeleteTO(item)}>x</button>
                </li>
              })}
              <Row>
                <Col xs={12} lg="8">
                  <Form.Control value={to} onChange={(e) => setTo(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarTo} variant="primary">Agregar</Button>
                </Col>
              </Row>
              <Row>
                <Form.Label><strong>CC:</strong></Form.Label>
                {ccC?.map((item, index) => {
                  return <li key={index}>{item}
                    <button onClick={() => handleDeleteCC(item)}>x</button>
                  </li>
                })}
                <Col xs={12} lg="8">
                  <Form.Control value={cc} onChange={(e) => setCc(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarCC} variant="primary">Agregar</Button>
                </Col>
              </Row>
              <Row>
                <Form.Label><strong>CCO:</strong></Form.Label>
                {ccoC?.map((item, index) => {
                  return <li key={index}>{item}
                    <button onClick={() => handleDeleteCCO(item)}>x</button>
                  </li>
                })}
                <Col xs={12} lg="8">
                  <Form.Control value={cco} onChange={(e) => setCco(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarCCO} variant="primary">Agregar</Button>
                </Col>
              </Row>
              <Form.Label><strong>Asunto:</strong></Form.Label>
              <Form.Control name="subject" onChange={handleChange} value={sendCo?.subject} as="textarea" rows={2}/>

              <Form.Label><strong>Archivos Adjuntos</strong></Form.Label> <br/>
              {attach?.map((attach, key) => {
                return <li key={key}>{attach.id}{attach.nombreArchivo}</li>
              })}

              <Form.Label><strong>Mensaje</strong></Form.Label>
              <Form.Control disabled readOnly name="body"
                            value="**El mensaje esta predeterminado por el sistema con los datos ingresados en el WR**
        - Si agrega nuevos correos asegurarse esten bien escritos y aparezcan en la lista de agregados" as="textarea"
                            rows={3}/>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseT}>
                Cancelar
              </Button>
              <Button onClick={enviarCorreo} variant="primary"><FontAwesomeIcon icon={faPaperPlane}/> Enviar</Button>
            </Modal.Footer>
          </Modal>
        </>
      </Container>
    </Spinner>
  )
}

export default VisualizarWR;
